import {getParams} from 'app/assets/js/util.js';
import { renderController } from 'BootQuery/Assets/js/BootQuery.js';

export default function createModal(properties, onShown) {
	const originalRoute = {...window.Bootstrap.bootquery};
	let modalLink = properties.modalLink;
	let modalModule = properties.modalModule || null;

	let linkparts = modalLink.split('/').filter(function(e) {
		return e != '';
	});
	let controller = linkparts[0];
	let method = linkparts[1];
	let params = getParams(modalLink);

	console.warn('Loading JSON for ' + controller + '/' + method);
	getJSON('get', controller, method, params, true, function(data) {
		if (data && data.bootquery) {
			data.bootquery.isModal = true;
		}

		let templateModule = modalModule || data.bootquery.moduleName;
		getTemplate([data.bootquery.template, 'modal'], templateModule).then(function(fetchedTemplates) {
			let modalTemplate = fetchedTemplates.modal;
			let template = fetchedTemplates[data.bootquery.template];
			let rendered = $(handlebarsRender(template, data));

			let modalHeading = $(rendered).find(properties.modalHeading);
			if (modalHeading.find('.section-title')) {
				modalHeading.find('.section-title').append('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>');
			} else {
				modalHeading.append('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>');
			}

			let renderData = $.extend({}, properties);
			renderData.modalHeading = modalHeading.html();
			renderData.modalBody = rendered.find(properties.modalBody).html();
			renderData.modalFooter = rendered.find(properties.modalFooter).html();

			let modalWindow = handlebarsRender(modalTemplate, renderData);
			let modal = $(modalWindow).modal();

			if (properties.modalSummoner) {
				modal.data('modal-summoner', properties.modalSummoner);
			}

			if (properties.modalSelectUpdate) {
				$(modal).attr('data-modal-select-update', properties.modalSelectUpdate);
			}

			$(modal).off('shown.bs.modal').on('shown.bs.modal', function(e) {
				activateElements('.modal-dialog', data);

				if(typeof onShown == 'function') {
					onShown(modal);
				}
			});
			$(modal).off('hide.bs.modal').on('hide.bs.modal', function(e) {
				if (properties.modalTarget) {
					console.warn('TODO: Implement modal-targets');
				} else if (properties.modalReload) {
					window.targetElement = '.bq-content';
					renderController(
						'get',
						originalRoute.controller,
						originalRoute.method,
						originalRoute.parameters,
						originalRoute.moduleName
					);
				}
			});

			// Modal is just hidden when dismissed and I don't want trouble with the leftovers
			$(modal).off('hidden.bs.modal').on('hidden.bs.modal', function(e) {
				$(this).closest('.modal').remove();
				if ($('body > .modal.in').length) {
					$('body').addClass('modal-open'); // Fix scrolling if last modal not closed
				}
			});
		});
	});
}
