<template>
	<div class="card">
		<template v-if="loaded">
			<div class="card-header d-flex module-header">
				<div>
					<span class="fas fa-map-marker-alt"></span>
					Trackers
				</div>

				<div class="ml-auto">
					<AddButton/>
				</div>
			</div>
			<div class="card-body">
				<Alerts :alerts="alerts"/>
				<hr>
				<Trackers :trackers="trackers"/>
			</div>
		</template>
		<div class="text-center" v-else>
			<div class="spinner-grow m-3"></div>
		</div>
	</div>
</template>

<script>
import Alerts from './Alerts.vue';
import Trackers from './Trackers.vue';
import AddButton from './actions/AddButton.vue';

export default {
	components: {
		Alerts,
		Trackers,
		AddButton
	},
	data() {
		return {
			loaded: false,
			trackers: null,
			alerts: null,
			createdEventHandle: null,
			updatedEventHandle: null
		};
	},
	methods: {
		onNewTracker(tracker) {
			const alreadyExists = this.trackers.some(
				existing => existing.ID === tracker.ID
			);
			if (!alreadyExists) {
				this.trackers.push(tracker);
			}
		},
		onTrackerUpdated(updatedTracker) {
			const trackerIdx = this.trackers.findIndex(
				oldTracker => oldTracker.ID === updatedTracker.ID
			);
			if (trackerIdx !== -1) {
				this.$set(this.trackers, trackerIdx, updatedTracker);
			}
		}
	},
	created() {
		const module = window.BootQuery.getModuleInstance('GsmTrackers');
		Promise.all([module.alerts(), module.trackers()]).then(
			([alerts, trackers]) => {
				this.alerts = alerts;
				this.trackers = trackers;
				this.loaded = true;
			}
		);

		this.createdEventHandle = module.subscribeWebSocket(
			'gsmTrackers/trackerCreated',
			this.onNewTracker.bind(this)
		);
		this.updatedEventHandle = module.subscribeWebSocket(
			'gsmTrackers/trackerUpdated',
			this.onTrackerUpdated.bind(this)
		);
	},
	beforeDestroy() {
		if (this.createdEventHandle) {
			this.unsubscribeWebSocket(this.createdEventHandle);
			this.unsubscribeWebSocket(this.updatedEventHandle);
		}
	}
};
</script>

<style scoped lang="scss">
.module-header {
	align-items: center;
}
</style>
