<template>
	<div class="input-group bg-light">
		<div class="input-group-prepend border-top">
			<!--  TODO: MMS?-->
			<!-- <button class="btn btn-link">
            <span class="fas fa-paperclip"></span>
			</button>-->
		</div>
		<textarea
			ref="textarea"
			rows="1"
			class="form-control border-bottom-0"
			@keydown="onKey"
			v-model="content"
		></textarea>
		<div class="input-group-append">
			<button @click.prevent="submit" :disabled="!canSubmit" class="btn btn-success rounded-0">
				<span class="fas fa-arrow-right"></span>
			</button>
		</div>
	</div>
</template>

<script>
import autosize from 'autosize';

export default {
	props: {
		disabled: Boolean
	},
	data() {
		return {
			content: ''
		};
	},
	computed: {
		canSubmit() {
			return !this.disabled && this.content.trim().length > 0;
		}
	},
	methods: {
		onKey(ev) {
			// Submit on return, but let the newline be inserted on shift+return
			if (ev.keyCode === 13 && !ev.shiftKey) {
				ev.preventDefault();
				this.submit();
			}
		},
		submit() {
			if (this.canSubmit) {
				this.$emit('submit', this.content);
				this.content = '';
			}
		}
	},
	mounted() {
		autosize(this.$refs.textarea);
	},
	updated() {
		autosize.update(this.$refs.textarea);
	}
};
</script>

<style lang="scss" scoped>
textarea {
	max-height: 160px;
}
</style>
