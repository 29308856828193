module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPerson : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "	&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showInfo : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"far fa-id-card\"></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCompany : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"far fa-building\"></span>\n	";
},"7":function(container,depth0,helpers,partials,data) {
    return "		<span>\n			<div class=\"popover-content-element\" hidden>\n				<span class=\"fa fa-lg fa-spinner fa-spin\"></span>\n			</div>\n			<a href=\"#\" class=\"noparse btn btn-sm btn-secondary py-0\" data-toggle=\"popover\" data-container=\"body\" data-trigger=\"click\" data-popover-content-element=\"true\" data-html=\"true\" data-customer-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.typedID : depth0), depth0))
    + "\">\n				<span class=\"fa fa-eye\"></span>\n			</a>\n		</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "	&nbsp;\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ID : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})