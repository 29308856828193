module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"nav nav-tabs card-header-tabs flex-row\">\n    "
    + ((stack1 = container.invokePartial(partials.tabs,(data && data.root),{"name":"tabs","hash":{"params":((stack1 = (depth0 != null ? depth0.resultdata : depth0)) != null ? stack1.pagination_params : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</ul>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})