module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.state : depth0), depth0),{"name":"state","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=helpers.blockHelperMissing, alias4=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <div class=\"panel panel-default\" data-state=\""
    + alias2(alias1((depth0 != null ? depth0.state : depth0), depth0))
    + "\">\n\n        <div class=\"panel-heading\">\n\n            <span class=\"glyphicon glyphicon-th\"></span>&nbsp;Biranje\n            <button type=\"button\" class=\"close panel-minimize\">&minus;</button>\n\n        </div>\n\n        <form role=\"form\" method=\"get\" action=\"/dialer/dial\" data-target=\"#dialer-container\">\n\n            <span class=\"dialer-status dialing\" style=\""
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.dialing : depth0), depth0),{"name":"dialing","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n\n                <div class=\"panel-body dialer-body\" style=\"height: 400px; background: radial-gradient(ellipse at center, rgba(130,140,149,1) 39%,rgba(40,52,59,1) 100%); text-align: center;\">\n\n                    <span style=\"color: white;\">\n\n                        <span style=\"font-size: 24px;\">"
    + alias2(helpers.tr.call(alias4,"label.pick_up",{"name":"tr","hash":{},"data":data}))
    + "<br><br><br></span>\n                        <span class=\"icomoon icomoon-arrow-up\" style=\"font-size: 50px;\"></span>\n                        <br>\n                        <span class=\"icomoon icomoon-phone-hang-up\" style=\"font-size: 75px; margin-top: 40px;\"></span>\n                        <br><br><br>\n\n                        <span style=\"font-size: 24px;\">"
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.Calls : depth0), depth0),{"name":"Calls","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n                        <br>\n                        <strong>"
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.Calls : depth0), depth0),{"name":"Calls","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</strong>\n\n                    </span>\n\n                </div>\n\n            </span>\n\n            <span class=\"dialer-status ringing\" style=\""
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.ringing : depth0), depth0),{"name":"ringing","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n\n                <div class=\"panel-body dialer-body\" style=\"height: 400px; background: radial-gradient(ellipse at center, rgba(249,198,103,1) 0%,rgba(247,150,33,1) 100%); text-align: center;\">\n\n                    <span style=\"color: white;\">\n\n                        <span style=\"font-size: 24px;\">"
    + alias2(helpers.tr.call(alias4,"label.incoming_call",{"name":"tr","hash":{},"data":data}))
    + "<br><br><br></span>\n                        <span class=\"icomoon icomoon-bell2\" style=\"font-size: 75px; margin-top: 40px;\"></span>\n                        <br><br><br><br><br><br>\n                        <span style=\"font-size: 24px;\">"
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.Calls : depth0), depth0),{"name":"Calls","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n                        <br>\n                        <strong>"
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.Calls : depth0), depth0),{"name":"Calls","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</strong>\n\n                    </span>\n\n                </div>\n\n            </span>\n\n            <span class=\"dialer-status call\" style=\""
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.call : depth0), depth0),{"name":"call","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n\n                <div class=\"panel-body dialer-body\" style=\"background: radial-gradient(ellipse at center, rgba(157,213,58,1) 0%,rgba(161,213,79,1) 18%,rgba(128,194,23,1) 56%,rgba(124,188,10,1) 100%); text-align: center;\">\n\n                    <span style=\"color: white; text-align: center;\">\n\n                        <span style=\"font-size: 24px;\">"
    + alias2(helpers.tr.call(alias4,"label.in_call",{"name":"tr","hash":{},"data":data}))
    + "<br><br><br></span>\n                        <span class=\"icomoon icomoon-phone\" style=\"font-size: 75px; margin-top: 40px;\"></span>\n                        <br><br><br><br>\n                        <span style=\"font-size: 24px;\"><span class=\"glyphicon glyphicon-phone\"></span> Bojan Nemčić</span>\n                        <br>\n                        <strong>091 7919832</strong>\n                        <br><br>\n\n                        <div style=\"display: inline-block; text-align: center; float: left; margin-left: 20px;\">\n\n                            <a class=\"btn btn-danger\" style=\"font-size: 14px;\">\n\n                                <span class=\"icomoon icomoon-phone-hang-up\"></span>\n                                &nbsp;&nbsp;\n                                "
    + alias2(helpers.tr.call(alias4,"label.hang_up",{"name":"tr","hash":{},"data":data}))
    + "\n                            </a>\n\n                        </div>\n\n                        <span style=\"display: inline-block; text-align: center;\">\n\n                            <a class=\"btn btn-warning\" style=\"font-size: 14px;\">\n\n                                <span class=\"icomoon icomoon-clock\"></span>\n                                &nbsp;&nbsp;\n                                "
    + alias2(helpers.tr.call(alias4,"label.put_on_hold",{"name":"tr","hash":{},"data":data}))
    + "\n                            </a>\n\n                        </span>\n\n                        <span style=\"display: inline-block; text-align: center; float: right; margin-right: 20px;\">\n\n                            <a class=\"btn btn-primary\" style=\"font-size: 14px;\">\n\n                                <span class=\"glyphicon glyphicon-transfer\"></span>\n                                &nbsp;&nbsp;"
    + alias2(helpers.tr.call(alias4,"label.transfer",{"name":"tr","hash":{},"data":data}))
    + "\n\n                            </a>\n\n                        </span>\n\n                    </span>\n\n                    <br><br>\n\n                </div>\n\n            </span>\n\n            <span class=\"dialer-status calling\" style=\""
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? depth0.calling : depth0), depth0),{"name":"calling","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n\n                <div class=\"panel-body dialer-body\" style=\"height: 400px; background: radial-gradient(ellipse at center, rgba(197,222,234,1) 0%,rgba(138,187,215,1) 31%,rgba(6,109,171,1) 100%); text-align: center;\">\n\n                    <span style=\"color: white;\">\n\n                        <span style=\"font-size: 24px;\">"
    + alias2(helpers.tr.call(alias4,"label.calling",{"name":"tr","hash":{},"data":data}))
    + "<br><br><br></span>\n                        <span class=\"icomoon icomoon-feed\" style=\"font-size: 75px; margin-top: 40px;\"></span>\n                        <br><br><br><br><br><br>\n                        <span style=\"font-size: 24px; font-weight: bold;\">091 7919832</span>\n\n                    </span>\n\n                </div>\n\n            </span>\n\n        </form>\n\n        <div class=\"panel-footer\">\n\n            <span class=\"dialer-status phone-ready\">"
    + alias2(helpers.tr.call(alias4,"label.idle",{"name":"tr","hash":{},"data":data}))
    + "</span>\n            <span class=\"dialer-status wait-pickup\" style=\"display: none;\">"
    + alias2(helpers.tr.call(alias4,"label.waiting_to_pick_up",{"name":"tr","hash":{},"data":data}))
    + "</span>\n            <span class=\"dialer-status phone-ringing\" style=\"display: none;\">"
    + alias2(helpers.tr.call(alias4,"label.ringing",{"name":"tr","hash":{},"data":data}))
    + "</span>\n            <span class=\"dialer-status in-call\" style=\"display: none;\">"
    + alias2(helpers.tr.call(alias4,"label.in_call",{"name":"tr","hash":{},"data":data}))
    + "</span>\n\n        </div>\n\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "display: none;";
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.CallerIDName : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.ConnectingCalls : depth0), depth0),{"name":"ConnectingCalls","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.ConnectingCalls : depth0), depth0),{"name":"ConnectingCalls","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.CallerPhoneNumber : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"col-md-5 col-lg-12 col-sm-5 col-xs-12 visible-xs visible-sm visible-md visible-lg\" id=\"dialer-container\">\n\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.module : depth0), depth0),{"name":"module","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true})