import Module from 'BootQuery/Assets/js/module';
import Vue from 'BootQuery/Assets/js/vue.js';
import CallCenterComponent from '../components/CallCenter.vue';
import { renderController } from 'BootQuery/Assets/js/BootQuery.js';

export default class Callcenter extends Module {
	constructor() {
		super();

		this.canUseAdvancedFeatures = false;

		this.lastStatus = {
			Pauses: [],
			Calls: []
		};
		this.callTimers  = {};
		this.pauseTimers = {};
		this.queueTimers = {};
		this.paused = false;
		this.subscribedExtensions = [];
		this.statistics = {};
		this.queueCalls = [];
		this.extensions = {};
		this.extension  = '';
		this.ownQueues  = [];
		this.pauses     = [];
		this.agents     = {};
		this.lastNewCustomMessageSubscribed = false;
	}

	timeStringToSeconds(time) {
		if(time) {
			let parts = time.split(':');

			return parseInt(parts[0], 10) * 3600 + parseInt(parts[1], 10) * 60 + parseInt(parts[2], 10);
		} else {
			return 0;
		}
	}

	init(data) {
		super.init();

		this.subscribeWebSocket('PeerStatusChanged',  this.onPeerStatusChanged.bind(this));
		this.subscribeWebSocket('QueueStatistics',    this.onStatisticsUpdate.bind(this));
		this.subscribeWebSocket('QueueMemberPause',   this.onMemberPaused.bind(this));
		this.subscribeWebSocket('QueueMemberUpdate',  this.onMemberUpdated.bind(this));
		this.subscribeWebSocket('QueueCallerJoin',    this.onCallerJoin.bind(this));
		this.subscribeWebSocket('QueueCallerLeave',   this.onCallerLeave.bind(this));
		this.subscribeWebSocket('QueueCallerAbandon', this.onCallerAbandon.bind(this));

		this.extension              = data.bootquery.session.extension;
		this.canUseAdvancedFeatures = data.modules.Callcenter.canUseAdvancedFeatures;
		this.ownQueues              = data.modules.Callcenter.ownQueues;
	}

	activateElements(target, _data) {
		const $container = target.findElement('#callcenter-container');
		if ($container.length) {
			this.renderCallCenter($container);
		}

		if (target.findElement('#cc-custom-messages-form').length) {
			this.activateCustomMessages(target);
		} else {
			this.unsubscribeWebSocketAll('newCustomMessage');
			this.lastNewCustomMessageSubscribed = false;
		}
	}

	activateCustomMessages(target) {
		target
			.findElement('#callcenter-record-new-message')
			.ev('click.callcenter', ev => {
				ev.preventDefault();
				const dialer = window.BootQuery.getModuleInstance('Dialer');
				dialer.dial('*31');
			});

		target.findElement('.callcenter-delete-message')
			.ev('click.callcenter', ev => {
				ev.preventDefault();
				const $row = $(ev.currentTarget).closest('tr');
				$row.find('input[type=hidden][name$="[$deleted]"]').val('true');
				$row.hide('fast');
			});

		target.findElement('.custom-message-play').each(
			(_i, el) => this.activateCustomMessagePlayer($(el))
		);

		target.findElement('.callcenter-rename-message')
			.ev('click.callcenter', ev => {
				ev.preventDefault();
				this.renameMessage($(ev.currentTarget));
			});

		if (!this.lastNewCustomMessageSubscribed) {
			this.subscribeWebSocket('newCustomMessage', () => {
				renderController('get', 'callcenter', 'customMessages', {}, 'Callcenter');
			});
			this.lastNewCustomMessageSubscribed = true;
		}
	}

	renameMessage($btn) {
		const messageID = $btn.data('messageid');
		const name = $btn.siblings('input[name$="[$name]"]').val().trim();
		$btn.siblings('input[name$="[$renamed]"]').val('true');
		$(`.note-name[data-message-id="${messageID}"]`).text(name);
	}

	activateCustomMessagePlayer($btn) {
		const $audio = $btn.siblings('audio');
		const audio = $audio[0];
		$audio.ev('play.callcenter', () => {
			$btn.children('.fa')
				.removeClass('fa-play fa-stop')
				.addClass('fa-stop');
		});
		$audio.ev('pause.callcenter, ended.callcenter', () => {
			$btn.children('.fa')
				.removeClass('fa-play fa-stop')
				.addClass('fa-play');
		});

		$btn.ev('click.callcenter', () => {
			if (audio.paused || audio.ended) {
				audio.play();
			} else {
				audio.pause();
				audio.currentTime = 0;
			}
		});
	}

	renderCallCenter($container) {
		const container = $container[0];
		if(container.dataset.activated) {
			return; // Don't double-render
		}
		container.dataset.activated = true;
		setTimeout(() => {
			this.component = new Vue({
				el: container,
				render: h => h(CallCenterComponent)
			});
		}, 0);
	}

	pauseTimer(extension) {
		let instance = window.BootQuery.moduleInstances.Callcenter.pauseTimers[extension];
		let module   = window.BootQuery.moduleInstances.Callcenter;

		if(instance) {
			let time = moment.unix(instance.currentOffset + 1);
			let elem = $('.agent-row[data-extension="' + extension + '"] .call-time');

			instance.currentOffset = time.unix();

			if(!module.callTimers[extension]) {
				elem.text(time.utc().format('HH:mm:ss'));
			}

			if(time.unix() > instance.maxTime) {
				elem.removeClass('badge-light').removeClass('badge-yellow').addClass('badge-danger');
			}
		}
	}

	callTimer(extension) {
		let instance = window.BootQuery.moduleInstances.Callcenter.callTimers[extension];

		if(instance) {
			let time = moment.unix(instance.currentOffset + 1);
			let elem = $('.agent-row[data-extension="' + extension + '"] .call-time');

			elem.text(time.utc().format('HH:mm:ss'));
			instance.currentOffset = time.unix();
		}
	}

	queueCallTimer(channel) {
		let instance = window.BootQuery.moduleInstances.Callcenter.queueTimers[channel];

		if(instance) {
			let time = moment.unix(instance.currentOffset + 1);
			let elem = $('.queue-call-row[data-channel="' + channel + '"] .queue-time');

			elem.text(time.utc().format('HH:mm:ss'));
			instance.currentOffset = time.unix();
		}
	}

	onMemberPaused(data) {
		this.emit('MemberPaused', data);
	}

	onMemberUpdated(data) {
		if($('#callcenter-display-card').length == 0) {
			return;
		}

		// Re-render the entire agent section, no point in trying to insert the record only
		getTemplate('display', 'Callcenter').then((template) => {
			$.get('/callcenter/display.json', {}, (status) => {
				this.extensions = status.result.extensions;

				status.tr = function() { return tr; };

				let rendered = handlebarsRender(template, status);
				$('#extension-list').replaceWith($(rendered).find('#extension-list'));

				activateElements('#extension-list', status);
			});
		});
	}

	onPeerStatusChanged(data) {
		this.emit('PeerStatusChanged', data);
	}

	onCallerJoin(data) {
		this.emit('CallerJoin', data);
	}

	onCallerLeave(data) {
		this.emit('CallerLeave', data);
	}

	onCallerAbandon(data) {
		if($('#queue-calls').length) {
			getTemplate('queueCalls', 'Callcenter').then((template) => {
				data.tr = function() { return tr; };
				let rendered = handlebarsRender(template, {result: {calls: data.Calls}});
				$('#queue-calls').replaceWith(rendered);
			});
		}
	}

	onStatisticsUpdate(data) {
		this.emit('StatisticsUpdate', data);
		if($('#callcenter-display-card').length == 0) {
			return;
		}
	}

	onUserStatusChanged(data) {
		getTemplate('userStatusCell', 'Callcenter').then((template) => {
			let rendered = handlebarsRender(template, {context: data});

			$(`.callcenter-user-status-cell[data-userid="${data.userID}"]`).replaceWith($(rendered));
		});
	}

	static canHandleRoute(route) {
		return route.startsWith('/callcenter/display');
	}

	handleRoute(route) {
		if (route.startsWith('/callcenter/display')) {
			$(document).trigger(
				'renderController',
				[window.targetElement, window.Bootstrap]
			);
			const tgt = $('<div/>', {id: 'callcenter-container'});
			$(window.targetElement).html(tgt);
			this.renderCallCenter(tgt);
			$(document).trigger(
				'activateElements',
				[$(window.targetElement), window.Bootstrap]
			);
		} else {
			throw new Error(`Don't know how to handle route '${route}'`);
		}
	}
}
