module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<base href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.pdf_base : stack1), depth0))
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "\n	<link rel=\"stylesheet\" href=\""
    + alias2(helpers.resolveAssetName.call(alias1,"vendor.css",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\">\n	<link rel=\"stylesheet\" href=\""
    + alias2(helpers.resolveAssetName.call(alias1,"fonts.css",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\">\n	<link rel=\"stylesheet\" href=\""
    + alias2(helpers.resolveAssetName.call(alias1,"main.css",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.modules : depth0)) != null ? stack1.User : stack1)) != null ? stack1.themes : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.modules : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<link rel=\"canonical\" href=\"http://clickvox/"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.method : stack1), depth0))
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.json_data : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	<script async src=\""
    + alias2(helpers.resolveAssetName.call(alias1,"vendor.js",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\"></script>\n	<script async src=\""
    + alias2(helpers.resolveAssetName.call(alias1,"main.js",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\"></script>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "		<link rel=\""
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "stylesheet\" href=\""
    + alias2(helpers.resolveAssetName.call(alias1,helpers.concat.call(alias1,"theme_",(depth0 != null ? depth0.name : depth0),".css",{"name":"concat","hash":{},"data":data}),{"name":"resolveAssetName","hash":{},"data":data}))
    + "\" class=\"theme-stylesheet\" title=\""
    + alias2(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "alternate ";
},"7":function(container,depth0,helpers,partials,data) {
    return "		<link rel=\"stylesheet\" href=\""
    + container.escapeExpression(helpers.resolveAssetName.call(depth0 != null ? depth0 : (container.nullContext || {}),"theme_default.css",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\" class=\"theme-stylesheet\" title=\"default\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.assets : depth0)) != null ? stack1.scripts : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.assets : depth0)) != null ? stack1.stylesheets : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "			<script type=\"text/javascript\" src=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\"></script>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "			<link type=\"text/css\" rel=\"stylesheet\" media=\"screen\" href=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\">\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<script async type=\"text/javascript\" id=\"initial_json\">\n		window.Bootstrap = "
    + ((stack1 = container.lambda((depth0 != null ? depth0.json_data : depth0), depth0)) != null ? stack1 : "")
    + "\n	</script>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "		<link rel=\"stylesheet\" type=\"text/css\" href=\"/app/assets/css/report.css\">\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.report : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "print"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.pdf : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return " pdf";
},"22":function(container,depth0,helpers,partials,data) {
    return "no-pdf";
},"24":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.bodyClass : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html>\n\n<html>\n\n<head>\n\n	<meta charset=\"utf-8\">\n	<meta name=\"viewport\" content=\"width=device-width, user-scalable=yes, initial-scale=1, maximum-scale=1\">\n	<link rel=\"icon\" type=\"image/png\" href=\"/app/assets/img/AsteriskGradient.png\">\n	<title>ClickVOX</title>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.pdf_base : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</head>\n\n<body class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bootquery : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bodyClass : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n";
},"useData":true})