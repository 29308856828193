module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <input type=\"hidden\" name=\"new\" value=\"true\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ID : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <input type=\"hidden\" name=\"ID\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.invokePartial(partials.form,(depth0 != null ? depth0.formBinding : depth0),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    Nisu definirana polja uz ovaj događaj\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"button\" class=\"btn btn-success event-modal-save\">\n                        <span class=\"fa fa-check\"></span> "
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                    </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">"
    + alias2(helpers.tr.call(alias1,"label.add",{"name":"tr","hash":{},"data":data}))
    + " "
    + alias2(alias3((depth0 != null ? depth0.typeName : depth0), depth0))
    + "</h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <div class=\"modal-body\" data-form=\"customEvent\">\n                <input type=\"hidden\" name=\"eventTypeID\" value="
    + alias2(alias3((depth0 != null ? depth0.typeID : depth0), depth0))
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["new"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.formBinding : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias2(helpers.tr.call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.formBinding : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true})