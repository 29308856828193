<template>
    <router-link
        :to="`/${$route.params.accountID}/threads/editor-${uuid}`"
        tag="a"
        class="mail-thread-list-item noparse list-group-item list-group-item-action seen"
        :class="{active: isSelected}"
        style="text-overflow: ellipsis;"
    >
        <div class="row">
            <div class="col text-truncate" style="overflow: hidden;">
                <small class="text-nowrap">
                    <strong>
                        <span class="fas fa-arrow-right"></span>
                        <span>{{addressPreview}}</span>
                    </strong>
                </small>
            </div>
            <div class="col-auto">
                <span class="badge badge-success">Nova poruka</span>
            </div>
        </div>
        <div>
            <span class="mail-message-subject">{{subject}}</span>
        </div>
        <div>
            <small>{{preview}}</small>
        </div>
    </router-link>
</template>

<script>
export default {
	props: {
		uuid: String,
		subject: String,
		toAddresses: Array,
		preview: String
	},
	computed: {
		addressPreview() {
			return this.toAddresses.join(', ');
		},
		isSelected() {
			return this.$route.params.threadID === `editor-${this.uuid}`;
		}
	}
};
</script>

<style>
</style>
