module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"panel-heading\" id=\"phonebook-"
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-header\">\n                    <span class=\"glyphicon glyphicon-edit\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"label.edit_entry",{"name":"tr","hash":{},"data":data}))
    + "\n                    <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">×</button>\n                </div>\n\n                <div class=\"panel-body\">\n                    <div id=\"phonebook-"
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-container\">\n                        <form id=\"phonebook-modify-"
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-form"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.edit_whatever_id : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" class=\"form-horizontal\" action=\""
    + alias2(alias1((depth0 != null ? depth0.form_url : depth0), depth0))
    + "\" method=\"post\" role=\"form\">\n                            <input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1((depth0 != null ? depth0.form_action : depth0), depth0))
    + "\" />\n                            <input type=\"hidden\" name=\"edited_what\" value=\""
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "\" />\n\n"
    + ((stack1 = helpers["with"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.form)) && stack1.entry),{"name":"with","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </form>\n                    </div>\n                </div>\n\n                <div class=\"panel-footer\">\n                    <div class=\"row\">\n                        <div class=\"col-xs-12\" style=\"float: left;\">\n"
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                <div class=\"save-button pull-right\">\n                                    <button type=\"submit\" class=\"btn btn-success pull-right\" form=\"phonebook-modify-"
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-form"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.edit_whatever_id : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                        <span class=\"glyphicon glyphicon-ok\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                                    </button>\n                                    <span class=\"save-status pull-right\" style=\"padding: 7px 15px;\" data-form=\"phonebook-modify-"
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-form"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.edit_whatever_id : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                    </span>\n                                </div>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.edit_whatever_id : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + ((stack1 = container.invokePartial(partials.form,depth0,{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "                                <div class=\"pull-left\">\n                                    <a href=\""
    + alias1(container.lambda((depth0 != null ? depth0.list_url : depth0), depth0))
    + "\" class=\"btn btn-info form-cancel\">\n                                        <span class=\"glyphicon glyphicon-chevron-left\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.back_to_list",{"name":"tr","hash":{},"data":data}))
    + "\n                                    </a>\n                                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "                                <div class=\"pull-right\">\n                                    <a href=\""
    + alias1(container.lambda((depth0 != null ? depth0.list_url : depth0), depth0))
    + "\" class=\"btn btn-danger\" data-dismiss=\"modal\">\n                                        <span class=\"glyphicon glyphicon-remove\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n                                    </a>\n                                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"content-wrapper\">\n\n<div class=\"container-fluid\" id=\"main-container\">\n\n    "
    + ((stack1 = container.invokePartial(partials.header,depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"row-fluid\">\n\n        "
    + ((stack1 = container.invokePartial(partials.menu,depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"col-md-12 col-lg-12 bq-content bq-default\">\n\n            <div class=\"panel panel-default embedable\">\n\n"
    + ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.bootquery : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n\n        </div>\n\n    </div>\n\n</div>\n\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})