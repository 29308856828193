module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <option "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.extension : depth0), depth0))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"local-extension-settings row\">\n    <div class=\"col-12\">\n        <div class=\"form-group row\">\n            <label class=\"col-12 col-form-label col-md-2 col-form-label control-label text-left text-md-right\" for=\"rememberExtension\">Zapamti zadnju ekstenziju: </label>\n            <div class=\"col-12 col-md-10 col-form-label\">\n                <input type=\"hidden\" name=\"localDevice[rememberExtension]\" value=\"false\">\n                <div class=\"custom-control custom-checkbox\">\n                    <input type=\"checkbox\" name=\"localDevice[rememberExtension]\" value=\"true\" class=\"custom-control-input\" id=\"rememberExtension\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rememberExtension : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    <label class=\"custom-control-label\" for=\"rememberExtension\">&zwnj;</label>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-12\">\n        <div class=\"form-group row\">\n            <label class=\"col-12 col-form-label col-md-2 col-form-label control-label text-left text-md-right\" for=\"lockExtension\">Zaključaj ekstenziju: </label>\n            <div class=\"col-12 col-md-10 col-form-label\">\n                <input type=\"hidden\" name=\"localDevice[lockExtension]\" value=\"false\">\n                <div class=\"custom-control custom-checkbox\">\n                    <input type=\"checkbox\" name=\"localDevice[lockExtension]\" value=\"true\" class=\"custom-control-input\" id=\"lockExtension\"  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lockExtension : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    <label class=\"custom-control-label\" for=\"lockExtension\">&zwnj;</label>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-12\">\n        <div class=\"form-group row\">\n            <label class=\"col-12 col-form-label col-md-2 control-label text-left text-md-right\" for=\"lockedExtension\">\n                Lokal:\n            </label>\n            <div class=\"col-12 col-md-10 col-lg-6\">\n                <select class=\"form-control\" name=\"localDevice[lockedExtension]\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.lockExtension : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " id=\"lockedExtension\">\n                    <option value=\"null\">&nbsp;</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.extensions : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true})