module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form action=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.form_url), depth0))
    + "\" method=\"post\" name=\"ticketing-event-type\" id=\"ticketing-event-type\">\n	<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.form_action), depth0))
    + "\">\n	<div class=\"card\">\n		<div class=\"card-header stick-top\">\n			<span class=\"fa fa-pencil-alt\"></span> "
    + alias2(helpers.tr.call(alias3,"label.edit",{"name":"tr","hash":{},"data":data}))
    + "\n		</div>\n		<div class=\"card-body\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.eventType : stack1)) != null ? stack1.isBuiltin : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "			<div class=\"event-type-editor-container\"></div>\n		</div>\n		<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "			<div class=\"save-button\">\n				<span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"ticketing-event-type\">\n				</span>\n				<button type=\"submit\" class=\"btn btn-success\" form=\"ticketing-event-type\">\n					<span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				<h4>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.eventType : stack1)) != null ? stack1.name : stack1), depth0))
    + "</h4>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"name\">\n						<span class=\"label-text\">"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.name",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n					</label>\n					<div class=\"col-12 col-md-9\">\n						<input name=\"name\" value=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.eventType : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"form-control\" type=\"text\">\n					</div>\n				</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "			<a href=\"/settings/settings/#setting-ticketing\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n				<span class=\"fa fa-times\"></span>&nbsp;"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "			<a href=\"/settings/settings/#setting-ticketing\" class=\"btn btn-link form-cancel mr-auto\">\n				<span class=\"fa fa-arrow-left\"></span>&nbsp;"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.back",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})