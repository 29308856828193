function doRequest(requestParams) {
	return new Promise((resolve, reject) => {
		$.ajax(requestParams)
			.done((data, textStatus, jqXHRs) => {
				if(typeof(data) === 'string') {
					data = JSON.parse(data);
				}
				printPHPDebugs(data);
				resolve(data);
			}).fail(function(jqXHR, textStatus, errorThrown) {
				console.error(`Error on request to ${requestParams.url}: `, textStatus, errorThrown);
				console.log('Full jqXHR: ', jqXHR);
				if (jqXHR.responseJSON) {
					printPHPDebugs(jqXHR.responseJSON);
					console.error('Error: ', jqXHR.responseJSON);
					reject(jqXHR);
				} else if ($.parseHTML(jqXHR.responseText)) {
					console.error('Got HTML instead of JSON: ', jqXHR.responseText);
					reject('Got HTML instead of JSON');
				} else {
					reject();
				}
			});
	});
}

function requestWithBody(method, url, data, oldPostMethod = false) {
	let contentType = undefined;
	if (oldPostMethod) {
		data = {paramsJSON: JSON.stringify(data)};
	} else {
		data = JSON.stringify(data);
		contentType = 'json';
	}
	return doRequest({
		method,
		url,
		data,
		contentType,
		async: true,
		dataType: 'json'
	});
}

/**
 * Create a GET request with given URL and params, return result
 * @param {string} url Endpoint URL
 * @param {Object} params Object of query params
 * @return {Promise<Object>} data returned from endpoint
 */
export function get(url, data) {
	return doRequest({
		method: 'GET',
		url,
		data,
		async: true,
		dataType: 'json'
	});
}

/**
 * Create a POST request with given URL and body, return result
 * @param {string} url Endpoint URL
 * @param {Object} params Object to send as body. Will be JSON encoded
 * @return {Promise<Object>} data returned from endpoint
 */
export function post(url, data, oldPostMethod = false) {
	return requestWithBody('POST', url, data, oldPostMethod);
}

/**
 * Create a PUT request with given URL and body, return result
 * @param {string} url Endpoint URL
 * @param {Object} params Object to send as body. Will be JSON encoded
 * @return {Promise<Object>} data returned from endpoint
 */
export function put(url, data, oldPostMethod = false) {
	return requestWithBody('PUT', url, data, oldPostMethod);
}

/**
 * Create a PATCH request with given URL and body, return result
 * @param {string} url Endpoint URL
 * @param {Object} params Object to send as body. Will be JSON encoded
 * @return {Promise<Object>} data returned from endpoint
 */
export function patch(url, data, oldPostMethod = false) {
	return requestWithBody('PATCH', url, data, oldPostMethod);
}