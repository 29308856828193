<template>
  <div class="card">
    <div class="card-header d-flex" v-if="loaded && !error">
      <b-dropdown :text="tr('Callcenter:button.display')" right class="ml-auto">
        <template slot="button-content">
            <span class="fas fa-eye"></span>&nbsp;
            {{ tr('Callcenter:button.display') }}
        </template>

        <b-dropdown-item v-for="queue in queues" :key="queue.Name" @click="toggleQueueObserve(queue.Name)">
            <b-form-checkbox :checked="queue.isObserved">
                {{queue.Name}}
            </b-form-checkbox>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="toggleShowAllAgents">
            <b-form-checkbox :checked="showAllAgents">
                {{ tr('Callcenter:label.show_all_agents') }}
            </b-form-checkbox>
        </b-dropdown-item>
        <b-dropdown-item @click="toggleShowQueueCallers">
            <b-form-checkbox :checked="showQueueCallers">
                {{ tr('Callcenter:label.show_queue_callers') }}
            </b-form-checkbox>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="card-body">
      <template v-if="loaded && !error">
        <Statistics :queues="queues"/>
        <div class="row" v-if="extensionsLoaded">
            <div :class="showQueueCallers ? 'col-xl-6' : 'col-xl-12'" class="col-md pt-3 pb-2">
                <div class="row" v-if="showQueueCallers === true">
                    <div class="col-12">
                        <div class="border-bottom mb-2">
                            <strong>{{ tr('Callcenter:label.agents') }}</strong>
                        </div>
                    </div>
                </div>
                <Extensions :extensions="extensions" :ownExtension="ownExtension" :showQueueCallers="showQueueCallers" />
            </div>
            <div v-if="showQueueCallers" class="col-6 col-md pt-3 pb-2">
                <QueueCalls :calls="calls" />
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-grow m-3"></div>
        </div>
      </template>
      <div v-if="!loaded && !error" class="text-center">
        <div class="spinner-grow m-3"></div>
      </div>
      <template v-else>
          <h6 class="text-danger">{{error}}</h6>
      </template>
    </div>
  </div>
</template>

<script>
import Statistics from './Statistics.vue';
import Extensions from './Extensions.vue';
import QueueCalls from './QueueCalls.vue';
import bDropdown from 'bootstrap-vue/es/components/dropdown/dropdown';
import bDropdownItem from 'bootstrap-vue/es/components/dropdown/dropdown-item';
import bDropdownDivider from 'bootstrap-vue/es/components/dropdown/dropdown-divider';
import bFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox';
import { cloneDeep } from 'lodash';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { setUserSetting, getUserSetting } from 'BootQuery/Assets/js/BootQuery.js';

export default {
	components: {
		Statistics,
		Extensions,
		QueueCalls,
		bDropdown,
		bDropdownItem,
		bDropdownDivider,
		bFormCheckbox
	},
	data() {
		return {
			showAllAgents: false,
			showQueueCallers: false,
			queues: [],
			extensions: {},
			calls: [],
			statistics: {},
			pauses: {},
			loaded: false,
			extensionsLoaded: false,
			settings: {},
			error: null
		};
	},

	created() {
		this.fetchData();

		this.module.on('StatisticsUpdate',  this.onStatisticsUpdate.bind(this));
		this.module.on('PeerStatusChanged', this.onPeerStatusChanged.bind(this));
		this.module.on('MemberPaused',      this.onMemberPaused.bind(this));
		this.module.on('CallerJoin',        this.onCallerJoin.bind(this));
		this.module.on('CallerLeave',       this.onCallerLeave.bind(this));
	},
	computed: {
		module() {
			return window.BootQuery.getModuleInstance('Callcenter');
		},
		ownExtension() {
			return window.Bootstrap.bootquery.session.extension;
		}
	},
	methods: {
		onStatisticsUpdate(data) {
			const queueIdx = this.queues.findIndex(queue => queue.Name === data.Name);
			if (queueIdx !== -1) {
				data.isObserved = !!this.queues[queueIdx].isObserved;
				this.$set(this.queues, queueIdx, data);
			}
		},
		toggleQueueObserve(queueName) {
			const queue = this.queues.find(queue => queue.Name === queueName);

			queue.isObserved = !queue.isObserved;
			this.saveObservedQueues();
		},
		saveObservedQueues() {
			const observedQueueNames = this.queues
				.filter(queue => queue.isObserved)
				.map(queue => queue.Name);

			setUserSetting('Callcenter.observedQueues', observedQueueNames);
		},
		async toggleShowAllAgents() {
			this.showAllAgents = !this.showAllAgents;
			await setUserSetting('Callcenter.showAllAgentsStatus', this.showAllAgents);
			this.extensionsLoaded = false;
			this.fetchData();
		},
		toggleShowQueueCallers() {
			this.showQueueCallers = !this.showQueueCallers;
			setUserSetting('Callcenter.showQueueCallers', this.showQueueCallers);
		},
		onPeerStatusChanged(data) {
			const ext = this.extensions[data.Extension];
			if(ext) {
				if (data.StatusTimestamp > ext.StatusTimestamp) {
					this.$set(this.extensions, data.Extension, data);
				}
			}
		},
		onMemberPaused(data) {
			if(this.extensions[data.Extension]) {
				/**
                 * This is an array for potential future use
                 * Currently, there is only one active pause active at the time
                 */

				// THERE CAN BE ONLY ONE!
				console.log(`${data.Extension}.Pauses`);
				const pauses = data.State === 'Unpaused' ? [] : [data];
				this.$set(this.extensions[data.Extension], 'Pauses', pauses);
			}
		},
		onCallerJoin(data) {
			this.calls = data.Calls;
		},
		onCallerLeave(data) {
			this.calls = data.Calls;
		},
		async fetchData() {
			const data = await Api.get('/api/callcenter/status');

			if(data.error) {
				this.error = data.error;
			}

			this.queues = data.queues;
			this.extensions = data.extensions;
			this.calls = data.calls;
			this.statistics = data.statistics;
			this.pauses = data.pauses;

			this.showAllAgents    = await getUserSetting('Callcenter.showAllAgentsStatus');
			this.showQueueCallers = await getUserSetting('Callcenter.showQueueCallers');

			this.loaded = true;
			this.extensionsLoaded = true;

			const queueSettings = await Api.get('/api/queues/');
			queueSettings.forEach((queue) => {
				this.settings[queue.name] = queue;
			});
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
