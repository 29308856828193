module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"card\">\n"
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depth0 != null ? depth0.tables : depth0),((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.table : stack1),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"card-header\">\n                    <ol class=\"breadcrumb bg-transparent m-0 p-0\">\n                        <li class=\"breadcrumb-item\">\n                            <a href=\"/customerChat/chat\">\n                                <span class=\"fas fa-comments\"></span>\n                                "
    + alias2(helpers.tr.call(alias1,"label.client_chat",{"name":"tr","hash":{},"data":data}))
    + "\n                            </a>\n                        </li>\n                        <li class=\"breadcrumb-item active\">\n                            <span class=\"fas fa-history\"></span>\n                            "
    + alias2(helpers.tr.call(alias1,"label.client_chat_history",{"name":"tr","hash":{},"data":data}))
    + "\n                        </li>\n                    </ol>\n                </div>\n                <div class=\"card-body\">\n                    "
    + ((stack1 = container.invokePartial(partials.sectionHead,depth0,{"name":"sectionHead","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                "
    + ((stack1 = container.invokePartial(partials.table,depth0,{"name":"table","hash":{"noBottomMargin":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div class=\"card-footer\">\n                    "
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})