module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"row newrow\" style=\"margin-bottom: 15px;\">\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0["new"] : depth0), depth0),{"name":"new","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		<div class=\"col-sm-4\">\n			<button class=\"btn btn-default listform-button-cancel-new\" style=\"width: 100%;\">\n				<span class=\"glyphicon glyphicon-remove\"></span>&nbsp;Odustani\n			</button>\n		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			"
    + ((stack1 = container.invokePartial(partials.formNestedEmpty,depth0,{"name":"formNestedEmpty","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.listform : depth0), depth0),{"name":"listform","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})