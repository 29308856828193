<template>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <b-button-group size="sm" class="w-100">
                <b-button :variant="extensionStatusClass(extension)">
                    <span class="fa fa-power-off"></span>
                </b-button>
                <b-dropdown
                    :disabled="extension.Status === 'Unavailable' || extension.Status === 'Unknown'"
                    :text="extension.ContactInfo ? extension.ContactInfo.displayName : extension.Extension" class="w-100 extension-action-dropdown"
                    size="sm"
                    variant="light">
                    <b-dropdown-item
                        :href="`/callcenter/${ownExtension}/dial/`"
                        v-if="!extension.Calls"
                        @click.prevent="extensionAction('dial', extension.Extension)">
                        <span class="fa fa-phone"></span>&nbsp;
                        {{ tr('Callcenter:button.call') }}
                    </b-dropdown-item>
                    <template v-else>
                        <b-dropdown-item
                            v-if="isIncomingRinging"
                            :href="`/callcenter/${ownExtension}/pickup/`"
                            @click.prevent="extensionAction('pickup', extension.Extension)">
                            <span class="fas fa-hands-helping"></span>&nbsp;
                            {{ tr('Callcenter:button.pickup') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            :href="`/callcenter/${ownExtension}/spy/`"
                            @click.prevent="extensionAction('spy', extension.Extension)">
                            <span class="fa fa-assistive-listening-systems"></span>&nbsp;
                            {{ tr('Callcenter:button.spy') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            :href="`/callcenter/${ownExtension}/whisper/`"
                            @click.prevent="extensionAction('whisper', extension.Extension)">
                            <span class="fa fa-user-secret"></span>&nbsp;
                            {{ tr('Callcenter:button.whisper') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            :href="`/callcenter/${ownExtension}/barge/`"
                            @click.prevent="extensionAction('barge', extension.Extension)">
                            <span class="fa fa-users"></span>&nbsp;
                            {{ tr('Callcenter:button.barge') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            :href="`/callcenter/${ownExtension}/hangup/`"
                            @click.prevent="extensionAction('hangup', extension.Extension)"
                            class="text-danger">
                            <span class="fa fa-times"></span>&nbsp;
                            {{ tr('Callcenter:button.hangup') }}
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
            </b-button-group>
        </div>

        <div class="col-xl-3 col-lg-2 col-md-3 col-sm-4">
            <b-badge class="agent-status-label" :variant="agentStatusClass(extension)">
                {{ agentStatusLabel(extension) }}
            </b-badge>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-4">
            <template v-if="extension.Status !== 'Idle'">
                <a v-if="otherParty" href="#">
                    {{otherParty.ContactInfo.displayName}}
                </a>
            </template>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4">
            <b-badge variant="light" class="agent-status-label call-time">
                {{ callTimer(extension) }}
            </b-badge>
        </div>
    </div>
</template>

<script>
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import bBadge from 'bootstrap-vue/es/components/badge/badge';
import bButton from 'bootstrap-vue/es/components/button/button';
import bButtonGroup from 'bootstrap-vue/es/components/button-group/button-group';
import bDropdown from 'bootstrap-vue/es/components/dropdown/dropdown';
import bDropdownItem from 'bootstrap-vue/es/components/dropdown/dropdown-item';

export default {
	components: {
		bBadge,
		bButton,
		bButtonGroup,
		bDropdown,
		bDropdownItem
	},
	props: {
		extension: Object,
		ownExtension: String
	},
	data() {
		return {
			timer: null
		};
	},
	methods: {
		extensionStatusClass(extension) {
			if (extension.Pauses && extension.Pauses.length) {
				return 'yellow';
			} else if (extension.Status === 'Unavailable' || extension.Status === 'Unknown') {
				return 'danger';
			} else {
				return 'success';
			}
		},
		agentStatusClass(extension) {
			if (extension.Pauses && extension.Pauses.length) {
				return 'yellow';
			} else if (extension.Status === 'Idle') {
				return 'secondary';
			} else if (extension.Status === 'InUse') {
				return 'primary';
			} else if (extension.Status === 'Busy' || extension.Status === 'Hold') {
				return 'success';
			} else if (extension.Status === 'Unavailable' || extension.Status === 'Unavailable') {
				return 'light';
			} else if (extension.Status === 'Ringing') {
				return 'orange';
			}
		},
		agentStatusLabel(extension) {
			if (extension.Pauses && extension.Pauses.length) {
				return extension.Pauses[0].Name;
			} else if (extension.Status === 'Idle') {
				return tr('Callcenter:label.available');
			} else if (extension.Status === 'InUse') {
				return tr('Callcenter:label.in_call');
			} else if (extension.Status === 'Busy') {
				return tr('Callcenter:label.calling');
			} else if (extension.Status === 'Unavailable' || extension.Status === 'Unavailable') {
				return tr('Callcenter:label.unavailable');
			} else if (extension.Status === 'Ringing') {
				return tr('Callcenter:label.ringing');
			} else if (extension.Status === 'Hold') {
				return tr('Callcenter:label.on_hold');
			}
		},
		extensionAction(action, extension) {
			const url = `/api/callcenter/${this.ownExtension}/${action}/`;

			Api.post(url, {target_extension: extension}).then(data => {
				console.log(data);
			});
		},
		callTimer(extension) {
			if(extension.Calls) {
				for(var call of extension.Calls) {
					if(call.CallTimer) {
						return call.CallTimer;
					}
				}
			} else if(extension.Pauses && extension.Pauses.length) {
				if(extension.Pauses[0].Elapsed) {
					return extension.Pauses[0].Elapsed;
				}
			}

			return '00:00:00';
		},
		clearTimer() {
			if(this.timer) {
				window.clearInterval(this.timer);
				this.timer = null;
			}
		},
		startTimer(field) {
			this.clearTimer();
			this.timer = window.setInterval(() => {
				if(this.activeCall) {
					let timeSec    = moment.duration(this.activeCall[field]).asSeconds() + 1;
					let timeString = moment.utc(timeSec * 1000).format('HH:mm:ss');

					this.$set(this.activeCall, field, timeString);
					this.$set(this.activeCall, 'CallTimer', timeString);
				} else if(this.extension.Pauses && this.extension.Pauses.length) {
					let startTime = moment(this.extension.Pauses[0].StartTime);
					let now = moment();
					let elapsedMSec = now - startTime;
					let elapsed = moment.utc(elapsedMSec).format('HH:mm:ss');

					this.$set(this.extension.Pauses[0], 'Elapsed', elapsed);
				}
			}, 1000);
		}
	},
	computed: {
		activeCall() {
			if(this.extension.Calls) {
				if(this.extension.Calls.length === 1) {
					return this.extension.Calls[0];
				} else {
					return this.extension.Calls.find(c => c.isOnHold === false);
				}
			}

			return null;
		},
		otherParty() {
			let activeCall = this.activeCall;

			if (activeCall) {
				if(activeCall.BridgedCalls && activeCall.BridgedCalls.length > 0) {
					return activeCall.BridgedCalls.find(c => c.OnHold === false);
				}

				if(activeCall.ConnectingCalls && activeCall.ConnectingCalls.length > 0) {
					return activeCall.ConnectingCalls.find(c => c.OnHold === false);
				}
			}
			return null;
		},
		isIncomingRinging() {
			const call = this.activeCall;
			if (call) {
				// If there is an active ringing call
				// and we're not the one dialing
				return call.ConnectingCalls &&
					call.ConnectingCalls.length > 0 &&
					this.extension.Status !== 'Busy';
			} else {
				return false;
			}
		}
	},
	destroyed() {
		if (this.timer) {
			window.clearInterval(this.timer);
			this.timer = null;
		}
	},
	watch: {
		'extension.Status': {
			immediate: true,
			handler(status) {
				this.clearTimer();
				let field = '';

				if(status === 'Ringing') {
					field = 'RingDuration';
				} else if(status === 'Busy') {
					field = 'ChannelDuration';
				} else if(status === 'InUse') {
					field = 'CallElapsed';
				}

				if(field) {
					this.startTimer(field);
				}
			}
		},
		'extension.Pauses': {
			immediate: true,
			handler(pauses) {
				if(!this.activeCall) {
					if(pauses && pauses.length) {
						this.clearTimer();
						this.startTimer();
					}
				}
			}
		}
	}
};
</script>

<style scoped lang="scss">
    .extension-action-dropdown /deep/ button {
        width: 100%;
    }
</style>
