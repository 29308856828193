import {mapValues, merge, sortBy, forEach, clone, pickBy} from 'lodash';
import Sortable from 'sortablejs';
import Field from './field';
import * as Fields from './index.js';

export default class List extends Field {
	constructor(optionsOrType) {
		super(optionsOrType);
		if (!this.options.fields) {
			this.options.fields = {};
		}

		this.options.fields = mapValues(this.options.fields, (field, id) => {
			field.id = id;
			return Fields.constructField(field);
		});
	}

	async render() {
		let renderOptions = merge({}, this.options, {readonly: true});
		this.field = $(
			handlebarsRender(await getTemplate('editableFormFieldWrapper'), this.options)
		);

		let listField = $(handlebarsRender(await getTemplate('formEditorList'), renderOptions));

		let order = this.options.order || [];
		let ordered = Object.entries(this.options.fields).map(pair => merge(pair[1], {key: pair[0]}));
		if (order.length) {
			ordered = sortBy(ordered, field => order.indexOf(field.key));
		}
		let itemsEl = listField.find('.formeditor-list-items');
		forEach(ordered, (field) => {
			field.render().then(rendered => itemsEl.append(rendered));
		});

		this.field.find('.editable-field-wrapper').append(listField);
		this.field.find('label > .label-text')
			.prop('contenteditable', true)
			.prop('spellcheck', false);
		this.bindEvents();
		return this.field;
	}

	async bindEvents() {
		super.bindEvents();
		this.sortable = Sortable.create(this.field.find('.formeditor-list-items').get(0), {
			group: {
				name: 'formbuilder',
				put: true,
				pull: false
			},
			handle: '.editable-field-drag-handle',
			dataIdAttr: 'data-field-id',
			onAdd: async evt => {
				const type = evt.item.dataset.formType;
				let field = Fields.constructField({
					type: type,
					new: true,
					nested: true
				});
				this.options.fields[field.id] = field;
				$(evt.item).replaceWith(await field.render());
			},
			animation: 150
		});
	}

	getDefinition() {
		let newOpts = clone(this.options);
		let fields = mapValues(newOpts.fields, field => field.getDefinition());
		fields = pickBy(fields, (item) => {
			if (item.deleted && item.new) {
				return false;
			}
			return true;
		});
		newOpts.order = this.sortable.toArray();
		newOpts.fields = fields;
		return newOpts;
	}
}