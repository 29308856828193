<template>
<div class="col-12">
    <div class="row border-bottom mb-2">
        <div class="col-1">
            <span class="fas fa-sort-numeric-down"></span>
        </div>
        <div class="col-4">
            <strong>{{ tr('Callcenter:label.caller') }}</strong>
        </div>
        <div class="col-4">
            <strong>{{ tr('Callcenter:label.queue') }}</strong>
        </div>
        <div class="col-3">
            <strong>{{ tr('Callcenter:label.waiting_time') }}</strong>
        </div>
    </div>

    <div
        v-for="(call, index) in calls"
        :key="index"
        class="row mb-2 queue-call-row"
        :class="{'text-danger': call.ContactInfo.priorityLevel}"
        :data-channel="call.Channel"
        >
        <div class="col-12">
            <QueueCall :call="call" />
        </div>
    </div>
</div>
</template>

<script>
import QueueCall from './QueueCall.vue';

export default {
    components: {
        QueueCall
    },
    props: {
        calls: Array
    }
}
</script>

<style>

</style>
