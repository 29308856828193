module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.address : depth0), depth0))
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return ", ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>\n            <th style=\"text-align: right;\">CC: </th>\n            <td>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.cc : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </td>\n        </tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = alias1((depth0 != null ? depth0.currentHtml : depth0), depth0)) != null ? stack1 : "")
    + "\n<hr>\n-------- Forwarded Message --------<br>\n<table border=\"0\">\n    <tbody>\n        <tr>\n            <th style=\"text-align: right;\">Subject: </th>\n            <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.subject : stack1), depth0))
    + "</td>\n        </tr>\n        <tr>\n            <th style=\"text-align: right;\">Date: </th>\n            <td>"
    + alias2(helpers.format_date.call(alias3,((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.date : stack1),helpers.tr.call(alias3,"format.datetime_s",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "</td>\n        </tr>\n        <tr>\n            <th style=\"text-align: right;\">From: </th>\n            <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.fromMailAddress : stack1), depth0))
    + "</td>\n        </tr>\n        <tr>\n            <th style=\"text-align: right;\">To: </th>\n            <td>\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.to : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </td>\n        </tr>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.cc : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n</table>\n<br>\n<blockquote cite=\"mid:"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.messageID : stack1), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? depth0.previousHtml : depth0), depth0)) != null ? stack1 : "")
    + "</blockquote>";
},"useData":true})