module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n"
    + ((stack1 = container.invokePartial(partials.doNotCallWarning,depth0,{"name":"doNotCallWarning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<form method=\"post\" action=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.form_url), depth0))
    + "\" id=\"campaigns-contact-form\" data-validation-error=\"dataVerified\">\n	<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.form_action), depth0))
    + "\">\n	<input type=\"hidden\" name=\"id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.contactID : stack1), depth0))
    + "\">\n	<div class=\"card contact-card\">\n		<div class=\"card-header d-flex\">\n			<span class=\"mr-auto\">\n				<a href=\"/campaigns/logout/?campaign="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaignID : stack1), depth0))
    + "&agent="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-sm btn-danger\">\n					<span class=\"fa fa-sign-out-alt\"></span>\n					"
    + alias2(helpers.tr.call(alias3,"button.logout",{"name":"tr","hash":{},"data":data}))
    + "\n				</a>\n			</span>\n\n			<span class=\"pt-1\" id=\"campaigns-dialer-status\">\n				"
    + alias2(helpers.tr.call(alias3,"label.call_contact",{"name":"tr","hash":{},"data":data}))
    + "\n			</span>\n\n            <span class=\"ml-auto\">\n"
    + ((stack1 = helpers.unless.call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.state_wait : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"campaigns-contact-form\">\n                </span>\n                <button class=\"btn btn-sm btn-success\" type=\"submit\" form=\"campaigns-contact-form\">\n                    <span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                </button>\n				<button data-campaign=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaignID : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-sm btn-warning\" type=\"button\" id=\"campaign-skip-contact\">\n					<span class=\"fa fa-forward\"></span>\n					"
    + alias2(helpers.tr.call(alias3,"button.skip",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.paused : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "            </span>\n		</div>\n		<div class=\"card-block\">\n			<div class=\"row no-gutters\">\n				<div class=\"col-12 col-xl-3 bg-light py-2 border-right contact-first-pane\">\n					<div data-form=\"basic\">\n						"
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.basic),{"name":"form","hash":{"plain":true,"readonly":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n				</div>\n				<div class=\"col-12 col-xl-9 contact-second-pane\">\n"
    + ((stack1 = helpers.unless.call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.allowedToCall : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "                </div>\n			</div>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<a href=\"/campaigns/pause/?campaign="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaignID : stack1), depth0))
    + "&agent="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "&pause=1\" class=\"btn btn-yellow btn-sm\">\n						<span class=\"fa fa-pause\"></span> "
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.pause",{"name":"tr","hash":{},"data":data}))
    + "\n					</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.state_wait : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "						<button data-campaign=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaignID : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" data-contact=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.contactID : stack1), depth0))
    + "\" class=\"btn btn-sm btn-primary\" id=\"campaign-make-call\" type=\"button\">\n							<span class=\"fa fa-phone\"></span>&nbsp;"
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.make_call",{"name":"tr","hash":{},"data":data}))
    + "\n						</button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.calls : stack1)) != null ? stack1.numbers : stack1)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<button data-campaign=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaignID : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-sm btn-primary\" id=\"campaign-next-call\" type=\"button\">\n								<span class=\"fa fa-arrow-right\"></span>&nbsp;"
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.next_call",{"name":"tr","hash":{},"data":data}))
    + "\n							</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<button data-campaign=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaignID : stack1), depth0))
    + "\" data-agent=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-sm btn-primary\" id=\"campaign-next-contact\" type=\"button\">\n								<span class=\"fa fa-arrow-right\"></span>&nbsp;"
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.next_contact",{"name":"tr","hash":{},"data":data}))
    + "\n							</button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"row px-3 py-3\">\n							<div class=\"col\">\n								"
    + ((stack1 = container.invokePartial(partials.doNotCallContact,depth0,{"name":"doNotCallContact","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</div>\n						</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "						<ul class=\"nav nav-tabs\">\n							<li class=\"nav-item\">\n								<a class=\"nav-link active\" data-toggle=\"tab\" href=\"#scenario\">\n									<span class=\"tab-icon\">\n										<span class=\"fa fa-file-alt\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias2(helpers.tr.call(alias1,"tab.scenario",{"name":"tr","hash":{},"data":data}))
    + "\n									</span>\n								</a>\n							</li>\n							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#events\">\n									<span class=\"tab-icon\">\n										<span class=\"fas fa-list\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias2(helpers.tr.call(alias1,"tab.events",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary ticket-event-count\">\n											"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.events : stack1)) != null ? stack1.resultdata : stack1)) != null ? stack1.count : stack1), depth0))
    + "\n										</span>\n									</span>\n								</a>\n							</li>\n							<li class=\"nav-item\">\n								<a class=\"nav-link\" data-toggle=\"tab\" href=\"#reminders\">\n									<span class=\"tab-icon\">\n										<span class=\"fa fa-business-time\"></span>\n									</span>\n									<span class=\"tab-text\">\n										"
    + alias2(helpers.tr.call(alias1,"tab.reminders",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary ticket-event-count\">"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.reminders : stack1)) != null ? stack1.resultdata : stack1)) != null ? stack1.count : stack1), depth0))
    + "</span>\n									</span>\n								</a>\n							</li>\n						</ul>\n						<div class=\"tab-content\">\n							<div class=\"tab-pane fade show active\" id=\"scenario\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.scenario)) && stack1.fields),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "							</div>\n							<div class=\"tab-pane fade show\" id=\"events\">\n								<div class=\"card m-3\">\n"
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depth0 != null ? depth0.tables : depth0),"events",{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "								</div>\n							</div>\n							<div class=\"tab-pane fade show\" id=\"reminders\">\n								<div class=\"card m-3\">\n"
    + ((stack1 = helpers["with"].call(alias1,helpers.lookup.call(alias1,(depth0 != null ? depth0.tables : depth0),"reminders",{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "								</div>\n							</div>\n						</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "									<div data-form=\"scenario\" class=\"py-3\">\n										"
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.scenario),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "									</div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "									<div class=\"py-3 px-3\">\n										<strong>"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_scenario_fields",{"name":"tr","hash":{},"data":data}))
    + "</strong>\n									</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "										<div class=\"card-body\">\n											"
    + ((stack1 = container.invokePartial(partials.sectionHead,depth0,{"name":"sectionHead","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n										"
    + ((stack1 = container.invokePartial(partials.table,depth0,{"name":"table","hash":{"small":true,"noBottomMargin":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										<div class=\"card-footer\">\n											"
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "										</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})