module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " col-md-2 col-form-label control-label text-left text-md-right";
},"3":function(container,depth0,helpers,partials,data) {
    return " col-md-10";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"far fa-check-square\"></span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"far fa-square\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "            <input type=\"hidden\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\" value=\"false\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disabled : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <div class=\"custom-control custom-checkbox\">\n                <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.disabled : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\" value=\"true\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"custom-control-input\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.autofocus : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disabled : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readonly : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <label class=\"custom-control-label\" for=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\">&zwnj;</label>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"13":function(container,depth0,helpers,partials,data) {
    return "id=\""
    + container.escapeExpression(helpers.array_to_path.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\"";
},"15":function(container,depth0,helpers,partials,data) {
    return "checked";
},"17":function(container,depth0,helpers,partials,data) {
    return "autofocus";
},"19":function(container,depth0,helpers,partials,data) {
    return "readonly";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"col-12\">\n    <div class=\"form-group row\">\n        <label class=\"col-12 col-form-label"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" for=\""
    + container.escapeExpression(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\">\n            "
    + ((stack1 = container.invokePartial(partials.formLabel,depth0,{"name":"formLabel","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <div class=\"col-12"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " col-form-label\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.plain : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true})