module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"datatableDropdown",{"name":"content","hash":{"mode":"prepend"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "		<h6 class=\"dropdown-header\">"
    + alias2(helpers.tr.call(alias1,"label.show_calls",{"name":"tr","hash":{},"data":data}))
    + "</h6>\n		<div class=\"dropdown-item\">\n			<div class=\"custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0\">\n				<input type=\"checkbox\" name=\"calls-show-queue-calls\" id=\"calls-show-queue-calls\" class=\"datatable-column-display-checkbox custom-control-input\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.showQueueCalls),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n				<label class=\"custom-control-label\" for=\"calls-show-queue-calls\">"
    + alias2(helpers.tr.call(alias1,"label.queue_calls",{"name":"tr","hash":{},"data":data}))
    + "</label>\n			</div>\n		</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"global.headerButtons",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})