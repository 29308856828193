import Module from 'BootQuery/Assets/js/module';

export default class Queues extends Module {
	constructor() {
		super();
	}

	init(data) {
		super.init(data);
	}

	activateElements(target, data) {
		this.bindConfirmCallBack(target);
	}

	bindConfirmCallBack(target) {
		target.findElement('.queues-confirm-callback').off('click.queues').on('click.queues', (e) => {
			e.preventDefault();
			e.stopPropagation();

			let callID = $(e.currentTarget).data('callid');
			let ID     = $(e.currentTarget).data('id');
			let parameters = window.Bootstrap.bootquery.parameters;
			parameters.callID = callID;
			parameters.ID = ID;

			getJSON('get', 'queues', 'confirmCallback', parameters, true, (data) => {
				getTemplate('queueCalls', 'Queues').then((template) => {
					let rendered = handlebarsRender(template, data);

					$('#queueCalls-table').replaceWith($(rendered).find('#queueCalls-table'));
					activateElements($('#queueCalls-table'), data);
				});
			});
		});

		target.findElement('.queues-save-note').off('click.queues').on('click.queues', (e) => {
			let input = $(e.currentTarget).siblings('input:first');

			if(input) {
				let note = $(input).val();
				let callID = $(e.currentTarget).data('callid');
				let ID = $(e.currentTarget).data('id');
				let parameters    = window.Bootstrap.bootquery.parameters;
				parameters.callID = callID;
				parameters.ID     = ID;
				parameters.note   = note;

				getJSON('get', 'queues', 'saveCallNote', parameters, true, (data) => {
					getTemplate('queueCalls', 'Queues').then((template) => {
						let rendered = handlebarsRender(template, data);

						$('#queueCalls-table').replaceWith($(rendered).find('#queueCalls-table'));
						activateElements($('#queueCalls-table'), data);
					});
				});
			}
		});

		target.findElement('.callback-button').off('click.queues').on('click.queues', (e) => {
			e.preventDefault();
			e.stopPropagation();

			let extension = $(e.currentTarget).data('extension');
			let number = $(e.currentTarget).data('number');
			let callID = $(e.currentTarget).data('callid');
			let parameters    = window.Bootstrap.bootquery.parameters;
			parameters.callID = callID;

			// Make the call
			$.get('/dialer/dial', {extension: extension, number: number}, (data) => {
				console.log('Dial attempted!');
				// Mark as attempted
				getJSON('get', 'queues', 'attemptCallback', parameters, true, (data) => {
					getTemplate('queueCalls', 'Queues').then((template) => {
						let rendered = handlebarsRender(template, data);

						$('#queueCalls-table').replaceWith($(rendered).find('#queueCalls-table'));
						activateElements($('#queueCalls-table'), data);
					});
				});
			});

			// /dialer/dial?extension={{@root.bootquery.session.extension}}&number={{#if caller.phoneNumber}}{{caller.phoneNumber}}{{else}}{{caller.fullPhoneNumber}}{{/if}}
		});
	}
}