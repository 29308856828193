<template>
    <div class="card">
        <div class="card-header d-flex">
            <div class="d-flex align-items-center">
                <div>
                    <span class="fas fa-comments"></span>
                    {{ tr("CustomerChat:menu_entries.chat") }}
                </div>
            </div>
            <!-- I honestly have no idea where to put this -->
            <Modal v-if="userSettingsOpen" @close="closeUserSettings">
                <template slot="title">
                    <span class="fas fa-cog"></span>
                    Chat postavke
                </template>
                <UserSettings
                    :groups="module.groups"
                    ref="userSettings"
                    :disabled="savingSettings"
                />
                <template slot="footer">
                    <button
                        type="button"
                        class="btn btn-link text-danger"
                        @click="closeUserSettings"
                        :disabled="savingSettings"
                    >Odustani</button>
                    <button
                        type="button"
                        class="btn btn-success"
                        @click="saveUserSettings"
                        :disabled="savingSettings"
                    >
                        <span v-if="savingSettings" class="fas fa-spin fa-spinner"></span>
                        <span v-else class="fa fa-check"></span>
                        Spremi
                    </button>
                </template>
            </Modal>
            <a
                href="/customerChat/history"
                type="button"
                class="btn btn-sm btn-secondary ml-auto"
                @click.prevent="navigateToHistory">
                <span class="fas fa-history"></span>
            </a>
            &nbsp;&nbsp;
            <button
                type="button"
                class="btn btn-sm btn-secondary"
                v-if="module"
                @click="openUserSettings"
            >
                <span class="fas fa-cog"></span>
            </button>
        </div>
        <div class="card-block" v-if="loaded && loggedInGroups.length">
            <div class="row no-gutters">
                <div class="chat-customers-pane col-12 col-xl-3 border-right">
                    <CustomerList
                        :currentCustomers="activeChats"
                        :waitingCustomers="waitingChats"
                        :activeItem.sync="selectedChatID"
                    />
                </div>
                <div class="chat-messages-pane col-12 col-xl-9 bg-light border-right">
                    <ChatArea
                        v-if="selectedChat"
                        :chatID="selectedChat.ID"
                        :customer="selectedChat.customer"
                        :messages="selectedChat.messages"
                        :state="selectedChat.state"
                        @acceptChat="acceptChat($event)"
                        @endChat="endChat($event)"
                        @closeChat="closeChat($event)"
                        @sendMessage="message => sendMessage(selectedChatID, message)"
                        ref="chatArea"
                    />
                </div>
            </div>
        </div>
        <div v-else class="card-body text-center">
            <span v-if="loaded === false" class="fa fa-spin fa-spinner fa-4x"></span>
            <span v-else-if="loggedInGroups.length === 0">Niste ulogirani u niti jednu grupu</span>
        </div>
    </div>
</template>

<script>
import CustomerList from './CustomerList.vue';
import ChatArea from './ChatArea.vue';
import UserSettings from './UserSettings.vue';
import Modal from 'BootQuery/Assets/js/bootstrap-components/Modal.vue';
import { setUserSetting } from 'BootQuery/Assets/js/BootQuery.js';
import uuid from 'uuid/v4';
import { lowerFirst } from 'lodash';

export default {
	components: {
		CustomerList,
		ChatArea,
		UserSettings,
		Modal
	},
	created() {
		const customerChat = window.BootQuery.getModuleInstance('CustomerChat');
		if (customerChat.loaded) {
			this.init(customerChat);
		} else {
			customerChat.on('loaded', () => this.init(customerChat));
		}
	},
	methods: {
		init(customerChat) {
			this.loaded = true;
			this.module = customerChat;
			this.groups = customerChat.groups;
			this.chats = customerChat.chats;
			if (!this.selectedChatID && this.activeChats.length) {
				this.selectedChatID = this.activeChats[0].ID;
			}
		},
		acceptChat(chatID) {
			console.log('Accept chat', chatID);
			this.module.chatSocket.send('acceptChat', {chatID});
		},
		endChat(chatID) {
			console.log('End chat', chatID);
			this.module.chatSocket.send('endChat', {chatID});
		},
		closeChat(chatID) {
			const chatIndex = this.chats.findIndex(chat => chat.ID === chatID);
			this.module.chats.splice(chatIndex, 1);
		},
		sendMessage(chatID, content) {
			console.log('Sending message:', chatID, content);
			const chat = this.chats.find(chat => chat.ID === chatID);
			const message = {
				chatID,
				content,
				direction: true,
				tmpUUID: uuid(),
				timestamp: new Date()
			};
			chat.messages.push(message);
			this.module.chatSocket.send('message', message);
		},
		openUserSettings() {
			this.userSettingsOpen = true;
		},
		closeUserSettings() {
			this.userSettingsOpen = false;
		},
		async saveUserSettings() {
			const data = this.$refs.userSettings.getData();
			this.savingSettings = true;

			this.module.groups = this.module.groups.map(group => {
				group.loggedIn = data.loggedInGroups.includes(group.ID);
				return group;
			});

			await Promise.all([
				setUserSetting(
					'CustomerChat.loggedInGroups',
					data.loggedInGroups
				),
				this.module.sendGroupLogins()
			]);
			this.closeUserSettings();
			this.savingSettings = false;
		},
		navigateToHistory() {
			history.pushState({
				controller: lowerFirst(window.Bootstrap.bootquery.controller),
				method: lowerFirst(window.Bootstrap.bootquery.method),
				parameters: window.Bootstrap.bootquery.parameters
			}, null, '/customerChat/history');
			renderController('get', 'customerChat', 'history', {}, 'CustomerChat');
		}
	},
	computed: {
		selectedChat() {
			if (!this.selectedChatID) {
				return null;
			}

			const activeMatch = this.activeChats.find(chat => chat.ID === this.selectedChatID);
			if (activeMatch) {
				return activeMatch;
			} else {
				return this.waitingChats.find(chat => chat.ID === this.selectedChatID);
			}
		},
		activeChats() {
			const userID = window.Bootstrap.bootquery.session.userID;
			return this.chats.filter(chat => chat.agentID === userID);
		},
		waitingChats() {
			return this.chats.filter(chat => chat.state === 'waiting' && this.loggedInGroupIDs.includes(chat.groupID));
		},
		loggedInGroups() {
			if (!this.groups) {
				return [];
			}
			return this.groups.filter(group => group.loggedIn);
		},
		loggedInGroupIDs() {
			return this.loggedInGroups.map(group => group.ID);
		}
	},
	watch: {
		chats(newVal) {
			if (!this.selectedChatID) {
				return;
			}
			const newSelected = newVal.find(chat => chat.ID === this.selectedChatID);
			if (!newSelected) {
				this.selectedChatID = null;
			}
		},
		selectedChat(newVal) {
			if (!newVal || !this.$refs.chatArea) {
				return;
			}
			console.log('Scroll to bottom');
			this.$refs.chatArea.scrollToBottom();
		}
	},
	data() {
		return {
			module: null,
			chats: [],
			data: [],
			groups: [],
			selectedChatID: null,
			loaded: false,
			userSettingsOpen: false,
			savingSettings: false
		};
	}
};
</script>

<style lang="scss" scoped>
.card-block {
    height: calc(100vh - 140px);

    > .row {
        height: 100%;
    }
}

.chat-customers-pane,
.chat-messages-pane {
    height: 100%;
    overflow-y: hidden;
}
</style>