import Module from 'BootQuery/Assets/js/module';
import FormEditor from 'BootQuery/Assets/js/form-editor';
import {find, get} from 'lodash';

export default class Phonebook extends Module {
	constructor() {
		super();
	}

	init(data) {
		super.init(data);
	}

	activateElements(target, data) {
		this.activatePersonFormEditor(target, data);
		this.activateCompanyFormEditor(target, data);
		if (target.findElement('#form-editor').length) {
			this.formEditor = new FormEditor(get(data, 'result.definition'));
			this.formEditor.render(target.findElement('#form-editor'));

			target.findElement('.formedit-save-btn')
				.off('click.phonebook')
				.on('click.phonebook', (e) => {
					e.preventDefault();
					getJSON('POST', 'phonebook', 'customFormEditSave', this.formEditor.getDefinition(), false, ret => {
						console.log('Posted, got:', ret);
					});
				});
		}

		target.findElement('#person-company-switcher').ev('shown.bs.tab.phonebook', e => {
			const actionInput = target.findElement('input[name="action"]');
			const href = $(e.target).attr('href');
			if (href === '#add-person') {
				actionInput.val('insertPerson');
			} else if (href === '#add-company') {
				actionInput.val('insertCompany');
			}
		});
	}

	activatePersonFormEditor(target, data) {
		let editorEl = target.findElement('#phonebook-person-fields-form-editor');
		if (!editorEl.length) {
			return;
		}

		const ownTab = this.findPhonebookTab(data);
		const formDef = get(ownTab, 'data.phonebookPersonForm.definition');

		let formEditor = new FormEditor(formDef);
		formEditor.render(editorEl);

		// Add hidden input with JSON of form definition as value to the settings form
		target.findElement('#settings-form')
			.off('beforeSubmit.phonebookPerson')
			.on('beforeSubmit.phonebookPerson', (e) => {
				let input = $(e.currentTarget).find('[name="phonebookPerson[formDefinition]"]');
				if (!input.length) {
					input = $('<input/>', {
						type: 'hidden',
						name: 'phonebookPerson[formDefinition]'
					});
				}
				input.val(JSON.stringify(formEditor.getDefinition()));
				editorEl.append(input);
			});
	}

	activateCompanyFormEditor(target, data) {
		let editorEl = target.findElement('#phonebook-company-fields-form-editor');
		if (!editorEl.length) {
			return;
		}

		const ownTab = this.findPhonebookTab(data);
		const formDef = get(ownTab, 'data.phonebookCompanyForm.definition');

		let formEditor = new FormEditor(formDef);
		formEditor.render(editorEl);

		// Add hidden input with JSON of form definition as value to the settings form
		target.findElement('#settings-form').ev('beforeSubmit.phonebookCompany', (e) => {
			let input = $(e.currentTarget).find('[name="phonebookCompany[formDefinition]"]');
			if (!input.length) {
				input = $('<input/>', {
					type: 'hidden',
					name: 'phonebookCompany[formDefinition]'
				});
			}
			input.val(JSON.stringify(formEditor.getDefinition()));
			editorEl.append(input);
		});
	}

	findPhonebookTab(data) {
		return find(data.result.tabs, {
			key: 'phonebook'
		});
	}
}