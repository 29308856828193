module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	<span class=\"fa fa-check text-green\"></span>&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.displayName : depth0), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<a href=\"/dialer/dial?extension="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "&number="
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.caller : depth0)) != null ? stack1.phoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\"\n	class=\"btn "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.callbackAttempted : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + " btn-sm noparse callback-button\"\n	data-number=\""
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.caller : depth0)) != null ? stack1.phoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\"\n	data-extension=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "\"\n	data-callid= "
    + alias2(alias1((depth0 != null ? depth0.callID : depth0), depth0))
    + ">\n		<span class=\"fa fa-phone\"></span>\n		"
    + alias2(helpers.tr.call(alias3,"button.call",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n\n	<a href=\"/queues/confirmCallback?callID="
    + alias2(alias1((depth0 != null ? depth0.callID : depth0), depth0))
    + "\" class=\"btn btn-outline-primary btn-sm queues-confirm-callback noparse nofollow\" data-callid=\""
    + alias2(alias1((depth0 != null ? depth0.callID : depth0), depth0))
    + "\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n		<span class=\"fa fa-check\"></span>\n		"
    + alias2(helpers.tr.call(alias3,"button.complete",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.caller : depth0)) != null ? stack1.phoneNumber : stack1), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.caller : depth0)) != null ? stack1.fullPhoneNumber : stack1), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    return "btn-outline-warning";
},"10":function(container,depth0,helpers,partials,data) {
    return "btn-outline-success";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.callbackCompleted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})