module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "	<div class=\"card\" id=\"wizard-container\">\n		<div class=\"card-header\">\n			<span class=\"fa fa-download\"></span> "
    + alias2(helpers.tr.call(alias1,"label.import_dataset",{"name":"tr","hash":{},"data":data}))
    + "\n		</div>\n		<div class=\"card-body\">\n			"
    + alias2(helpers.log.call(alias1,depth0,{"name":"log","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.result : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"card-footer d-flex\">\n			<button class=\"btn btn-primary ml-auto\" type=\"submit\" form=\"import-form\">\n				"
    + alias2(helpers.tr.call(alias1,"button.next",{"name":"tr","hash":{},"data":data}))
    + "&nbsp;<span class=\"fa fa-arrow-right\"></span>\n			</button>\n		</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "			<form action=\"/campaigns/import\" method=\"POST\" id=\"import-form\">\n				<input type=\"hidden\" name=\"step\" value=\"2\">\n				<input type=\"hidden\" name=\"dataset_name\" value=\""
    + alias2(alias1((depth0 != null ? depth0.dataset_name : depth0), depth0))
    + "\">\n				<input type=\"hidden\" name=\"file\" value=\""
    + alias2(alias1((depth0 != null ? depth0.file : depth0), depth0))
    + "\">\n\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.columns : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"form-row\">\n					<div class=\"form-group col-md-2\">\n						<label for=\"settings[encoding]\">"
    + alias2(helpers.tr.call(alias3,"import.encoding",{"name":"tr","hash":{},"data":data}))
    + "</label>\n						<select class=\"pickle\" id=\"settings[encoding]\" name=\"settings[encoding]\" data-searchable=\"false\">\n							<option value=\"UTF8\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.UTF8 : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">UTF8</option>\n							<option value=\"CP1250\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.CP1250 : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">CP1250</option>\n						</select>\n					</div>\n				</div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.Csv : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"form-row\">\n					<div class=\"form-group col\">\n						<div class=\"custom-control custom-checkbox custom-control-inline\">\n							<input type=\"checkbox\" name=\"settings[header]\" id=\"settings[header]\" class=\"custom-control-input\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.header : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n							<label class=\"custom-control-label\" for=\"settings[header]\">"
    + alias2(helpers.tr.call(alias3,"import.first_row_header",{"name":"tr","hash":{},"data":data}))
    + "</label>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"card table-responsive\">\n					"
    + ((stack1 = container.invokePartial(partials.editTable,depth0,{"name":"editTable","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</div>\n			</form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<input type=\"hidden\" name=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][name]\" value=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "					<div class=\"form-row\">\n						<div class=\"form-group col-md-2\">\n							<label for=\"settings[delimiter]\">"
    + alias2(helpers.tr.call(alias1,"import.separator",{"name":"tr","hash":{},"data":data}))
    + "</label>\n							<input type=\"text\" class=\"form-control\" id=\"settings[delimiter]\" name=\"settings[delimiter]\" placeholder=\";\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.delimiter : stack1), depth0))
    + "\" autocomplete=\"off\">\n						</div>\n\n						<div class=\"form-group col-md-2\">\n							<label for=\"settings[enclosure]\">"
    + alias2(helpers.tr.call(alias1,"import.enclosure",{"name":"tr","hash":{},"data":data}))
    + "</label>\n							<input type=\"text\" class=\"form-control\" id=\"settings[enclosure]\" name=\"settings[enclosure]\" placeholder='\"' value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.enclosure : stack1), depth0))
    + "\" autocomplete=\"off\">\n						</div>\n					</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})