<template>
    <Modal large @close="cancel">
        <template slot="title">
            <span class="fas fa-envelope"></span> Novi mail
        </template>
        <MailEditor
            ref="editor"
            v-bind="editorData"
            @update:canSubmit="can => canSubmit = can"
            :showCancelSaveButtons="false"
        />

        <template slot="footer">
            <button
                type="button"
                class="btn btn-link text-danger ml-auto"
                :disabled="isSaving"
                @click.prevent="cancel"
            >{{ tr("button.cancel") }}</button>
            <button
                type="button"
                class="btn btn-success"
                :disabled="!canSubmit || isSaving"
                @click.prevent="submit"
            >
                <span class="fa fa-paper-plane"></span>
                {{ tr('button.send') }}
            </button>
        </template>
    </Modal>
</template>

<script>
import Modal from 'BootQuery/Assets/js/bootstrap-components/Modal.vue';
import MailEditor from 'app/Modules/Mails/Assets/components/MailEditor.vue';
export default {
	components: {
		Modal,
		MailEditor
	},
	props: {
		editorData: Object
	},
	data() {
		return {
			canSubmit: false,
			isSaving: false
		};
	},
	methods: {
		getTicketingModule() {
			return window.BootQuery.getModuleInstance('Ticketing');
		},
		submit() {
			if (!this.canSubmit) {
				return;
			}
			const data = this.$refs.editor.getData();
			this.isSaving = true;
			this.getTicketingModule().submitMailEditor(data);
		},
		cancel() {
			this.getTicketingModule().destroyMailEditor();
		}
	}
};
</script>
