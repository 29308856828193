module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a href=\"/phonebook/viewPerson/?personID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.ID : stack1), depth0))
    + "\">\n                <fa class=\"fa fa-id-card\"></fa>&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.fullName : stack1), depth0))
    + "\n            </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a href=\"/phonebook/viewCompany/?companyID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.company : depth0)) != null ? stack1.ID : stack1), depth0))
    + "\">\n                <fa class=\"fa fa-building\"></fa>&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.company : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n            </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div>\n        <strong>\n            <span class=\"fas fa-building\"></span>\n            Tvrtka:\n        </strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.companyID : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n    <br>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a href=\"/phonebook/viewCompany/?companyID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.companyID : stack1), depth0))
    + "\">\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.person : depth0)) != null ? stack1.companyName : stack1), depth0))
    + "\n            </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            -\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div>\n    <h5>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.person : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.company : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </h5>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.person : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias3(alias2(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias3(alias2(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?"
    + alias3(alias2((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-callerPhoneNumber_like_alnum="
    + alias3(alias2((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "&"
    + ((stack1 = container.invokePartial(partials.encode_url,depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n        <span class=\"fa fa-filter\"></span>\n        &nbsp;"
    + alias3(helpers.tr.call(alias1,"Calls:button.show_calls_from_number",{"name":"tr","hash":{},"data":data}))
    + "\n    </a>\n    <a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias3(alias2(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias3(alias2(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?"
    + alias3(alias2((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-calleePhoneNumber_like_alnum="
    + alias3(alias2((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "&"
    + ((stack1 = container.invokePartial(partials.encode_url,depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n        <span class=\"fa fa-filter\"></span>\n        &nbsp;"
    + alias3(helpers.tr.call(alias1,"Calls:button.show_calls_to_number",{"name":"tr","hash":{},"data":data}))
    + "\n    </a>\n	<br>\n	<button class=\"btn btn-success btn-block text-center clickvox-dial-button\" data-number=\""
    + alias3(alias2((depth0 != null ? depth0.numberE164 : depth0), depth0))
    + "\">\n		<span class=\"fa fa-phone\"></span> "
    + alias3(helpers.tr.call(alias1,"Dialer:button.call",{"name":"tr","hash":{},"data":data}))
    + " "
    + alias3(alias2((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\n	</button>\n</div>\n";
},"usePartial":true,"useData":true})