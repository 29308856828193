<template>
    <div class="message">
        <div class="message-bubble" :class="direction ? 'message-bubble-left' : 'message-bubble-right'">
            <div class="message-content" v-html="content">
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		direction: Boolean,
		content: String,
		timestamp: Date
	}
};
</script>

<style lang="scss" scoped>
    .message {
        width: 100%;
        clear: both;
        margin-bottom: 15px;
        display: inline-block;
    }
    .message-bubble {
        background-color: #eeeeee;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        padding: 10px 15px;
        position: relative;
    }

    .message-content /deep/ p {
        margin: 0;
    }

    .message-bubble /deep/ img {
        max-width: 100%;
    }

    .message-bubble:before {
        content: '';
        width: 0px;
        height: 0px;
        top: 0px;
        position: absolute;
    }

    .message-bubble-left {
        float: left;
        margin-left: 14px;
    }

    .message-bubble-left:before {
        border-left: 8px solid transparent;
        border-right: 8px solid #eeeeee;
        border-top: 8px solid #eeeeee;
        border-bottom: 8px solid transparent;
        border-top-left-radius: 4px;
        left: -14px;
    }

    .message-bubble-right {
        float: right;
        margin-right: 14px;
    }

    .message-bubble-right:before {
        border-left: 8px solid #eeeeee;
        border-right: 8px solid transparent;
        border-top: 8px solid #eeeeee;
        border-bottom: 8px solid transparent;
        border-top-right-radius: 4px;
        right: -14px;
    }
</style>
