import Field from './field';
import uuid4 from 'uuid/v4';
import {merge, sortBy, map, findIndex, cloneDeep, forEach, pickBy, mapValues, flow} from 'lodash';

export default class Select extends Field {
	constructor(optionsOrType) {
		super(optionsOrType);
		if (!this.options.selects) {
			this.options.selects = {};
		}
		if (!this.options.order) {
			this.options.order = [];
		}
	}

	renderFieldSettings() {
		let el = $('<div>Loading...</div>');

		getTemplate('formEditorSelectSettings').then(template => {
			let rendered = $.render(template, this.options);
			rendered.findElement('input, textarea, select')
				.off('change.formBuilder')
				.on('change.formBuilder', (e) => {
					let settings = merge({}, this.options, getFormData(rendered));
					this.emit('settingsChange', settings);
				});
			rendered.findElement('.edit-items-btn')
				.off('click.formButton')
				.on('click.formButton', (e) => {
					e.preventDefault();
					this.showListEditor($(e.currentTarget));
				});
			el.replaceWith(rendered);
		});

		return el;
	}

	async showListEditor(button) {
		let modal = $.render(await getTemplate('modal'), {});
		modal.find('.modal-header').text(tr('label.edit_items'));
		modal.find('.modal-dialog').addClass('modal-lg').attr('style', '');
		modal.find('.modal-body').addClass('p-0');

		let modalButtons = $.render(await getTemplate('formEditorModalButtons'), {});
		modalButtons.findElement('button[type=submit]')
			.off('click.formBuilder')
			.on('click.formBuilder', (e) => {
				e.preventDefault();

				const items = getFormData(modal.find('.list-group-item'));
				this.applyItemChanges(items);

				modal.modal('hide');
			});
		modal.find('.modal-footer').append(modalButtons);
		modal.attr('id', 'select-list-edit-modal');

		let order = this.options.order || [];
		let items = this.spreadItems(this.options.items);
		let selects = Object.entries(this.options.selects).map(pair => merge(pair[1], {key: pair[0]}));
		if (order.length) {
			selects = sortBy(selects, opt => order.indexOf(opt.key));
		}
		selects = map(selects, (select, index) => {
			if (items[index]) {
				select.items = items[index];
			}
			return select;
		});

		this.orderedSelects = selects;
		this.editor = $.render(await getTemplate('formEditorSelectItems'), {
			selects: selects
		});
		if (selects.length) {
			this.focusSelectItem(selects[0].key);
		}
		this.bindSelectPane(this.editor);

		this.editor.find('.add-select-btn')
			.off('click.formEditor')
			.on('click.formEditor', (e) => {
				e.preventDefault();
				this.addSelect();
			});

		modal.findElement('.modal-body').html(this.editor);
		modal = modal.appendTo('body');
		$('#select-list-edit-modal').modal('show');
		modal.on('hidden.bs.modal', (e) => {
			modal.modal('dispose');
			$('#select-list-edit-modal').remove();
		});
	}

	async addSelect() {
		let newSelect = {
			key: uuid4(),
			label: 'New dropdown',
			new: true,
			items: {}
		};
		this.orderedSelects.push(newSelect);

		let template = await getTemplate('formEditorSelectItemsCol');
		let selectEl = $(handlebarsRender(template, newSelect));
		this.bindSelectPane(selectEl);
		this.editor.findElement('.card-group').append(selectEl);
	}

	bindSelectPane(el) {
		this.bindItemRow(el.findElement('.list-group-item'));

		el.findElement('.select-list-add-btn')
			.off('click.formEditor')
			.on('click.formEditor', async (e) => {
				e.preventDefault();
				let selectName = $(e.currentTarget).closest('[data-select]').data('select');
				this.addItem(selectName);
			});
	}

	bindItemRow(itemRow) {
		itemRow.find('.select-list-delete-btn').off('click.formEditor').on('click.formEditor', (e) => {
			e.preventDefault();
			e.stopPropagation();

			let itemName = $(e.currentTarget).closest('.list-group-item').data('selectItem');
			let selectName = $(e.currentTarget).closest('[data-select]').data('select');
			this.deleteItem(selectName, itemName);
		});

		itemRow.findElement('.list-group-item').off('click.formEditor').on('click.formEditor', (e) => {
			e.preventDefault();
			let key = $(e.currentTarget).data('selectItem');
			let selectName = $(e.currentTarget).closest('[data-select]').data('select');
			this.focusSelectItem(selectName, key);
		});
	}

	deleteItem(selectName, itemName) {
		let select = this.selectColByName(selectName);
		let row = select.find(`.list-group-item[data-select-item="${itemName}"]`);

		if (row.find('input[name$="[new]"]').val() === 'true') {
			row.remove();
		} else {
			row.prop('hidden', true);
			row.removeClass('d-flex'); // It has "!important" and breaks hidden
			row.find('input[name$="[deleted]"]').val('true');
			row.attr('data-deleted', 'true');
		}
		this.focusSelectItem(selectName);
	}

	async addItem(selectName) {
		let select = this.selectColByName(selectName);
		let parentKey = null;
		let prevSelect = this.prevSelect(selectName);
		if (prevSelect) {
			let prevSelectEl = this.selectColByName(prevSelect.key);
			parentKey = prevSelectEl.find('.list-group-item.active').data('selectItem');
		}

		let item = $.render(await getTemplate('formEditorSelectItem'), {
			id: uuid4(),
			parentKey: parentKey,
			name: 'New item',
			isNew: true
		});
		this.bindItemRow(item);

		select.findElement('.list-group').append(item);
		this.focusSelectItem(selectName, item.id);
	}

	focusSelectItem(selectName, itemKey) {
		let select = this.selectColByName(selectName);

		let item = null;
		if (itemKey) {
			item = select.find(`.list-group-item[data-select-item="${itemKey}"]`);
		} else {
			item = select.find('.list-group-item[data-select-item]').not('[hidden], [data-deleted="true"]').first();
			itemKey = item.data('selectItem');
		}

		select.find('.list-group-item').removeClass('active');
		item.addClass('active');

		let nextSelect = this.nextSelect(selectName);
		if (nextSelect) {
			let nextSelectEl = this.selectColByName(nextSelect.key);
			let nextSelectItems = nextSelectEl.find('.list-group-item');

			nextSelectItems.removeClass('d-flex')
				.prop('hidden', true);
			nextSelectItems.filter(`[data-parent-key="${itemKey}"]`).not('[data-deleted="true"]')
				.addClass('d-flex')
				.prop('hidden', false);

			this.focusSelectItem(nextSelect.key);
		}
	}

	selectColByName(selectName) {
		return this.editor.findElement(`.card[data-select="${selectName}"]`);
	}

	nextSelect(currentSelectKey) {
		let currentIndex = findIndex(this.orderedSelects, select => {
			return select.key === currentSelectKey;
		});
		if (currentIndex !== -1 && this.orderedSelects.length > currentIndex + 1) {
			return this.orderedSelects[currentIndex + 1];
		}
		return null;
	}

	prevSelect(currentSelectKey) {
		let currentIndex = findIndex(this.orderedSelects, select => {
			return select.key === currentSelectKey;
		});
		if (currentIndex !== -1 && currentIndex > 0) {
			return this.orderedSelects[currentIndex - 1];
		}
		return null;
	}

	spreadItems(items, level = 0, parentKey = null, collector = []) {
		items = cloneDeep(items);
		if (collector.length < level + 1) {
			collector.push({});
		}
		forEach(items, (item, key) => {
			item.parentKey = parentKey;

			let subitems = [];
			if (item.items) {
				subitems = item.items;
				delete item.items;
			}

			collector[level][key] = item;
			this.spreadItems(subitems, level + 1, key, collector);
		});

		return collector;
	}

	unspreadItems(itemsArr, level = 0, parentKey = null) {
		let ownItems = itemsArr[level];
		if (parentKey) {
			ownItems = pickBy(ownItems, (item, itemKey) => item.parentKey === parentKey);
		}

		ownItems = mapValues(ownItems, (item, itemKey) => {
			if (itemsArr.length > level + 1) {
				item.items = this.unspreadItems(itemsArr, level + 1, itemKey);
			}
			delete item.parentKey;
			return item;
		});

		return ownItems;
	}

	applyItemChanges(submittedItems) {
		let itemsArr = Array(this.orderedSelects.length).fill({});
		forEach(this.orderedSelects, (select, level) => {
			if (!this.options.selects[select.key]) {
				this.options.selects[select.key] = select;
				this.options.order.push(select.key);
			}

			const colEl = this.selectColByName(select.key);
			let newLabel = colEl.find('.card-header > .label-text').text();
			if (typeof(newLabel) === 'string') {
				newLabel = newLabel.trim();
			}
			if (newLabel) {
				this.options.selects[select.key].label = newLabel;
			}

			let colItems = getFormData(colEl);
			colItems = flow(
				colItem => mapValues(colItem, item => {
					item.deleted = item.deleted === 'true';
					item.new = item.new === 'true';
					item.name = item.name.trim();
					return item;
				}),
				colItem => pickBy(colItem, item => {
					if (item.deleted && !item.new) {
						return false;
					}
					return true;
				})
			)(colItems);
			itemsArr[level] = colItems;
		});

		let items = this.unspreadItems(itemsArr);
		this.options.items = items;

		return this.options;
	}
}