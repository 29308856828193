<template>
	<div class="form-group row">
		<slot name="label">
			<label
				class="col-form-label"
				:class="labelSizeClasses"
				v-if="label && label.length"
				:for="fieldId"
			>{{label}}:</label>
		</slot>
		<slot name="control">
			<div :class="inputSizeClasses">
				<VueSelect
					ref="vueSelect"
					:value="selectValue"
					:disabled="disabled"
					:options="fullOptions"
					:multiple="multiple"
					:onSearch="onSearch"
					:label="textProp"
					:onChange="changeHandler"
					:filterable="filterable"
					:clearable="!required"
					:inputId="fieldId"
					@keyup.native="searchEmptyString"
				>
					<template slot="selected-option" slot-scope="option">
						<slot name="selected-option" v-bind="option">{{getOptionLabel(option)}}</slot>
					</template>
					<template slot="option" slot-scope="option">
						<slot name="option" v-bind="option">{{getOptionLabel(option)}}</slot>
					</template>
				</VueSelect>
			</div>
		</slot>
	</div>
</template>

<script>
import VueSelect from 'vue-select/src/index.js';
import fieldProps from './mixins/fieldProps';
import { get, find, isArray, isEqual } from 'lodash';
export default {
	mixins: [fieldProps],
	props: {
		options: Array,
		multiple: {
			default: false,
			type: Boolean
		},
		valueProp: {
			default: 'id',
			type: [String, Array]
		},
		textProp: {
			default: 'label',
			type: [String, Array]
		},
		onSearch: {
			default: undefined,
			type: Function
		},
		onChange: Function,
		filterable: {
			default: true,
			type: Boolean
		}
	},
	components: {
		VueSelect
	},
	data() {
		return {
			selectValue: this.valueForVueSelect(this.value)
		};
	},
	methods: {
		transformValue(inputValue) {
			if (typeof inputValue !== 'object') {
				let option = null;
				if (this.valueProp !== null && this.valueProp !== undefined) {
					option = find(this.getFullOptions(this.options), {
						[this.valueProp]: inputValue
					});
					if (
						!option &&
						this.textProp !== null &&
						this.textProp !== undefined
					) {
						option = {
							[this.valueProp]: inputValue,
							[this.textProp]: inputValue
						};
					}
				}
				inputValue = option;
			}
			return inputValue;
		},
		valueForVueSelect(value) {
			if (this.multiple) {
				if (!isArray(value)) {
					if (value !== undefined) {
						return [this.transformValue(value)];
					} else {
						return [];
					}
				} else {
					return value.map(this.transformValue);
				}
			} else {
				return this.transformValue(value);
			}
		},
		getOptionLabel(option) {
			if (typeof option === 'string') {
				return option;
			}
			if (this.hasTextProp) {
				return get(option, this.textProp);
			} else {
				return option;
			}
		},
		getFullOptions(options) {
			return options.map(option => {
				if (typeof option === 'object') {
					return option;
				} else {
					if (this.hasValueProp && this.hasTextProp) {
						return {
							[this.valueProp]: option,
							[this.textProp]: option
						};
					} else {
						return null;
					}
				}
			});
		},
		changeHandler(newVal) {
			const transVal = val => {
				if (this.hasValueProp) {
					return get(val, this.valueProp);
				} else {
					return val;
				}
			};

			let outVal = newVal;
			if (this.multiple) {
				if (isArray(outVal)) {
					outVal = outVal.map(transVal);
				} else {
					outVal = transVal(outVal);
				}
			} else {
				outVal = transVal(outVal);
			}

			if (typeof this.onChange === 'function') {
				// If true is returned from onChange handler then prevent change, otherwise continue
				// It's wrapped in Promise.resolve because the return type can optionally
				// be a promise in case we're doing something async
				const prev = this.selectValue;
				Promise.resolve(this.onChange(outVal, newVal)).then(cbOut => {
					if (cbOut !== true) {
						this.$emit('input', outVal);
					} else {
						this.selectValue = JSON.parse(JSON.stringify(prev));
					}
				});
			} else {
				this.$emit('input', outVal);
			}
		},
		searchEmptyString(string) {
			const vueSelect = this.$refs.vueSelect;
			const searchStr = vueSelect.search;
			if (searchStr.length === 0) {
				vueSelect.onSearch(searchStr, vueSelect.toggleLoading);
			}
		}
	},
	watch: {
		value(newVal) {
			const newSelectValue = this.valueForVueSelect(newVal);
			if (!isEqual(this.selectValue, newSelectValue)) {
				this.selectValue = newSelectValue;
			}
		}
	},
	computed: {
		hasValueProp() {
			return this.valueProp !== null && this.valueProp !== undefined;
		},
		hasTextProp() {
			return this.textProp !== null && this.textProp !== undefined;
		},
		fullOptions() {
			return this.getFullOptions(this.options);
		}
	}
};
</script>

<style lang="scss" scoped>
/deep/ .form-control {
	height: calc(2.25rem - 8px);
}
</style>