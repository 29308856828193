module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav class=\"navbar px-0 py-0 menu-container\" >\n    <ul class=\"navbar-nav flex-column mt-0\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Menu)) && stack1.menus)) && stack1.main),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <li class=\"nav-item mt-auto menu-toggle-item\">\n            <a href=\"#\" class=\"nav-link py-1 px-3 text-center menu-toggle-btn\">\n                <span class=\"fa fa-chevron-left menu-toggle-icon-fold\"></span>\n                <span class=\"fa fa-chevron-right menu-toggle-icon-expand\"></span>\n            </a>\n        </li>\n    </ul>\n</nav>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "            <li class=\"nav-item "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.active : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-title=\""
    + alias2(helpers.tr.call(alias1,helpers.concat.call(alias1,(depth0 != null ? depth0.module : depth0),":menu_entries.",(depth0 != null ? depth0.name : depth0),{"name":"concat","hash":{},"data":data}),{"name":"tr","hash":{},"data":data}))
    + "\">\n                <a class=\"nav-link px-3 py-2\" href=\"/"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.method : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-controller=\""
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.controller : stack1), depth0))
    + "\" data-method=\""
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.method : stack1), depth0))
    + "\" data-order=\""
    + alias2(alias3((depth0 != null ? depth0.order : depth0), depth0))
    + "\">\n                    <div class=\"row flex-nowrap\">\n                        <div class=\"col menu-item-icon\">\n                            <span class=\"icon-with-badge\">\n                                <span class=\""
    + alias2(alias3((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>\n                                <span class=\"badge badge-danger menu-item-notification-counter\" hidden></span>\n                            </span>\n                        </div>\n                        <div class=\"col px-2 menu-item-label\">\n                            <div>"
    + alias2(helpers.tr.call(alias1,helpers.concat.call(alias1,(depth0 != null ? depth0.module : depth0),":menu_entries.",(depth0 != null ? depth0.name : depth0),{"name":"concat","hash":{},"data":data}),{"name":"tr","hash":{},"data":data}))
    + "</div>\n                        </div>\n                    </div>\n                </a>\n            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "active";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.method : stack1), depth0))
    + "/";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})