module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	Da\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "	<a href=\"/voicemails/markAsHeard?mailbox="
    + alias2(alias1((depth0 != null ? depth0.mailbox : depth0), depth0))
    + "&context="
    + alias2(alias1((depth0 != null ? depth0.context : depth0), depth0))
    + "&fileName="
    + alias2(alias1((depth0 != null ? depth0.messageFileName : depth0), depth0))
    + "&voicemailID="
    + alias2(alias1((depth0 != null ? depth0.voicemailID : depth0), depth0))
    + "\" class=\"btn btn-outline-primary btn-sm\" class=\"noparse nofollow\">\n		"
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.mark_as_listened",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.heard : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})