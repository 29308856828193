module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "✔️ Odgovoren poziv od *"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.callerName : depth0), depth0))
    + "*\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "❌ Propusten poziv od *"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.callerName : depth0), depth0))
    + "*\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "Vidi u clickvox: "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.clickvox_root : depth0), depth0))
    + "/calls/all/\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n- Broj: "
    + alias3(alias2((depth0 != null ? depth0.numberFormatted : depth0), depth0))
    + "\n- Vrijeme: "
    + alias3(alias2((depth0 != null ? depth0.timestamp : depth0), depth0))
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.clickvox_root : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})