import VueRouter from 'vue-router';
import { trim } from 'lodash';

import ConversationList from '../components/ConversationList.vue';
import ConversationArea from '../components/ConversationArea.vue';

// Export as factory function so we construct a new instance every time
// Otherwise, other app links won't refresh the router's internal stuff
// And it will think it's on another page
export default function getRouter() {
	const router = new VueRouter({
		mode: 'history',
		base: '/sms/',
		routes: [
			{
				path: '/conversations/:phoneNumber?',
				components: {
					conversationList: ConversationList,
					conversationArea: ConversationArea
				}
			}
		]
	});

	router.afterEach((to, _from) => {
		const method = trim(to.path, '/');
		window.Bootstrap.bootquery.method = method;
		window.Bootstrap.bootquery.parameters = to.query;
	});

	return router;
}
