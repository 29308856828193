module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "col-xl-12";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xl-6";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.CallStart : depth0), depth0),{"name":"CallStart","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "data-callelapsed=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.CallElapsed : depth0), depth0))
    + "\"";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "data-pauseid=\""
    + alias2(alias1((depth0 != null ? depth0.pauseID : depth0), depth0))
    + "\" data-pauseintended=\""
    + alias2(alias1((depth0 != null ? depth0.intendedDuration : depth0), depth0))
    + "\" data-pausestart=\""
    + alias2(alias1((depth0 != null ? depth0.startTime : depth0), depth0))
    + "\"";
},"10":function(container,depth0,helpers,partials,data) {
    return "						btn-yellow\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Idle : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.InUse : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Busy : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Unavailable : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Ringing : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Hold : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Unknown : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "btn-success";
},"15":function(container,depth0,helpers,partials,data) {
    return "btn-danger";
},"17":function(container,depth0,helpers,partials,data) {
    return "							<span class=\"queue-login-icon text-success fa fa-sign-in-alt\"></span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "							<span class=\"queue-login-icon text-danger fa fa-sign-out-alt\"></span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "							"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<a href=\"/callcenter/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "/dial/\" class=\"extension-action nofollow noparse dropdown-item\" data-target-extension=\""
    + alias2(alias1((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\">\n								<span class=\"fa fa-phone\"></span> Poziv\n							</a>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.callcenter : depth0)) != null ? stack1.canUseAdvancedFeatures : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "								<a href=\"/callcenter/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "/spy/\" class=\"extension-action nofollow noparse dropdown-item\" data-target-extension=\""
    + alias2(alias1((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\">\n									<span class=\"fa fa-assistive-listening-systems\"></span> Prisluškivanje\n								</a>\n								<a href=\"/callcenter/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "/whisper/\" class=\"extension-action nofollow noparse dropdown-item\" data-target-extension=\""
    + alias2(alias1((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\">\n									<span class=\"fa fa-user-secret\"></span> Šaptanje agentu\n								</a>\n								<a href=\"/callcenter/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "/barge/\" class=\"extension-action nofollow noparse dropdown-item\" data-target-extension=\""
    + alias2(alias1((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\">\n									<span class=\"fa fa-users\"></span> Upad u poziv\n								</a>\n								<a href=\"/callcenter/"
    + alias2(alias1((depth0 != null ? depth0.Extension : depth0), depth0))
    + "/hangup/\" class=\"extension-action nofollow noparse dropdown-item text-danger\" data-target-extension=\""
    + alias2(alias1((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\">\n									<span class=\"fa fa-times\"></span> Prekid poziva\n								</a>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "				badge-yellow\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Idle : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.InUse : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Busy : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Unavailable : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Ringing : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Hold : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Unknown : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "badge-secondary";
},"35":function(container,depth0,helpers,partials,data) {
    return "badge-primary";
},"37":function(container,depth0,helpers,partials,data) {
    return "badge-success";
},"39":function(container,depth0,helpers,partials,data) {
    return "badge-light";
},"41":function(container,depth0,helpers,partials,data) {
    return "badge-orange";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Pauses : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    return "						"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.Name : depth0), depth0))
    + "\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Idle : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.InUse : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Busy : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Unavailable : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Ringing : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Hold : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Unknown : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"48":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.available",{"name":"tr","hash":{},"data":data}));
},"50":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.in_call",{"name":"tr","hash":{},"data":data}));
},"52":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.calling",{"name":"tr","hash":{},"data":data}));
},"54":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.unavailable",{"name":"tr","hash":{},"data":data}));
},"56":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.ringing",{"name":"tr","hash":{},"data":data}));
},"58":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.on_hold",{"name":"tr","hash":{},"data":data}));
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Calls : depth0),{"name":"each","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.BridgedCalls : depth0),{"name":"each","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ConnectingCalls : depth0),{"name":"each","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					<a href=\"#\" class=\"noparse btn btn-outline-primary btn-sm callcenter-contact-display\" data-toggle=\"popover\" title=\""
    + ((stack1 = container.invokePartial(partials.connectedPartyPopoverTitle,depth0,{"name":"connectedPartyPopoverTitle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\" data-content=\""
    + ((stack1 = container.invokePartial(partials.connectedPartyPopover,depth0,{"name":"connectedPartyPopover","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\" data-html=\"true\" data-container=\"body\" data-trigger=\"click\">\n						"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\n					</a>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					<a href=\"#\" class=\"noparse btn btn-outline-success btn-sm callcenter-contact-display\" data-toggle=\"popover\" title=\""
    + ((stack1 = container.invokePartial(partials.connectedPartyPopoverTitle,depth0,{"name":"connectedPartyPopoverTitle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\" data-content=\""
    + ((stack1 = container.invokePartial(partials.connectedPartyPopover,depth0,{"name":"connectedPartyPopover","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\" data-html=\"true\" data-container=\"body\" data-trigger=\"click\">\n						"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\n					</a>\n";
},"66":function(container,depth0,helpers,partials,data) {
    return "text-danger";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Idle : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"69":function(container,depth0,helpers,partials,data) {
    return "badge-yellow";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Calls : depth0),{"name":"each","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"72":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.RingDuration : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data})) != null ? stack1 : "");
},"73":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.RingDuration : depth0), depth0))
    + "\n";
},"75":function(container,depth0,helpers,partials,data) {
    return "							00:00:00\n";
},"77":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.InUse : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.program(82, data, 0),"data":data})) != null ? stack1 : "");
},"78":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Calls : depth0),{"name":"each","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"79":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.CallElapsed : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(75, data, 0),"data":data})) != null ? stack1 : "");
},"80":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.CallElapsed : depth0), depth0))
    + "\n";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.Busy : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.program(83, data, 0),"data":data})) != null ? stack1 : "");
},"83":function(container,depth0,helpers,partials,data) {
    return "					00:00:00\n				";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.blockHelperMissing;

  return "<div class=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Callcenter)) && stack1.showQueueCallers),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " col-lg-12 col-md-12 agent-row mb-3\" data-extension=\""
    + alias3(alias2((depth0 != null ? depth0.Extension : depth0), depth0))
    + "\" "
    + ((stack1 = alias4.call(depth0,alias2((depth0 != null ? depth0.Calls : depth0), depth0),{"name":"Calls","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = alias4.call(depth0,alias2((depth0 != null ? depth0.Pause : depth0), depth0),{"name":"Pause","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-status=\""
    + alias3(alias2((depth0 != null ? depth0.Status : depth0), depth0))
    + "\">\n	<div class=\"row\">\n		<div class=\"col-xl-4 col-lg-4 col-md-4 col-sm-4\">\n			<div class=\"btn-group w-100\" role=\"group\">\n				<button type=\"button\" class=\"btn\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Pauses : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "					agent-status-label\" style=\"white-space: nowrap; width: auto;\">\n						<span class=\"fa fa-power-off\"></span>\n				</button>\n\n				<div class=\"btn-group w-100\" role=\"group\">\n					<button class=\"btn btn-light agent-status-label dropdown-toggle\" data-toggle=\"dropdown\" style=\"white-space: nowrap\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.LoggedIn : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ContactInfo : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "					</button>\n					<div class=\"dropdown-menu dropdown-menu-right\">\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.Calls : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</div>\n		</div>\n\n		<div class=\"col-xl-3 col-lg-2 col-md-3 col-sm-4\">\n			<span class=\"badge\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Pauses : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "			agent-status-label\" style=\"white-space: nowrap;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Pauses : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(47, data, 0),"data":data})) != null ? stack1 : "")
    + "			</span>\n		</div>\n\n		<div class=\"col-xl-3 col-lg-4 col-md-3 col-sm-4\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.Idle : depth0),{"name":"unless","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n		<div class=\"col-xl-2 col-lg-2 col-md-2 col-sm-4\">\n			<span class=\"badge badge-light agent-status-label call-time "
    + ((stack1 = alias4.call(depth0,alias2(((stack1 = (depth0 != null ? depth0.Pause : depth0)) != null ? stack1.isPauseOverdue : stack1), depth0),{"name":"Pause.isPauseOverdue","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Pauses : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.Ringing : depth0),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.program(77, data, 0),"data":data})) != null ? stack1 : "")
    + "			</span>\n		</div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true})