module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.note : depth0), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.add_note",{"name":"tr","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"popover-content-element\" hidden>\n    <div class=\"input-group\">\n        <input type=\"text\" class=\"form-element\" value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.note : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" placeholder=\""
    + alias2(helpers.tr.call(alias1,"label.add_note",{"name":"tr","hash":{},"data":data}))
    + "\">&nbsp;\n        <button class=\"btn btn-sm btn-primary queues-save-note\" data-callID=\""
    + alias2(alias3((depth0 != null ? depth0.callID : depth0), depth0))
    + "\" data-id=\""
    + alias2(alias3((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"><span class=\"fa fa-check\"></span></button>\n    </div>\n</div>\n\n<span\n    class=\"edit-note\"\n    data-callID=\""
    + alias2(alias3((depth0 != null ? depth0.callID : depth0), depth0))
    + "\"\n    data-toggle=\"popover\"\n    data-placement=\"left\"\n    data-container=\"body\"\n    data-trigger=\"click\"\n    data-html=\"true\"\n    data-popover-content-element=\"true\"\n    data-original-title=\"<span class='fa fa-pencil-alt'></span>&nbsp;"
    + alias2(helpers.tr.call(alias1,"label.edit_note",{"name":"tr","hash":{},"data":data}))
    + "\"\n    tabindex=\"1\"\n>\n    <span class=\"fa fa-edit\"></span>&nbsp;\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.note : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n</span>";
},"useData":true})