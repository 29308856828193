<template>
    <div class="card h-100">
        <div class="card-header text-center">
            {{ customer.displayName }}
            <button v-if="state === 'active'" class="btn btn-danger btn-sm float-right" @click.prevent="endChat">
                End chat
            </button>
            <button v-else-if="state === 'ended'" class="btn btn-danger btn-sm float-right"  @click.prevent="closeChat">
                <span class="fas fa-times"></span>
            </button>
        </div>
        <div class="card-body" ref="messageArea">
            <Message
                v-for="(message, index) in messages" :key="index"
                :direction="message.direction"
                :content="message.content"
                :timestamp="message.timestamp"
            />
        </div>
        <div class="card-block">
            <ChatInput v-if="state === 'active'" @submit="sendMessage" />
            <div v-else-if="state === 'ended'" class="text-center border-top p-3">
                <a
                    :href="`/ticketing/create/?fromChatID=${chatID}`"
                    class='btn btn-primary btn-lg'
                >
                    <span class="fa fa-list-alt"></span>
                    Pretvori u ticket
                </a>
                <button type="button" class="btn btn-danger btn-lg" @click.prevent="closeChat">
                    <span class="fas fa-times"></span>
                    Zatvori
                </button>
            </div>
            <div v-else class="text-center border-top p-3">
                <button type="button" class="btn btn-primary btn-lg" @click.prevent="acceptChat">
                    Preuzmi
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Message from './Message.vue';
import ChatInput from './ChatInput.vue';

export default {
	components: {
		Message,
		ChatInput
	},
	props: {
		messages: Array,
		customer: Object,
		state: String,
		chatID: {}
	},
	methods: {
		acceptChat() {
			this.$emit('acceptChat', this.chatID);
		},
		endChat() {
			this.$emit('endChat', this.chatID);
		},
		closeChat() {
			this.$emit('closeChat', this.chatID);
		},
		sendMessage(message) {
			this.$emit('sendMessage',  message);
		},
		scrollToBottom() {
			this.$nextTick(() => {
				const messageArea = this.$refs.messageArea;
				messageArea.scrollTo(0, messageArea.scrollHeight);
			});
		}
	},
	watch: {
		messages(newMessages, oldMessages) {
			this.scrollToBottom();
		}
	}
};
</script>

<style lang="scss" scoped>
    @import "~quill/dist/quill.bubble.css";

    .card {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border: none;
    }

    .card-body {
        overflow-y: auto;
    }
</style>
