<template>
	<div class="card">
		<div class="card-header py-2 d-flex">
			<router-link
				v-if="$mq !== 'xl' && $mq !== 'lg'"
				tag="a"
				:to="`/${$route.params.accountID}/threads`"
				class="btn btn-link"
			>
				<span class="fas fa-arrow-left"></span>
			</router-link>
			<template v-if="thread">
				<strong class="thread-title" :title="thread.subject">{{thread.subject}}</strong>
				<span class="ml-auto d-flex">
					<button id="mail-tickets-info-btn" class="btn btn-link btn-sm" v-if="thread.hasTicket">
						<span class="fas fa-list-alt"></span>
						<bPopover
							target="mail-tickets-info-btn"
							:trigger="['click', 'blur']"
							placement="left"
							@show="fetchTicketList"
						>
							<template slot="title">Tickets</template>
							<template v-if="thread.tickets">
								<a
									v-for="ticket in thread.tickets"
									:key="ticket.ID"
									:href="`/ticketing/edit/${ticket.ID}`"
								>#{{ticket.ID}}: {{ticket.title}}</a>
							</template>
							<div v-else class="spinner-grow"></div>
						</bPopover>
					</button>
					<div class="dropdown">
						<a href="#" data-toggle="dropdown" class="py-1 px-2 text-dark float-right">
							<span class="fa fa-ellipsis-v"></span>
						</a>
						<div class="dropdown-menu dropdown-menu-right">
							<h6 class="dropdown-header font-weight-bold">
								<span class="fas fa-list-alt"></span>
								{{ tr("Mails:label.ticket") }}
							</h6>
							<a class="dropdown-item" :href="`/ticketing/create/?fromMailThreadID=${threadID}`">
								<span class="fa fa-plus"></span>
								{{ tr("Mails:button.ticket_new") }}
							</a>
							<a href="#" class="dropdown-item noparse" @click.prevent="openTicketPicker">
								<span class="fa fa-link"></span>
								{{ tr("Mails:button.ticket_assign") }}
							</a>
						</div>
					</div>
					<TicketPickerModal
						v-if="ticketPickerOpen"
						@close="closeTicketPicker"
						@submit="submitTicketPicker"
					/>
				</span>
			</template>
			<div v-else-if="standaloneEditor" class="py-1 w-100 d-flex">
				<strong>{{ standaloneEditor.subject || '[ No title ]' }}</strong>
				&nbsp;
				<span class="ml-auto badge badge-success">Nova poruka</span>
			</div>
			<div v-else class="py-1">&nbsp;</div>
		</div>
		<div class="card-body py-0 thread-view">
			<div v-if="isLoading" class="text-center">
				<div class="spinner-grow m-3"></div>
			</div>
			<template v-else>
				<template v-for="(item, index) in threadItems">
					<Message
						v-if="item.type === 'message'"
						:key="item.message.ID"
						:ID="item.message.ID"
						:subject="item.message.subject"
						:date="item.message.date"
						:fromMailAddress="item.message.fromMailAddress"
						:to="item.message.to"
						:cc="item.message.cc"
						:bcc="item.message.bcc"
						:htmlBody="item.message.htmlBody"
						:textBody="item.message.textBody"
						:attachments="item.message.attachments"
						:priority="item.message.priority"
						:sent="item.message.sent"
						:collapsed="index + 3 < threadItems.length"
						cardClass="mt-3"
						@reply="type => onReply(type, item.message.rootID, item.message)"
						@forward="onForward(item.message.rootID, item.message)"
					/>
					<div
						:id="`editor-${item.editor.uuid}`"
						class="card mt-3"
						v-if="item.type === 'editor'"
						:key="item.editor.uuid"
					>
						<div class="card-body">
							<MailEditor
								:availableAccounts="availableAccounts"
								:availableTicketingGroups="availableTicketingGroups"
								:availableTicketTypes="availableTicketTypes"
								:offerTicketCreateOnSend="!item.editor.inReplyToMessageID"
								:accountID.sync="item.editor.accountID"
								:fixedAccount="item.editor.fixedAccount"
								:signatureTemplateData="signatureTemplateData"
								:to.sync="item.editor.to"
								:cc.sync="item.editor.cc"
								:bcc.sync="item.editor.bcc"
								:subject.sync="item.editor.subject"
								:content.sync="item.editor.content"
								:htmlContent.sync="item.editor.htmlContent"
								:textContent.sync="item.editor.textContent"
								:attachments.sync="item.editor.attachments"
								:priority.sync="item.editor.priority"
								:disabled="item.editor.isSending"
								@submit="data => $emit('submitEditor', item.editor.uuid, data)"
								@cancel="$emit('cancelEditor', item.editor.uuid)"
							/>
						</div>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import TicketPickerModal from './TicketPickerModal.vue';
import MailEditor from './MailEditor.vue';
import Message from './Message.vue';
import bPopover from 'bootstrap-vue/es/components/popover/popover';

import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { processThread } from '../js/util.js';

export default {
	components: {
		TicketPickerModal,
		MailEditor,
		Message,
		bPopover
	},
	props: {
		threadPreload: Object,
		editors: Array,
		availableAccounts: Array,
		availableTicketingGroups: Array,
		availableTicketTypes: Array,
		threads: Array,
		signatureTemplateData: Object
	},
	data() {
		return {
			thread: null,
			isLoading: false,
			loadedNew: false,
			ticketPickerOpen: false
		};
	},
	computed: {
		threadID() {
			const threadID = this.$route.params.threadID;
			if (!threadID) {
				return null;
			}

			if (threadID.startsWith('editor-')) {
				return threadID;
			} else {
				return parseInt(threadID);
			}
		},
		standaloneEditor() {
			if (typeof this.threadID !== 'string') {
				return null;
			}

			const uuid = this.threadID.match(/editor-(.+)/)[1];
			return this.editors.find(editor => editor.uuid === uuid);
		},
		threadItems() {
			if (!this.thread && !this.standaloneEditor) {
				return null;
			}

			if (this.standaloneEditor) {
				return [
					{
						type: 'editor',
						editor: this.standaloneEditor
					}
				];
			}

			const messages = this.thread.messages || [];
			const items = [];
			messages.forEach(message => {
				items.push({
					type: 'message',
					message
				});
				const editor = this.editors.find(
					editor => editor.inReplyToMessageID === message.ID
				);
				if (editor) {
					items.push({
						type: 'editor',
						editor: editor
					});
				}
			});

			return items;
		}
	},
	methods: {
		async fetchThread() {
			if (!this.threadID || typeof this.threadID !== 'number') {
				this.thread = null;
				this.isLoading = false;
				return;
			}

			this.isLoading = true;
			const { thread, messages } = await Api.get(
				`/api/mails/threads/${this.threadID}`
			);
			thread.messages = messages;
			thread.seen = true;
			this.thread = processThread(thread);
			this.loadedNew = true;
			this.isLoading = false;

			const threadInList = this.threads.find(
				thread => thread.rootID === this.threadID
			);
			if (threadInList) {
				this.$set(threadInList, 'seen', true);
			}
		},
		openTicketPicker() {
			this.ticketPickerOpen = true;
		},
		closeTicketPicker() {
			this.ticketPickerOpen = false;
		},
		submitTicketPicker(ticketID) {
			Api.post('/api/ticketing/addMailThreadToTicket', {
				ticketID,
				mailID: this.threadID
			});
			this.$set(this.thread, 'hasTicket', true);
			this.$set(this.thread, 'tickets', null); // Force popover refresh
			const threadInList = this.threads.find(
				thread => thread.rootID === this.threadID
			);
			if (threadInList) {
				this.$set(threadInList, 'hasTicket', true);
			}

			this.closeTicketPicker();
		},
		fetchTicketList() {
			if (this.thread.tickets) {
				return;
			}
			Api.get('/api/ticketing/tickets', {
				withMailID: this.threadID
			}).then(tickets => {
				this.$set(this.thread, 'tickets', tickets);
			});
		},
		onReply(type, threadID, prevMessage) {
			const account = this.availableAccounts.find(
				acc => acc.ID === prevMessage.accountID
			);
			const addresses = { to: [], cc: [], bcc: [] };

			const replyingToSelf =
				prevMessage.fromMailAddress === account.email;
			if (type === 'all') {
				if (!replyingToSelf) {
					addresses.to.push(prevMessage.fromMailAddress);
				}
				Object.keys(addresses).forEach(addressType => {
					prevMessage[addressType].forEach(address => {
						if (
							!replyingToSelf &&
							address.address === account.email
						) {
							return;
						}
						addresses[addressType].push(address.address);
					});
				});
			} else {
				if (replyingToSelf) {
					prevMessage.to.forEach(address =>
						addresses.to.push(address.address)
					);
				} else {
					addresses.to.push(prevMessage.fromMailAddress);
				}
			}

			let subject = prevMessage.subject;
			if (!subject.toUpperCase().startsWith('RE:')) {
				subject = `Re: ${subject}`;
			}

			this.$emit('newMessage', {
				subject,
				...addresses,
				accountID: account.ID,
				fixedAccount: true,
				threadID,
				inReplyToMessageID: prevMessage.ID
			});
		},
		onForward(threadID, prevMessage) {
			let subject = prevMessage.subject;
			if (!subject.toUpperCase().startsWith('FWD:')) {
				subject = `Fwd: ${subject}`;
			}

			this.$emit('newMessage', {
				subject,
				accountID: prevMessage.accountID,
				forwardMessageID: prevMessage.ID
			});
		},
		threadUpdated(thread) {
			if (thread.rootID === this.threadID) {
				this.thread = thread;
			}
		},
		scrollToMessage(messageID, smooth = true) {
			this.$nextTick().then(() => {
				const message = document.getElementById(`msg${messageID}`);
				if (message) {
					message.scrollIntoView({
						behavior: smooth ? 'smooth' : 'auto',
						block: 'start',
						inline: 'nearest'
					});
				}
			});
		},
		scrollToEditor(editorUUID, smooth = true) {
			this.$nextTick().then(() => {
				const editor = document.getElementById(`editor-${editorUUID}`);
				if (editor) {
					editor.scrollIntoView({
						behavior: smooth ? 'smooth' : 'auto',
						block: 'start',
						inline: 'nearest'
					});
				}
			});
		}
	},
	mounted() {
		this.shouldInitialScroll = true;
	},
	watch: {
		threadID: {
			handler(threadID) {
				if (this.$route.params.threadPreload) {
					this.thread = this.$route.params.threadPreload;
				}
				this.fetchThread();
			},
			immediate: true
		},
		threadItems(items) {
			if (!items) {
				return;
			}
			if (this.shouldInitialScroll) {
				const hash = this.$route.hash;
				if (hash && hash.startsWith('#msg')) {
					this.shouldInitialScroll = false;
					const messageID = parseInt(hash.match(/#msg(.+)/)[1]);
					this.$nextTick().then(() =>
						this.scrollToMessage(messageID, false)
					);
					return;
				}
			}

			const mostRecent = items.reduce((mostRecent, item) => {
				if (!mostRecent) {
					return item;
				}

				const extractDate = thing => {
					if (thing.type === 'editor') {
						return thing.editor.editStart;
					} else {
						return thing.message.date;
					}
				};

				const itemDate = extractDate(item);
				const mostRecentDate = extractDate(mostRecent);

				return itemDate > mostRecentDate ? item : mostRecent;
			}, null);

			if (mostRecent) {
				if (mostRecent.type === 'editor') {
					this.scrollToEditor(
						mostRecent.editor.uuid,
						!this.loadedNew
					);
				} else {
					this.scrollToMessage(
						mostRecent.message.ID,
						!this.loadedNew
					);
				}
				this.loadedNew = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.thread-view {
	height: 100%; // Makes chrome not break header...
	overflow-y: auto;

	> :last-child {
		margin-bottom: 1rem;
	}
}

.thread-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
