module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\"dropdown-item\">\n        <div class=\"custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0\">\n            <input type=\"checkbox\" name=\"callcenter-queue-login-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" id=\"callcenter-queue-login-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" class=\"callcenter-queue-login-checkbox custom-control-input\" data-queue=\""
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.LoggedIn : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label class=\"custom-control-label\" for=\"callcenter-queue-login-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "</label>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\"dropdown-item\">\n        <div class=\"custom-control custom-radio mb-2 mr-sm-2 mb-sm-0\">\n            <input type=\"radio\" name=\"callcenter-pause-active\" id=\"callcenter-pause-active-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" class=\"callcenter-pause-active-checkbox custom-control-input\" value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label class=\"custom-control-label\" for=\"callcenter-pause-active-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</label>\n        </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<h6 class=\"dropdown-header\">"
    + alias2(helpers.tr.call(alias1,"label.queues",{"name":"tr","hash":{},"data":data}))
    + "</h6>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.queues : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<h6 class=\"dropdown-header\">"
    + alias2(helpers.tr.call(alias1,"label.pauses",{"name":"tr","hash":{},"data":data}))
    + "</h6>\n<div class=\"dropdown-item\">\n    <div class=\"custom-control custom-radio mb-2 mr-sm-2 mb-sm-0\">\n        <input type=\"radio\" name=\"callcenter-pause-active\" id=\"callcenter-pause-active-0\" class=\"callcenter-pause-active-checkbox custom-control-input\" value=\"0\" checked>\n        <label class=\"custom-control-label\" for=\"callcenter-pause-active-0\">"
    + alias2(helpers.tr.call(alias1,"label.no_pause",{"name":"tr","hash":{},"data":data}))
    + "</label>\n    </div>\n</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.pauses : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})