function getOptionWithID(options, id) {
	let theChosenOne = null;

	if (id == null || id == '') {
		id = 'null';
	}

	for (let k in options) {
		let option = options[k];
		if (option['id'] == null || option['id'] == '') {
			option['id'] = 'null';
		}

		if (option['id'] == id) {
			theChosenOne = option;
			break;
		}
	}

	return theChosenOne;
}

let privateMethods = {
	render: function(element, optionalOptions) {
		let pickleElement = element.data('pickle')['pickleElement'];
		let options = optionalOptions || methods.options(element);
		let values = methods.valuesArray(element);

		const isMultiple = methods.multiple(element);
		const formatFunction = element.data('pickle')['options']['formatOption'].bind(element);
		let newHTML = '';
		for (let k in options) {
			let option = options[k];

			let skip = false;
			if (isMultiple) {
				for (let v in values) {
					let value = values[v];
					if (value == option.id) {
						skip = true;
						continue;
					}
				}
			}

			if (!skip) {
				let formatted = formatFunction(option);
				if(formatted == '') {
					formatted = '&nbsp;';
				}

				newHTML += `
					<div class="dropdown-item pickle-option" data-pickle-option-id="${option['id']}">
						${formatted}
					</div>
				`;
			}
		}
		pickleElement.find('.pickle-options-list')[0].innerHTML = newHTML;
	},

	renderSelected: function(element) {
		let pickleElement = element.data('pickle')['pickleElement'];
		let values = methods.valuesArray(element);

		const isMultiple = methods.multiple(element);
		const formatFunction = element.data('pickle')['options']['formatSelectedOption'].bind(element);

		let newContent = '';
		for (let k in values) {
			let value = values[k];

			let selectedOption = getOptionWithID(element.data('pickle')['result'], value);

			if (isMultiple) { // Multi select
				if (selectedOption) {
					let id = selectedOption.id;
					let text = selectedOption.text;
					newContent 	+= `
						<span class="btn btn-dark btn-sm pickle-tag" data-pickle-value="${id}">
							${text}
							<span class="pickle-remove badge badge-default">&times;</span>
						</span>
					`;
				}
			} else { // Single select
				newContent = selectedOption ? formatFunction(selectedOption) : '\u00a0';
			}
		}
		pickleElement.find('.selected-option')[0].innerHTML = newContent;

		$(element).trigger({
			type: 'renderedSelected',
			newContent,
			selectedOptionElement: pickleElement.find('.selected-option')
		});
	},

	results: function(element, term, callback) {
		let data = element.data('pickle');

		data.pickleElement.find('.pickle-spinner-icon').prop('hidden', false);
		data.pickleElement.find('.pickle-search-icon').prop('hidden', true);

		let resultsFunc = data.options.results.bind(element);

		resultsFunc(term, data.result, function(results, keepCurrent) {
			data.pickleElement.find('.pickle-spinner-icon').prop('hidden', true);
			data.pickleElement.find('.pickle-search-icon').prop('hidden', false);
			methods.options(element, results, keepCurrent);
			callback(results);
		}, element);
	}
};

let methods = {
	init: function(originalElement, userOptions) {
		originalElement.prop('hidden', true);
		originalElement.addClass('pickle-activated');

		if (originalElement.data('pickle') && originalElement.data('pickle')['pickleElement']) {
			originalElement.data('pickle')['pickleElement'].remove();
		}

		let options = $.extend({
			initialOptions: null,

			results: function pickleDefaultResults(searchTerm, prevResults, callback, originalElement) {
				let results = originalElement.data('pickle')['options']['initialOptions'];

				let matchedResults = [];
				for (let k in results) {
					let result = results[k];

					if (window.Pickle.defaultCompareResult(result, searchTerm)) {
						matchedResults.push(result);
					}
				}
				callback(matchedResults, true);
			},
			searchable: $(originalElement).is('[data-searchable]') ? $(originalElement).data('searchable') : true,
			spinnerIconClass: 'glyphicon glyphicon-refresh pickle-spinning',
			searchIconClass: 'glyphicon glyphicon-search',
			multiple: $(originalElement).is('[multiple]'),
			disabled: $(originalElement).is(':disabled'),
			placeholder: '',
			formatOption: function formatOption(option) {
				return option['text'];
			},
			formatSelectedOption: function formatSelectedOption(option) {
				return option['text'];
			}
		}, userOptions);

		let initialOptions = options.initialOptions;

		if (!initialOptions) {
			initialOptions = [];
			originalElement.find('option').each(function() {
				let option = $(this);
				let id = option.attr('value');
				let text = option.attr('data-html-text') ? option.attr('data-html-text') : option.html();

				initialOptions.push({id, text});
			});
		}

		options.initialOptions = initialOptions;

		let attributes = {};
		$(originalElement[0].attributes).each(function() {
			attributes[this.name] = this.value;
		});

		let spinnerIconClass = 'pickle-spinner-icon form-control-feedback ' + options.spinnerIconClass;
		let searchIconClass = 'pickle-search-icon form-control-feedback ' + options.searchIconClass;

		let caret = options.multiple ? '' : '<span class="pickle-caret fa fa-caret-down"></span>';

		let buttonClass;
		if (options.multiple) {
			buttonClass = 'form-control pickle-button pickle-multi-select-button clearfix';
		} else {
			buttonClass = 'form-control text-left pickle-button pickle-single-select-button clearfix';
		}
		let btnType = options.multiple ? 'div' : 'a';

		let btnProp = '';
		if (options.disabled) {
			buttonClass += ' disabled';
		}
		if (btnType === 'a') {
			btnProp += ' href="#"';
		}

		let pickleElementSource = `
			<div class="pickle-container dropdown">
				<${btnType} class="${buttonClass}" tabindex="0" ${btnProp}>
					<span class="selected-option pull-left">&nbsp;</span>
					${caret}
				</${btnType}>
				<div class="pickle-options dropdown-menu">
					<div class="dropdown-item pickle-input-container">
						<div class="has-feedback">
						<input type="text" class="pickle-input form-control w-100" value=""/>
							<span class="${spinnerIconClass}" hidden></span>
							<span class="${searchIconClass}"></span>
						</div>
					</div>
					<div class="pickle-options-list"></div>
				</div>
			</div>
		`;

		let pickleElement = $(pickleElementSource).insertAfter(originalElement);
		pickleElement.find('.selected-option').attr('data-pickle-placeholder', options.placeholder);
		originalElement.data('pickle', {
			result: initialOptions,
			pickleElement: pickleElement,
			options: options
		});

		pickleElement.off('input', '.pickle-input').on('input', '.pickle-input', function(e) {
			privateMethods.results(originalElement, $(e.currentTarget).val(), function(matchedResults) {
				privateMethods.render(originalElement, matchedResults);
			});
		});

		pickleElement.off('click', '.pickle-button').on('click', '.pickle-button', function(e) {
			e.preventDefault();

			if (!pickleElement.is('.show')) {
				methods.open(originalElement);
			}
		});

		pickleElement.off('click', '.pickle-remove').on('click', '.pickle-remove', function(e) {
			e.preventDefault();

			let tagValue = $(e.currentTarget).closest('.pickle-tag').data('pickle-value');
			methods.unselect(originalElement, tagValue);
		});

		pickleElement.off('keydown').on('keydown', function(e) {
			if (methods.disabled(originalElement)) {
				return;
			}
			let code = e.keyCode || e.which;

			let buttonsThatTendToshowDropdowns = [10, 13, 32, 40];

			if (!pickleElement.is('.show')) {
				if (buttonsThatTendToshowDropdowns.indexOf(code) != -1) {
					e.stopPropagation();
					e.preventDefault();
					methods.open(originalElement);
				}
			} else {
				let resultElements = pickleElement.find('.pickle-option');
				if (code === 13) {
					e.preventDefault();
					e.stopPropagation();
					let currentlyActive = resultElements.filter('.active').eq(0);
					if (!currentlyActive.length) {
						currentlyActive = resultElements.eq(0);
					}
					let value = currentlyActive.data('pickle-option-id') || '';
					methods.select(originalElement, value);
					pickleElement.find('.pickle-button').focus();
					methods.close(originalElement);
				} else if (code === 27) {
					pickleElement.find('.pickle-button').focus();
					methods.close(originalElement);
				} else if (code === 9) {
					e.stopPropagation();
					e.preventDefault();

					let target = $(e.currentTarget);
					let input = target.find('.pickle-input');
					let indexOffset = e.shiftKey ? -2 : 1;

					$(':input:eq(' + ($(':input').index(input) + indexOffset) + ')').focus();
					methods.close(originalElement);
				} else if (code === 38 || code === 40) {
					e.stopPropagation();
					e.preventDefault();

					if (!resultElements.filter('.active').length) {
						resultElements.eq(0).addClass('active');
					} else if (code == 38 || code == 40) {
						let currentlyActive = resultElements.filter('.active').eq(0);

						if (code === 38) { // UP ARROW KEY
							if (currentlyActive.prev('.pickle-option').length) {
								currentlyActive.prev('.pickle-option').addClass('active');
								currentlyActive.removeClass('active');
							}
						} else if (code === 40) { // DOWN ARROW KEY
							if (currentlyActive.next('.pickle-option').length) {
								currentlyActive.next('.pickle-option').addClass('active');
								currentlyActive.removeClass('active');
							}
						}

						let optionsContainer = pickleElement.find('.pickle-options');
						let inputContainer = optionsContainer.find('.pickle-input-container');

						let inputContainerHeight = inputContainer.height();
						let activeElementOffset = currentlyActive.position().top - inputContainerHeight;
						let containerScrollOffset = optionsContainer.scrollTop();
						let containerHeight = optionsContainer.height();
						let halfHeight = currentlyActive.innerHeight() / 2;

						if (code === 40 && activeElementOffset > containerHeight) {
							let actualOffset = activeElementOffset + containerScrollOffset;
							optionsContainer.scrollTop(actualOffset - containerHeight + halfHeight);
						} else if (code === 38 && activeElementOffset < inputContainerHeight) {
							let actualOffset = activeElementOffset + containerScrollOffset;
							optionsContainer.scrollTop(actualOffset - halfHeight);

						}
					}
				}
			}

		});

		pickleElement.off('mouseenter', '.pickle-option').on('mouseenter', '.pickle-option', function(e) {
			$(e.currentTarget).parent().children('.pickle-option').removeClass('active');
			$(e.currentTarget).addClass('active');
		});

		pickleElement.off('click', '.pickle-option').on('click', '.pickle-option', function(e) {
			e.stopPropagation();
			e.preventDefault();

			let value = $(e.currentTarget).data('pickle-option-id') || '';
			methods.select(originalElement, value);

			$(pickleElement).find('.pickle-button').focus();
			methods.close(originalElement);
		});

		pickleElement.find('.pickle-options').off('scroll').on('scroll', function(e) {
			let target = $(e.currentTarget);
			target.children('.pickle-input-container').css({
				top: target.scrollTop()
			});
		});

		originalElement.off('change').on('change', function(e) {
			privateMethods.renderSelected($(e.currentTarget));
		});

		methods.options(originalElement, initialOptions);

		return this;
	},

	open: function(element) {
		$('.pickle-activated').not(element).pickle('close');

		methods.refresh(element);

		let pickleElement = element.data('pickle').pickleElement;
		pickleElement
			.find('.pickle-options').addClass('show')
			.parent().addClass('show');

		if (!methods.searchable(element)) {
			pickleElement.find('.pickle-input-container').prop('hidden', true);
		} else {
			pickleElement.find('.pickle-input-container').prop('hidden', false);
			pickleElement.find('.pickle-input').focus();
		}

		let inputContainerHeight = pickleElement.find('.pickle-input-container').outerHeight();
		pickleElement.find('.pickle-options').attr('style', '');
		let paddingTop = parseInt(pickleElement.find('.pickle-options').css('padding-top'));

		pickleElement.find('.pickle-options').css({paddingTop: inputContainerHeight + paddingTop});

		let children = pickleElement.find('.pickle-options').children('li');
		let theChosenOne = null;

		children.removeClass('active');
		if (element.val()) {
			let filtered = children.filter('[data-pickle-option-id="' + element.val() + '"]');
			if (filtered.length) {
				theChosenOne = filtered.eq(0);
			}
		}

		if (!theChosenOne) {
			theChosenOne = children.eq(0);
		}
		$(theChosenOne).addClass('active');
	},

	close: function(element) {
		let pickleElement = element.data('pickle').pickleElement;
		pickleElement.find('.pickle-input').val('');
		pickleElement
			.find('.pickle-options').removeClass('show')
			.parent().removeClass('show');
		pickleElement.removeClass('show');
	},

	toggle: function(element) {
		let pickleElement = element.data('pickle').pickleElement;

		if (pickleElement.is('.show')) {
			methods.close(element);
		} else {
			methods.open(element);
		}
	},

	disabled: function(element, newDisabled) {
		let pickleElement = element.data('pickle').pickleElement;

		if (typeof(newDisabled) != 'undefined') {
			element.prop('disabled', newDisabled);
			pickleElement.children('.pickle-button').toggleClass('disabled', newDisabled);
		}

		return element.is(':disabled');
	},

	persistentOptions: function(element) {
		let persistentOptions = [];
		let options = element.pickle('options');

		for (let k in options) {
			let option = options[k];

			if (option.persist) {
				persistentOptions.push(option);
			}
		}

		return persistentOptions;
	},

	options: function(element, newOptions, keepCurrent) {
		if (newOptions) {
			let oldOptions = methods.options(element);
			newOptions = newOptions.slice(0);
			let values = methods.valuesArray(element);

			if (keepCurrent) {
				for (let ko in oldOptions) {
					for (let kv in values) {
						let option = oldOptions[ko];
						let value = values[kv];

						if (option.id == value) {
							option.persistent_persist = option.persist;
							option.persist = true;
						}
					}
				}
			}

			for (let ko in oldOptions) {
				let oldOption = oldOptions[ko];
				if (oldOption.persist) {
					let exists = false;
					for (let no in newOptions) {
						let newOption = newOptions[no];
						if (oldOption.id == newOption.id) {
							exists = true;
							break;
						}
					}
					if (!exists) {
						if (typeof(oldOption.persistent_persist) !== 'undefined') {
							oldOption.persist = oldOption['persistent_persist'];
						}
						newOptions.push(oldOption);
					}
				}
			}

			element.data('pickle').result = newOptions;
			let newHTML = '';
			for (let k in newOptions) {
				let option = newOptions[k];
				let dataAttributes  = 'data-pickle-persist="' + (option.persist ? 'true' : 'false') + '"';
				newHTML += `
					<option value="${option.id}" ${dataAttributes}>
							${option.text}
					</option>
				`;
			}
			element[0].innerHTML = newHTML;

			let changed = false;
			let newValues = [];

			for (let k in values) {
				let value = values[k];
				let option = getOptionWithID(newOptions, value);
				if (option) {
					newValues.push(value);
				} else {
					changed = true;
				}
			}

			if (methods.multiple(element)) {
				element.val(newValues);
			} else {
				if (values.length) {
					element.val(newValues[0]);
				} else {
					element.val('');
				}
			}

			if (changed) {
				element.trigger('change');
			}

			privateMethods.render(element);
			privateMethods.renderSelected(element);
		}

		return element.data('pickle')['result'];
	},

	valuesArray: function(element) {
		let values = element.val();
		if (!values || typeof(values) != 'object' || values.constructor !== Array) {
			values = [values];
		}

		return values;
	},

	searchable: function(element, newSearchable) {
		let searchableOption = element.data('pickle')['options']['searchable'];
		let searchable = false;
		if (searchableOption) {
			if (typeof(searchableOption) == 'function') {
				let searchableFunc = searchableOption.bind(element);
				searchable = searchableFunc();
			} else {
				searchable = true;
			}
		}

		return searchable;
	},

	multiple: function(element, newMulti) {
		if (newMulti === false || newMulti === true) {
			element.data('pickle')['isMulti'] = newMulti;
		}

		return element.data('pickle')['options']['multiple'];
	},

	refresh: function(element) {
		privateMethods.results(element, '', function(newOptions) {
			methods.options(element, newOptions);
		});
	},

	research: function(element) {
		privateMethods.results(element, element.data('pickle')['pickleElement'].find('.pickle-input').val(), function(newOptions) {
			methods.options(element, newOptions);
		});
	},

	option: function(element, optionID, newValue) {
		if (newValue) {
			let options = element.pickle('options');

			let option = getOptionWithID(options, optionID);
			if (option) {
				option = newValue;
			} else {
				options.push(newValue);
			}
			element.pickle('options', options);
		}

		return getOptionWithID(element.pickle('options'), optionID);
	},

	select: function(element, value) {
		if (methods.multiple(element)) {
			let values = methods.valuesArray(element);
			values.push(value);
			element.val(values);
		} else {
			element.val(value);
		}

		$(element).trigger({
			type: 'select',
			value: value
		});
		$(element).trigger('change');
	},

	unselect: function(element, value) {
		if (methods.multiple(element)) {
			let values = methods.valuesArray(element);
			let index = values.indexOf('' + value); // I want a valid type system.
			if (index >= 0) {
				values.splice(index, 1);
			}
			element.val(values);
		} else {
			element.val('');
		}

		$(element).trigger({
			type: 'unselect',
			value: value
		});
		$(element).trigger('change');
	}
};

$(document).on('click', function(e) {
	let target = $(e.target);

	let ignored = $([]);
	if (target.is('.pickle-container')) {
		ignored = target;
	} else if (target.closest('.pickle-container').length) {
		ignored = target.closest('.pickle-container');
	}

	let $toClose = $('.pickle-activated').filter(function() {
		let $el = $(this).data('pickle').pickleElement;
		return !ignored.is($el);
	});
	$toClose.pickle('close');
});

$.fn.pickle = function(methodOrOptions, options) {
	if (methods[methodOrOptions]) {
		let argsToPass = [];
		for (let k in arguments) {
			if (k > 0) {
				argsToPass.push(arguments[k]);
			}
		}

		if ($(this).length == 1) {
			argsToPass.unshift($(this));
			return methods[methodOrOptions].apply($(this), argsToPass);
		} else {
			$(this).each(function() {
				let newArgsToPass = argsToPass.splice(0);
				newArgsToPass.unshift($(this));
				methods[methodOrOptions].apply($(this), newArgsToPass);
			});
		}
	} else {
		$(this).each(function() {
			methods.init($(this), methodOrOptions);
		});
	}
};


window.Pickle = {};
window.Pickle.defaultCompareResult = function compareResult(result, searchTerm) {
	let text = result.text || '';
	text = $('<span>' + text + '</span>').text(); // Quick 'n' ugly way to get rid of HTML tags.
	text = text.toLowerCase().trim();

	searchTerm = searchTerm || '';
	searchTerm = searchTerm.toLowerCase().trim();

	return (text.indexOf(searchTerm) !== -1);
};