module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class='text-left'>\n	<span class='glyphicon glyphicon-earphone'></span>&nbsp;&nbsp;\n	<a href='/phonebook/view/personID_eq="
    + alias2(alias1((depth0 != null ? depth0.personID : depth0), depth0))
    + "' style='color: white;'>\n		"
    + alias2(alias1((depth0 != null ? depth0.extensionName : depth0), depth0))
    + "\n	</a>\n</div>\n<br>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.result : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<a class='btn btn-primary' href='/"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.parentContext : depth0)) != null ? stack1.bootquery : stack1)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.parentContext : depth0)) != null ? stack1.bootquery : stack1)) != null ? stack1.method : stack1), depth0))
    + "/?"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.parentContext : depth0)) != null ? stack1.bootquery : stack1)) != null ? stack1.controller : stack1), depth0))
    + "-groupName_like_ci="
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.parentContext : depth0)) != null ? stack1.contextData : stack1)) != null ? stack1.groupName : stack1), depth0))
    + "&"
    + ((stack1 = container.invokePartial(partials.encode_url,depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1' style='width: 100%; text-align: left;'>\n	<span class='glyphicon glyphicon-filter'></span>\n	&nbsp;"
    + alias3(helpers.tr.call(alias1,"button.show_group_calls",{"name":"tr","hash":{},"data":data}))
    + "\n</a>\n";
},"usePartial":true,"useData":true})