module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "    "
    + ((stack1 = container.invokePartial(partials.locationsHead,depth0,{"name":"locationsHead","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <form name=\"location-delete-form\" class=\"datatable-form\" action=\"/phonebook/companyLocations/?companyLocations-companyID"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.companyLocationID_eq), depth0))
    + "\" method=\"POST\">\n        <table class=\"table table-striped table-hover datatable table-sticky-header\">\n            <thead>\n                "
    + ((stack1 = container.invokePartial(partials.tableheader,depth0,{"name":"tableheader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </thead>\n\n            <tbody>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.result : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "            </tbody>\n\n            <tfoot>\n                <tr>\n                    <td colspan=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.columns : depth0)) != null ? stack1.length : stack1), depth0))
    + "\" class=\"text-right\">\n                        <button class=\"btn btn-danger delete-action\" type=\"submit\" value=\"Obriši\" name=\"location-delete\">\n                            <span class=\"glyphicon glyphicon-trash\"></span>&nbsp;&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.delete",{"name":"tr","hash":{},"data":data}))
    + "\n                        </button> &nbsp;&nbsp;\n                        <span>\n                            <a href=\"/phonebook/addCompanyLocation/?companyID_eq="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.parameters)) && stack1.companyID_eq), depth0))
    + "\" class=\"btn btn-success\">\n                                <span class=\"glyphicon glyphicon-plus\"></span>&nbsp; "
    + alias2(helpers.tr.call(alias3,"button.add",{"name":"tr","hash":{},"data":data}))
    + "\n                        </a>\n                        </span>\n                    </td>\n                </tr>\n            </tfoot>\n        </table>\n        "
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.blockHelperMissing;

  return "                    <tr "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <td>\n                            <a href=\"/phonebook/view/?locationID_eq="
    + alias3(alias2((depth0 != null ? depth0.locationID : depth0), depth0))
    + "\" class=\"btn btn-default\">\n                                <span class=\"glyphicon glyphicon-list\"></span>\n                            </a>\n                            <input type=\"checkbox\" name=\"locationDelete-"
    + alias3(alias2((depth0 != null ? depth0.locationID : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n                        </td>\n                        <td>"
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "</td>\n                        <td>"
    + alias3(alias2((depth0 != null ? depth0.locationMark : depth0), depth0))
    + "</td>\n                        <td>"
    + alias3(alias2((depth0 != null ? depth0.locationType : depth0), depth0))
    + "</td>\n                        <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.streetName : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cityName : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            "
    + alias3(alias2((depth0 != null ? depth0.countryName : depth0), depth0))
    + "\n                        </td>\n                        <td>\n"
    + ((stack1 = alias4.call(depth0,alias2((depth0 != null ? depth0.phoneNumbers : depth0), depth0),{"name":"phoneNumbers","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </td>\n                        <td>\n"
    + ((stack1 = alias4.call(depth0,alias2((depth0 != null ? depth0.emails : depth0), depth0),{"name":"emails","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " class=\"active\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                "
    + alias2(alias1((depth0 != null ? depth0.streetName : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.streetNumber : depth0), depth0))
    + ",\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                "
    + alias2(alias1((depth0 != null ? depth0.postalCode : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.cityName : depth0), depth0))
    + ",\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <span style=\"white-space: nowrap;\">\n                               <a href=\"#\">\n                                   "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "\n                               </a>"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                           </span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return ",";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <span style=\"white-space: nowrap;\">\n                                "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.email : depth0), depth0))
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                           </span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                        <tr>\n                            <td colspan=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.columns : depth0)) != null ? stack1.length : stack1), depth0))
    + "\">"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data}))
    + "</td>\n                        </tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.companyLocations : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})