module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"card-group\">\n    <div class=\"card col-3 p-0 border-0\">\n        <div class=\"list-group formbuilder-available-fields\">\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"text\">"
    + alias2(helpers.tr.call(alias1,"form.type_text",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"number\">"
    + alias2(helpers.tr.call(alias1,"form.type_number",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"date\">"
    + alias2(helpers.tr.call(alias1,"form.type_date",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"select\">"
    + alias2(helpers.tr.call(alias1,"form.type_dropdown",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"list\">"
    + alias2(helpers.tr.call(alias1,"form.type_list",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"textarea\">"
    + alias2(helpers.tr.call(alias1,"form.type_textarea",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"richtext\">"
    + alias2(helpers.tr.call(alias1,"form.type_richtext",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"checkbox\">"
    + alias2(helpers.tr.call(alias1,"form.type_checkbox",{"name":"tr","hash":{},"data":data}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"separator\">"
    + alias2(helpers.tr.call(alias1,"form.type_separator",{"name":"tr","hash":{},"data":data}))
    + "</a>\n        </div>\n    </div>\n    <div class=\"card col-9 p-0\">\n        <div class=\"card-body formbuilder-form\">\n\n        </div>\n    </div>\n</div>";
},"useData":true})