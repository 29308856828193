<template>
	<div class="card" style="flex-grow: 1; flex-shrink: 0; flex-basis: 600px;">
		<div class="card-header px-2 py-2 d-flex">
			<div class="py-1" v-if="conversationInfo">
				<strong :title="conversationInfo.formattedPhoneNumber">
					{{displayName}}
				</strong>
			</div>
			<div v-else-if="isNewConversation" class="py-1">
				<span class="badge badge-success">Nova</span>
				{{newNumberInput}}
			</div>
			<div class="py-3"></div>
		</div>
		<div ref="messagesContainer" class="card-body messages-container">
			<template v-if="selectedPhoneNumber">
				<template v-if="messagesLoaded">
					<Message
						v-for="message in messages"
						:key="message.ID"
						:content="message.content"
						:date="message.date"
						:direction="message.direction"
						:status="message.status"
					/>
				</template>
				<div v-else class="text-center">
					<span class="spinner-grow m-3"></span>
				</div>
			</template>
		</div>
		<div class="card-block" v-if="selectedPhoneNumber && messagesLoaded || isNewConversation">
			<template v-if="isNewConversation">
				<SelectField
					label="S broja"
					:options="accounts"
					textProp="phoneNumber"
					valueProp="ID"
					:required="true"
					v-model="newSelectedAccountID"
				/>
				<InputField label="Na broj" v-model="newNumberInput"/>
			</template>
			<SMSInput @submit="sendMessage" :disabled="submitDisabled"/>
		</div>
	</div>
</template>

<script>
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import uuid from 'uuid/v4';

import Message from './Message.vue';
import SMSInput from './SMSInput.vue';
import InputField from 'BootQuery/Assets/js/forms/fields/Text.vue';
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';

export default {
	components: {
		Message,
		SMSInput,
		InputField,
		SelectField
	},
	props: {
		conversations: Array,
		accounts: Array
	},
	data() {
		return {
			conversationInfo: null,
			messages: [],
			messagesLoaded: false,
			newNumberInput: '',
			newSelectedAccountID: null
		};
	},
	computed: {
		selectedPhoneNumber() {
			const number = this.$route.params.phoneNumber;
			return number && number !== 'new' ? number : null;
		},
		selectedAccount() {
			let accountID = null;
			if (this.isNewConversation) {
				accountID = this.newSelectedAccountID;
			} else if (this.messages && this.messages.length) {
				accountID = this.messages[this.messages.length - 1].accountID;
			}

			return this.accounts.find(account => account.ID === accountID);
		},
		isNewConversation() {
			return this.$route.params.phoneNumber === 'new';
		},
		submitDisabled() {
			if (this.isNewConversation) {
				return (
					!this.newSelectedAccountID ||
					this.newNumberInput.length === 0
				);
			} else {
				return false;
			}
		},
		displayName() {
			if (!this.conversationInfo) {
				return null;
			}
			if (this.conversationInfo.contacts.length) {
				const name = this.conversationInfo.contacts[0].name;
				const number = this.conversationInfo.formattedPhoneNumber;
				return `${name} (${number})`;
			} else {
				return this.conversationInfo.formattedPhoneNumber;
			}
		}
	},
	methods: {
		async fetchMessages() {
			const messages = await Api.get(
				`/api/sms/otherParties/${this.selectedPhoneNumber}/messages`
			);
			return messages.map(message => {
				message.date = new Date(message.date);
				return message;
			});
		},
		fetchConversation() {
			const selectedNumber = this.selectedPhoneNumber;
			if (selectedNumber) {
				this.messagesLoaded = false;
				Promise.all([
					Api.get(`/api/sms/otherParties/${selectedNumber}`),
					this.fetchMessages()
				]).then(([conversationInfo, messages]) => {
					this.conversationInfo = conversationInfo;
					this.messages = messages;
					this.messagesLoaded = true;
					this.scrollToBottom();
				});
			} else {
				this.conversationInfo = null;
				this.messages = [];
			}
		},
		sendMessage(content) {
			const account = this.selectedAccount;

			let toNumber = null;
			const tmpID = `tmp:${uuid()}`;
			const message = {
				ID: tmpID,
				content: content,
				accountID: account.ID,
				accountPhoneNumber: account.phoneNumber,
				date: new Date(),
				direction: true,
				status: null,
				seen: true
			};
			if (this.isNewConversation) {
				toNumber = this.newNumberInput;
			} else {
				this.messages.push(message);
				toNumber = this.selectedPhoneNumber;
				this.scrollToBottom();
			}

			Api.post(`/api/sms/accounts/${account.phoneNumber}/messages/send`, {
				to: toNumber,
				content: content,
				tmpID: tmpID
			}).then(response => {
				if (this.isNewConversation) {
					this.$router.replace(`/conversations/${response.sentTo}`);
				} else {
					this.$set(message, 'ID', response.ID);
				}
			});
		},
		onMessage(message) {
			if (!this.messages) {
				return;
			}
			// Message not for us?
			if (this.selectedPhoneNumber !== message.otherPartyPhoneNumber) {
				return;
			}

			const hasExisting = this.messages.some(
				existing =>
					existing.ID === message.ID || existing.ID === message.tmpID
			);
			if (!hasExisting) {
				this.messages.push(message);
				this.scrollToBottom();
			}
		},
		onMessageStatus(status) {
			const message = this.messages.find(
				msg => msg.ID === status.messageID
			);
			if (message) {
				this.$set(message, 'status', status.status);
				this.$set(message, 'statusMessage', status.statusMessage);
			}
		},
		scrollToBottom() {
			this.$nextTick(() => {
				const messagesContainer = this.$refs.messagesContainer;
				messagesContainer.scrollTo(0, messagesContainer.scrollHeight);
			});
		}
	},
	watch: {
		selectedPhoneNumber: {
			immediate: true,
			handler(selectedNumber) {
				// Show the name quickly if possible
				if (this.conversations) {
					const fromList = this.conversations.find(
						conv => conv.phoneNumber === selectedNumber
					);
					this.conversationInfo = fromList || null;
				}
				this.fetchConversation();
			}
		},
		isNewConversation: {
			immediate: true,
			handler(isNew) {
				this.newSelectedAccountID = this.accounts[0].ID;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.messages-container {
	overflow-y: auto;
	height: 100%;
}
</style>
