module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.resultdata : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"result-pagination row justify-content-between\" style=\"text-align: center;\">\n    <div class=\"col text-center text-sm-left\">\n        <div class=\"btn-group "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].small : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.prevPage : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\" class=\"btn btn-secondary navlink "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.prevPage : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].targetSelector : depths[1]),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span class=\"fa fa-fast-backward\"></span></a>\n            <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.prevPage : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\" class=\"btn btn-secondary navlink "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.prevPage : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].targetSelector : depths[1]),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <span class=\"fa fa-backward\"></span>\n                <span class=\"d-none d-md-inline\">&nbsp;&nbsp;"
    + alias2(helpers.tr.call(alias1,"pagination.previous",{"name":"tr","hash":{},"data":data}))
    + "</span>\n            </a>\n        </div>\n    </div>\n\n    <div class=\"col text-center\">\n        <button\n            class=\"btn btn-secondary page-selector "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].small : depths[1]),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n            id=\"page-selector\"\n            type=\"text\"\n            data-toggle=\"popover\"\n            title=\""
    + alias2(helpers.tr.call(alias1,"pagination.choose_page",{"name":"tr","hash":{},"data":data}))
    + "\"\n            data-html=\"true\"\n            data-content=\"\n            <form action='/"
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/"
    + alias2(alias3((depth0 != null ? depth0.pagination_params : depth0), depth0))
    + "' method='get'>\n            <div class='input-group' style='max-width: 150px;'>\n                <input type='number' name='"
    + alias2(alias3((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "Page' id='page-selector-number' class='form-control' placeholder='"
    + alias2(helpers.tr.call(alias1,"pagination.placeholder.page_number",{"name":"tr","hash":{},"data":data}))
    + "' value='"
    + alias2(alias3((depth0 != null ? depth0.page : depth0), depth0))
    + "' style='height: 31px;' size='5'>\n                <span class='input-group-btn'>\n                    <button class='btn btn-secondary' type='submit' id='page-selector-go'>"
    + alias2(helpers.tr.call(alias1,"pagination.button.go",{"name":"tr","hash":{},"data":data}))
    + " <span class='glyphicon glyphicon-chevron-right'></span></button>\n                </span>\n            </div>\n            </form>\"\n            data-placement=\"top\">\n            <span class=\"d-none d-md-inline\">\n                "
    + alias2(helpers.tr.call(alias1,"pagination.indicator",{"name":"tr","hash":{},"data":data}))
    + "\n            </span>\n            <span class=\"d-inline d-md-none\">\n                "
    + alias2(alias3((depth0 != null ? depth0.page : depth0), depth0))
    + " / "
    + alias2(alias3((depth0 != null ? depth0.pageCount : depth0), depth0))
    + "\n            </span>\n        </button>\n    </div>\n\n    <div class=\"col text-center text-sm-right\">\n        <div class=\"btn-group "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].small : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\" class=\"btn btn-secondary navlink "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nextPage : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].targetSelector : depths[1]),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <span class=\"d-none d-md-inline\">"
    + alias2(helpers.tr.call(alias1,"pagination.next",{"name":"tr","hash":{},"data":data}))
    + "&nbsp;&nbsp;</span>\n                <span class=\"fa fa-forward\"></span>\n            </a>\n            <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\" class=\"btn btn-secondary navlink "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nextPage : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].targetSelector : depths[1]),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span class=\"fa fa-fast-forward\"></span></a>\n        </div>\n    </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "btn-group-sm";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "/"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (data && data.root)) && stack1.bootquery),{"name":"with","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/?"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pagination_params : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].name : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "=1";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return alias2(alias1((depth0 != null ? depth0.controller : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.method : depth0), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.pagination_params : depth0), depth0))
    + "&";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return container.escapeExpression(container.lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "Page";
},"12":function(container,depth0,helpers,partials,data) {
    return "page";
},"14":function(container,depth0,helpers,partials,data) {
    return "#";
},"16":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return "data-target=\""
    + container.escapeExpression(container.lambda((depths[1] != null ? depths[1].targetSelector : depths[1]), depth0))
    + "\"";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "/"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (data && data.root)) && stack1.bootquery),{"name":"with","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/?"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pagination_params : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].name : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "="
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.prevPage : depth0), depth0));
},"22":function(container,depth0,helpers,partials,data) {
    return "btn-sm";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "/"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (data && data.root)) && stack1.bootquery),{"name":"with","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/?"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pagination_params : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].name : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "="
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.nextPage : depth0), depth0));
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "/"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (data && data.root)) && stack1.bootquery),{"name":"with","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "/?"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pagination_params : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].name : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "="
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.pageCount : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true})