<template>
    <div class="card subform-card">
        <div class="card-body">
            <div class="form-group row" v-for="(row, index) in rows" :key="index">
                <div class="col">
                    <div class="row">
                        <slot v-bind:row="row" v-bind:parentPath="fullFieldPath.concat([index])" />
                    </div>
                </div>
                <div class="col-auto form-group d-flex">
                    <button class="btn btn-outline btn-danger mt-auto" @click="removeRow(index)">
                        <span class="fa fa-trash-alt"></span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 col-md-2 ml-auto">
                    <button type="button" class="btn btn-block btn-success" @click="newRow">
                        <span class="fa fa-plus"></span>
                        Dodaj
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import fieldProps from './mixins/fieldProps';
export default {
	mixins: [fieldProps],
	props: {
		value: {
			type: Array,
			default() {
				return [];
			}
		},
		newRowDefaults: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			rows: this.value
		};
	},
	methods: {
		newRow() {
			this.rows.push(this.newRowDefaults);
		},
		removeRow(index) {
			this.rows.splice(index, 1);
		}
	}
};
</script>
