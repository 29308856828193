module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"card\">\n                    <div class=\"card-header p-0\" id=\"heading"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n                        <h5 class=\"mb-0\"></h5>\n                            <button class=\"btn w-100 m-0 text-left "
    + ((stack1 = helpers.unless.call(alias3,(data && data.first),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-toggle=\"collapse\" data-target=\"#collapse"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" aria-expanded=\"true\" aria-controls=\"collapse"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" type=\"button\">\n                            "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n                            </button>\n                        </h5>\n                    </div>\n\n                    <div id=\"collapse"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" class=\"collapse "
    + ((stack1 = helpers["if"].call(alias3,(data && data.first),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-labelledby=\"heading"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" data-parent=\"#accordion\"  data-settings-form=\"mailboxSettings-"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" data-form=\"mailboxSettings-"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n                        <div class=\"card-body\">\n                            "
    + ((stack1 = container.invokePartial(partials.form,helpers.lookup.call(alias3,(depths[1] != null ? depths[1].forms : depths[1]),helpers.concat.call(alias3,"mailboxSettings-",(depth0 != null ? depth0.name : depth0),{"name":"concat","hash":{},"data":data}),{"name":"lookup","hash":{},"data":data}),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "collapsed";
},"4":function(container,depth0,helpers,partials,data) {
    return "show";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"container-fluid py-3\">\n    <div class=\"row\">\n        <div class=\"col\">\n            <div id=\"accordion\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mailboxes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true})