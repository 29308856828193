import { render, staticRenderFns } from "./SMS.vue?vue&type=template&id=f60fe3b2&scoped=true&"
import script from "./SMS.vue?vue&type=script&lang=js&"
export * from "./SMS.vue?vue&type=script&lang=js&"
import style0 from "./SMS.vue?vue&type=style&index=0&id=f60fe3b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f60fe3b2",
  null
  
)

component.options.__file = "SMS.vue"
export default component.exports