import Module from 'BootQuery/Assets/js/module.js';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import Vue from 'BootQuery/Assets/js/vue.js';
import FacebookChatComponent from '../components/FacebookChat.vue';
import getRouter from './router.js';
import SoundMessage from 'app/assets/sounds/short.mp3';

export default class FacebookChat extends Module {
	async init(_data) {
		super.init();
		this.messageSound = new Audio(SoundMessage);
		$(document).ev(
			'renderController.facebookChat',
			this.destroyChatInterface.bind(this)
		);
		this.loaded = false;
		this.facebookAccounts = await Api.get(
			'/api/facebookChat/availableAccounts'
		);
		if (this.facebookAccounts.length) {
			this.unreadChatIDs = await Api.get(
				'/api/facebookChat/unreadChatIDs'
			);
			this.setupEvents();
		}
		this.loaded = true;
		this.updateChatNotifier();
		this.emit('loaded');
	}

	setupEvents() {
		const newMessageEvents = this.facebookAccounts.map(
			account => `facebookChat/${account.ID}/newMessage`
		);
		this.subscribeWebSocket(newMessageEvents, this.onMessage.bind(this));
		const seenEvents = this.facebookAccounts.map(
			account => `facebookChat/${account.ID}/chatSeen`
		);
		this.subscribeWebSocket(seenEvents, this.onSeen.bind(this));
	}

	activateElements(target, _data) {
		const $chatContainer = target.findElement('#facebook-chat-container');
		if ($chatContainer.length) {
			this.renderChatInterface($chatContainer);
		}
	}

	async renderChatInterface($chatContainer) {
		const chatContainer = $chatContainer[0];
		setTimeout(() => {
			this.component = new Vue({
				el: chatContainer,
				router: getRouter(),
				render: h => h(FacebookChatComponent)
			});
		}, 0);
	}

	destroyChatInterface() {
		if (this.component) {
			this.component.$destroy();
			this.component = null;
		}
	}

	updateChatNotifier() {
		const menuItem = $(
			'.menu-container .nav-item > .nav-link[data-controller="facebookChat"]'
		);
		const menuItemBadge = menuItem.find('.menu-item-notification-counter');
		menuItemBadge.text(this.unreadChatIDs.length);
		menuItemBadge.prop('hidden', this.unreadChatIDs.length === 0);
	}

	onMessage(message) {
		if (!message.seen && !message.direction) {
			if (this.unreadChatIDs.indexOf(message.entityID) === -1) {
				this.unreadChatIDs.push(message.entityID);
			}
			this.updateChatNotifier();
			this.messageSound.play();
		}
		this.emit('newMessage', message);
	}

	onSeen(data) {
		const index = this.unreadChatIDs.indexOf(data.entityID);
		if (index !== -1) {
			this.unreadChatIDs.splice(index, 1);
			this.updateChatNotifier();
		}
	}
}