<template>
	<div class="list-group list-group-flush">
		<router-link
			tag="a"
			:to="`/conversations/${conversation.phoneNumber}`"
			class="noparse list-group-item list-group-item-action conversation-list-item"
			:class="{active: active, seen: conversation.seen}"
		>
			<div class="row">
				<div class="col text-truncate" style="overflow: hidden;">
					<h6 class="conversation-list-title mb-0">{{displayName}}</h6>
				</div>
				<div class="col-auto">
					<small>{{conversation.lastMessage.date | formatDate(tr('format.datetime')) }}</small>
				</div>
			</div>
			<div class="conversation-list-preview">
				<small>{{conversation.lastMessage.content}}</small>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		active: Boolean,
		conversation: Object
	},
	computed: {
		displayName() {
			if (this.conversation.contacts.length) {
				return this.conversation.contacts[0].name;
			} else {
				return this.conversation.formattedPhoneNumber;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.conversation-list-preview {
	max-height: 42px;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
