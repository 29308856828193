module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " text-danger";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row mb-2 queue-call-row"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.priorityLevel : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-channel=\""
    + alias2(alias1((depth0 != null ? depth0.Channel : depth0), depth0))
    + "\">\n    <div class=\"col-1\">\n        "
    + alias2(alias1((depth0 != null ? depth0.QueuePosition : depth0), depth0))
    + "\n    </div>\n    <div class=\"col-4\">\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.displayName : stack1), depth0))
    + "\n    </div>\n    <div class=\"col-4\">\n        "
    + alias2(alias1((depth0 != null ? depth0.QueueName : depth0), depth0))
    + "\n    </div>\n    <div class=\"col-3 queue-time\">\n        "
    + alias2(alias1((depth0 != null ? depth0.QueueTime : depth0), depth0))
    + "\n    </div>\n</div>";
},"useData":true})