<template>
    <div class="list-group list-group-flush">
        <router-link
            v-for="chat in chats"
            :key="chat.entityID"
            tag="a"
            :to="`/chats/${chat.entityID}`"
            class="noparse list-group-item list-group-item-action chat-list-item"
            :class="{
                seen: chat.seen,
                active: selectedChatID === chat.entityID
            }"
        >
            <div class="row">
                <div class="col text-truncate" style="overflow: hidden;">
                    <h6 class="chat-list-title mb-0">{{chat.entity.firstName}} {{chat.entity.lastName}}</h6>
                </div>
                <div class="col-auto">
                    <small>{{chat.lastMessage.date | formatDate(tr('format.datetime')) }}</small>
                </div>
            </div>
            <small class="chat-list-preview">{{chat.lastMessage.text}}</small>
        </router-link>
    </div>
</template>

<script>
export default {
	props: {
		chats: Array
	},
	computed: {
		selectedChatID() {
			if (this.$route.params.chatID) {
				return parseInt(this.$route.params.chatID);
			} else {
				return null;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.chat-list-item {
	text-overflow: ellipsis;
}

.chat-list-title {
    line-height: 1.4;
}

.chat-list-item .chat-list-title,
.chat-list-item .chat-list-preview {
	font-weight: bold;
}

.chat-list-item.seen .chat-list-title,
.chat-list-item.seen .chat-list-preview {
	font-weight: normal;
}
</style>
