module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <tr>\n                        <td>"
    + alias2(alias1((data && data.key), depth0))
    + "</td>\n                        <td>\n                            <div class=\"custom-control custom-checkbox\">\n                                <input type=\"checkbox\" name=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][in]\" id=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][in]\" class=\"custom-control-input\" value=\".+\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0["in"] : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                <label class=\"custom-control-label\" for=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][in]\">&zwnj;</label>\n                            </div>\n                        </td>\n                        <td>\n                            <div class=\"custom-control custom-checkbox\">\n                                <input type=\"checkbox\" name=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][out]\" id=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][out]\" class=\"custom-control-input\" value=\".+\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.out : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                <label class=\"custom-control-label\" for=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][out]\">&zwnj;</label>\n                            </div>\n                        </td>\n                        <td>\n                            <div class=\"custom-control custom-checkbox\">\n                                <input type=\"checkbox\" name=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][pbx]\" id=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][pbx]\" class=\"custom-control-input\" value=\".+\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.pbx : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                <label class=\"custom-control-label\" for=\"recording["
    + alias2(alias1((data && data.key), depth0))
    + "][pbx]\">&zwnj;</label>\n                            </div>\n                        </td>\n                    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container-fluid py-3\">\n    <div class=\"row\">\n        <div class=\"col\">\n            <table class=\"table table-striped border\">\n                <thead>\n                    <tr>\n                        <th>Lokal</th>\n                        <th>Dolazni</th>\n                        <th>Odlazni</th>\n                        <th>Interni</th>\n                    </tr>\n                </thead>\n                <tbody>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.settings : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"useData":true})