module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <thead>\n        <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.columns : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </tr>\n    </thead>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <th style=\"min-width: 300px;\" data-column-index=\""
    + alias2(alias1((data && data.index), depth0))
    + "\">\n                <div class=\"form-group row mb-0\">\n                    <label for=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][label]\" class=\"col-sm-4 col-form-label\">"
    + alias2(helpers.tr.call(alias3,"form.name",{"name":"tr","hash":{},"data":data}))
    + "</label>\n                    <div class=\"col-sm-8\">\n                        <input type=\"text\" class=\"form-control form-control-sm label-input\" name=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][label]\" id=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][label]\" value=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n                    </div>\n                </div>\n\n                <div class=\"form-group row mb-0\">\n                    <label for=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][type]\" class=\"col-sm-4 col-form-label\">"
    + alias2(helpers.tr.call(alias3,"form.type",{"name":"tr","hash":{},"data":data}))
    + "</label>\n                    <div class=\"col-sm-8\">\n                        <select class=\"form-control form-control-sm mt-2 type-selector custom-select\" name=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][type]\" id=\"settings[columns]["
    + alias2(alias1((data && data.index), depth0))
    + "][type]\" data-width=\"100%\" data-ismandatory=\"true\">\n                            <option value=\"text\"     "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.text : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.text",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                            <option value=\"number\"   "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.number : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.number",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                            <option value=\"phone\"    "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.phone : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.phone_number",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                            <option value=\"email\"    "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.email",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                            <option value=\"date\"     "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.date : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.date",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                            <option value=\"time\"     "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.time : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.time",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                            <option value=\"datetime\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.datetime : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias3,"form.datetime",{"name":"tr","hash":{},"data":data}))
    + "</option>\n                        </select>\n                    </div>\n                </div>\n            </th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.label : depth0), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.settings)) && stack1.header),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <td data-column-index=\""
    + alias2(alias1((data && data.index), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.values : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "                        <span class=\"fa fa-eye float-right text-muted\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias2(helpers.tr.call(alias3,"import.original_data",{"name":"tr","hash":{},"data":data}))
    + ": "
    + alias2(alias1((depth0 != null ? depth0.formatted_value : depth0), depth0))
    + "\"></span>\n                    </td>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.values : depth0)) != null ? stack1.error : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                                <span class=\"text-red\">"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"import.invalid_data",{"name":"tr","hash":{},"data":data}))
    + "</span><br>\n                                <span>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.values : depth0)) != null ? stack1.error : stack1), depth0))
    + "</span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                "
    + container.escapeExpression(helpers.log.call(alias1,depth0,{"name":"log","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.values : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "                                        <br>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            "
    + ((stack1 = container.invokePartial(partials.renderValue,depth0,{"name":"renderValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <td data-column-index=\""
    + alias2(alias1((data && data.index), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.values : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(31, data, 0),"data":data})) != null ? stack1 : "")
    + "                    <span class=\"fa fa-eye float-right text-muted\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias2(helpers.tr.call(alias3,"import.original_data",{"name":"tr","hash":{},"data":data}))
    + ": "
    + alias2(alias1((depth0 != null ? depth0.formatted_value : depth0), depth0))
    + "\"></span>\n                </td>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.values : depth0)) != null ? stack1.error : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                            <span class=\"text-red\">"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"import.invalid_data",{"name":"tr","hash":{},"data":data}))
    + "</span><br>\n                            <span>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.values : depth0)) != null ? stack1.error : stack1), depth0))
    + "</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.values : depth0),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    return "                                    <br>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = container.invokePartial(partials.renderValue,depth0,{"name":"renderValue","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table class=\"table table-striped table-bordered\" id=\"preview-table\">\n"
    + ((stack1 = helpers.block.call(alias1,"previewTableHeader",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.preview : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n</table>";
},"usePartial":true,"useData":true})