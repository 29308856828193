import {
	isArray
} from 'lodash';
import Vue from 'BootQuery/Assets/js/vue.js';
import BulkEdit from '../components/BulkEdit.vue';

export default class BulkModal {
	constructor(stateIDs, onSave = null) {
		if (!isArray(stateIDs)) {
			throw new Error('Available states not provided (or not array)');
		}
		this.states = stateIDs;
		this.modal = null;
		this.component = null;
		this.onSave(onSave);
	}

	onSave(onSave) {
		if (onSave && typeof(onSave) !== 'function') {
			throw new Error('onSave handler given, but not a function');
		}
		this.onSaveHandler = onSave;
	}

	async render() {
		this.modal = $.render(await getTemplate('Ticketing.bulkModal'), {});
		this.modal.modal('show');

		const bodyEl = this.modal.findElement('.modal-body');
		setTimeout(() => {
			this.component = new Vue({
				el: bodyEl[0],
				render: h =>
					h(BulkEdit, {
						props: {
							availableStates: this.states
						},
						ref: 'bulkEdit'
					})
			});
		}, 0);

		this.modal.on('hide.bs.modal', () => {
			console.log('DESTROY');
			if (this.component) {
				this.component.$destroy();
				this.component = null;
			}
			this.modal.remove();
		});

		this.modal.findElement('.bulk-edit-modal-save').ev('click.ticketing', ev => {
			ev.preventDefault();
			const compRoot = this.component.$refs.bulkEdit;
			const data = compRoot.getData();
			this.modal.modal('hide');
			if (this.onSaveHandler) {
				this.onSaveHandler(data);
			}
		});
	}
}