module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.success : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.failure : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                            		<div class=\"col-md-10 col-md-offset-1 alert alert-success\">Promjene uspješno spremljene</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                            		<div class=\"col-md-10 col-md-offset-1 alert alert-danger\">Greška pri spremanju promjena</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.settings : depth0), depth0),{"name":"settings","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "									"
    + ((stack1 = container.invokePartial(partials.form,depth0,{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"content-wrapper\">\n\n	<div class=\"container-fluid\" id=\"main-container\">\n\n	    "
    + ((stack1 = container.invokePartial(partials.header,depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n	    <div class=\"row-fluid\">\n\n	        "
    + ((stack1 = container.invokePartial(partials.menu,depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n	        <div class=\"col-md-12 col-lg-12 bq-content bq-default\">\n\n	            <div class=\"panel panel-default\">\n		            <div class=\"panel-heading\">\n		            	<span class=\"glyphicon glyphicon-cog\"></span>&nbsp;&nbsp;"
    + alias2(helpers.tr.call(alias1,"label.voicemail_settings",{"name":"tr","hash":{},"data":data}))
    + "\n		            </div>\n\n		            <div class=\"panel-body\">\n\n		            	<form id=\"voicemails-form\" action=\"/voicemails/"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.method : stack1), depth0))
    + "/?extensionID_eq="
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.parameters : stack1)) != null ? stack1.extensionID_eq : stack1), depth0))
    + "\" method=\"post\">\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.bootquery : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			            	<input type=\"hidden\" name=\"action\" value=\"update\">\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.form : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		            	</form>\n		            </div>\n\n		            <div class=\"panel-footer\">\n		            	<div class=\"row\">\n			            	<div class=\"col-xs-12\">\n                                <div class=\"pull-left\">\n                                	<a href=\"/voicemails/"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.method : stack1), depth0))
    + "/\" class=\"btn btn-info\">\n                                		<span class=\"glyphicon glyphicon-chevron-left\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias1,"button.back",{"name":"tr","hash":{},"data":data}))
    + "\n                                	</a>\n                                </div>\n\n                                <div class=\"pull-right\">\n                                	<span class=\"save-status\" data-form=\"voicemails-form\"></span>\n\n	                                <button type=\"submit\" class=\"btn btn-success\" form=\"voicemails-form\">\n	                                    <span class=\"glyphicon glyphicon-ok\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n	                                </button>\n                                </div>\n                            </div>\n                        </div>\n		            </div>\n	            </div>\n	        </div>\n	    </div>\n	</div>\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})