module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "	<div class=\"card\" id=\"wizard-container\">\n		<div class=\"card-header\">\n			<span class=\"fa fa-download\"></span> Import\n		</div>\n		<div class=\"card-body\">\n			"
    + alias2(helpers.log.call(alias1,depth0,{"name":"log","hash":{},"data":data}))
    + "\n			"
    + alias2(helpers.tr.call(alias1,"label.import_complete",{"name":"tr","hash":{},"data":data}))
    + "\n		</div>\n		<div class=\"card-footer d-flex\">\n			<a class=\"btn btn-primary ml-auto\" href=\"/campaigns/dataset/?dataset="
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.dataset : stack1), depth0))
    + "\">\n				OK&nbsp;<span class=\"fa fa-arrow-right\"></span>\n			</a>\n		</div>\n	</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})