module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <label class=\"col-12 col-form-label "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.label : depth0), depth0))
    + ":\n            </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " col-md-2 col-form-label text-left text-md-right";
},"4":function(container,depth0,helpers,partials,data) {
    return "col-md-10";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                        "
    + ((stack1 = container.invokePartial(partials.formListRow,depth0,{"name":"formListRow","hash":{"nested":(depths[1] != null ? depths[1].nested : depths[1]),"plain":(depths[1] != null ? depths[1].plain : depths[1]),"readonly":(depths[1] != null ? depths[1].readonly : depths[1]),"disabled":"true","key":(depths[1] != null ? depths[1].key : depths[1]),"parentPath":(depths[1] != null ? depths[1].parentPath : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.readonly : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"row add-btn-row\">\n                            <div class=\"col-12 col-sm-3 col-md-2 ml-auto\">\n                                <button type=\"button\" class=\"listform-button-add btn btn-block btn-success\" name=\""
    + container.escapeExpression(helpers.array_to_path.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),"$addBtn",{"name":"array_to_path","hash":{},"data":data}))
    + "\">\n                                    <span class=\"fa fa-plus\"></span>\n                                    Dodaj\n                                </button>\n                            </div>\n                        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"col-12\">\n    <div class=\"form-group row\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"col-12 "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <div class=\"card subform-card\">\n                <div class=\"card-body\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.rowsFields : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.plain : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true})