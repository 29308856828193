import { EventEmitter } from 'events';
import { get as apiGet } from 'BootQuery/Assets/js/apiRequest.js';

export default class TicketPicker extends EventEmitter {
	constructor(opts = {}) {
		super();
		this.opts = opts || {};
		this.modal = null;
		this.render();
	}

	async render() {
		this.modal = $.render(
			await getTemplate('Ticketing.ticketPickerModal'),
			{
				title: this.opts.title || 'Odaberi ticket'
			}
		);
		this.modal.modal();
		const picker = this.modal.findElement('.ticket-picker');
		const submitBtn = this.modal.findElement('button[type="submit"]');

		picker.pickle({
			results(searchString, results, callback) {
				let $el = $(this);
				if ($el.data('isQuerying')) {
					return;
				}
				$el.data('currentSearchString', searchString);
				if ($el.data('lastSearchString') === searchString) {
					$el.data('isQuerying', false);
					callback(results, searchString.length);
					return;
				}

				$el.data('isQuerying', true);
				apiGet('/api/ticketing/tickets/', {
					title: searchString.trim()
				}).then(tickets => {
					const options = tickets.map(ticket => {
						return {
							id: ticket.ID,
							text: ticket.title
						};
					});
					$el.data('isQuerying', false);
					$el.data('lastSearchString', searchString);
					callback(options, searchString.length);
					if (
						$el.data('currentSearchString', searchString) !==
						$el.data('lastSearchString')
					) {
						$el.pickle('research');
					}
				});
			}
		});
		picker.on('change.ticketing', e => {
			const val = picker.val();
			submitBtn.prop('disabled', !val);
		});
		submitBtn.ev('click.ticketing', async e => {
			e.preventDefault();
			const ticketID = parseInt(picker.val());
			const option = picker.pickle('option', ticketID);

			picker.pickle('disabled', true);
			submitBtn.prop('disabled', true);

			this.emit('submit', {
				ticketID,
				ticketTitle: option.text
			});
		});
	}

	close() {
		this.modal.modal('hide');
		this.modal.modal('dispose');
		this.modal.remove();
	}
}
