<template>
	<span>
		<button class="btn btn-success" title="Novi" @click="openModal">
			<span class="fas fa-plus"></span>
			Novi
		</button>
		<Modal v-if="modalVisible" @close="closeModal">
			<template slot="title">
				<span class="fas fa-plus"></span>
				Dodaj
			</template>

			<template>
				<TrackerSettings
					ref="trackerSettings"
					:isNew="true"
					:disabled="submittingSettings"
					@update:canSubmit="$ev => canSubmit = $ev"
				/>
			</template>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-link text-danger"
					@click="closeModal"
				>Odustani</button>
				<button
					type="button"
					class="btn btn-success"
					@click="submitTracker"
					:disabled="!canSubmit || submittingSettings"
				>
					<span v-if="submittingSettings" class="fas fa-spin fa-spinner"></span>
					<span v-else class="fa fa-check"></span>
					Spremi
				</button>
			</template>
		</Modal>
	</span>
</template>

<script>
import Modal from 'BootQuery/Assets/js/bootstrap-components/Modal.vue';
import TrackerSettings from '../TrackerSettings.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		Modal,
		TrackerSettings
	},
	data() {
		return {
			modalVisible: false,
			submittingSettings: false,
			canSubmit: false
		};
	},
	methods: {
		openModal() {
			this.modalVisible = true;
		},
		closeModal() {
			this.modalVisible = false;
		},
		async submitTracker() {
			this.submittingSettings = true;
			const data = this.$refs.trackerSettings.getChanges();
			await Api.post('/api/gsmTrackers/trackers', data);
			this.submittingSettings = false;
			this.closeModal();
		}
	}
};
</script>

<style>
</style>
