module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"text-danger\">\n			"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"Dialer:label.cant_call_without_extension",{"name":"tr","hash":{},"data":data}))
    + "\n		</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"text-center\">\n	<button class=\"btn btn-success btn-block clickvox-dial-btn\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasExtension : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n		<span class=\"fas fa-phone\"></span>\n		"
    + container.escapeExpression(helpers.tr.call(alias1,"Dialer:button.call",{"name":"tr","hash":{},"data":data}))
    + "\n	</button>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasExtension : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true})