import {getOwnProperty, setOwnProperty, arrayToPath, splitNestedInputName, intersectionFromStart} from 'app/assets/js/util.js';
import {filter, forEach, uniq, last, map, partial, join, some, merge, reduce, upperFirst, nth, flow} from 'lodash';
import createModal from 'app/assets/js/modals.js';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { isObject, isArray, isString } from 'util';
import { activatePopovers } from './BootQuery';
import Quill from './quill';

function getNestedFieldPath(pathArr, prefix) {
	let path = valueToDefinitionPath(pathArr);
	if (prefix) {
		if (path[0] === prefix) {
			path.shift();
		}
	}
	return 'fields.' + path.join('.fields.');
}

function valueToDefinitionPath(valuePathArr) {
	return filter(valuePathArr, function(part) {
		return part.substr(0, 3) !== 'new' && !part.match(/^\d+$/);
	});
}

function valuePathToBase(valuePathArr, baseArr) {
	var valuePath = valuePathArr.slice();
	var base = baseArr.slice();
	var newPath = [];
	forEach(base, function(basePart) {
		if (!valuePath.length) {
			return;
		}
		if (valuePath[0] == basePart) {
			newPath.push(valuePath.shift());
		}
		if (valuePath.length && valuePath[0].match(/^\d+$/)) {
			newPath.push(valuePath.shift());
		}
	});
	return newPath;
}

function rowToOption(option, fieldDefinition) {
	return {
		id: option[fieldDefinition.idColumn],
		text: option[fieldDefinition.textColumn],
		rowData: option
	};
}

function getRecursiveDependants($form, field, processedDependants) {
	if (typeof(processedDependants) === 'undefined') {
		processedDependants = {};
	}
	var data = $form.data('form');
	var allDependants = [];
	forEach(field.dependants, function(dependant) {
		var fullFieldPath = null;
		if (dependant[0] == '/') {
			fullFieldPath = dependant.substr(1).split('.');
		} else {
			fullFieldPath = field.parentPath.concat(dependant.split('.'));
		}
		var fullFieldPathStr = fullFieldPath.join('.');
		allDependants.push(fullFieldPathStr);
		if (!processedDependants[fullFieldPathStr]) {
			var definitionPath = getNestedFieldPath(fullFieldPath, data.formDefinition.prefix);
			var dependantDef = window.getOwnProperty(data.formDefinition, definitionPath);
			var dependantDependants = getRecursiveDependants($form, dependantDef, processedDependants);
			allDependants = allDependants.concat(dependantDependants);
			processedDependants[fullFieldPathStr] = dependantDependants;
		} else {
			allDependants = allDependants.concat(processedDependants[fullFieldPathStr]);
		}
	});
	return uniq(allDependants);
}

function rebindForm($form, pathsToDiff) {
	var formData = getFormData($form);
	var pathsToBind = reduce(pathsToDiff, function(paths, path) {
		paths[path] = {
			definitionPath: valueToDefinitionPath(path.split('.')).join('.')
		};
		return paths;
	}, {});
	var data = {
		module: window.Bootstrap.bootquery.moduleName,
		form: $form.data('form').formDefinition.nameWithModule,
		formData: formData,
		pathsToBind: pathsToBind
	};
	if ($form.data('form').formDefinition.prefix) {
		data.formPrefix = $form.data('form').formDefinition.prefix;
	}
	Api.post('/api/rebindForm', data).then(rebinded => {
		forEach(rebinded, (field, valuePath) => {
			var data = $form.data('form');
			var definitionPath = getNestedFieldPath(field.definitionPath.split('.'), data.formDefinition.prefix);
			var fieldDef = window.getOwnProperty(data.formDefinition, definitionPath);
			var elName = window.arrayToPath(valuePath.split('.'));
			var $selectEl = $('select[name="' + elName + '"]');
			var optionFormatFunc = partial(rowToOption, partial.placeholder, fieldDef);
			var pickleOptions = map(field.newBinding.options, optionFormatFunc);

			data.disableOnChangeHandler = true;

			$selectEl.val(field.newBinding.value);
			$selectEl.pickle('options', pickleOptions);
			$selectEl.pickle('select', field.newBinding.value);
			$selectEl.pickle('disabled', !!field.newBinding.disabled);

			data.disableOnChangeHandler = false;
		});
	});
}

function rebindSelect($el, alsoRebindSelf) {
	var $form = formElementForControl($el);
	var data = $form.data('form');
	var formDef = data.formDefinition;
	if (data.disableOnChangeHandler) {
		return;
	}
	var elName = $el.attr('name');
	var fieldPath = splitNestedInputName(elName);
	var fieldDef = getOwnProperty(formDef, getNestedFieldPath(fieldPath, formDef.prefix));
	var fullFieldPath = fieldDef.parentPath.concat(fieldDef.key);

	var dependants = getRecursiveDependants($form, fieldDef);
	var joinWithDots = partial(join, partial.placeholder, '.');
	var fullDependencyPaths = flow(
		dependant => map(dependant, dependant => {
			var intersection = intersectionFromStart(fullFieldPath, dependant.split('.'));
			var valuePathBase = valuePathToBase(fieldPath, intersection.commonMatch);
			return valuePathBase.concat(intersection.arr2Remaining);
		}),
		dependant => filter(dependant, val => val.length),
		dependant => map(dependant, joinWithDots)
	)(dependants);

	if (alsoRebindSelf) {
		fullDependencyPaths.unshift(joinWithDots(fieldPath));
	}

	if (fullDependencyPaths.length) {
		rebindForm($form, fullDependencyPaths);
	}
}

function clearInvalidationState($form) {
	$form.find('.is-invalid').removeClass('is-invalid');
	$form.find('.form-error-info').remove();
}

function flattenValidationErrors(validationErrors, parentPath = []) {
	return Object.entries(validationErrors).reduce( (errors, [fieldName, field]) => {
		const path = parentPath.concat([fieldName]);

		if (isArray(field) || isString(field)) {
			errors.push({
				fieldPath: path,
				errors: field
			});
		} else if (isObject(field)) {
			errors = errors.concat(flattenValidationErrors(field, path));
		} else {
			throw new TypeError('Expected error(string|array) or sub-fields (object), got' + typeof(field));
		}

		return errors;
	}, []);
}

async function showValidationState($form, validationErrors) {
	clearInvalidationState($form);

	const errorInfoTemplate = await getTemplate('formValidationErrorInfo');

	const formDef = $form.data('form').formDefinition;
	const pathPrefix = formDef.prefix ? [formDef.prefix] : [];
	const flatErrors = flattenValidationErrors(validationErrors, pathPrefix);
	flatErrors.forEach(errorInfo => {
		if (!errorInfo || errorInfo.length === 0) {
			return;
		}
		const elName = arrayToPath(errorInfo.fieldPath);
		const inputEl = $form.findElement(`[name="${elName}"]`);

		inputEl.addClass('is-invalid');
		const errorInfoEl = $.render(errorInfoTemplate, {errors: errorInfo.errors});
		activatePopovers(errorInfoEl);
		inputEl.first().parent().append(errorInfoEl);
	});
}

function activateForm($form, options) {
	if (!$form.is('form')) {
		let name = options.formDefinition.name;
		let $potentialForm = $form.parents().last().find('[data-form="' + name + '"]');
		if ($potentialForm.length) {
			$form = $potentialForm;
		}
	}
	if ($form.data('form') && $form.data('form').activated) {
		return;
	}
	$form.data('form', {
		activated: true,
		newListRowCounts: {}
	});
	var data = $form.data('form');
	var formDef = options.formDefinition;
	data.formDefinition = formDef;

	let $actualForm = $form;
	if (!$actualForm.is('form')) {
		$actualForm = $actualForm.closest('form');
	}
	if ($actualForm.is('form')) {
		let validators = $actualForm.data('validators');
		if (!validators) {
			validators = {};
			$actualForm.data('validators', validators);
		}

		let validationErrorField = $actualForm.data('validationError');

		const formName = formDef.nameWithModule || formDef.name;
		validators[formName] = () => {
			const dataToValidate = {
				module: window.Bootstrap.bootquery.moduleName,
				formPrefix: formDef.prefix,
				form: formName,
				formData: getFormData($form)
			};

			return Api.post('/api/validateForm', dataToValidate).then(status => {
				showValidationState($form, status.validationErrors);

				if($actualForm.findElement(`input[name="${validationErrorField}"]`).length == 0) {
					$actualForm.append($('<input/>', { type: 'hidden', name: validationErrorField, value: 'true'}));
				}

				if(status.validationErrors.length !== 0 && validationErrorField) {
					$actualForm.findElement(`input[name="${validationErrorField}"]`).val('false');

					return true; // Allow submission, we'll just store the error state
				} else {
					return status.validationErrors.length === 0;
				}
			});
		};
		$actualForm.ev('submit.form', function(e) {
			e.preventDefault();
			e.stopPropagation();

			setFormSaveStatus($actualForm, 'validating');
			getValidationResult($actualForm).then(allValidated => {
				if (allValidated) {
					setFormSaveStatus($actualForm, 'saving');
					submitForm($actualForm, window.Bootstrap);
				} else {
					setFormSaveStatus($actualForm, 'validation-error');
				}
			});

			return false;
		});
	}

	activateFormElements($form);

	$form.find('.listform-button-add').off('click.form').on('click.form', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var $el = $(e.currentTarget);
		var listPathStr = splitNestedInputName($el.attr('name')).slice(0, -1).join('.');
		var listDef     = getListDefinitionForElement($el);
		getTemplate('formListRow').then(function(template) {
			if (!data.newListRowCounts[listPathStr]) {
				data.newListRowCounts[listPathStr] = 0;
			}
			var newCount = ++data.newListRowCounts[listPathStr];
			var renderContext = $.extend(listDef.newRowFields, {
				$id: 'new' + newCount,
				$rowIndex: 'new' + newCount,
				$is_new_row: true,
				editable: true,
				key: listDef.key,
				parentPath: listDef.parentPath
			});

			var $btnRow = $el.closest('.add-btn-row');
			var $rendered = $(handlebarsRender(template, renderContext));
			$rendered.insertBefore($btnRow)
				.hide()
				.slideDown();
			$rendered.addClass('addedrow');
			activateElements('.addedrow');
			activateFormElements($form, $rendered);
			$rendered.removeClass('addedrow');
		});
	});
}

export function getValidationResult($actualForm) {
	const validators = $actualForm.data('validators');
	const validationPromises = Object.values(validators).map(validator => validator());
	return Promise.all(validationPromises).then(validations => {
		return validations.every(validated => validated === true);
	});
}

function activateFormElements($form, target) {
	if (!target) {
		target = $form;
	}
	let $target = $(target);
	let data = $form.data('form');
	let formDef = data.formDefinition;

	$target.findElement('.form-quill').each(function() {
		const $el = $(this);
		const valueInputName = $el.data('valueInputName');
		const valueInput = $target.findElement(`input[name="${valueInputName}"]`);
		const quill = new Quill($el[0] , {
			readOnly: false,
			theme: 'snow',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],

					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'script': 'sub'}, { 'script': 'super' }],

					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

					['link', 'image'],
					['blockquote', 'code-block'],
					['clean']
				],
				autoLinks: true
			}
		});
		setTimeout(() => {
			quill.pasteHTML(valueInput.val());
			const contentHeight = $el.find('.ql-editor').prop('scrollHeight');
			$el.find('.ql-editor').css({
				height: Math.max(100, Math.min(contentHeight, 360))
			});
			quill.on('text-change', () => {
				const htmlContent = quill.container.firstChild.innerHTML;
				valueInput.val(htmlContent);
			});
		}, 0);
	});

	$target.findElement('.form-pickle').each(function() {
		let $el = $(this);
		let elName = $el.attr('name');
		let path            = splitNestedInputName(elName);
		let definition      = getOwnProperty(formDef, getNestedFieldPath(path, formDef.prefix));
		let ownName         = last(splitNestedInputName($el.attr('name')));
		let newOptionElName = arrayToPath(parentPath($el).concat([ownName+':$newOptionText']));
		let initialOptions;
		if (definition && definition.options) {
			initialOptions = map(definition.options, (option) => {
				return rowToOption(option, definition);
			});
		}
		$el.data('lastSearchString', '');
		$el.pickle({
			results: function(searchString, results, callback) {
				let $el = $(this);
				if ($el.data('isQuerying')) {
					return;
				}
				$el.data('currentSearchString', searchString);
				if ($el.data('lastSearchString') === searchString) {
					$el.data('isQuerying', false);
					callback(results, searchString.length);
					return;
				}

				$el.data('isQuerying', true);
				let formData = getFormData($form);
				let searchStringPath = path.slice();
				let key = searchStringPath.pop();
				searchStringPath.push(key + ':$searchString');
				setOwnProperty(formData, searchStringPath, searchString);

				let pathsToBind = {};
				pathsToBind[path.join('.')] = {
					definitionPath: valueToDefinitionPath(path).join('.')
				};
				let data = {
					module: window.Bootstrap.bootquery.moduleName,
					form: formDef.nameWithModule,
					formData: formData,
					pathsToBind: pathsToBind
				};
				if (formDef.prefix) {
					data.formPrefix = formDef.prefix;
				}
				Api.post('/api/rebindForm', data).then(rebound => {
					let newField = rebound[path.join('.')].newBinding;
					let options = map(newField.options, partial(rowToOption, partial.placeholder, newField));
					$el.data('isQuerying', false);
					$el.data('lastSearchString', searchString);
					if (definition.newEntry) {
						let newDef = definition.newEntry;
						if (newDef.type === 'simple') {
							let hasExactMatches = some(options, function(option) {
								let text = option.rawText;
								if (!text || typeof(text) !== 'string') {
									return false;
								}
								return text.toLowerCase() === searchString.toLowerCase();
							});
							if (!hasExactMatches && searchString.length) {
								options.push({
									id: '$unselectedNewOption$',
									isNewOption: true,
									isNewTextualOption: true,
									text: searchString.trim(),
									searchString: searchString.trim()
								});
							}
						} else if (newDef.type === 'modal') {
							options.push({
								id: '$createNewFromModal$',
								isNewOption: true,
								isNewFromModalOption: true
							});
						}
					}
					callback(options, searchString.length);
					if ($el.data('currentSearchString', searchString) !== $el.data('lastSearchString')) {
						$el.pickle('research');
					}
				});
			},

			formatOption: function(option) {
				var text = option.text;
				if (option.isNewOption) {
					if (option.selectedNewOption) {
						return '<strong>' + option.searchString + '<strong>';
					} else if (option.isNewFromModalOption) {
						return '<strong>+ Dodaj novo</strong>';
					} else {
						return 'Unesi <strong>"' + option.searchString + '"</strong> kao novo';
					}
				}
				if (option.rowData && option.rowData.formattedText) {
					return option.rowData.formattedText;
				}
				if (!text || !text.length) {
					return '&nbsp';
				}
				return text;
			},

			formatSelectedOption: function(option) {
				var text = option.text;
				if (option.isNewOption && option.isNewTextualOption) {
					var $newOptionText = $('input[name="' + newOptionElName + '"]');
					return $newOptionText.length ? $newOptionText.val() : option.searchString;
				}
				if (option.rowData && option.rowData.selectedFormattedText) {
					return option.rowData.selectedFormattedText;
				}
				if (!text || !text.length) {
					return '&nbsp';
				}
				return text;
			},
			initialOptions: initialOptions
		});
		if (definition.newEntry && definition.newEntry.type === 'modal') {
			var options = $el.pickle('options');
			options.push({
				id: '$createNewFromModal$',
				isNewOption: true,
				isNewFromModalOption: true
			});
			$el.pickle('options', options);
		}
		$el.off('select.form').on('select.form', function(e) {
			var value = e.value;
			var $newOptionText = $('input[name="' + newOptionElName + '"]');
			if (value === '$unselectedNewOption$' || value === '$newOption$') {
				var option = $el.pickle('option', e.value);
				if (e.value === '$unselectedNewOption$') {
					option = merge(option, {
						id: '$newOption$',
						persist: true,
						selectedNewOption: true
					});
					$el.pickle('option', '$newOption$', option);
					value = '$newOption$';
					$el.val(value);
				}
				if (!$newOptionText.length) {
					var newElemParams = {
						type: 'hidden',
						value: option.searchString,
						name: newOptionElName
					};
					$newOptionText = $('<input/>', newElemParams).insertAfter($el);
				}
				$newOptionText.val(option.searchString);
			} else if (e.value === '$createNewFromModal$') {
				var modalParams = definition.newEntry.modalParams || {};
				var params = {};
				forEach(modalParams, function(value, key) {
					params['modal' + upperFirst(key)] = value;
				});
				createModal(params, function(modal) {
					$(modal).find('form').on('succesfull-submit.form', function(e) {
						// let $modalForm = $(e.currentTarget);
						let newID = e.submit_info.mainFormID;
						$(modal).modal('hide');
						data.disableOnChangeHandler = true;
						$el.pickle('option', newID, {id: newID});
						$el.pickle('select', newID);
						data.disableOnChangeHandler = false;
						rebindSelect($el, true);
					});
				});
			}

			if (value !== '$newOption$') {
				$newOptionText.remove();
			}
		});
	});

	$target.findElement('select.form-pickle').off('change.form').on('change.form', function(e) {
		if (data.disableOnChangeHandler) {
			return;
		}
		rebindSelect($(e.currentTarget));
	});

	$target.findElement('.listform-button-edit').off('click.form').on('click.form', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var $el = $(e.currentTarget);
		var $row = $el.closest('.listform-row');
		getTemplate('formListRow').then(function(template) {
			var $el = $(e.currentTarget);
			var rowIndex = getRowIndexForElement($el);
			var listDef = getListDefinitionForElement($el);
			var rowDef = listDef.rowsFields[rowIndex];

			var renderContext = $.extend(rowDef, {
				editable: true,
				isEditableReplacedRow: true,
				key: listDef.key,
				parentPath: listDef.parentPath
			});
			var renderedRaw = handlebarsRender(template, renderContext);
			var $rendered = $(renderedRaw);
			$row.addClass('listform-row-edit-hidden').prop('hidden', true);
			$rendered.insertAfter($row);
			$rendered.addClass('addedrow');
			activateElements('.addedrow');
			activateFormElements($form, $rendered);
			$rendered.removeClass('addedrow');
		});
	});

	$target.findElement('.listform-button-cancel-edit').off('click.form').on('click.form', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var $el = $(e.currentTarget);
		var $row = $el.closest('.listform-row');
		var $originalRow = getOriginalRow($row);
		$originalRow.removeClass('listform-row-edit-hidden').prop('hidden', false);
		$row.remove();
	});

	$target.findElement('.listform-button-remove').off('click.form').on('click.form', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var $el = $(e.currentTarget);
		var $checkbox = $el.find('input[type=checkbox]');

		var idElementName = arrayToPath(parentPath($checkbox).concat(['$id']));
		var $row = $el.closest('.listform-row');
		var $originalRow = getOriginalRow($row);

		var afterHide;
		var $activeRow = $row;
		if ($originalRow.length) {
			$activeRow = $originalRow;
			afterHide = function() {
				$(this).remove();
			};
		}
		$activeRow.find('input[name="' + $checkbox.attr('name') + '"]').prop('checked', true);
		$activeRow.find('input[name="' + idElementName + '"]').prop('disabled', false);
		$row.slideUp('normal', afterHide);
	});

	$target.findElement('.listform-button-newrow-remove').off('click.form').on('click.form', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var $el = $(e.currentTarget);
		$el.closest('.listform-row').slideUp('normal', function() {
			$(this).remove();
		});
	});
}

function getListDefinitionForElement($el) {
	var $form = formElementForControl($el);
	var formDef = $form.data('form').formDefinition;
	var path = splitNestedInputName($el.attr('name'));
	var listPath;
	if (last(path) === '$addBtn') {
		listPath = path.slice(0, -1);
	} else {
		listPath = path.slice(0, -2);
	}
	return getOwnProperty(formDef, getNestedFieldPath(listPath, formDef.prefix));
}

function getRowIndexForElement($el) {
	var path = splitNestedInputName($el.attr('name'));
	if (last(path) === '$addBtn') {
		return null;
	}
	return nth(path, path.length - 2);
}

function parentPath($el) {
	return splitNestedInputName($el.attr('name')).slice(0, -1);
}

function getOriginalRow($editableRow) {
	var listformRowID = $editableRow.data('listformRowId');
	if (!listformRowID) {
		return $([]);
	}
	return $('.listform-row-edit-hidden[data-listform-row-id="' + listformRowID + '"]');
}

function formElementForControl($el) {
	if ($el.closest('[data-form]').length) {
		return $el.closest('[data-form]');
	} else {
		return $el.closest('form');
	}
}

$.fn.form = function(options) {
	return this.each(function() {
		var settings = $.extend($.fn.form.defaults, options);
		activateForm($(this), settings);
		return this;
	});
};

$.fn.form.defaults = {

};