<template>
    <div class="tab-pane fade" :class="active ? 'show active' : ''">
        <slot />
    </div>
</template>

<script>
export default {
	props: {
		id: String,
		title: String,
		icon: String,
		badgeContent: String,
		badgeVariation: {
			default: 'secondary',
			type: String
		}
	},
	data() {
		return {
			active: false
		};
	},
	computed: {
		href() {
			return `#${this.id}`;
		}
	}
};
</script>

<style>

</style>
