module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " -->\n					 "
    + ((stack1 = container.invokePartial(partials.agentRow,depth0,{"name":"agentRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				<!-- ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row\" id=\"extension-list\">\n	<div class=\"col-xs-12 col-lg-12\">\n		\n		<table class=\"table\" style=\"margin-bottom: 0;\">\n			<thead>\n				<tr>\n					<th style=\"width: 34px; border: none; padding: 0;\"></th>\n					<th style=\"border: none; padding: 0;\"></th>\n					<th style=\"width: 34px; border: none; padding: 0;\"></th>\n					<th style=\"border: none; padding: 0;\"></th>\n					<th style=\"border: none; padding: 0; width: 50%;\"></th>\n					<th style=\"border: none; padding: 0;\"></th>\n				</tr>\n				<tr style=\"border-bottom: 2px solid #DDD;\" class=\"extension-table-head-row\">\n					<th>Connected</th>\n					<th>Agent</th>\n					<th>Logged</th>\n					<th>Status</th>\n					<th>Poziv</th>\n					<th>Vrijeme</th>\n				</tr>\n			</thead>\n\n			<tbody>\n				<!-- "
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.extensions : stack1), depth0),{"name":"result.extensions","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " -->\n				\n			</tbody>\n		</table>\n		\n	</div>\n	\n</div>";
},"usePartial":true,"useData":true})