module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "        <a href=\"/campaigns/stop/?campaign="
    + alias1(container.lambda((depth0 != null ? depth0.campaignID : depth0), depth0))
    + "\" class=\"btn btn-danger btn-sm\">\n            <span class=\"fa fa-stop\"></span> "
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.stop",{"name":"tr","hash":{},"data":data}))
    + "\n        </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.unused : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "            <a href=\"/campaigns/activate/?campaign="
    + alias1(container.lambda((depth0 != null ? depth0.campaignID : depth0), depth0))
    + "\" class=\"btn btn-success btn-sm\">\n                <span class=\"fa fa-play\"></span> "
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.start",{"name":"tr","hash":{},"data":data}))
    + "\n            </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.allow_login : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.unused : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                <a href=\"/campaigns/login/?campaign="
    + alias2(alias1((depth0 != null ? depth0.campaignID : depth0), depth0))
    + "&agent="
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-primary btn-sm\">\n                    <span class=\"fa fa-sign-in-alt\"></span> "
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.login",{"name":"tr","hash":{},"data":data}))
    + "\n                </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.can_resume : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.unused : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <a href=\"/campaigns/logout/?campaign="
    + alias3(alias2((depth0 != null ? depth0.campaignID : depth0), depth0))
    + "&agent="
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-danger btn-sm\">\n                <span class=\"fa fa-sign-out-alt\"></span> "
    + alias3(helpers.tr.call(alias1,"button.logout",{"name":"tr","hash":{},"data":data}))
    + "\n            </a>\n        ";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                <button data-campaign=\""
    + alias2(alias1((depth0 != null ? depth0.campaignID : depth0), depth0))
    + "\" data-agent=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.userID), depth0))
    + "\" class=\"btn btn-primary btn-sm\" id=\"campaign-next-call\">\n                    <span class=\"fa fa-play\"></span> "
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.resume",{"name":"tr","hash":{},"data":data}))
    + "\n                </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_manager : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_agent : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})