<template>
    <div class="card" :class="[cardClass, {'mail-collapsed': collapsedVal}]" :id="`msg${ID}`" @click="collapsedVal = false">
        <div class="d-flex px-3 py-2 w-100 justify-content-between card-block message-header">
            <slot name="header">
                <span>
                    <slot name="title" v-bind="$props">
                        <strong class="message-title">
                            <span
                                v-if="isHighPriority"
                                class="fas fa-exclamation text-danger"
                            ></span>
                            {{subject}}
                        </strong>
                    </slot>
                    <br>
                    <strong>{{ tr("Mails:label.address_from") }}:</strong>
                    {{fromMailAddress}}
                    <br>
                    <AddressList :label="tr('Mails:label.address_to')" :addresses="to"/>
                    <br v-if="cc.length">
                    <AddressList
                        v-if="cc.length && !collapsedVal"
                        :label="tr('Mails:label.address_cc')"
                        :addresses="cc"
                    />
                    <br v-if="bcc.length">
                    <AddressList
                        v-if="bcc.length && !collapsedVal"
                        :label="tr('Mails:label.address_bcc')"
                        :addresses="bcc"
                    />
                </span>
                <span class="d-inline-flex">
                    <span class="text-muted text-nowrap">{{ date | formatDate( tr('format.datetime') ) }}</span>
                    <div class="dropdown">
                        <a href="#" class="px-2 ml-3 text-dark float-right" data-toggle="dropdown">
                            <span class="fa fa-ellipsis-v"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a @click.prevent="print" target="_blank" :href="printURL" class="dropdown-item">
                                <span class="fas fa-print"></span> {{ tr("Mails:button.print") }}
                            </a>
                        </div>
                    </div>
                </span>
            </slot>
        </div>
        <div class="card-body">
            <slot name="content" v-bind="$props">
                <template v-if="!collapsedVal">
                    <HTMLMessage v-if="htmlBody" :html="htmlBody" />
                    <pre v-else>{{textBody}}</pre>
                </template>
                <pre v-else>{{previewTextBody}}</pre>
            </slot>
        </div>

        <div v-if="attachments.length && !collapsedVal" class="bg-light border-top card-block">
            <div class="py-1 px-2">
                <small class="text-muted">
                    {{ tr('Mails:label.attachments') }}
                </small>
            </div>
            <div class="py-1 px-2">
                <div class="row">
                    <div
                        class="col-auto attachment-wrapper"
                        v-for="(attachment, index) in attachments"
                        :key="index"
                    >
                        <Attachment
                            :messageID="attachment.messageID"
                            :ID="attachment.ID"
                            :filename="attachment.filename"
                            :size="10000"
                            :type="attachment.mime"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer message-footer" v-if="!collapsedVal">
            <slot name="footer">
                <div class="d-flex d-flex px-3 py-2 message-actions">
                    <div v-if="!sentVal" class="d-flex" style="align-items: center;">
                        <span class="spinner-border spinner-border-sm text-primary"></span>
                        &nbsp;
                        Sending...
                    </div>

                    <button
                        type="button"
                        class="btn btn-link text-danger ml-auto cancel-edit-btn"
                        hidden
                    >
                        <span class="fa fa-times-circle"></span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary ml-auto mail-action"
                        data-mail-forward="true"
                        @click.prevent="forward"
                    >
                        <span class="fa fa-arrow-right"></span>
                        {{ tr("Mails:button.forward") }}
                    </button>
                    &nbsp;
                    <div class="btn-group mail-action">
                        <button type="button" class="btn btn-primary" @click.prevent="replyAll">
                            <span class="fa fa-reply-all"></span>
                            {{ tr("Mails:button.reply_all") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary dropdown-toggle"
                            data-toggle="dropdown"
                        >
                            <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item" @click.prevent="replyAll">
                                <span class="fa fa-reply-all"></span>
                                {{ tr("Mails:button.reply_all") }}
                            </a>
                            <a href="#" class="dropdown-item" @click.prevent="replySender">
                                <span class="fa fa-reply"></span>
                                {{ tr("Mails:button.reply") }}
                            </a>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <div v-if="collapsedVal" class="mail-collapsed-overlay">
            <div class="mail-collapsed-overlay-content">
                <button class="btn btn-primary">
                    Pokazi
                    <span class="fas fa-arrow-down"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import AddressList from './AddressList.vue';
import Attachment from './Attachment.vue';
import HTMLMessage from './HTMLMessage.vue';

export default {
	components: {
		AddressList,
		Attachment,
		HTMLMessage
	},
	props: {
		ID: Number,
		subject: String,
		date: Date,
		fromMailAddress: String,
		to: Array,
		cc: Array,
		bcc: Array,
		htmlBody: String,
		textBody: String,
		attachments: Array,
		priority: Number,
		cardClass: String,
		sent: Boolean,
		collapsed: Boolean
	},
	data() {
		return {
			sentListener: null,
			sentVal: this.sent,
			collapsedVal: this.collapsed
		};
	},
	methods: {
		replyAll() {
			this.$emit('reply', 'all');
		},
		replySender() {
			this.$emit('reply', 'sender');
		},
		forward() {
			this.$emit('forward');
		},
		print() {
			const iframe = document.createElement('iframe');
			iframe.src = this.printURL;
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
			iframe.contentWindow.addEventListener('afterprint', () => {
				document.body.removeChild(iframe);
			});
			iframe.contentWindow.addEventListener('load', () => {
				iframe.contentWindow.print();
			});
		},
		onSent(info) {
			if (info.ID === this.ID) {
				this.sentVal = true;
			}
		}
	},
	computed: {
		isHighPriority() {
			return this.priority < 3;
		},
		printURL() {
			return `/api/mails/message/${this.ID}/print`;
		},
		mailsModule() {
			return window.BootQuery.getModuleInstance('Mails');
		},
		previewTextBody() {
			return (this.textBody || '').substr(0, 128);
		}
	},
	watch: {
		sentVal: {
			immediate: true,
			handler(sent) {
				if (!sent) {
					if (!this.sentListener) {
						this.sentListener = this.onSent.bind(this);
						this.mailsModule.addListener(
							'messageSent',
							this.sentListener
						);
					}

				} else {
					if (this.sentListener) {
						this.mailsModule.removeListener(
							'messageSent',
							this.sentListener
						);
						this.sentListener = null;
					}
				}
			}
		}
	},
	$beforeDestroy() {
		if (this.sentListener) {
			this.mailsModule.removeListener(
				'messageSent',
				this.sentListener
			);
			this.sentListener = null;
		}
	}
};
</script>

<style lang="scss" scoped>
    .message-header {
        position: sticky;
        top: 0;
        background: var(--light);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

    .message-title {
        font-size: 1.125rem;
    }

    .attachment-wrapper {
        max-width: 300px;
    }

    .mail-collapsed {
        cursor: pointer;
        opacity: 0.9;
        & > * {
            pointer-events: none;
        }

        > .card-body {
            height: 90px;
            overflow: hidden;
        }
    }

    .mail-collapsed-overlay {
        position: absolute;
        z-index: 9999;
        left: 0; right: 0; top: 0; bottom: 0;
        background: linear-gradient(to bottom, rgba(100,100,100,0.4) 0%,rgba(100,100,100,0) 100%);

        display: flex;
        > .mail-collapsed-overlay-content {
            display: flex;
            align-self: center;
            margin: 0 auto;
        }
    }
    .mail-collapsed:hover .mail-collapsed-overlay {
        background: linear-gradient(to bottom, rgba(0,0,0,0.66) 0%,rgba(0,0,0,0) 100%);
    }
</style>
