<template>
    <transition appear name="modal" :duration="300">
        <span>
            <div class="modal-backdrop fade show"></div>
            <div class="modal fade show">
                <div class="modal-dialog modal-dialog-centered" :class="{'modal-lg': large, 'modal-sm': small}" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <slot name="header">
                                <h5 class="modal-title">
                                    <slot name="title">Modal title</slot>
                                </h5>
                                <button type="button" class="close" @click="close">
                                    <span>&times;</span>
                                </button>
                            </slot>
                        </div>
                        <div class="modal-body">
                            <slot></slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer">
                                <button
                                    type="button"
                                    class="btn btn-link text-danger"
                                    @click="close"
                                >Zatvori</button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </transition>
</template>

<script>
export default {
	props: {
		large: Boolean,
		small: Boolean
	},
	methods: {
		close() {
			this.$emit('close');
		}
	}
};
</script>

<style scoped lang="scss">
.modal {
    display: block;
    overflow-y: auto;
}

.modal-enter-active .modal-backdrop,
.modal-leave-active .modal-backdrop {
    transition: opacity 0.3s;
}

.modal-enter .modal-backdrop,
.modal-leave-to .modal-backdrop {
    opacity: 0;
}

.modal-enter-active .modal-content,
.modal-leave-active .modal-content {
    transition: all 0.3s ease;
}

.modal-enter .modal-content,
.modal-leave-to .modal-content {
    transform: scale(0);
    opacity: 0;
}

</style>
