module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<tr>\n	<td colspan=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.columnCount : depth0), depth0))
    + "\">\n		<div class=\"d-flex justify-content-between align-items-baseline\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.footer : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFooterActions : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<div class=\"align-self-center\">\n				"
    + ((stack1 = container.invokePartial(helpers.lookup.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"footer",{"name":"lookup","hash":{},"data":data}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			<div class=\"align-self-center ml-auto text-right d-flex\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.rowActions : depth0)) != null ? stack1.custom : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.rowActions : depth0)) != null ? stack1.deletable : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMultipleAddActions : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "			</div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<button\n						data-table-action=\""
    + alias2(alias1((depth0 != null ? depth0.action : depth0), depth0))
    + "\"\n						data-table=\""
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "\"\n						class=\"btn btn-secondary ml-2\"\n						disabled\n					>\n						"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n					</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "				<button type=\"submit\" class=\"btn btn-danger table-delete-btn ml-2\" data-table=\""
    + alias1(container.lambda((depth0 != null ? depth0.tableName : depth0), depth0))
    + "\" data-table-action=\"delete\"\n				 disabled>\n					<span class=\"fa fa-trash-alt\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.delete",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<div class=\"dropdown\">\n					<button type=\"button\" class=\"btn btn-success dropdown-toggle ml-2\" data-toggle=\"dropdown\">\n						<span class=\"fa fa-plus\"></span>&nbsp;"
    + container.escapeExpression(helpers.tr.call(alias1,"button.add",{"name":"tr","hash":{},"data":data}))
    + "\n					</button>\n					<div class=\"dropdown-menu\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.rowActions : depth0)) != null ? stack1.add : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n				</div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "						<a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.method : depth0), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,helpers.lookupMap.call(alias3,depths[1],(depth0 != null ? depth0.params : depth0),{"name":"lookupMap","hash":{},"data":data}),{"name":"encode_query_string","hash":{},"data":data}))
    + "\" class=\"dropdown-item\"\n						 data-table-action=\"add\" "
    + ((stack1 = helpers.each.call(alias3,helpers.lookupMap.call(alias3,depths[1],(depth0 != null ? depth0.params : depth0),{"name":"lookupMap","hash":{},"data":data}),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n							<span class=\"fa fa-plus\"></span>&nbsp;\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "						</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " data-"
    + alias2(alias1((data && data.key), depth0))
    + "=\""
    + alias2(alias1(depth0, depth0))
    + "\" ";
},"13":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.method : depth0), depth0))
    + "\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.rowActions : depth0)) != null ? stack1.add : stack1)) != null ? stack1["0"] : stack1),{"name":"with","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.method : depth0), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,(depth0 != null ? depth0.params : depth0),{"name":"encode_query_string","hash":{},"data":data}))
    + "\" class=\"btn btn-success ml-2\"\n				 data-table-action=\"add\" "
    + ((stack1 = helpers.each.call(alias3,helpers.lookupMap.call(alias3,depths[1],(depth0 != null ? depth0.params : depth0),{"name":"lookupMap","hash":{},"data":data}),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n					<span class=\"fa fa-plus\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.add",{"name":"tr","hash":{},"data":data}))
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				</a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.shouldShowFooterRow : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})