module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "		<li>\n			<a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.pdf_url : stack1), depth0))
    + "\" class=\"noparse\" target=\"_blank\">\n				<span class=\"fa fa-file-pdf\" style=\"color: #F50000;\"></span> "
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"report.export_pdf",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n		</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "		<li>\n			<a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.print_url : stack1), depth0))
    + "\" class=\"print-btn\">\n				<span class=\"fa fa-file-printer\"></span> "
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"report.print",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n		</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"btn-group dropdown\">\n	<button type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\">\n		<span class=\"fa fa-file-alt\"></span> "
    + container.escapeExpression(helpers.tr.call(alias1,"report.report",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"caret\"></span>\n	</button>\n	<ul class=\"pull-right dropdown-menu\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.pdf_url),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.print_url),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>\n";
},"useData":true})