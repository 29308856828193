<template>
     <div class="form-group row">
        <slot name="label">
            <label class="col-form-label" :class="labelSizeClasses" v-if="label && label.length" :for="fieldId">{{label}}: </label>
        </slot>
        <slot name="control">
            <div :class="inputSizeClasses">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <span class="far fa-calendar-alt"></span>
                        </div>
                    </div>
                    <input
                        class="form-control"
                        :class="{'is-invalid': hasError}"
                        type="text"
                        :id="fieldId"
                        :name="fieldName"
                        v-model="inputValue"
                        :disabled="disabled"
                        placeholder="__.__.____. __:__"
                        v-mask="{
                            alias: 'datetime',
                            inputFormat: 'dd.mm.yyyy. HH:MM',
                            seperator: '.',
                            placeholder: '__.__.____. __:__',
                            clearIncomplete: true
                        }" />
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import fieldProps from './mixins/fieldProps';

export default {
	mixins: [fieldProps],
	data() {
		let inputValue = null;
		if (this.value) {
			const dateMoment = moment(this.value, moment.ISO_8601, true);
			if (dateMoment.isValid()) {
				inputValue = dateMoment.format('DD.MM.YYYY. HH:mm');
			} else {
				console.warn('ISO 8601 please, what is this shit: ', this.value);
			}
		}

		return {
			inputValue
		};
	},
	watch: {
		inputValue(val) {
			let standardVal = null;
			const dateMoment = moment(val, 'DD.MM.YYYY. HH:mm', true);
			if (dateMoment.isValid()) {
				standardVal = dateMoment.toISOString();
			}
			this.$emit('input', standardVal);
		}
	}
};
</script>
