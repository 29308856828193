module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.extensions : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			"
    + ((stack1 = container.invokePartial(partials.agentRow,depth0,{"name":"agentRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<div class=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Callcenter)) && stack1.showQueueCallers),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " col-lg-12 col-md-12 agent-row mb-3\">\n			<div class=\"row\">\n				<div class=\"col-xl-4 col-lg-4 col-md-4 col-sm-4\">\n					<em>"
    + container.escapeExpression(helpers.tr.call(alias1,"label.no_agents",{"name":"tr","hash":{},"data":data}))
    + "</em>\n				</div>\n			</div>\n		</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xl-12";
},"7":function(container,depth0,helpers,partials,data) {
    return "col-xl-6";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.extensions : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true})