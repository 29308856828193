<template>
     <div class="form-group row">
        <slot name="label">
            <label class="col-form-label" :class="labelSizeClasses" v-if="label && label.length" :for="fieldId">{{label}}: </label>
        </slot>
        <slot name="control">
            <div :class="inputSizeClasses">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" :class="{'is-invalid': hasError}" type="checkbox" :id="fieldId" :name="fieldName" v-model="inputValue" :disabled="disabled">
                    <label :for="fieldId" class="custom-control-label">&nbsp;</label>
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import inputField from './mixins/inputField';

export default {
	mixins: [inputField]
};
</script>
