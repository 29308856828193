module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return "    "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Queue : depth0), depth0),{"name":"Queue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Queue : depth0), depth0),{"name":"Queue","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "glyphicon-log-in";
},"4":function(container,depth0,helpers,partials,data) {
    return "glyphicon-log-out";
},"6":function(container,depth0,helpers,partials,data) {
    return "    glyphicon-minus-sign\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "  glyphicon-check\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "  glyphicon-bell\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "  glyphicon-earphone\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "  glyphicon-minus-sign\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.Calls : depth0), depth0),{"name":"Calls","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return "        "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.BridgedCalls : depth0), depth0),{"name":"BridgedCalls","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.BridgedCalls : depth0), depth0),{"name":"BridgedCalls","hash":{},"fn":container.noop,"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return "("
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.CallerIDName : depth0), depth0),{"name":"CallerIDName","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.CallerIDName : depth0), depth0),{"name":"CallerIDName","hash":{},"fn":container.noop,"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + ")";
},"19":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.CallerIDName : depth0), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.CallerIDNum : depth0), depth0));
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.ConnectingCalls : depth0), depth0),{"name":"ConnectingCalls","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing, alias3=container.escapeExpression;

  return "<div class=\"btn-group col-lg-4 col-md-4 col-sm-4\" style=\"font-size: 0.7em;\">\n\n  <button type=\"button\" class=\"btn btn-info phone-status dropdown-toggle\" data-toggle=\"dropdown\">\n\n    <span\n    class=\"glyphicon\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Reachable : depth0), depth0),{"name":"Reachable","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Registered : depth0), depth0),{"name":"Registered","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Ok : depth0), depth0),{"name":"Ok","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Ringing : depth0), depth0),{"name":"Ringing","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Up : depth0), depth0),{"name":"Up","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Unknown : depth0), depth0),{"name":"Unknown","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Unreachable : depth0), depth0),{"name":"Unreachable","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Unregistered : depth0), depth0),{"name":"Unregistered","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    phone-status-icon\">\n  </span>&nbsp;&nbsp;\n\n  <span>"
    + alias3(alias1((depth0 != null ? depth0.DisplayName : depth0), depth0))
    + "</span>\n\n</button>\n\n<button type=\"button\" class=\"btn status "
    + alias3(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + " dropdown-toggle\" data-toggle=\"dropdown\">\n  <span class=\"caret\"></span>\n</button>\n\n<ul class=\"dropdown-menu pull-right\" role=\"menu\" style=\"margin-right: 40px;\">\n    <li><a href=\"#\"><span class=\"glyphicon glyphicon-earphone\"></span>&nbsp;&nbsp;Pick up</a></li>\n    <li><a href=\"#\">Another action</a></li>\n    <li><a href=\"#\">Something else here </a></li>\n    <li class=\"divider\"></li>\n    <li><a href=\"#\">Separated link</a></li>\n  </ul>\n\n</div>\n\n<span class=\"btn status "
    + alias3(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + " agent-status col-lg-5 col-md-5 col-sm-5\">\n\n  <span class=\"glyphicon\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Reachable : depth0), depth0),{"name":"Reachable","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Registered : depth0), depth0),{"name":"Registered","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Ok : depth0), depth0),{"name":"Ok","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Ringing : depth0), depth0),{"name":"Ringing","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Up : depth0), depth0),{"name":"Up","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Unknown : depth0), depth0),{"name":"Unknown","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Unreachable : depth0), depth0),{"name":"Unreachable","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Unregistered : depth0), depth0),{"name":"Unregistered","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  agent-status-icon\"></span>&nbsp;&nbsp;\n\n  <span class=\"agent-status-label status "
    + alias3(alias1((depth0 != null ? depth0.Status : depth0), depth0))
    + "\">\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Ringing : depth0), depth0),{"name":"Ringing","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.Up : depth0), depth0),{"name":"Up","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n\n</span>\n\n</span>\n\n<span class=\"btn btn-info call-time col-lg-2 col-md-2 col-sm-2 col-lg-offset-1 col-md-offset-1 col-sm-offset-1\">00:00</span>\n";
},"useData":true})