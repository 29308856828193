import Module from 'BootQuery/Assets/js/module';
import FormEditor from 'BootQuery/Assets/js/form-editor';
import * as Fields from 'BootQuery/Assets/js/form-editor/fields';
import {merge, get, forEach, filter} from 'lodash';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { activateElements as bqActivateElements } from 'BootQuery/Assets/js/BootQuery.js';

class CampaignFormEditor extends FormEditor {
	async render(target) {
		await super.render(target);

		setTimeout(() => {
			target.findElement('input[type="checkbox"]').ev('change.campaigns', (e) => {
				let row = $(e.currentTarget).closest('.row');
				row.find('input,button').not(e.currentTarget).each((_, el) => {
					$(el).prop('disabled', !$(e.currentTarget).prop('checked'));
				});

				row.find('span[contenteditable]').each((_, el) => {
					$(el).attr('contenteditable', $(e.currentTarget).prop('checked'));
				});

				row.find('label').removeClass('text-muted');
				if(!$(e.currentTarget).prop('checked')) {
					row.find('label').addClass('text-muted');
				}
			});
		}, 0);
	}

	constructField(optionsOrType) {
		let options = optionsOrType;
		if (typeof(optionsOrType) === 'string') {
			options = {
				type: optionsOrType,
				use_in_campaign: true,
				readonly: false,
				visible_to_agents: true,
				is_basic: false,
				is_scenario: true,
				new: true
			};
		}

		let field = null;
		switch(options.type) {
		case 'select':
			field = new CampaignSelect(options);
			break;
		case 'list':
			field = new CampaignList(options);
			break;
		default:
			field = new CampaignField(options);
			break;
		}

		return field;
	}
}

class CampaignField extends Fields.Field {
	processSettings(settings) {
		return processCampaignSettings(settings);
	}

	async bindEvents() {
		await super.bindEvents();

		this.field.find('input[name="use_in_campaign"]').ev('change', (e) => {
			let row = $(e.currentTarget).closest('.row');
			let settings = merge({}, this.options, getFormData(row));
			this.emit('settingsChange', settings);
		});
	}
}

class CampaignSelect extends Fields.Select {
	processSettings(settings) {
		return processCampaignSettings(settings);
	}
}

class CampaignList extends Fields.List {
	processSettings(settings) {
		console.log('Settigns: ', settings);
		return processCampaignSettings(settings);
	}
}

function processCampaignSettings(settings) {
	if (typeof(settings.mandatory) !== 'boolean') {
		settings.mandatory = settings.mandatory === 'true';
	}
	if (typeof(settings.readonly) !== 'boolean') {
		settings.readonly = settings.readonly === 'true';
	}
	if (typeof(settings.visible_to_agents) !== 'boolean') {
		settings.visible_to_agents = settings.visible_to_agents === 'true';
	}
	if (typeof(settings.use_in_campaign) !== 'boolean') {
		settings.use_in_campaign = settings.use_in_campaign === 'true';
	}
	if (typeof(settings.use_in_campaign) !== 'boolean') {
		settings.use_in_campaign = settings.use_in_campaign === 'true';
	}

	settings['is_' + settings.data_type] = true;

	return settings;
}

export default class Campaigns extends Module {
	constructor() {
		super();
		this.changeTimer = 0;
		this.extension = window.Bootstrap.bootquery.session.extension;
		this.extensionState = {};
		this.campaign = 0;
		this.userID = window.Bootstrap.bootquery.session.userID;
	}

	init(data) {
		super.init();

		this.subscribeWebSocket(`Campaigns/nextContact/${this.extension}`,   this.onNextContact.bind(this));
		this.subscribeWebSocket(`Campaigns/contactChange/${this.extension}`, this.onContactChange.bind(this));
		this.subscribeWebSocket('PeerStatusChanged',  this.onPeerStatusChanged.bind(this));

		const dialer = window.BootQuery.getModuleInstance('Dialer');
		$.get(`/api/campaigns/agents/${window.Bootstrap.bootquery.session.userID}/campaigns`, {}, (data) => {
			this.campaign = data;
		});

		dialer.on('render', (data, target) => {
			let numberID = 0;

			if(data.activeChannel) {
				numberID = data.activeChannel.ContactInfo.phoneNumberID;
			} else if(data.otherChannels && data.otherChannels.length) {
				numberID = data.otherChannels[0].ContactInfo.phoneNumberID;
			}

			if(numberID && this.campaign) {
				// numberID = 335184; // Temporary test thing

				$.get(`/api/campaigns/${this.campaign}/contact/${numberID}`, {}, (contactID) => {
					if(contactID) {
						console.log(data);
						getTemplate('callStatusContact', 'Campaigns').then(template => {
							let rendered = renderTemplate(template, {contactID: contactID});
							target.findElement('.dialer-extra-section').html(rendered);
						});
					} else {
						target.findElement('.dialer-extra-section').html('');
					}
				});
			} else {
				target.findElement('.dialer-extra-section').html('');
			}
		});
	}

	upload(button) {
		let form = $(button).closest('form')[0];
		let formData = new FormData(form);
		$(form).find('.upload-label').html(
			'<span class="fa fa-spinner fa-spin"></span>&nbsp;Uploading ' + formData.get('file').name
		);

		$.ajax({
			type:'POST',
			url: '/api/upload',
			data: formData,
			xhr: () => {
				let myXhr = $.ajaxSettings.xhr();
				if(myXhr.upload) {
					myXhr.upload.addEventListener('progress', (e) => {
						if(e.lengthComputable) {
							let max = e.total;
							let current = e.loaded;

							let percentage = (current * 100) / max;
							$(form).find('.progress-bar').css({width: percentage + '%'});

							if(percentage >= 100) {
								// console.log('Done!');
							}
						}
					}, false);
				}
				return myXhr;
			},
			cache:false,
			contentType: false,
			processData: false,

			success: (data) => {
				$(form).find('.upload-label').html('<span class="fa fa-check"></span>&nbsp;' + data[0].name);
				$(form).find('.upload-delete').removeAttr('disabled');
				$(form).find('input[type="file"]').data('name', data[0].name);
				$(form).find('input[type="file"]').data('tmpName', data[0].tmpName);
			},

			error: (data) => {
				console.error('Error uploading file:', data);
			}
		});
	}

	handleDataUpload(target) {
		// Handle uploads
		target.findElement('input[type="file"]').off('change').on('change', (e) => {
			e.preventDefault;
			this.upload(e.currentTarget);
		});

		target.findElement('.upload-delete').off('click').on('click', (e) => {
			e.preventDefault();

			let tmpName = $(e.currentTarget).closest('.upload').find('input[type="file"]').data('tmpName');

			$.ajax({
				type:'POST',
				url: '/api/upload',
				data: { action: 'delete', 'tmpName': tmpName },

				success: (data) => {
					if(data.status == 'success') {
						$(e.currentTarget).closest('.upload').find('.upload-label').html(
							'<span class="fa fa-cloud-upload"></span> ' + tr('button.upload')
						);
						$(e.currentTarget).closest('.upload').find('.upload-delete').attr('disabled', true);
						$(e.currentTarget).closest('.upload').find('.progress-bar').css({width: 0});
					}
				},

				error: (data) => {
					console.error('Error deleting file:', data);
				}
			});
		});
	}

	renderImportWizard(target, data, targetColumn) {
		let targetColumnIndex = -1;

		if($(targetColumn).closest('th').length) {
			targetColumnIndex = parseInt($(targetColumn).closest('th').data('columnIndex'), 10);
		}

		if(target.findElement('[id^=settings]').length > 0) {
			let submitData = {
				settings: {
					delimiter: target.findElement('#settings\\[delimiter\\]').val(),
					enclosure: target.findElement('#settings\\[enclosure\\]').val(),
					encoding: target.findElement('#settings\\[encoding\\]').val(),
					header: target.findElement('#settings\\[header\\]')[0].checked,
					columns: []
				},
				file: data.file,
				dataset_name: data.dataset_name
			};

			$('.type-selector').each((idx, el) => {
				if(submitData.settings.columns[idx] == undefined) {
					submitData.settings.columns[idx] = {};
				}

				submitData.settings.columns[idx]['type'] = $(el).val();
			});

			$('.label-input').each((idx, el) => {
				if(submitData.settings.columns[idx] == undefined) {
					submitData.settings.columns[idx] = {};
				}

				submitData.settings.columns[idx]['label'] = $(el).val();
			});

			return new Promise(function(resolve, reject) {
				getJSON('post', 'campaigns', 'processData', submitData, true, async (import_data) => {
					const newContent = $.render(await getTemplate('processData', 'Campaigns'), import_data);

					if(targetColumnIndex >= 0) {
						let columnContents = newContent.findElement('#preview-table').findElement(
							`td[data-column-index=${targetColumnIndex}]`
						);
						let replaceCells = $('#preview-table').findElement(
							`td[data-column-index=${targetColumnIndex}]`
						);

						forEach(replaceCells, (cell, index) => {
							$(cell).html(columnContents[index].innerHTML);
						});
					} else {
						// jQuery, y I need to do dis, I don't even
						$('#wizard-container').html(newContent.findElement('#wizard-container')[0].innerHTML);
						// this.activateElements('#wizard-container');
						bqActivateElements('#wizard-container');
					}
				});
			});
		}
	}

	activateElements(target, data) {
		this.activateFormEditor(target, data);
		this.handleDataUpload(target);

		target.findElement('#settings\\[header\\], #settings\\[encoding\\], .type-selector').ev('change', (e) => {
			this.renderImportWizard(target, data.result, e.currentTarget);
		});

		target.findElement('#campaign-make-call').ev('click', (e) => {
			e.preventDefault();

			Api.post('/api/campaigns/makeCall/', $(e.currentTarget).data()).then(call_data => {
				getJSON(
					'post',
					'campaigns',
					'contact',
					{ id: $(e.currentTarget).data('contact') },
					true,
					(contactData) => {
						renderControllerFromData(contactData, 'Campaigns');
					}
				);
			});
		});

		target.findElement('#campaign-next-call').ev('click', (e) => {
			e.preventDefault();

			let campaign = $(e.currentTarget).data('campaign');
			let agent =    $(e.currentTarget).data('agent');

			getJSON('get', 'campaigns', 'nextCall', {campaign: campaign, agent: agent}, true, (data) => {
				if(data.result) {
					renderController('get', 'campaigns', 'contact', {id: data.result.ID}, 'Campaigns', (d) => {
						if(
							!data.result.dialerData.numbers ||
							(data.result.dialerData.numbers && data.result.dialerData.numbers.length == 0)
						) {
							// Disable the next call button
							$('#campaign-next-call').prop('disabled', true);
							$('#campaign-next-contact').prop('disabled', true);
						}
					});
					history.pushState({
						controller: 'campagins',
						method:     'contact',
						parameters: { id: data.result.ID }
					}, null, `/campaigns/contact/?id=${data.result.ID}`);
				} else {
					console.warn('No data returned for next call, cancelling...');
				}
			});
		});

		target.findElement('#campaign-next-contact').ev('click', (e) => {
			e.preventDefault();

			let campaign = $(e.currentTarget).data('campaign');
			let agent =    $(e.currentTarget).data('agent');

			getJSON('get', 'campaigns', 'nextCall', {campaign: campaign, agent: agent}, true, (data) => {
				renderController('get', 'campaigns', 'contact', {id: data.result.ID}, 'Campaigns', (d) => {
					if(
						!data.result.dialerData.numbers ||
						(data.result.dialerData.numbers && data.result.dialerData.numbers.length == 0)
					) {
						// Disable the next call button
						$('#campaign-next-call').prop('disabled', true);
						$('#campaign-next-contact').prop('disabled', true);
					}
				});
				history.pushState({
					controller: 'campagins',
					method:     'contact',
					parameters: { id: data.result.ID }
				}, null, `/campaigns/contact/?id=${data.result.ID}`);
			});
		});

		target.findElement('#campaign-skip-contact').ev('click', (e) => {
			e.preventDefault();

			let campaign = $(e.currentTarget).data('campaign');
			let agent =    $(e.currentTarget).data('agent');

			getJSON('get', 'campaigns', 'nextContact', {campaign: campaign, agent: agent}, true, (data) => {
				console.log(data);
				if(data.result) {
					renderController('get', 'campaigns', 'contact', {id: data.result.ID}, 'Campaigns');
					history.pushState({
						controller: 'campagins',
						method:     'contact',
						parameters: { id: data.result.ID }
					}, null, `/campaigns/contact/?id=${data.result.ID}`);
				} else {
					// Didn't get a contact for some reason, redirect to campaigns list
					renderController('get', 'campaigns', 'campaigns', {}, 'Campaigns');
					history.pushState({
						controller: 'campagins',
						method:     'campaigns',
						parameters: { }
					}, null, '/campaigns/campaigns');
				}
			});
		});

		target.findElement('#store-campaign').ev('click', (e) => {
			e.preventDefault();

			let formDefinition = this.formEditor.getDefinition();
			let submitData = {
				'form':       formDefinition,
				'parameters': data.bootquery.parameters
			};

			getJSON('post', 'campaigns', 'saveCampaign', submitData, true, (importData) => {
				renderControllerFromData(importData, 'Campaigns');
			});
		});

		target.findElement('#save-template').ev('click', async (e) => {
			const template = await getTemplate('Campaigns.templateSaveForm');
			const modal = $.render(template, {});

			modal.findElement('#store-template').ev('click', (e) => {
				e.preventDefault();

				let name = $('#campaigns-template-name').val();

				if(name) {
					let formDefinition = this.formEditor.getDefinition();
					let template = filter(formDefinition.definition.fields, (field) => {
						return field.is_scenario;
					});

					template = template.map((field) => {
						delete field.id;
						return field;
					});

					if(template.length > 0) {
						Api.post('/api/campaigns/templates', {name: name, fields: template}).then((template) => {
							modal.modal('hide');
						});

						modal.on('hidden.bs.modal', (e) => {
							modal.remove();
						});
					}
				}
			});

			modal.modal('show');
		});

		target.findElement('#add-from-template').ev('click', (e) => {
			e.preventDefault();
			let templateID = $('#template-selector').val();

			if(templateID) {
				$.get(`/api/campaigns/templates/${templateID}`, {}, (template) => {
					forEach(template.template, (field) => {
						let addedField = this.formEditor.constructField(field);

						this.formEditor.options.fields[addedField.id] = addedField;
						this.formEditor.options.order.push(addedField.id);
						this.formEditor.render($('#campaigns-form-editor'));
					});
				});
			}
		});

		target.findElement('#template-selector').pickle({
			results: function(searchString, results, callback) {
				Api.get('/api/campaigns/templates', {search: searchString}).then((res) => {
					let options = res.map((r) => {
						return {id: r.ID, text: r.name};
					});

					callback(options, searchString.length);
				});
			}
		});

		target.findElement('select[name="dialtype"]').ev('change', (e) => {
			let type = $(e.currentTarget).val();

			$(`#${type}-dial-extra`).find('input').each((index, el) => {
				$(el).prop('disabled', false);
			});

			$(`div[id$='dial-extra']:not('#${type}-dial-extra')`).find('input').each((index, el) => {
				$(el).prop('disabled', true);
			});
		});

		target.findElement('.phonenumber-button').ev('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			let campaignID     = this.campaign;
			let agentID        = window.Bootstrap.bootquery.session.userID;
			let phoneNumber    = $(e.currentTarget).data('number');
			let extension      = window.Bootstrap.bootquery.session.extension;
			let campaignDataID = window.Bootstrap.result.contactID;

			$.post('/campaigns/manualCall', {
				campaignID: campaignID,
				agentID: agentID,
				phoneNumber: phoneNumber,
				extension: extension,
				campaignDataID: campaignDataID
			}, (data) => {
				// console.log('Did a thing!', data);
			});
		});

		target.findElement('#reminders-table [data-table-action="add"]').ev('click', async (e) => {
			let campaignID = $(e.currentTarget).data('campaignid');
			let contactID  = $(e.currentTarget).data('contactid');

			if(campaignID && contactID) {
				e.preventDefault();

				const template = await getTemplate('Campaigns.reminderAddForm');
				const modal = $.render(template, {});

				modal.findElement('#campaigns-add-reminder').ev('click', (e) => {
					e.preventDefault();

					let timestamp  = $('#campaigns-reminder-timestamp').val();
					let body       = $('#campaigns-reminder-body').val();

					if(!timestamp) {
						$('#campaigns-reminder-timestamp').addClass('is-invalid');
					}

					if(!body || body.length == 0) {
						$('#campaigns-reminder-body').addClass('is-invalid');
					}

					if(timestamp && body && body.length > 0) {
						getJSON(
							'post', 'campaigns', 'addReminder',
							{
								contactID: contactID,
								campaignID: campaignID,
								timestamp: timestamp,
								body: body
							},
							true,
							(data) => {
								getTemplate('Campaigns.contactView').then((template) => {
									let rendered = renderTemplate(template, data);
									target.findElement('#reminders').html($(rendered).findElement('#reminders').html());
									bqActivateElements('#reminders');
								});
								modal.modal('hide');
							}
						);

						modal.on('hidden.bs.modal', (e) => {
							modal.remove();
						});
					}
				});

				modal.modal('show');
				modal.on('shown.bs.modal', (e) => {
					bqActivateElements('.modal');
				});
			}
		});
	}

	activateFormEditor(target, data) {
		let editorEl = target.findElement('#campaigns-form-editor');
		if (!editorEl.length) {
			return;
		}

		const formDef = get(data, 'bootquery.form');
		this.formEditor = new CampaignFormEditor(formDef);
		this.formEditor.render(editorEl);
	}

	handleCampaignCreation(target, data) {
		let formDefinition = this.formEditor.getDefinition();
		let submitData = {
			'form':    formDefinition,
			'dataset': data.dataset,
			'name':    data.name
		};

		// Submit to the wizard backend
		getJSON('post', 'campaigns', 'createCampaign', submitData, true, (wizard_data) => {
			// console.log(wizard_data);
		});
	}

	async onNextContact(data) {
		console.log(`Switching to next contact in ${data.timeout}`);
	}

	async onContactChange(data) {
		if(!data.contactID) {
			return;
		}

		const $form = $('#campaigns-contact-form');
		const formData = getFormData($form);

		getJSON('post', 'campaigns', 'contact', formData, true, () => {
			renderController('get', 'campaigns', 'contact', {id: data.contactID}, 'Campaigns');
		});
	}

	onPeerStatusChanged(data) {
		if(data.Extension === this.extension) {
			if(data.Status !== 'Idle') {
				$('#campaign-next-call').prop('disabled', true);
				$('#campaign-make-call').prop('disabled', true);
				$('#campaign-next-contact').prop('disabled', true);
			} else {
				$('#campaign-next-call').prop('disabled', false);
				$('#campaign-make-call').prop('disabled', false);
				$('#campaign-next-contact').prop('disabled', false);
			}

			this.extensionState = data;
		}
	}
}
