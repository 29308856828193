module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span class=\"form-error-info px-3 mx-3\">\n    <div class=\"popover-content-element\" hidden>\n        <ul class=\"mb-0\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.errors : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n    <a href=\"#\" class=\"form-error-info-badge noparse badge badge-danger\" data-toggle=\"popover\" data-container=\"body\" data-trigger=\"hover\" data-popover-content-element=\"true\" data-html=\"true\">\n        &nbsp;<span class=\"fa fa-exclamation\"></span>&nbsp;\n    </a>\n</span>";
},"useData":true})