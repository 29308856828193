module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(helpers.format_date.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.context : depth0),(depth0 != null ? depth0.format : depth0),(depth0 != null ? depth0.sourceFormat : depth0),{"name":"format_date","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    "
    + container.escapeExpression(helpers.format_date.call(alias1,(depth0 != null ? depth0.context : depth0),helpers.tr.call(alias1,"format.datetime_s",{"name":"tr","hash":{},"data":data}),(depth0 != null ? depth0.sourceFormat : depth0),{"name":"format_date","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.format : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})