module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "								<option value=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? depth0.text : depth0), depth0))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "selected";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "				"
    + ((stack1 = container.invokePartial(partials["Ticketing.groupSettingsCard"],depth0,{"name":"Ticketing.groupSettingsCard","hash":{"expand":(data && data.first),"form":helpers.lookup.call(alias1,(depths[1] != null ? depths[1].forms : depths[1]),helpers.concat.call(alias1,"groups-",(depth0 != null ? depth0.ID : depth0),{"name":"concat","hash":{},"data":data}),{"name":"lookup","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					"
    + ((stack1 = container.invokePartial(partials["Ticketing.ticketTypeListItem"],depth0,{"name":"Ticketing.ticketTypeListItem","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "				<a class=\"list-group-item list-group-item-action\" href=\"/ticketing/editEventType/?ID="
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "				<div class=\"list-group-item\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_event_types",{"name":"tr","hash":{},"data":data}))
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "				"
    + ((stack1 = container.invokePartial(partials["Ticketing.prioritySettingsCard"],depth0,{"name":"Ticketing.prioritySettingsCard","hash":{"expand":(data && data.first),"form":helpers.lookup.call(alias1,(depths[1] != null ? depths[1].forms : depths[1]),helpers.concat.call(alias1,"priorities-",(depth0 != null ? depth0.ID : depth0),{"name":"concat","hash":{},"data":data}),{"name":"lookup","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"container-fluid py-3\">\n	<ul class=\"nav nav-tabs\">\n		<li class=\"nav-item\">\n			<a href=\"#ticketing-general\" data-toggle=\"tab\" class=\"nav-link active\">"
    + alias2(helpers.tr.call(alias1,"tab.general",{"name":"tr","hash":{},"data":data}))
    + "</a>\n		</li>\n		<li class=\"nav-item\">\n			<a href=\"#ticketing-groups\" data-toggle=\"tab\" class=\"nav-link\">"
    + alias2(helpers.tr.call(alias1,"tab.groups",{"name":"tr","hash":{},"data":data}))
    + "</a>\n		</li>\n		<li class=\"nav-item\">\n			<a href=\"#ticketing-ticket-types\" data-toggle=\"tab\" class=\"nav-link\">"
    + alias2(helpers.tr.call(alias1,"tab.ticket_types",{"name":"tr","hash":{},"data":data}))
    + "</a>\n		</li>\n		<li class=\"nav-item\">\n			<a href=\"#ticketing-event-types\" data-toggle=\"tab\" class=\"nav-link\">"
    + alias2(helpers.tr.call(alias1,"tab.event_types",{"name":"tr","hash":{},"data":data}))
    + "</a>\n		</li>\n		<li class=\"nav-item\">\n			<a href=\"#ticketing-priorities\" data-toggle=\"tab\" class=\"nav-link\">"
    + alias2(helpers.tr.call(alias1,"tab.priorities",{"name":"tr","hash":{},"data":data}))
    + "</a>\n		</li>\n	</ul>\n	<div class=\"tab-content\">\n		<div id=\"ticketing-general\" class=\"tab-pane fade show active\">\n			<div class=\"container-fluid\">\n				<div class=\"col-12\">\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label\" for=\"ticketing-autoselectCompanyAsClient\">\n							"
    + alias2(helpers.tr.call(alias1,"Ticketing:form.autoselect_company_as_client",{"name":"tr","hash":{},"data":data}))
    + ":\n						</label>\n						<div class=\"col-12 col-form-label\">\n							<input type=\"hidden\" name=\"ticketing[autoselectCompanyAsClient]\" value=\"false\">\n							<div class=\"custom-control custom-checkbox\">\n								<input type=\"checkbox\" id=\"ticketing-autoselectCompanyAsClient\" name=\"ticketing[autoselectCompanyAsClient]\" value=\"true\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.autoselectCompanyAsClient : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " class=\"custom-control-input\">\n								<label class=\"custom-control-label\" for=\"ticketing[autoselectCompanyAsClient]\">&zwnj;</label>\n							</div>\n						</div>\n					</div>\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label\" for=\"ticketing-defaultTab\">\n							"
    + alias2(helpers.tr.call(alias1,"Ticketing:form.default_tab",{"name":"tr","hash":{},"data":data}))
    + ":\n						</label>\n						<div class=\"col-12\">\n							<select class=\"pickle\" name=\"ticketing[defaultTab]\" id=\"ticketing-defaultTab\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ticketingTabs : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "							</select>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div id=\"ticketing-groups\" class=\"tab-pane fade\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			<button type=\"button\" class=\"btn btn-success float-right my-3 ticketing-groups-add-btn\">\n				<span class=\"fa fa-plus\"></span> "
    + alias2(helpers.tr.call(alias1,"button.add_group",{"name":"tr","hash":{},"data":data}))
    + "\n			</button>\n		</div>\n		<div id=\"ticketing-ticket-types\" class=\"tab-pane pt-3 fade\">\n			<ul class=\"list-group ticket-type-list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ticketTypes : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n			<button type=\"button\" class=\"btn btn-success float-right my-3\" data-add-ticket-type>\n				<span class=\"fa fa-plus\"></span> "
    + alias2(helpers.tr.call(alias1,"button.add_ticket_type",{"name":"tr","hash":{},"data":data}))
    + "\n			</button>\n		</div>\n		<div id=\"ticketing-event-types\" class=\"tab-pane fade\">\n			<div class=\"list-group mb-3\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.eventTypes : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "			</div>\n			<a class=\"btn btn-success float-right\" href=\"/ticketing/addEventType\">\n				<span class=\"fa fa-plus\"></span> "
    + alias2(helpers.tr.call(alias1,"button.add_event_type",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n		</div>\n		<div id=\"ticketing-priorities\" class=\"tab-pane fade\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.priorities : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n			<button type=\"button\" class=\"btn btn-success float-right my-3 ticketing-priorities-add-btn\">\n				<span class=\"fa fa-plus\"></span> "
    + alias2(helpers.tr.call(alias1,"button.add_priority",{"name":"tr","hash":{},"data":data}))
    + "\n			</button>\n		</div>\n	</div>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true})