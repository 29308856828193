import uuid4 from 'uuid/v4';
import Socket from './socket.js';
import {get, setWith, isString, isObject, isArray, cloneDeep, reduce, some} from 'lodash';
import {EventEmitter} from 'events';

function parsePath(path) {
	return path.split('/').filter(segment => segment.length);
}

function hasSubscription(subscriptions, event) {
	return some(event, (val, key) => {
		if (subscriptions[key] === true) {
			return true;
		} else if (isObject(subscriptions[key])) {
			if (isObject(val)) {
				return hasSubscription(subscriptions[key], val);
			}
		}
		return false;
	});
}

function normalizeSubscription(subscription) {
	if (isString(subscription)) {
		return setWith({}, parsePath(subscription), true, Object);
	} else if (isArray(subscription)) {
		return subscription.reduce((events, event) => {
			const eventPath = parsePath(event);

			let currentPath = [];
			for (let i in eventPath) {
				currentPath.push(eventPath[i]);
				if (get(events, currentPath) === true) {
					return events;
				}
			}
			setWith(events, eventPath, true, Object);
			return events;
		}, {});
	} else if (isObject(subscription)) {
		return subscription;
	}
}

function getRemovableSubscriptionParts(subscription, othersArr) {
	return reduce(subscription, (unsubs, val, key) => {
		const others = othersArr
			.map(other => other[key])
			.filter(other => other !== undefined);

		if (others.length) {
			if (isObject(val)) {
				let subparts = getRemovableSubscriptionParts(val, others);
				if (Object.keys(subparts).length) {
					unsubs[key] = getRemovableSubscriptionParts(val, others);
				}
			}
		} else {
			unsubs[key] = val;
		}
		return unsubs;
	}, {});
}

export default class Module extends EventEmitter {
	constructor() {
		super();
		this.didInit = false;
		this.websocketSubscriptions = [];
		this.socketToken = this.generateWebSocketToken();
		this.boundEvents = {};
	}

	init(data) {
		this.didInit = true;
		if (window.socket) {
			if (window.socket.socket.readyState === Socket.SOCKET_STATE.OPEN) {
				this.__socketConnect(window.socket);
			}
			window.socket.on('connect', () => this.__socketConnect(window.socket));
		}
	}

	activateElements(target, data) {}

	generateWebSocketToken() {
		return this.constructor.name + ':' + uuid4();
	}

	subscribeWebSocket(event, callback) {
		if (!this.didInit) {
			throw new Error(
				`Init in parent not called for module ${this.constructor.name}`
			);
		}
		const handle = uuid4();
		let subscription = {
			event: normalizeSubscription(event),
			callback,
			handle
		};

		this.websocketSubscriptions.push(subscription);
		if (window.socket && window.socket.socket.readyState === Socket.SOCKET_STATE.OPEN) {
			this.doSubscribe(subscription);
		}

		return handle;
	}

	unsubscribeWebSocket(handle) {
		if (!this.didInit) {
			throw new Error(
				`Init in parent not called for module ${this.constructor.name}`
			);
		}
		this.websocketSubscriptions.forEach((subscription, index, subscriptions) => {
			if (subscription.handle === handle) {
				subscriptions.splice(index, 1);
				this.doUnsubscribe(subscription);
			}
		});
	}

	unsubscribeWebSocketAll(event = null) {
		let toUnsub;
		if (event === null) {
			toUnsub = this.websocketSubscriptions;
		} else {
			event = normalizeSubscription(event);
			toUnsub = this.websocketSubscriptions.filter(sub => hasSubscription(event, sub.event));
		}

		toUnsub.slice(0).forEach(sub => {
			this.unsubscribeWebSocket(sub.handle);
		});
	}

	__socketConnect(socket) {
		// console.log('Connecten sie');
		this.websocketSubscriptions.forEach(subscription => {
			this.doSubscribe(subscription);
		});
	}

	onEvent(event, data) {
		this.websocketSubscriptions.forEach(subscription => {
			if (hasSubscription(subscription.event, event)) {
				subscription.callback(cloneDeep(data));
			}
		});
	}

	doUnsubscribe(subscription) {
		let otherSubscriptionEvents = this.websocketSubscriptions.map(sub => sub.event);
		let toUnsubscribe = getRemovableSubscriptionParts(subscription.event, otherSubscriptionEvents);
		if (Object.keys(toUnsubscribe).length) {
			window.socket.send('unsubscribe', {
				events: toUnsubscribe,
				token: this.socketToken
			});
		}
	}

	doSubscribe(subscription) {
		let rootEvents = Object.keys(subscription.event);
		rootEvents.forEach((event) => {
			if (this.boundEvents[event]) {
				return; // Already bound
			}
			this.boundEvents[event] = window.socket.on(event, (data) => {
				// For me?
				if (data.for.indexOf(this.socketToken) !== -1) {
					this.onEvent(data.fullPath, data.data);
				}
			});
		});
		window.socket.send('subscribe', {
			events: subscription.events || subscription.event,
			token: this.socketToken
		});
	}

	static canHandleRoute(_route) {
		return false;
	}
}