import { render, staticRenderFns } from "./UploadingAttachment.vue?vue&type=template&id=45fd7d8f&scoped=true&"
import script from "./UploadingAttachment.vue?vue&type=script&lang=js&"
export * from "./UploadingAttachment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45fd7d8f",
  null
  
)

component.options.__file = "UploadingAttachment.vue"
export default component.exports