module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <span class=\"fa fa-phone\"></span>\n    Poziv\n    "
    + ((stack1 = container.invokePartial(partials.callResultIcon,(depth0 != null ? depth0.data : depth0),{"name":"callResultIcon","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_mail : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <span class=\"fa fa-envelope\"></span>\n    E-mail\n    <span class=\"fa fa-"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.direction : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\"></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "arrow-right";
},"7":function(container,depth0,helpers,partials,data) {
    return "arrow-left";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_call : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})