<template>
<div class="row">
    <div class="col-1">
        {{call.QueuePosition}}
    </div>
    <div class="col-4">
        {{call.ContactInfo.displayName}}
    </div>
    <div class="col-4">
        {{call.QueueName}}
    </div>
    <div class="col-3 queue-time">
        {{ this.callTime() }}
    </div>
    <!-- <div class="col-3">
        <a href="#" class="btn btn-sm btn-success callcenter-queue-call-pickup" data-channel={{Channel}}>
            <span class="fa fa-phone"></span>&nbsp;{{tr "button.call_pickup"}}
        </a>
    </div> -->
</div>
</template>

<script>
export default {
    props: {
        call: Object
    },
    data() {
        return { timer: null };
    },
    watch: {
        call: {
            immediate: true,
            handler(call) {
                if (this.timer) {
                    window.clearInterval(this.timer);
                    this.timer = null;
                }

                this.timer = window.setInterval(() => {
                    let elapsedMSec = moment.duration(this.callTime()).asSeconds() * 1000;
                    let elapsed = moment.utc(elapsedMSec + 1000).format('HH:mm:ss');

                    this.$set(this.call, 'Elapsed', elapsed);
                }, 1000);
            }
        }
    },
    destroyed() {
        if (this.timer) {
            window.clearInterval(this.timer);
        }
    },
    methods: {
        callTime() {
            if(this.call.Elapsed) {
                return this.call.Elapsed;
            }

            let elapsedMSec = moment.duration(this.call.QueueTime).asSeconds() * 1000;
            let elapsedString = moment.utc(elapsedMSec).format('HH:mm:ss');

            return elapsedString;
        }
    }
}
</script>

<style>

</style>
