<template>
	<button
		:disabled="!personID"
		:id="`gsm-${context}-tracker-${trackerID}-person-btn`"
		:class="buttonClass"
		title="Osoba"
	>
		<span class="far fa-id-card"></span>
		<bPopover
			:target="`gsm-${context}-tracker-${trackerID}-person-btn`"
			:trigger="['click', 'blur']"
			placement="left"
			@show="fetchPersonInfo"
		>
			<template v-if="personInfoFetched">
				<h5>
					<a :href="`/phonebook/viewPerson/?personID=${personID}`">
						<span class="fa fa-id-card"></span>
						&nbsp;
						{{personInfo.fullName}}
					</a>
				</h5>
				<hr>
				<h6>Brojevi telefona:</h6>
				<ul v-if="personInfo.phoneNumbers && personInfo.phoneNumbers.length">
					<li v-for="phoneNumber in personInfo.phoneNumbers" :key="phoneNumber.ID">
						<a
							:href="`tel:${phoneNumber.e164}`"
							@click.prevent="dial(phoneNumber.e164)"
						>{{phoneNumber.fullPhoneNumber}}</a>
					</li>
				</ul>
			</template>
			<div v-else class="spinner-grow"></div>
		</bPopover>
	</button>
</template>

<script>
import bPopover from 'bootstrap-vue/es/components/popover/popover';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		bPopover
	},
	props: {
		trackerID: {
			type: Number,
			required: true
		},
		personID: {
			type: Number,
			required: false
		},
		context: {
			type: String,
			required: true
		},
		buttonClass: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			personInfo: null,
			personInfoFetched: false
		};
	},
	methods: {
		async fetchPersonInfo() {
			this.personInfoFetched = false;
			const info = await Api.get(
				`/api/phonebook/personInfo/${this.personID}`
			);
			if (info) {
				this.personInfo = info;
			}
			this.personInfoFetched = true;
		},
		dial(phoneNumber) {
			const dialer = window.BootQuery.getModuleInstance('Dialer');
			dialer.dial(phoneNumber);
		}
	}
};
</script>

<style>
</style>
