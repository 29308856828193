<template>
    <span>
        <strong v-if="addresses">{{label}}: </strong>
        <template v-for="(address, index) in addresses">
            <a :key="address.address" :href="`mailto:${address.address}`">
                {{address.address}}
            </a>
            <template v-if="index < addresses.length - 1">, </template>
        </template>
    </span>
</template>

<script>
export default {
	props: {
		addresses: Array,
		label: String
	}
};
</script>
