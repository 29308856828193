<template>
    <div class="message-attachment">
        <a :href="url" target="_blank" v-if="type === 'image'">
            <img
                :src="url"
                :alt="facebookURL"
                class="img-fluid rounded message-attachment-image"
            >
        </a>
        <a v-else class="message-attachment-file" :href="url" target="_blank">{{fileName}}</a>
    </div>
</template>

<script>
export default {
	props: {
		type: String,
		ID: Number,
		messageID: Number,
		facebookURL: String,
		savedFilename: String
	},
	computed: {
		url() {
			return `/api/facebookChat/chats/1/attachments/${this.ID}`;
		},
		fileName() {
			return this.savedFilename.split('/').pop();
		}
	}
};
</script>

<style lang="scss" scoped>
.message-attachment {
	max-width: 250px;
}
</style>
