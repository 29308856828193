module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Menu)) && stack1.menus)) && stack1.table),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"btn-group\">\n		<button type=\"button\" class=\"btn btn-secondary dropdown-toggle\" data-toggle=\"dropdown\">\n			<span class=\"fa fa-eye\"></span>\n			&nbsp;<span class=\"caret\"></span>\n		</button>\n		<div class=\"dropdown-menu dropdown-menu-right dropdown datatable-dropdown\">\n"
    + ((stack1 = helpers.block.call(alias1,"datatableDropdown",{"name":"block","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n	<div class=\"btn-group datatable-refresh\" data-datatable-name=\""
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "\" role=\"group\" aria-label=\"...\">\n		<div class=\"btn-group\">\n			<button type=\"button\" class=\"datatable-refresh-button btn btn-primary\" data-datatable-name=\""
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n				<span class=\"fa fa-sync-alt\"></span>\n				<span class=\"datatable-update-count\">0</span>\n			</button>\n			<button type=\"button\" class=\"btn btn-secondary dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n				<span class=\"caret\"></span>\n				<span class=\"sr-only\">Toggle Dropdown</span>\n			</button>\n			<div class=\"dropdown-menu dropdown-menu-right datatable-dropdown\">\n				<div class=\"dropdown-item\">\n					<div class=\"custom-control custom-radio\">\n						<input name=\"refresh-type\" type=\"radio\" id=\"refresh-type-manual\" value=\"manual\" class=\"datatable-refresh-toggle custom-control-input\" data-datatable-name=\""
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "\" checked>\n						<label class=\"custom-control-label\" for=\"refresh-type-manual\">"
    + alias3(helpers.tr.call(alias1,"label.refresh_manual",{"name":"tr","hash":{},"data":data}))
    + "</label>\n					</div>\n				</div>\n				<div class=\"dropdown-item\">\n					<div class=\"custom-control custom-radio\">\n						<input name=\"refresh-type\" type=\"radio\" id=\"refresh-type-auto\" value=\"auto\" class=\"datatable-refresh-toggle custom-control-input\" data-datatable-name=\""
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n						<label class=\"custom-control-label\" for=\"refresh-type-auto\">"
    + alias3(helpers.tr.call(alias1,"label.refresh_auto",{"name":"tr","hash":{},"data":data}))
    + "</label>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.template : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			"
    + ((stack1 = container.invokePartial(helpers.concat.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.key),".",(depth0 != null ? depth0.template : depth0),{"name":"concat","hash":{},"data":data}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			<h6 class=\"dropdown-header\">"
    + container.escapeExpression(helpers.tr.call(alias1,"label.show_columns",{"name":"tr","hash":{},"data":data}))
    + "</h6>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.columns : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "				<div class=\"dropdown-item\">\n					<div class=\"custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0\">\n						<input type=\"checkbox\" name=\"datatable-"
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "-column-display-"
    + alias2(alias1((depth0 != null ? depth0.column : depth0), depth0))
    + "\" id=\"datatable-"
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "-column-display-"
    + alias2(alias1((depth0 != null ? depth0.column : depth0), depth0))
    + "\" class=\"datatable-column-display-checkbox custom-control-input\" data-table=\""
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\" data-key=\""
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.visible : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<label class=\"custom-control-label\" for=\"datatable-"
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "-column-display-"
    + alias2(alias1((depth0 != null ? depth0.column : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</label>\n					</div>\n				</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "checked";
},"10":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"header-buttons text-center text-sm-right text-nowrap d-flex justify-content-between\">\n"
    + ((stack1 = helpers.block.call(alias1,"buttons",{"name":"block","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.block.call(alias1,"buttonsAfter",{"name":"block","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true})