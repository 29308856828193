<template>
    <div
        class="mail-event-wrapper"
        :class="{
            'mail-event-collapsed': collapsible && collapsed
        }"
    >
        <Message ref="message" v-bind="message" @reply="onReply" @forward="onForward" cardClass="my-3">
            <template slot="title" slot-scope="{ subject, isHighPriority }">
                <div class="card-title">
                    <span class="fas fa-envelope"></span>
                    {{subject}}
                    <span
                        v-if="isHighPriority"
                        class="fas fa-exclamation text-danger"
                    ></span>
                </div>
            </template>

            <template slot="content" slot-scope="{ htmlBody, textBody }">
                <div class="mail-event-content-outer">
                    <div class="mail-event-content">
						<HTMLMessage v-if="htmlBody" :html="htmlBody" />
                        <pre v-else>{{textBody}}</pre>
                    </div>
                    <a
                        v-if="collapsible"
                        href="#"
                        :class="collapserClass"
                        @click.prevent="toggleCollapse"
                    >
                        {{ collapserText }}
                        <span :class="collapserIcon"></span>
                    </a>
                </div>
            </template>
        </Message>
    </div>
</template>

<script>
import Message from 'app/Modules/Mails/Assets/components/Message.vue';
import HTMLMessage from 'app/Modules/Mails/Assets/components/HTMLMessage.vue';

export default {
	components: {
		Message,
		HTMLMessage
	},
	props: {
		message: Object
	},
	data() {
		return {
			collapsed: false,
			collapsible: false
		};
	},
	computed: {
		collapserText() {
			if (this.collapsed) {
				return this.tr('Ticketing:button.show');
			} else {
				return this.tr('Ticketing:button.hide');
			}
		},
		collapserIcon() {
			const dir = this.collapsed ? 'down' : 'up';
			return `fas fa-arrow-${dir}`;
		},
		collapserClass() {
			if (this.collapsed) {
				return 'ticket-mail-content-show';
			} else {
				return 'ticket-mail-content-hide';
			}
		}
	},
	methods: {
		getTicketingModule() {
			return window.BootQuery.getModuleInstance('Ticketing');
		},
		onReply(type) {
			this.getTicketingModule().replyMail(type, this.message);
		},
		onForward() {
			this.getTicketingModule().forwardMail(this.message);
		},
		toggleCollapse() {
			this.collapsed = !this.collapsed;
		}
	},
	mounted() {
		// Just pretend you didn't see this...
		const contentEl = this.$refs.message.$el.querySelector('.card-body');
		const eventsEl = document.querySelector('#events');

		eventsEl.style.display = 'block';
		const contentHeight = contentEl.scrollHeight;
		eventsEl.style.display = '';

		if (contentHeight > 100) {
			this.collapsible = true;
			this.collapsed = true;
		}
	}
};
</script>

<style lang="scss" scoped>
/deep/ .mail-event-content-outer {
    position: relative;
}

/deep/ .mail-event-content {
    transition: height 0.5s;
    text-overflow: ellipsis;
    overflow-y: hidden;
}

.mail-event-collapsed /deep/ .mail-event-content {
    max-height: 100px;
    transition: height 0.5s;
}

.ticket-mail-content-hide,
.ticket-mail-content-show {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    text-align: center;
}
</style>
