module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        "
    + ((stack1 = container.invokePartial(partials["CustomerChat.groupSettingsCard"],depth0,{"name":"CustomerChat.groupSettingsCard","hash":{"expand":(data && data.first),"form":helpers.lookup.call(alias1,(depths[1] != null ? depths[1].forms : depths[1]),helpers.concat.call(alias1,"groups-",(depth0 != null ? depth0.ID : depth0),{"name":"concat","hash":{},"data":data}),{"name":"lookup","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container-fluid py-3\" id=\"customerchat-groups\">\n    <h3>Feedback</h3>\n    <div class=\"row\" data-form=\"feedbackSettings\">\n        "
    + ((stack1 = container.invokePartial(partials.form,((stack1 = (depth0 != null ? depth0.forms : depth0)) != null ? stack1.feedback : stack1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <h3>Grupe </h3>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.groups : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <button type=\"button\" class=\"btn btn-success float-right my-3 customerchat-groups-add-btn\">\n        <span class=\"fa fa-plus\"></span> "
    + container.escapeExpression(helpers.tr.call(alias1,"CustomerChat:button.add_group",{"name":"tr","hash":{},"data":data}))
    + "\n    </button>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true})