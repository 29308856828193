<template>
    <div class="form-group row">
        <slot name="label">
            <label class="col-form-label" :class="labelSizeClasses" @click="focusEditor" v-if="label && label.length" :for="fieldId">{{label}}: </label>
        </slot>
        <slot name="control">
            <div :class="inputSizeClasses">
                <!-- Quill is weird, but this div fixes it -->
                <div>
                    <Quill
                        v-bind="contentBindObj"
                        v-on="eventBindObj"
                        ref="editor"
                        :disabled="disabled"
                    />
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import Quill from 'BootQuery/Assets/js/quill/quill.vue';
import fieldProps from './mixins/fieldProps';

export default {
	mixins: [fieldProps],
	components: {
		Quill
	},
	props: {
		format: {
			type: String,
			default: 'html',
			validator(format) {
				return ['html', 'text', 'json'].includes(format);
			}
		}
	},
	data() {
		return {
			inputValue: this.value
		};
	},
	watch: {
		inputValue(val) {
			this.$emit('input', val);
		}
	},
	computed: {
		propForContent() {
			if (this.format === 'json') {
				return 'content';
			}
			return this.format;
		},
		eventBindObj() {
			const evName = `update:${this.propForContent}`;
			return {
				[evName]: ($ev) => this.inputValue = $ev
			};
		},
		contentBindObj() {
			return {
				[this.propForContent]: this.inputValue
			};
		}
	},
	methods: {
		focusEditor() {
			this.$refs.editor.focus();
		}
	}
};
</script>
