module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.message : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.name : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a  href=\"#\"\n                class=\"dropdown-item user-status-set px-3\"\n                data-status-id=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"\n                data-user-id=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].status : depths[1])) != null ? stack1.userID : stack1), depth0))
    + "\"\n                data-current=\""
    + alias2(alias1((depth0 != null ? depth0.active : depth0), depth0))
    + "\"\n            >\n                <span class=\"text-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.color : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.icon : stack1), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"float-right pt-1 fa fa-check\"></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<a id=\"user-status-dropdown-root\" class=\"dropdown-item\" data-toggle=\"collapse\" href=\"#statusCollapse\" role=\"button\" aria-expanded=\"false\">\n    <div class=\"row no-gutters\">\n        <div class=\"col text-truncate\">\n            <span class=\"text-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.settings : stack1)) != null ? stack1.color : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.settings : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n            <br>\n            <span class=\"text-muted\">"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.message : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div class=\"col-auto text-top\">\n            <span class=\"fa fa-chevron-down\"></span>\n        </div>\n    </div>\n</a>\n\n<div class=\"collapse\" id=\"statusCollapse\">\n    <div class=\"mx-3 mt-1 border-left border-secondary\">\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.statuses : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"px-3 mx-1 py-1\">\n            <div class=\"form-row\" style=\"min-width: 250px;\">\n                <input type=\"text\" placeholder=\"Status\" class=\"form-control form-control-sm\" id=\"user-status-message\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.message : stack1), depth0))
    + "\">\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true,"useDepths":true})