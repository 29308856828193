module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<a href=\"/ticketing/edit/"
    + alias2(alias1((depth0 != null ? depth0.ticketID : depth0), depth0))
    + "\">\n    <h5>#"
    + alias2(alias1((depth0 != null ? depth0.ticketID : depth0), depth0))
    + " - "
    + alias2(alias1((depth0 != null ? depth0.ticketTitle : depth0), depth0))
    + "</h5>\n</a>";
},"useData":true})