module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"form-group\">\n    <label>"
    + alias2(helpers.tr.call(alias1,"label.label",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n    <input type=\"text\" name=\"label\" class=\"form-control\" value=\""
    + alias2(alias3((depth0 != null ? depth0.label : depth0), depth0))
    + "\">\n</div>\n<div class=\"form-group\">\n    <input type=\"hidden\"   name=\"mandatory\" value=\"false\">\n    <div class=\"custom-control custom-checkbox\">\n        <input type=\"checkbox\" class=\"custom-control-input\" value=\"true\" id=\"mandatory-"
    + alias2(alias3((depth0 != null ? depth0.id : depth0), depth0))
    + "\" name=\"mandatory\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mandatory : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"mandatory-"
    + alias2(alias3((depth0 != null ? depth0.id : depth0), depth0))
    + "\">"
    + alias2(helpers.tr.call(alias1,"label.mandatory",{"name":"tr","hash":{},"data":data}))
    + "</label>\n    </div>\n\n    <input type=\"hidden\"   name=\"readonly\" value=\"false\">\n    <div class=\"custom-control custom-checkbox\">\n        <input type=\"checkbox\" class=\"custom-control-input\" value=\"true\" id=\"readonly-"
    + alias2(alias3((depth0 != null ? depth0.id : depth0), depth0))
    + "\" name=\"readonly\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readonly : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <label class=\"custom-control-label\" for=\"readonly-"
    + alias2(alias3((depth0 != null ? depth0.id : depth0), depth0))
    + "\">"
    + alias2(helpers.tr.call(alias1,"label.readonly",{"name":"tr","hash":{},"data":data}))
    + "</label>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.block.call(depth0 != null ? depth0 : (container.nullContext || {}),"fieldSettings",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})