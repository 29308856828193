module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"card\">\n    <div class=\"card-body py-1\">\n        <div class=\"row\">\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"days",{"name":"array_to_path","hash":{},"data":data}))
    + "\" class=\"col-12 col-form-label col-form-label-sm\">"
    + alias2(helpers.tr.call(alias1,"label.days",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n                    <div class=\"col-12\">\n                        <input id=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"days",{"name":"array_to_path","hash":{},"data":data}))
    + "\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"days",{"name":"array_to_path","hash":{},"data":data}))
    + "\" type=\"number\" step=\"1\" min=\"0\" max=\"365\" class=\"form-control form-control-sm\" value=\""
    + alias2(alias3((depth0 != null ? depth0.days : depth0), depth0))
    + "\">\n                    </div>\n                </div>\n            </div>\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"hours",{"name":"array_to_path","hash":{},"data":data}))
    + "\" class=\"col-12 col-form-label col-form-label-sm\">"
    + alias2(helpers.tr.call(alias1,"label.hours",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n                    <div class=\"col-12\">\n                        <input id=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"hours",{"name":"array_to_path","hash":{},"data":data}))
    + "\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"hours",{"name":"array_to_path","hash":{},"data":data}))
    + "\" type=\"number\" step=\"1\" min=\"0\" max=\"24\" class=\"form-control form-control-sm\" value=\""
    + alias2(alias3((depth0 != null ? depth0.hours : depth0), depth0))
    + "\">\n                    </div>\n                </div>\n            </div>\n            <div class=\"col\">\n                <div class=\"form-group row\">\n                    <label for=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"minutes",{"name":"array_to_path","hash":{},"data":data}))
    + "\" class=\"col-12 col-form-label col-form-label-sm\">"
    + alias2(helpers.tr.call(alias1,"label.minutes",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n                    <div class=\"col-12\">\n                        <input id=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"minutes",{"name":"array_to_path","hash":{},"data":data}))
    + "\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.prefix : depth0),"minutes",{"name":"array_to_path","hash":{},"data":data}))
    + "\" type=\"number\" step=\"1\" min=\"0\" max=\"60\" class=\"form-control form-control-sm\" value=\""
    + alias2(alias3((depth0 != null ? depth0.minutes : depth0), depth0))
    + "\">\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true})