module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "<div class=\"text-left\">\n	<strong>\n		"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.ticket_count",{"name":"tr","hash":{},"data":data}))
    + ":\n	</strong>\n	"
    + alias1(container.lambda((depth0 != null ? depth0.count : depth0), depth0))
    + "\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.resultdata : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})