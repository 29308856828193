<template>
	<div class="sms-wrapper">
		<div v-if="loading" class="text-center">
			<span class="spinner-grow m-3"></span>
		</div>
		<Multipane class="sms-split" v-else-if="accounts.length">
			<router-view
				ref="conversationList"
				name="conversationList"
				:conversations.sync="conversations"
			/>
			<MultipaneResizer/>
			<router-view
				ref="conversationArea"
				name="conversationArea"
				:conversations.sync="conversations"
				:accounts="accounts"
			/>
		</Multipane>
		<div class="alert alert-danger text-center" v-else>{{ tr("Sms:label.no_accounts_available") }}</div>
	</div>
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';

export default {
	components: {
		Multipane,
		MultipaneResizer
	},
	data() {
		return {
			loading: true,
			accounts: null,
			conversations: null
		};
	},
	created() {
		const module = window.BootQuery.getModuleInstance('Sms');
		module.accounts().then(accounts => {
			this.accounts = accounts;
			this.loading = false;
		});
		module.on('newMessage', message => {
			this.$refs.conversationList.onMessage(message);
			this.$refs.conversationArea.onMessage(message);
		});
		module.on('messageStatus', status => {
			this.$refs.conversationArea.onMessageStatus(status);
		});
	}
};
</script>

<style scoped lang="scss">
.sms-wrapper {
	height: calc(100vh - 88px);
}

.sms-wrapper > .sms-split {
	min-height: 0;
	height: 100%;

	> .card {
		height: 100%;
		&:not(:first-child) {
			&,
			> .card-header {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&:not(:last-child) {
			&,
			> .card-header {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
}
</style>
