module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.invokePartial(partials.chatTicketRow,depth0,{"name":"chatTicketRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"d-flex ml-auto\">\n    <div class=\"ml-auto\">\n        <div class=\"popover-content-element\" hidden>\n            <span class=\"chat-tickets\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tickets : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </span>\n        </div>\n        <button type=\"button\" data-toggle=\"popover\" class=\"btn btn-link btn-sm py-0 chat-tickets-popover-btn\" data-popover-content-element=\"true\" data-html=\"true\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.tickets : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <span class=\"fa fa-list-alt\"></span>\n        </button>\n    </div>\n\n    <div class=\"dropdown\">\n        <a href=\"#\" data-toggle=\"dropdown\" class=\"ml-1 px-2 text-dark float-right\">\n            <span class=\"fa fa-ellipsis-v\"></span>\n        </a>\n        <div class=\"dropdown-menu dropdown-menu-right\">\n            <h6 class=\"dropdown-header font-weight-bold\">\n                <span class=\"fas fa-list-alt\"></span> Ticket\n            </h6>\n            <a class=\"dropdown-item\" href=\"/ticketing/create/?fromChatID="
    + alias3(alias2((depth0 != null ? depth0.chatID : depth0), depth0))
    + "\">\n                <span class=\"fa fa-plus\"></span> Kreiraj novi\n            </a>\n            <a class=\"dropdown-item noparse assign-chat-to-ticket-btn\" href=\"#\" data-chat-id=\""
    + alias3(alias2((depth0 != null ? depth0.chatID : depth0), depth0))
    + "\">\n                <span class=\"fa fa-link\"></span> Pridruži postojećem\n            </a>\n        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true})