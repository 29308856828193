import Module from 'BootQuery/Assets/js/module';
import TicketPicker from 'app/Modules/Ticketing/Assets/js/TicketPicker.js';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { popoverForTrigger } from 'app/assets/js/util.js';

export default class Calls extends Module {
	activateElements(target, data) {
		target.findElement('#calls-show-queue-calls').ev('change', function(e) {
			e.preventDefault();

			let showQueueCalls = $(this).prop('checked');
			setUserSetting('Calls.showQueueCalls', showQueueCalls).done(() => {
				$('#calls-filter-form').submit();
			});
		});

		target
			.findElement('.assign-call-to-ticket-btn')
			.ev('click.calls', e => {
				e.preventDefault();
				const callID = parseInt($(e.currentTarget).data('callId'));
				this.openTicketPicker(callID);
			});

		target
			.findElement('.contact-number-link')
			.on('inserted.bs.popover', async e => {
				const btn = $(e.currentTarget);
				if (btn.data('contactPopoverActivated')) {
					return;
				}
				const phoneNumber = btn.data('phoneNumber');
				const numberE164 = btn.data('numberE164');
				const personID = btn.data('personId') || null;
				const companyID = btn.data('companyId') || null;
				const userID = btn.data('userId') || null;
				const contactInfo = await this.fetchContactInfo({
					personID,
					companyID,
					userID
				});

				const renderData = {
					...contactInfo,
					phoneNumber,
					numberE164,
					bootquery: data.bootquery,
					tableName: 'calls'
				};
				const content = $.render(
					await getTemplate('contactOptionsPopover'),
					renderData
				);
				content.find('.clickvox-dial-button').ev('click', e => {
					e.preventDefault();
					// Must use this, jQuery's data() casts it to number...
					const numberToDial = e.currentTarget.dataset.number;
					const dialer = window.BootQuery.getModuleInstance('Dialer');
					dialer.dial(numberToDial);
				});
				popoverForTrigger(btn).find('.popover-body').html(content);
				btn.data('contactPopoverActivated', true);
			});
		target
			.findElement('.number-link')
			.on('inserted.bs.popover', async e => {
				const btn = $(e.currentTarget);
				if (btn.data('numberPopoverActivated')) {
					return;
				}

				const phoneNumber = btn.data('phoneNumber');
				const numberE164 = btn.data('numberE164');
				const renderData = {
					phoneNumber,
					numberE164,
					bootquery: data.bootquery,
					tableName: 'calls'
				};
				const content = $.render(
					await getTemplate('numberOptionsPopover'),
					renderData
				);
				content.find('.clickvox-dial-button').ev('click', e => {
					e.preventDefault();
					// Must use this, jQuery's data() casts it to number...
					const numberToDial = e.currentTarget.dataset.number;
					const dialer = window.BootQuery.getModuleInstance('Dialer');
					dialer.dial(numberToDial);
				});
				popoverForTrigger(btn).find('.popover-body').html(content);
				btn.data('numberPopoverActivated', true);
			});
	}

	async fetchContactInfo(idObj) {
		const dataObj = {};
		const tasks = [];
		if (idObj.personID) {
			const personTask = Api
				.get(`/api/phonebook/personInfo/${idObj.personID}`)
				.then(person => dataObj.person = person);
			tasks.push(personTask);
		}
		if (idObj.companyID) {
			const companyTask = Api
				.get(`/api/phonebook/companyInfo/${idObj.companyID}`)
				.then(company => dataObj.company = company);
			tasks.push(companyTask);
		}
		if (idObj.userID) {
			const userTask = Api
				.get(`/api/users/${idObj.userID}`)
				.then(user => dataObj.user = user);
			tasks.push(userTask);
		}
		await Promise.all(tasks);
		return dataObj;
	}

	async openTicketPicker(callID) {
		const picker = new TicketPicker({
			title: 'Pridruzi poziv ticketu'
		});
		picker.on('submit', async data => {
			await Api.post('/api/ticketing/addCallToTicket', {
				callID,
				ticketID: data.ticketID
			});
			picker.close();
			const ticketRow = $.render(await getTemplate('callTicketRow'), {
				ticketID: data.ticketID,
				ticketTitle: data.ticketTitle
			});
			const callRow = $(`[data-datatable-row-id="${callID}"]`);
			const ticketList = callRow.find('.call-tickets');
			ticketList.append(ticketRow);
			callRow.find('.call-tickets-popover-btn').prop('hidden', false);
		});
	}
}
