module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.fullName : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.fullName : stack1), depth0))
    + "\n        <br>\n        <small class=\"text-muted\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.fullNumber : stack1), depth0))
    + "</small>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.username : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.username : stack1), depth0))
    + "\n        <br>\n        <small class=\"text-muted\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.fullNumber : stack1), depth0))
    + "</small>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.ContactInfo : depth0)) != null ? stack1.fullNumber : stack1), depth0))
    + "\n    ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isOnHold : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})