<template>
  <div class="col-3">
    <div class="card mb-3 statistics-box">
      <div class="card-header">{{title}}</div>
      <div class="card-body">{{value}}</div>
      <div class="card-footer" style="padding: 0;">
        <table class="table table-striped table-bordered" style="margin-bottom: 0;">
          <tbody>
            <template v-if="queues && queues.length">
              <tr v-for="queue in queues" :key="queue.name">
                <th class="text-right" style="vertical-align: middle;">{{queue.Name}}:</th>
                <td style="width: 30%;">{{queue.Summary[queueValueKey]}}</td>
              </tr>
            </template>
            <tr v-else>
              <th
                class="text-center text-muted"
                style="vertical-align: middle;"
              >{{ tr("Callcenter:label.no_queues_observed") }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title: String,
        value: String,
        queues: Array,
        queueValueKey: String
    }
}
</script>

<style lang="scss" scoped>
    .statistics-box > .card-body {
        font-size: 3em;
        text-align: center;
        font-weight: bold;
        color: rgb(85, 85, 85);
    }
</style>
