<template>
	<div class="col-12 col-sm-6 col-md-4 col-lg-3">
		<div class="card border-danger">
			<div class="card-header bg-danger text-white text-center py-1 d-flex" :title="phoneNumber">
				<div>
					{{displayName}}
					<span class="badge">{{firstAlertCounter}}</span>
				</div>
				<div class="ml-auto">
					<CallButton
						context="alert"
						:trackerID="trackerID"
						:phoneNumber="phoneNumber"
						buttonClass="btn btn-link text-white btn-sm"
					/>
					<PersonButton
						context="alert"
						:trackerID="trackerID"
						:personID="personID"
						buttonClass="btn btn-link text-white btn-sm"
					/>
					<LocationButton
						context="alert"
						:trackerID="trackerID"
						buttonClass="btn btn-link text-white btn-sm"
					/>
				</div>
			</div>
			<div class="card-block">
				<div class="list-group list-group-flush">
					<div v-for="alert in alerts" :key="alert.ID" class="list-group-item d-flex">
						<div>
							<span class="badge badge-danger">{{alertCounters[alert.ID]}}</span>
							{{alert.type}}
						</div>
						<div class="ml-auto">
							<button
								class="btn btn-sm btn-outline-success"
								:disabled="alert.isBeingMarkedAsSolved"
								@click.prevent="markAlertAsSolved(alert.ID)"
							>
								<div v-if="alert.isBeingMarkedAsSolved" class="spinner-border spinner-border-sm"></div>
								<span v-else class="fas fa-check"></span>
								Rijeseno
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LocationButton from './actions/LocationButton.vue';
import CallButton from './actions/CallButton.vue';
import PersonButton from './actions/PersonButton.vue';
import moment from 'moment';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		LocationButton,
		PersonButton,
		CallButton
	},
	props: {
		trackerID: Number,
		trackerName: String,
		personName: String,
		personID: Number,
		phoneNumber: String,
		alerts: Array
	},
	data() {
		return {
			alertCounters: this.calcAlertCounters(),
			firstAlertCounter: this.calcFirstAlertCounter()
		};
	},
	computed: {
		displayName() {
			if (this.personID) {
				return this.personName;
			} else if (this.trackerName) {
				return this.trackerName;
			} else {
				return this.phoneNumber;
			}
		},
		firstAlertStart() {
			const sorted = this.alerts
				.slice()
				.sort((a, b) => new Date(b.date) - new Date(a.date));
			return sorted[0].date;
		}
	},
	methods: {
		calcAlertCounters() {
			return this.alerts.reduce((alerts, alert) => {
				alerts[alert.ID] = this.formatDiffFromDate(alert.date);
				return alerts;
			}, {});
		},
		calcFirstAlertCounter() {
			return this.formatDiffFromDate(this.firstAlertStart);
		},
		formatDiffFromDate(date) {
			const diff = moment().diff(moment(date));
			return moment.utc(diff).format('HH:mm:ss');
		},
		updateCounters() {
			this.alertCounters = this.calcAlertCounters();
			this.firstAlertCounter = this.calcFirstAlertCounter();
		},
		markAlertAsSolved(alertID) {
			const alert = this.alerts.find(al => al.ID === alertID);
			this.$set(alert, 'isBeingMarkedAsSolved', true);
			Api.post(
				`/api/gsmTrackers/alerts/${alertID}/resolve`,
				{}
			);
		}
	},
	timers: {
		updateCounters: { time: 1000, autostart: true, repeat: true }
	}
};
</script>

<style>
</style>
