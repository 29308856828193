module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"section-head\" style=\"margin-bottom: 5px;\">\n"
    + ((stack1 = helpers.block.call(depth0 != null ? depth0 : (container.nullContext || {}),"rows",{"name":"block","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"row\">\n		<div class=\"col-12 col-md\">\n			<form action=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,(depth0 != null ? depth0.filterlessParams : depth0),{"name":"encode_query_string","hash":{},"data":data}))
    + "\" method=\"get\" class=\"form form-horizontal filter-form\" id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-filter-form\" data-table=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "\">\n				<div class=\"row\">\n					<div id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-filter-row\" class=\"col-12 col-md pb-3 pb-lg-0\" style=\"align-content: center;\">\n						"
    + ((stack1 = container.invokePartial(partials.filters,depth0,{"name":"filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n					<div class=\"col-12 col-md-auto pb-3 pb-lg-0\">\n						"
    + ((stack1 = container.invokePartial(partials.limitSelector,depth0,{"name":"limitSelector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</form>\n		</div>\n		<div class=\"col-12 col-md-auto\" id=\"header-section-right\">\n			<div class=\"row\">\n				<div class=\"col-12 col-md-auto\">\n"
    + ((stack1 = helpers.block.call(alias3,"headerButtons",{"name":"block","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n		</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					"
    + ((stack1 = container.invokePartial(partials.headerButtons,depth0,{"name":"headerButtons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "	<h1>ClickVOX</h1>\n	<div style=\"position: relative; float: left; width: 100%; margin-bottom: 1em;\">\n		<div style=\"position: relative; float: left; width: 50%;\">\n			<table style=\"border: 0;\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.filters : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</table>\n			&nbsp;\n		</div>\n\n		<div style=\"position: relative; float: left; width: 50%; text-align: right;\">\n			"
    + alias2(helpers.tr.call(alias1,"Reporting:label.generated_at",{"name":"tr","hash":{},"data":data}))
    + " "
    + alias2(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.datetime), depth0))
    + "\n		</div>\n	</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "				<tr>\n					<td style=\"width: 1%; white-space: nowrap; font-weight: bold; border: 0;\">\n						"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + ":\n					</td>\n					<td style=\"border: 0;\">\n						"
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\n					</td>\n				</tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})