<template>
     <div class="form-group row">
        <slot name="label">
            <label class="col-form-label" :class="labelSizeClasses" v-if="label && label.length" :for="fieldId">{{label}}: </label>
        </slot>
        <slot name="control">
            <div :class="inputSizeClasses">
                <input class="form-control" :class="{'is-invalid': hasError}" type="text" :id="fieldId" :name="fieldName" v-model="inputValue" :disabled="disabled">
            </div>
        </slot>
    </div>
</template>

<script>
import inputField from './mixins/inputField';

export default {
	mixins: [inputField]
};
</script>
