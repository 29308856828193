import Module from 'BootQuery/Assets/js/module';
import * as LocalStorage from 'BootQuery/Assets/js/localStorage.js';
import {get, map} from 'lodash';
import {getTemplate, getFormData} from 'BootQuery/Assets/js/BootQuery';
import Vue from 'vue';
import VueInputMask from 'BootQuery/Assets/js/vue-inputmask';
import Thingie from './vuetest/Thingie.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { handlebarsRender } from 'BootQuery/Assets/js/BootQuery';

export default class User extends Module {
	init(data) {
		super.init();
		$(document).ev(
			'userSettingsChanged.user',
			(_e, changed) => this.onSettingsChanged(changed)
		);

		this.Status = {
			username: '',
			userID: null,
			currentStatus: {},
			statusList: [],
			statusElement: $('#user-status-dropdown'),
			setStatus: function(status) {
				if(status.ID !== this.currentStatus.ID) {
					this.currentStatus = status;

					map(this.statusList, (s) => {
						s.active = (status.ID === s.ID);
					});

					this.renderStatusMenu();
				}
			},
			renderStatusMenu: function() {
				getTemplate('userMenuToggle', 'User').then((template) => {
					let rendered = handlebarsRender(template, {status: this.currentStatus, username: this.username});

					$('.user-login-display').replaceWith(rendered);
				});

				getTemplate('userStatusMenu', 'User').then((template) => {
					let rendered = handlebarsRender(template, {status: this.currentStatus, statuses: this.statusList});

					this.statusElement.findElement('#user-status-dropdown-root')[0].innerHTML =
							$(rendered).findElement('#user-status-dropdown-root')[0].innerHTML;

					this.statusElement.findElement('#statusCollapse')[0].innerHTML =
							$(rendered).findElement('#statusCollapse')[0].innerHTML;

					this.statusElement.findElement('.user-status-set:not([data-current="true"])').ev('click.user', (e) => {
						e.preventDefault();
						e.stopPropagation();

						let userID   = $(e.currentTarget).data('userId');
						let statusID = $(e.currentTarget).data('statusId');
						let message  = $('#user-status-message').val();

						Api.patch(
							`/api/users/${userID}/status`,
							{ status: statusID, message: message, userID: userID}
						).then((data) => {
							this.setStatus(data);
						});
					});

					this.statusElement.findElement('.user-status-set[data-current="true"]').ev('click.user', (e) => {
						e.preventDefault();
						e.stopPropagation();
					});

					this.statusElement.findElement('#user-status-message').ev('blur.user', (e) => {
						let message  = $(e.currentTarget).val();
						let userID   = this.userID;
						let statusID = this.currentStatus.ID;

						console.log(userID, statusID, message);

						Api.patch(
							`/api/users/${userID}/status`,
							{ status: statusID, message: message, userID: userID}
						).then((data) => {
							this.setStatus(data);
						});
					});
				});
			}
		};

		// Try to remember last selected extension
		const extensionID = get(data, 'bootquery.session.extensionID');
		if (extensionID !== undefined) {
			LocalStorage.set('lastExtension', {
				extensionID: extensionID === 'null' ? null : parseInt(extensionID),
				extension: data.bootquery.session.extension
			});
		}

		this.Status.username      = data.bootquery.session.username;
		this.Status.userID        = data.bootquery.session.userID;
		this.Status.currentStatus = data.modules.User.status;
		this.Status.statusList    = data.modules.User.statuses;

		this.subscribeWebSocket('User/statusChanged', this.onUserStatusChanged.bind(this));
	}

	activateElements(target, data) {
		const vueTestEl = target.findElement('#vuetest');
		if (vueTestEl.length) {
			Vue.use(VueInputMask);
			// Wait for insert in dom (yeah stupid)
			setTimeout(() => {
				new Vue({
					el: vueTestEl[0],
					render: h => h(Thingie)
				});
			}, 0);
		}
		if (target.findElement('#setting-localExtension').length) {
			this.activateLocalExtensionSettings(target);
		}
		this.activateLoginPage(target, data);

		const userUserSettings = target.findElement('#userSettings-form #setting-user');
		if (userUserSettings.length) {
			const passwordElNames = ['previousPassword', 'newPassword', 'newPasswordConfirm'];
			const passwordFieldsSelector = passwordElNames
				.map(name => `input[name="userSettings[${name}]"]`)
				.join(', ');
			const passwordFields = userUserSettings.find(passwordFieldsSelector);
			userUserSettings.find('#password-change-collapse')
				.ev('show.bs.collapse', (e) => {
					passwordFields.prop('disabled', false);
					$(e.currentTarget).closest('form').findElement('[name="userSettings[passwordChanged]"]').val('true');
				});

			userUserSettings.find('#password-change-collapse')
				.ev('hidden.bs.collapse', (e) => {
					passwordFields.prop('disabled', true);
					$(e.currentTarget).closest('form').findElement('[name="userSettings[passwordChanged]"]').val('false');
				});
		}

		this.bindStatusMenu(target);

		target.findElement('.dropdown-item[data-toggle="collapse"]').ev('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			$($(e.currentTarget).attr('href')).collapse('toggle');
		});

		target.findElement('a[href="/user/logout"]').ev('click.user', ev => {
			ev.stopPropagation();
		});
	}

	bindStatusMenu(target) {
		const statusEl = target.findElement('#user-status-dropdown');
		if (!statusEl.length) {
			return;
		}
		statusEl.findElement('.user-status-set:not([data-current="true"])').ev('click.user', (e) => {
			e.preventDefault();
			e.stopPropagation();

			let userID   = $(e.currentTarget).data('userId');
			let statusID = $(e.currentTarget).data('statusId');
			let message  = $('#user-status-message').val();

			Api.patch(
				`/api/users/${userID}/status`,
				{ status: statusID, message: message, userID: userID}
			).then((data) => {
				this.Status.setStatus(data);
			});
		});

		statusEl.findElement('.user-status-set[data-current="true"]').ev('click.user', (e) => {
			e.preventDefault();
			e.stopPropagation();
		});

		statusEl.findElement('#user-status-message').ev('blur.user', (e) => {
			let message  = $(e.currentTarget).val();
			let userID   = this.Status.currentStatus.userID;
			let statusID = this.Status.currentStatus.ID;

			Api.patch(
				`/api/users/${userID}/status`,
				{ status: statusID, message: message, userID: userID}
			).then((data) => {
				this.Status.setStatus(data);
			});
		});
	}

	onSettingsChanged(changed) {
		if (!changed.User) {
			return;
		}

		if (changed.User.theme) {
			let themeFileName = window.build_manifest[`theme_${changed.User.theme}.css`];
			let $selectedStylesheet = $(`link.theme-stylesheet[href="${themeFileName}"]`);
			$('link.theme-stylesheet').prop('disabled', true);
			$selectedStylesheet.prop('disabled', false);
		}

		if (changed.User.locale) {
			window.target = 'body';
			window.targetElement = 'body';
			renderController('get', window.Bootstrap.bootquery.controller, window.Bootstrap.bootquery.method, {}, 'Settings');
		}
	}

	onUserStatusChanged(status) {
		this.Status.setStatus(status);
	}

	async activateLocalExtensionSettings(target) {
		let content = target.findElement('.local-extension-settings');
		let placeholder = target.findElement('#local-extension-settings-placeholder');
		if (placeholder.length) {
			const rememberExtension = LocalStorage.get('localDevice.rememberExtension', true);
			const lockedExtensionID = LocalStorage.get('localDevice.lockedExtensionID');
			const lockExtension = LocalStorage.get('localDevice.lockExtension');

			let extensions = await Api.get('/api/availableExtensions');
			if (extensions) {
				extensions = extensions.map(extension => {
					extension.selected = extension.ID === lockedExtensionID;
					return extension;
				});
			}
			content = $.render(await getTemplate('User.localExtensionContent'), {
				rememberExtension,
				lockExtension,
				extensions
			});
			placeholder.replaceWith(content);
			content.findElement('#lockedExtension').pickle();
		}

		this.updateLockExtensionDropdown(content);
		content.findElement('#lockExtension').ev('change.user', e => {
			this.updateLockExtensionDropdown(content);
		});

		target.findElement('#settings-form').ev('beforeSubmit.localExtension', e => {
			const data = getFormData($(e.currentTarget));
			if (!data.localDevice) {
				return;
			}

			const localDevice = data.localDevice;
			if (localDevice.rememberExtension !== undefined) {
				const rememberExtension = localDevice.rememberExtension === 'true';
				LocalStorage.set('localDevice.rememberExtension', rememberExtension);
			}
			if (localDevice.lockExtension !== undefined) {
				const lockExtension = localDevice.lockExtension === 'true';
				LocalStorage.set('localDevice.lockExtension', lockExtension);
			}
			if (localDevice.lockedExtension !== undefined) {
				let lockedExtension = null;
				if (localDevice.lockedExtension === 'null') {
					lockedExtension = null;
				} else {
					lockedExtension = parseInt(localDevice.lockedExtension);
				}
				LocalStorage.set('localDevice.lockedExtensionID', lockedExtension);
			}
		});
	}

	updateLockExtensionDropdown(target) {
		const shouldLockCheckbox = target.findElement('#lockExtension');
		const extensionDropdown = target.findElement('#lockedExtension');
		const shouldLock = shouldLockCheckbox.prop('checked');

		if (!shouldLock) {
			extensionDropdown.pickle('select', 'null');
			extensionDropdown.pickle('disabled', true);
		} else {
			extensionDropdown.pickle('disabled', false);
		}
	}

	async activateLoginPage(target, _data) {
		const placeholder = target.findElement('#login-extension-placeholder');
		if (!placeholder.length) {
			return;
		}

		const availableExtensions = await Api.get('/api/availableExtensions');
		const rememberExtension = LocalStorage.get('localDevice.rememberExtension', true);
		const lockExtension = LocalStorage.get('localDevice.lockExtension');
		if (lockExtension) {
			let lockedExtensionID = LocalStorage.get('localDevice.lockedExtensionID');
			let lockedExtension = '-';
			if (typeof(lockedExtensionID) === 'number') {
				const fromAvailable = availableExtensions.find(ext => ext.ID === lockedExtensionID);
				if (fromAvailable) {
					lockedExtension = fromAvailable.extension;
				} else {
					lockedExtensionID = null;
				}
			}
			const rendered = $.render(await getTemplate('User.loginExtensionStatic'), {
				extension: lockedExtension,
				extensionID: lockedExtensionID
			});
			placeholder.replaceWith(rendered);
		} else {
			let extensions = availableExtensions;
			if (rememberExtension) {
				let lastExtension = LocalStorage.get('lastExtension');
				if (lastExtension && lastExtension.extensionID) {
					extensions = extensions.map(extension => {
						extension.selected = extension.ID === lastExtension.extensionID;
						return extension;
					});
				}
			}

			const rendered = $.render(await getTemplate('User.loginExtensionPicker'), {
				extensions
			});
			placeholder.replaceWith(rendered);
			rendered.find('select').pickle();
		}
	}
}