<template>
	<div class="col">
		<SelectField
			v-if="!fixedAccount"
			v-model="accountIDInput"
			:label="tr('Mails:label.address_from')"
			:options="availableAccounts"
			textProp="email"
			valueProp="ID"
			inline
			:disabled="disabled"
		/>
		<MailAddress
			v-model="toProp"
			:label="tr('Mails:label.address_to')"
			:hasError="!toValid"
			inline
			:disabled="disabled"
		/>
		<MailAddress v-model="ccProp" :label="tr('Mails:label.address_cc')" inline :disabled="disabled"/>
		<MailAddress
			v-model="bccProp"
			:label="tr('Mails:label.address_bcc')"
			inline
			:disabled="disabled"
		/>
		<SelectField
			v-if="!fixedAccount"
			v-model="priorityProp"
			:label="tr('Mails:label.priority')"
			:options="availablePriorities"
			textProp="name"
			valueProp="value"
			inline
			:disabled="disabled"
		/>
		<TextField
			v-model="subjectProp"
			:label="tr('Mails:label.subject')"
			:hasError="!subjectValid"
			inline
			:disabled="disabled"
		/>
		<Checkbox
			v-if="offerTicketCreateOnSend && availableTicketingGroups.length"
			v-model="createTicketProp"
			:label="tr('Mails:form.create_ticket')"
			name="mailEditorCreateTicket"
			inline
			:disabled="disabled"
		/>
		<div class="card subform-card mb-3" v-if="createTicketProp">
			<div class="card-body">
				<SelectField
					v-model="createTicketGroupProp"
					:label="tr('Mails:form.create_ticket_group')"
					:options="availableTicketingGroups"
					textProp="name"
					valueProp="ID"
					inline
					:disabled="disabled"
				/>
				<SelectField
					v-model="createTicketTypeProp"
					:label="tr('Mails:form.create_ticket_type')"
					:options="availableTicketTypes"
					textProp="name"
					valueProp="ID"
					inline
					:disabled="disabled"
				/>
			</div>
		</div>
		<div class="form-group">
			<!-- Yes, this div is needed, otherwise Quill becomes a jackass -->
			<div>
				<Quill
					:content.sync="contentProp"
					:html.sync="htmlContentProp"
					:text.sync="textContentProp"
					:disabled="disabled"
				/>
			</div>
		</div>
		<div class="form-group">
			<EditorAttachmentList :attachments="attachmentsProp"/>
		</div>
		<button
			v-if="showCancelSaveButtons"
			type="button"
			class="btn btn-success float-right"
			:disabled="!canSubmit || disabled"
			@click.prevent="submit"
		>
			<span class="fa fa-paper-plane"></span>
			{{ tr('button.send') }}
		</button>
		<button
			v-if="showCancelSaveButtons"
			type="button"
			class="btn btn-link text-danger float-right"
			:disabled="disabled"
			@click.prevent="$emit('cancel')"
		>{{ tr("button.cancel") }}</button>
	</div>
</template>

<script>
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';
import TextField from 'BootQuery/Assets/js/forms/fields/Text.vue';
import Checkbox from 'BootQuery/Assets/js/forms/fields/Checkbox.vue';
import MailAddress from './MailAddressInput.vue';
import Quill from 'BootQuery/Assets/js/quill/quill.vue';
import EditorAttachmentList from './EditorAttachmentList.vue';
import {cloneDeep} from 'lodash';

export default {
	components: {
		TextField,
		Checkbox,
		SelectField,
		MailAddress,
		Quill,
		EditorAttachmentList
	},
	props: {
		fixedAccount: Boolean,
		availableTicketingGroups: Array,
		availableTicketTypes: Array,
		accountID: Number,
		availableAccounts: Array,
		signatureTemplateData: Object,
		to: Array,
		cc: Array,
		bcc: Array,
		content: Object,
		htmlContent: String,
		textContent: String,
		subject: String,
		disabled: Boolean,
		attachments: Array,
		priority: Number,
		offerTicketCreateOnSend: Boolean,
		createTicket: Boolean,
		createTicketGroup: Number,
		createTicketType: Number,
		showCancelSaveButtons: {
			type: Boolean,
			default: true
		}
	},
	data() {
		let accountID = this.accountID;
		if (!accountID && this.availableAccounts.length) {
			accountID = this.availableAccounts[0].ID;
			this.$emit('update:accountID', accountID);
		}

		let createTicketGroup = this.createTicketGroup || null;
		let createTicketType = this.createTicketType || null;
		if (this.offerTicketCreateOnSend) {
			if (!createTicketGroup && this.availableTicketingGroups.length) {
				createTicketGroup = this.availableTicketingGroups[0].ID;
			}
			if (!createTicketType && this.availableTicketTypes.length) {
				createTicketType = this.availableTicketTypes[0].ID;
			}
		}

		return {
			accountIDInput: accountID,
			toValue: this.to || [],
			ccValue: this.cc || [],
			bccValue: this.bcc || [],
			subjectValue: this.subject || '',
			contentValue: this.content,
			htmlContentValue: this.htmlContent,
			textContentValue: this.textContent,
			attachmentsValue: this.attachments || [],
			priorityValue: this.priority || 3,
			createTicketValue: this.createTicket || false,
			createTicketGroupValue: createTicketGroup,
			createTicketTypeValue: createTicketType,
			availablePriorities: [
				{value: 1, name: 'High'},
				{value: 3, name: 'Normal'},
				{value: 5, name: 'Low'}
			]
		};
	},
	methods: {
		getData() {
			return {
				accountID: this.accountIDInput,
				to: this.toProp,
				cc: this.ccProp,
				bcc: this.bccProp,
				content: this.contentProp,
				htmlContent: this.htmlContentProp,
				textContent: this.textContentProp,
				subject: this.subjectProp,
				attachments: this.attachmentsProp.map(attachment => {
					return {
						uploaded: attachment.uploadData.tmpName,
						original: attachment.uploadData.name
					};
				}),
				priority: this.priorityProp,
				createTicket: this.createTicketProp,
				createTicketGroup: this.createTicketGroupProp,
				createTicketType: this.createTicketTypeProp
			};
		},
		submit() {
			if (!this.canSubmit) {
				return;
			}
			this.$emit('submit', this.getData());
		},
		applyTemplateData(originalTemplate, variables) {
			let template = cloneDeep(originalTemplate);
			if (!template.ops) {
				return template;
			}
			template.ops = template.ops.map(op => {
				if (op.insert && op.insert.template) {
					op.insert = variables[op.insert.template.name] || '';
				}
				return op;
			});
			return template;
		}
	},
	computed: {
		toProp: {
			get() {
				return this.toValue;
			},
			set(val) {
				this.toValue = val;
				this.$emit('update:to', val);
			}
		},
		ccProp: {
			get() {
				return this.ccValue;
			},
			set(val) {
				this.ccValue = val;
				this.$emit('update:cc', val);
			}
		},
		bccProp: {
			get() {
				return this.bccValue;
			},
			set(val) {
				this.bccValue = val;
				this.$emit('update:bcc', val);
			}
		},
		subjectProp: {
			get() {
				return this.subjectValue || '';
			},
			set(val) {
				this.subjectValue = val;
				this.$emit('update:subject', val);
			}
		},
		contentProp: {
			get() {
				return this.contentValue || {};
			},
			set(val) {
				this.contentValue = val;
				this.$emit('update:content', val);
			}
		},
		htmlContentProp: {
			get() {
				return this.htmlContentValue || '';
			},
			set(val) {
				this.htmlContentValue = val;
				this.$emit('update:htmlContent', val);
			}
		},
		textContentProp: {
			get() {
				return this.textContentValue || '';
			},
			set(val) {
				this.textContentValue = val;
				this.$emit('update:textContent', val);
			}
		},
		attachmentsProp: {
			get() {
				return this.attachmentsValue;
			},
			set(val) {
				this.attachmentsValue = val;
				this.$emit('update:attachments', val);
			}
		},
		priorityProp: {
			get() {
				return this.priorityValue;
			},
			set(val) {
				this.priorityValue = val;
				this.$emit('update:priority', val);
			}
		},
		createTicketProp: {
			get() {
				return this.createTicketValue;
			},
			set(val) {
				this.createTicketValue = val;
				this.$emit('update:createTicket', val);
			}
		},
		createTicketGroupProp: {
			get() {
				return this.createTicketGroupValue;
			},
			set(val) {
				this.createTicketGroupValue = val;
				this.$emit('update:createTicketGroup', val);
			}
		},
		createTicketTypeProp: {
			get() {
				return this.createTicketTypeValue;
			},
			set(val) {
				this.createTicketTypeValue = val;
				this.$emit('update:createTicketType', val);
			}
		},
		subjectValid() {
			if (!this.subjectProp) {
				return false;
			}
			return this.subjectProp.trim().length !== 0;
		},
		toValid() {
			return this.toProp.length !== 0;
		},
		canSubmit() {
			return this.toValid && this.subjectValid;
		},
		currentAccountSignature() {
			return this.availableAccounts.find(
				account => account.ID === this.accountIDInput
			).signature;
		}
	},
	watch: {
		to(val) {
			this.toValue = val;
		},
		cc(val) {
			this.ccValue = val;
		},
		bcc(val) {
			this.bccValue = val;
		},
		subject(val) {
			this.subjectValue = val;
		},
		content(val) {
			this.contentValue = val;
		},
		htmlContent(val) {
			this.htmlContentValue = val;
		},
		textContent(val) {
			this.textContentValue = val;
		},
		attachments(val) {
			this.attachmentsValue = val;
		},
		priority(val) {
			this.priorityValue = val;
		},
		canSubmit: {
			immediate: true,
			handler() {
				this.$emit('update:canSubmit', this.canSubmit);
			}
		},
		currentAccountSignature: {
			immediate: true,
			handler(sig) {
				const content = cloneDeep(this.contentProp);
				if (!content.ops) {
					content.ops = [];
				}

				const startIdx = content.ops.findIndex(
					op => op.insert && op.insert['signature-marker'] === 'begin'
				);
				const endIdx = content.ops.findIndex(
					op => op.insert && op.insert['signature-marker'] === 'end'
				);
				if (startIdx !== -1 && endIdx !== -1) {
					const sigOps = endIdx - startIdx;
					content.ops.splice(startIdx, sigOps + 1);
				}

				if (sig && sig.ops) {
					sig = this.applyTemplateData(
						sig,
						this.signatureTemplateData || {}
					);
					sig.ops = [
						{insert: '\n'},
						{insert: {
							'signature-marker': 'begin'
						}},
						...sig.ops,
						{insert: {
							'signature-marker': 'end'
						}}
					];
					content.ops = content.ops.concat(sig.ops);
				}
				this.contentProp = content;
			}
		}
	}
};
</script>
