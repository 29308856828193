module.exports = {"name":"Dialer","button":{"call_number":(window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fullPhoneNumber","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.phoneNumber || (depth0 != null ? depth0.phoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"phoneNumber","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "Call ";
  stack1 = ((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : alias2),(options={"name":"fullPhoneNumber","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.fullPhoneNumber) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : alias2),(options={"name":"fullPhoneNumber","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.fullPhoneNumber) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true}),"call":"Call","hang_up":"Hang up","put_on_hold":"Put on hold","transfer":"Transfer","redial":"Redial","features":"Features","dnd":"Do not disturb","answer":"Antworten"},"label":{"pick_up":"Pick up","incoming_call":"Incoming call","in_call":"In call","calling":"Calling","idle":"Idle","waiting_to_pick_up":"Waiting to pick up","ringing":"Ringing","redirect_settings":"Redirections","cant_call_without_extension":"Unable to call without an active extension"},"state":{"available":"Available","away":"Away","xa":"Extended Absence","unavailable":"Unavailable","dnd":"Do Not Disturb"},"form":{"call_forward":"Call forward","call_forward_unconditional":"Unconditional call forward","call_forward_no_answer":"No answer","call_forward_busy":"Busy","call_forward_away":"Away","call_forward_xa":"Extended Absence"}}