<template>
    <div class="attachment-upload bg-white p-1 px-2 rounded border">
        <div class="attachment-upload-icon">
            <span class="fas fa-file"></span>
        </div>
        <div class="attachment-upload-info px-1">
            <div class="info-filename" :title="filename">
                <strong>{{filename}}</strong>
            </div>
            <div class="info-size text-muted">
                <small>{{size | formatFileSize}}</small>
            </div>
        </div>
        <div
            class="attachment-upload-progress"
            :class="done ? 'bg-success' : 'bg-primary'"
            :style="{width: `${progress}%`}"
        ></div>
        <button type="button" class="attachment-remove" @click="remove">
            <span class="fas fa-times-circle"></span>
        </button>
    </div>
</template>

<script>
import { formatFileSize } from 'app/assets/js/util.js';

export default {
	props: {
		filename: String,
		type: String,
		size: Number,
		file: {}
	},
	data() {
		return {
			done: !this.file,
			progress: this.file ? 0 : 100,
			uploadedInfo: null,
			xhr: null
		};
	},
	created() {
		if (this.file && !this.done) {
			this.startUpload(this.file);
		}
	},
	filters: {
		formatFileSize
	},
	methods: {
		startUpload(file) {
			this.xhr = new XMLHttpRequest();
			const fd = new FormData();

			this.xhr.open('POST', '/api/upload', true);
			this.xhr.onreadystatechange = () => {
				if (this.xhr.readyState == 4 && this.xhr.status == 200) {
					this.uploadedInfo = JSON.parse(this.xhr.response)[0];
					this.done = true;
					this.progress = 100;
					this.$emit('done', this.uploadedInfo);
					this.xhr = null;
				}
			};
			this.xhr.upload.addEventListener('progress', ev => {
				if (ev.lengthComputable) {
					const percentage = Math.round((ev.loaded * 100) / ev.total);
					this.progress = percentage;
				}
			});
			fd.append(file.name, file);
			this.xhr.send(fd);
		},
		remove() {
			if (this.xhr) {
				this.xhr.abort();
			}
			this.$emit('remove');
		}
	}
};
</script>

<style lang="scss" scoped>
.attachment-upload {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    position: relative;
}

.attachment-upload-icon {
    flex: 0 1 auto;
    display: inline-flex;
    align-items: center;

    > span {
        font-size: 1.6em;
    }
}

.attachment-upload-info {
    flex: 1 1 auto;
    line-height: 1.1;
    overflow: hidden;

    > .info-filename {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.attachment-upload-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    transition: width 0.1s ease-out;
}

.attachment-remove {
    position: absolute;
    top: -6px;
    right: -6px;
    background: none;
    border: none;
    color: #dc3545;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
}

.attachment-upload:hover .attachment-remove {
    opacity: 1;
}

.attachment-remove:hover {
    color: #c82333;
}
</style>
