module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div>\n                <small>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.detail : depth0), depth0))
    + "</small>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<!DOCTYPE html>\n<html>\n<head>\n\n	<meta charset=\"utf-8\">\n	<meta name=\"viewport\" content=\"width=device-width, user-scalable=yes, initial-scale=1, maximum-scale=1\">\n	<link rel=\"icon\" type=\"image/png\" href=\"/app/assets/img/AsteriskGradient.png\">\n	<title>ClickVOX</title>\n\n    <link rel=\"stylesheet\" href=\""
    + alias2(helpers.resolveAssetName.call(alias1,"fonts.css",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\">\n    <link rel=\"stylesheet\" href=\""
    + alias2(helpers.resolveAssetName.call(alias1,"theme_default.css",{"name":"resolveAssetName","hash":{},"data":data}))
    + "\" class=\"theme-stylesheet\" title=\"default\">\n</head>\n\n<body class=\"bg-light\">\n<div class=\"container py-3\">\n    <div class=\"jumbotron text-center\">\n        <h1 class=\"display-4\">\n            <strong>"
    + alias2(alias3((depth0 != null ? depth0.code : depth0), depth0))
    + "</strong>: "
    + alias2(alias3((depth0 != null ? depth0.message : depth0), depth0))
    + "\n        </h1>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.detail : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <a href=\"/\" class=\"noparse btn btn-lg btn-primary mt-3\">\n           <span class=\"fa fa-chevron-left\"></span> Vrati se\n        </a>\n    </div>\n</div>\n</body>";
},"useData":true})