import Module from 'BootQuery/Assets/js/module';

export default class Reporting extends Module {
	activateElements(target, data) {
		target.findElement('.reporting-print-btn')
			.off('click.reporting')
			.on('click.reporting', (e) => {
				e.preventDefault();
				let link = $(e.currentTarget).attr('href');
				let iframe = $('<iframe>');
				iframe.attr('src', link).css({display: 'none'});
				$('body').append(iframe);
				iframe.get(0).onload = () => {
					let cw = iframe.get(0).contentWindow;
					cw.onafterprint = () => iframe.remove();
					cw.print();
				};
			});
	}
}