module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a href=\"/phonebook/viewPerson/?personID="
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                <fa class=\"fa fa-id-card\"></fa>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.fullName : depth0), depth0))
    + "\n            </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a href=\"/phonebook/viewCompany/?companyID="
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                <fa class=\"fa fa-building\"></fa>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n            </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div>\n        <strong>\n            <span class=\"fas fa-building\"></span>\n            Tvrtka:\n        </strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.companyID : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n    <br>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <a href=\"/phonebook/viewCompany/?companyID="
    + alias2(alias1((depth0 != null ? depth0.companyID : depth0), depth0))
    + "\">\n                "
    + alias2(alias1((depth0 != null ? depth0.companyName : depth0), depth0))
    + "\n            </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            -\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.phoneNumbers : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <li>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "</li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"text-muted\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.emails : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                    <li>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.email : depth0), depth0))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"pb-1 pt-3 px-3\">\n    <h5>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_person : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_company : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </h5>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_person : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div>\n        <strong> <span class=\"fa fa-phone\"></span> Telefon: </strong>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.phoneNumbers : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div>\n        <strong> <span class=\"fa fa-envelope\"></span> E-mail: </strong>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.emails : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true})