module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <div class=\"card\">\n            <div class=\"card-header\">\n                <fa class=\"fa fa-plus\"></fa> Dodaj\n            </div>\n            <div class=\"card-body\">\n                <form method=\"post\" action=\"/phonebook/addPersonOrCompany/\" id=\"person-or-company-form\" data-form=\"person-or-company-form\">\n                    <div class=\"nav nav-tabs nav-fill\" id=\"person-company-switcher\">\n                        <a class=\"nav-item nav-link active\" data-toggle=\"tab\" href=\"#add-person\">\n                            <span class=\"tab-icon\">\n                                <span class=\"fa fa-id-card\"></span>\n                            </span>\n                            <span class=\"tab-text\">"
    + alias2(helpers.tr.call(alias1,"tab.person",{"name":"tr","hash":{},"data":data}))
    + "</span>\n                        </a>\n                        <a class=\"nav-item nav-link\" data-toggle=\"tab\" href=\"#add-company\">\n                            <span class=\"tab-icon\">\n                                <span class=\"fa fa-building\"></span>\n                            </span>\n                            <span class=\"tab-text\">"
    + alias2(helpers.tr.call(alias1,"tab.company",{"name":"tr","hash":{},"data":data}))
    + "</span>\n                        </a>\n                    </div>\n                    <div class=\"tab-content\">\n                        <div class=\"py-3 tab-pane fade show active\" id=\"add-person\" data-form=\"person\">\n                            <input type=\"hidden\" name=\"action\" value=\"insertPerson\">\n                            "
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.person),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                        <div class=\"py-3 tab-pane fade\" id=\"add-company\" data-form=\"company\">\n                            <input type=\"hidden\" name=\"action\" value=\"insertPerson\">\n                            "
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.company),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </form>\n            </div>\n            <div class=\"card-footer stick-bottom d-flex\">\n                <a href=\"#\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n                    <span class=\"fa fa-times\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n                </a>\n                <div class=\"save-button\">\n                    <span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"person-or-company-form\">\n                    </span>\n                    <button type=\"submit\" class=\"btn btn-success\" form=\"person-or-company-form\">\n                        <span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                    </button>\n                </div>\n			</div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})