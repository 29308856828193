import Quill from 'quill/core.js';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Link from 'quill/formats/link';
import Image from 'quill/formats/image';
import BubbleTheme from 'quill/themes/bubble';

Quill.register(
	{
		'formats/bold': Bold,
		'formats/italic': Italic,
		'formats/underline': Underline,
		'formats/link': Link,
		'formats/image': Image,
		'themes/bubble': BubbleTheme
	},
	true
);

export default Quill;
