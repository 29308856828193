<template>
    <router-link
        :to="`/${$route.params.accountID}/threads/${rootID}`"
        tag="a"
        class="mail-thread-list-item noparse list-group-item list-group-item-action"
        :class="{active: isSelected, seen: isSeen}"
        style="text-overflow: ellipsis;"
    >
        <div class="row">
            <div class="col text-truncate" style="overflow: hidden;">
                <small class="text-nowrap">
                    <strong>
                        <span :class="directionArrowClass"></span>
                        <span>{{addressPreview}}</span>
                    </strong>
                </small>
            </div>
            <div class="col-auto">
                <small>{{ lastMessageDate | formatDate( tr('format.datetime') )}}</small>
            </div>
        </div>
        <div>
            <span class="mail-message-subject">
                <span v-if="hasTicket" class="fas fa-list-alt"></span>
                <span v-if="isHighPriority" class="fas fa-exclamation text-danger"></span>
                {{lastMessageSubject}}
            </span>
        </div>
        <div>
            <small>{{preview}}</small>
        </div>
    </router-link>
</template>

<script>
export default {
	props: {
		rootID: Number,
		lastMessageDate: Date,
		firstMessageDate: Date,
		lastMessageSubject: String,
		lastMessageDirection: Boolean,
		lastMessageFromMailAddress: String,
		lastMessageToMailAddresses: Array,
		lastMessagePriority: Number,
		preview: String,
		messageCount: Number,
		hasTicket: Boolean,
		isSeen: Boolean
	},
	computed: {
		addressPreview() {
			if (this.lastMessageDirection) {
				return this.lastMessageToMailAddresses
					.map(addr => addr.name || addr.address)
					.join(', ');
			} else {
				return this.lastMessageFromMailAddress;
			}
		},
		isSelected() {
			const threadID = this.$route.params.threadID;
			if (!threadID) {
				return false;
			}
			return parseInt(threadID) === this.rootID;
		},
		directionArrowClass() {
			const arrowDir = this.lastMessageDirection ? 'right' : 'left';
			return `fas fa-arrow-${arrowDir}`;
		},
		isHighPriority() {
			return this.lastMessagePriority && this.lastMessagePriority < 3;
		}
	}
};
</script>

<style lang="scss" scoped>
.mail-thread-list-item {
    border-radius: 0;
}
</style>
