import fieldProps from './fieldProps';

export default {
	mixins: [fieldProps],
	data() {
		return {
			inputValue: this.value
		};
	},
	watch: {
		value(val) {
			this.inputValue = val;
		},
		inputValue(val) {
			this.$emit('input', val);
		}
	}
};