<template>
<div class="chat-input-row">
    <div class="chat-input border-top">
        <div ref="chatInput"></div>
    </div>
    <div class="chat-input-btn">
        <button type="button" class="btn btn-block btn-success" :disabled="!canSubmit" @click.prevent="submit()">
            <span class="fa fa-arrow-right"></span>
        </button>
    </div>
</div>
</template>

<script>
import ChatQuill from '../js/chatQuill';

export default {
	data() {
		return {
			htmlContent: '',
			textContent: ''
		};
	},
	computed: {
		canSubmit() {
			return this.textContent.length > 0;
		}
	},
	methods: {
		submit() {
			console.log('Submit?');
			if (!this.canSubmit) {
				return;
			}
			this.$emit('submit', this.htmlContent);
			this.quill.setText('');
		}
	},
	mounted() {
		this.quill = new ChatQuill(this.$refs.chatInput , {
			readOnly: false,
			placeholder: 'Type a message...',
			theme: 'bubble',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline'],
					['link', 'image']
				],
				keyboard: {
					bindings: {
						enter: {
							key: 13,
							shiftKey: false,
							handler: () => {
								this.submit();
							}
						}
					}
				}
			}
		});
		this.quill.on('text-change', () => {
			this.htmlContent = this.$refs.chatInput.firstChild.innerHTML.trim();
			this.textContent = this.$refs.chatInput.firstChild.innerText.trim();
		});
	}
};
</script>

<style lang="scss" scoped>
.chat-input-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.chat-input {
    margin-bottom: 0;
    display: flex;
    flex: 1 1 auto;
}

.chat-input-btn {
    display: flex;
    flex: 0 1 auto;

    > .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.chat-input /deep/ .ql-container {
    width: 100%;
    border-radius: 0;
    max-height: 12em;

    > .ql-editor {
        border-radius: 0;
    }
}
</style>
