<template>
  <div class="col-12">
    <form action="javascript:void(0);" v-if="loaded">
      <SelectField
        name="state"
        v-model="formValues.stateID"
        label="Status"
        :options="states"
        textProp="name"
        valueProp="ID"
      />
      <SelectField
        name="group"
        v-model="formValues.ticketGroupID"
        label="Group"
        :options="groups"
        textProp="name"
        valueProp="ID"
        :filterable="false"
        :onSearch="searchGroups"
      />
      <SelectField
        name="agent"
        v-model="formValues.assignedToUserID"
        label="Agent"
        :options="agents"
        textProp="displayName"
        valueProp="ID"
        :disabled="!formValues.ticketGroupID"
        :filterable="false"
        :onSearch="searchAgents"
      />
    </form>
    <div class="text-center m-3" v-else>
      <div class="spinner spinner-grow"></div>
    </div>
  </div>
</template>

<script>
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { clone } from 'lodash';

export default {
	components: {
		SelectField
	},
	props: {
		availableStates: Array
	},
	data() {
		return {
			loaded: false,
			states: null,
			groups: null,
			agents: null,
			formValues: {
				stateID: null,
				ticketGroupID: null,
				assignedToUserID: null
			}
		};
	},
	methods: {
		async getStates() {
			const states = await Api.get('/api/ticketing/states');
			this.states = states.filter(state =>
				this.availableStates.includes(state.ID)
			);
		},
		async searchAgents(search, loading) {
			if (loading) {
				loading(true);
			}

			let agents = [];

			const ticketGroupID = this.formValues.ticketGroupID;
			if (ticketGroupID) {
				agents = await Api.get(`/api/ticketing/groups/${ticketGroupID}/agents`, {
					displayName: search
				});
			}

			this.agents = agents;
			if (loading) {
				loading(false);
			}
		},
		async searchGroups(search, loading) {
			if (loading) {
				loading(true);
			}
			this.groups = await Api.get('/api/ticketing/groups', { name: search });
			if (loading) {
				loading(false);
			}
		},
		getData() {
			return clone(this.formValues);
		}
	},
	created() {
		const tasks = [this.searchAgents(), this.searchGroups(), this.getStates()];
		Promise.all(tasks).then(() => this.loaded = true);
	},
	watch: {
		'formValues.ticketGroupID'(newID) {
			this.formValues.assignedToUserID = null;
			this.searchAgents();
		}
	}
};
</script>
