import Vue from 'vue';
import VueTr from 'BootQuery/Assets/js/vue-tr';
import VueDateFormat from 'BootQuery/Assets/js/vue-dateformat.js';
import VueBoundary from 'BootQuery/Assets/js/vue-boundary';
import VueRouter from 'vue-router';
import VueTimers from 'vue-timers';
import VueMq from 'vue-mq';

Vue.use(VueTr);
Vue.use(VueDateFormat);
Vue.use(VueBoundary);
Vue.use(VueRouter);
Vue.use(VueTimers);
Vue.use(VueMq, {
	breakpoints: {
		xs: 576,
		sm: 768,
		md: 992,
		lg: 1200,
		xl: Infinity
	}
});
export default Vue;