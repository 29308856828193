import Module from 'BootQuery/Assets/js/module.js';
import FormEditor from 'BootQuery/Assets/js/form-editor';
import { find, get, cloneDeep, merge, lowerFirst, intersection } from 'lodash';
import { popoverForTrigger } from 'app/assets/js/util.js';
import { tr } from 'BootQuery/Assets/js/BootQuery.js';
import uuid from 'uuid/v4';
import phpToMomentDateFormat from 'BootQuery/Assets/js/phpToMoment.js';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import Vue from 'BootQuery/Assets/js/vue.js';
import MailEvent from '../components/MailEvent.vue';
import MailEditorModal from '../components/MailEditorModal.vue';
import ChatHistoryModal from '../components/ChatHistoryModal.vue';
import getStatisticsRouter from './statistics-router.js';
import StatisticsComponent from '../components/StatisticsWrapper.vue';
import BulkModal from './BulkModal.js';

export default class Ticketing extends Module {
	constructor() {
		super();
	}

	init() {
		super.init();
		Api.get('/api/ticketing/types').then(
			types => this.ticketTypes = types
		);
		Api.get('/api/ticketing/states').then(
			states => this.ticketStates = states
		);
		Api.get('/api/ticketing/groups').then(
			groups => this.groups = groups
		);
	}

	formTicketTypeID() {
		if (window.Bootstrap.result.ticket) {
			return window.Bootstrap.result.ticket.ticketTypeID;
		} else {
			return parseInt($('input[name="ticketTypeID"]').val());
		}
	}

	activateElements(target, data) {
		const $statisticsContainer = target.findElement('#ticketing-statistics-container');
		if ($statisticsContainer.length) {
			this.renderStatistics($statisticsContainer);
		}
		this.activateTicketEditTabs(target);
		this.activateEvents(target, data);
		this.activateSettings(target, data);
		this.activateTable(target, data);

		if (target.findElement('#ticketing-edit-form').length) {
			const customerDropdown = target.findElement(
				'select[name="ticket[customerID]"]'
			);
			customerDropdown.ev('renderedSelected', e => {
				this.activateClientInfoPopover($(e.selectedOptionElement));
			});
			const selectedOptionEl = customerDropdown
				.siblings('.pickle-container')
				.find('.selected-option');
			this.activateClientInfoPopover(selectedOptionEl);
			this.activateTicketShares(target);
			this.activateTicketReminders(target);
		}
	}

	activateTable(target, data) {
		const table = target.findElement('#tickets-table');
		if (!table.length) {
			return;
		}

		const changeBtn = table.find('[data-table-action="bulkModify"]');
		changeBtn.attr('type', 'button');
		changeBtn.off('click');
		changeBtn.ev('click.ticketing', ev => {
			ev.preventDefault();
			console.log('BULK CHANGE');

			const selection = [];
			const rowMatch = /^tickets-rowselect-(\d+)/;
			Object.entries(getFormData(table)).forEach(([name, val]) => {
				const match = name.match(rowMatch);
				if (match  && val === 'true') {
					selection.push(parseInt(match[1]));
				}
			});
			console.log('Selection: ', selection);
			if (selection.length) {
				this.bulkChange(selection, data);
			}

			return false;
		});
	}

	statesForSelection(selection, data) {
		const rows = data.tables.tickets.result;
		const typeIDs = selection.reduce((types, rowID) => {
			const row = rows.find(row => row.ID === rowID);
			const typeID = row.ticketTypeID;
			if (!types.includes(typeID)) {
				types.push(typeID);
			}
			return types;
		}, []);
		const types = data.result.ticketTypes.filter(
			type => typeIDs.includes(type.ID)
		);
		const typeStates = types.map(type => type.enabledStates);
		return intersection(...typeStates);
	}

	async bulkChange(selection, data) {
		const stateIDs = this.statesForSelection(selection, data);

		const modal = new BulkModal(stateIDs, changes => {
			const form = $('#tickets-filter-form');
			form.data('submittedByAction', 'bulkModify');
			form.append(
				$('<input/>', {
					name: 'bulkChanges',
					value: JSON.stringify(changes)
				})
			);
			form.submit();
		});
		modal.render();
	}

	activateEvents(target, data) {
		const events = target.findElement('#events');
		if (events.length === 0) {
			return;
		}

		this.activateMails(target, data);

		events
			.findElement('[data-add-event-id]')
			.ev('click.ticketing', async e => {
				e.preventDefault();
				let eventTypeID = parseInt(
					$(e.currentTarget).data('addEventId')
				);

				const eventID = uuid();
				let eventType = cloneDeep(
					find(data.result.eventTypes, { ID: eventTypeID })
				);
				let modal = $.render(
					await getTemplate('Ticketing.eventModal'),
					{
						formBinding: eventType.formBinding,
						typeID: eventTypeID,
						typeName: eventType.name,
						new: true
					}
				);

				modal
					.find('.event-modal-save')
					.ev('click.ticketing', async e => {
						e.preventDefault();
						const eventList = events.findElement('.event-list');
						const data = getFormData(modal.find('[data-form]'));
						const eventEl = $.render(
							await getTemplate('Ticketing.ticketEvent'),
							{
								eventID,
								is_custom: true,
								data: {
									stillBinding: true,
									eventTypeName: eventType.name,
									eventTime: new Date().toISOString()
								}
							}
						);
						eventEl
							.find('[data-edit-custom-event-id]')
							.data(
								'formEditElement',
								modal.find('.modal-body').detach()
							);
						eventEl.addClass('new-event');
						this.activateElements(eventEl, window.Bootstrap);
						this.setEventValue(eventID, eventEl, data);
						eventList
							.children()
							.last()
							.addClass('mb-3');
						eventEl.appendTo(eventList);
						modal.modal('hide');

						getJSON(
							'post',
							'ticketing',
							'bindEventPreview',
							data,
							true,
							async data => {
								const formRenderData = merge(
									data.result.formDefinition,
									{ plain: true, readonly: true }
								);
								const eventForm = $.render(
									await getTemplate('form'),
									formRenderData
								);
								eventEl
									.find('.custom-event-plain-form')
									.html(eventForm);
							}
						);
					});

				modal.on('hidden.bs.modal', () => {
					modal.modal('dispose');
					modal.remove();
				});
				modal.modal('show');
				modal
					.find('[data-form]')
					.form({ formDefinition: eventType.formBinding });
			});

		events
			.findElement('[data-edit-custom-event-id]')
			.ev('click.ticketing', async e => {
				e.preventDefault();
				const modalBtn = $(e.currentTarget);
				const eventID = modalBtn.data('editCustomEventId');
				const eventType = $(e.currentTarget).data(
					'editCustomEventType'
				);

				let afterShow = () => {};
				let modal;
				let initialData = {};

				if (modalBtn.data('formEditElement')) {
					const valInput = modalBtn
						.closest('.card')
						.findElement(`input[name="customEvents[${eventID}]"]`);
					const savedData = JSON.parse(valInput.val());
					const typeName = find(data.result.eventTypes, {
						ID: savedData.eventTypeID
					}).name;
					modal = $.render(
						await getTemplate('Ticketing.eventModal'),
						{
							typeID: savedData.eventTypeID,
							typeName,
							ID: eventID
						}
					);
					modal
						.find('.modal-body')
						.replaceWith(modalBtn.data('formEditElement'));
				} else {
					const event = find(data.result.events, event => {
						return (
							event.eventID == eventID && event.type === eventType
						);
					});
					initialData = event.data;
					modal = $.render(
						await getTemplate('Ticketing.eventModal'),
						{
							formBinding: event.formBinding,
							typeID:
								eventType === 'call'
									? 'call'
									: event.data.eventTypeID,
							typeName: event.data.eventTypeName,
							ID: event.eventID
						}
					);
					afterShow = () =>
						modal
							.find('[data-form]')
							.form({ formDefinition: event.formBinding });
				}

				modal
					.find('.event-modal-save')
					.ev('click.ticketing', async e => {
						e.preventDefault();
						const data = getFormData(modal.find('[data-form]'));
						this.setEventValue(
							eventID,
							modalBtn.closest('.card'),
							data
						);
						modalBtn.data(
							'formEditElement',
							modal.find('.modal-body').detach()
						);

						data['existingData'] = initialData.eventData;
						getJSON(
							'post',
							'ticketing',
							'bindEventPreview',
							data,
							true,
							async data => {
								const formRenderData = merge(
									data.result.formDefinition,
									{ plain: true, readonly: true }
								);
								const eventForm = $.render(
									await getTemplate('form'),
									formRenderData
								);
								modalBtn
									.closest('.card')
									.find('.custom-event-plain-form')
									.html(eventForm);
							}
						);
						modal.modal('hide');
					});

				modal.on('hidden.bs.modal', () => {
					modal.modal('dispose');
					modal.remove();
				});
				modal.modal('show');
				afterShow();
			});

		events
			.findElement('[data-delete-custom-event-id]')
			.ev('click.ticketing', async e => {
				e.preventDefault();

				const deleteBtn = $(e.currentTarget);
				const eventID = deleteBtn.data('deleteCustomEventId');
				const eventTypeID = deleteBtn.data('deleteCustomEventType');

				if (deleteBtn.closest('.new-event').length) {
					deleteBtn.closest('.card').remove();
				} else {
					this.setEventValue(eventID, deleteBtn.closest('.card'), {
						ID: eventID,
						eventTypeID,
						deleted: true
					});
					deleteBtn.closest('.card').prop('hidden', true);
				}
			});

		target.findElement('.add-mail-event').ev('click.ticketing', async e => {
			e.preventDefault();
			let to = [];
			const clientInfo = await this.clientInfo(
				target.find('[name="ticket[customerID]"]').val()
			);
			if (clientInfo && clientInfo.emails.length) {
				to = [clientInfo.emails[0].email];
			}

			let accountID;
			let subject;
			const group = this.getSelectedGroup();
			if (group) {
				accountID = group.defaultEmailAccountID;
				subject = this.formatSubject(group.mailSubjectFormat, '');
			}

			this.newMail({ to, accountID, subject });
		});

		target.findElement('.add-call-event').ev('click.ticketing', async e => {
			e.preventDefault();
			const extension = window.Bootstrap.bootquery.session.extension;
			const ticketID = data.bootquery.parameters.ID;
			const clientInfo = await this.clientInfo(
				target.find('[name="ticket[customerID]"]').val()
			);
			let numbers = [];
			if (clientInfo) {
				numbers = clientInfo.phoneNumbers.map(num => {
					return {
						phoneNumber: num.fullPhoneNumber.replace(/\s+/g, ''),
						formattedPhoneNumber: num.fullPhoneNumber
					};
				});
			}
			const modal = $.render(
				await getTemplate('Ticketing.callNumberPickerModal'),
				{
					numbers: numbers
				}
			);
			const numberPicker = modal.findElement('.ticketing-number-picker');
			const submitBtn = modal.findElement('.from-ticket-call-btn');
			submitBtn.ev('click', async e => {
				e.preventDefault();
				setTargetLoadingState(modal, true);
				const number = numberPicker.val();
				const dialResp = await Api.post(
					`/api/asterisk/extensions/${extension}/dial`,
					{ number }
				);
				setTargetLoadingState(modal, false);
				modal.findElement('.modal-footer').remove();
				modal
					.findElement('.modal-body')
					.empty()
					.append('<h1>Answer phone</h1>');

				const actionID = dialResp[0].uuid;

				target.findElement('.event-list');
				const nowStamp = new Date().toISOString();
				const callEventType = find(window.Bootstrap.result.eventTypes, {
					ID: 'call'
				});
				const callEvent = {
					is_call: true,
					type: 'call',
					eventID: actionID,
					timestamp: nowStamp,
					data: {
						inProgress: true,
						callStart: nowStamp,
						direction: true,
						calleePhoneNumber: number,
						callerPhoneNumber: extension
					},
					formBinding: cloneDeep(callEventType.formBinding)
				};
				const response = await this.waitForOriginateResponse(
					actionID,
					extension
				);
				if (response.Status) {
					let eventEl = $.render(
						await getTemplate('Ticketing.ticketEvent'),
						callEvent
					);
					if (ticketID === undefined) {
						// New ticket
						eventEl.append(
							$('<input/>', {
								name: `attachCall[${uuid()}]`,
								value: actionID,
								type: 'hidden'
							})
						);
					} else {
						const { eventID } = await Api.post(
							'/api/ticketing/addCallToTicket',
							{
								ticketID,
								originateUUID: actionID
							}
						);
						callEvent.eventID = eventID;
						eventEl = $.render(
							await getTemplate('Ticketing.ticketEvent'),
							callEvent
						);
					}
					window.Bootstrap.result.events.push(callEvent);
					this.activateElements(eventEl, window.Bootstrap);
					target
						.findElement('.event-list')
						.children()
						.last()
						.addClass('mb-3');
					target.findElement('.event-list').append(eventEl);
				}

				modal.modal('hide');
			});

			modal.on('hidden.bs.modal', () => {
				modal.modal('dispose');
				modal.remove();
			});
			modal.modal('show');
			numberPicker.pickle({
				initialOptions: numbers.map(num => {
					return {
						id: num.phoneNumber,
						text: num.formattedPhoneNumber,
						persist: true
					};
				}),
				formatOption(option) {
					if (option.isNew) {
						return `<strong>"${option.text}"</strong>`;
					} else {
						return option.text;
					}
				},
				results(searchString, results, callback) {
					results = results.filter(res => res.persist);
					if (searchString.length === 0) {
						callback(results, true);
						return;
					}
					const cleanSearch = searchString.replace(/\s+/g, '');
					const matchedIndex = results.findIndex(res => {
						return res.id === cleanSearch;
					});
					if (matchedIndex === -1) {
						results.unshift({
							id: cleanSearch,
							text: searchString,
							isNew: true
						});
					}
					callback(results, true);
				}
			});
			numberPicker.ev('change.ticketing', e => {
				const hasNumber =
					numberPicker.val() && numberPicker.val() !== 'null';
				submitBtn.prop('disabled', !hasNumber);
			});
		});

		target
			.findElement('.customer-chat-event-view-btn')
			.ev('click.ticketing', async e => {
				e.preventDefault();
				const card = $(e.currentTarget).closest('.card');
				const chatID = parseInt(card.data('customerChatEventId'));

				this.showChatHistoryModal(chatID);
			});
	}

	activateSettings(target, data) {
		const settings = target.findElement('#setting-ticketing');

		if (settings.length) {
			settings
				.findElement('[data-edit-ticket-type]')
				.ev('click.ticketing', e => {
					e.preventDefault();
					const btn = $(e.currentTarget);
					const ticketTypeListItem = btn.closest('.list-group-item');
					const ticketTypeID = btn.data('ticketTypeId');
					const ticketTypeDataName = `ticketing[ticketTypes][${ticketTypeID}]`;
					let ticketTypeData = null;
					let ticketTypeInfo = ticketTypeListItem.findElement(
						`input[type="hidden"][name="${ticketTypeDataName}"]`
					);
					if (ticketTypeInfo.length) {
						ticketTypeData = JSON.parse(ticketTypeInfo.val());
					} else {
						const ticketTypes = this
							.findTicketingTab(window.Bootstrap)
							.data
							.ticketTypes;
						ticketTypeData = cloneDeep(
							find(ticketTypes, {ID: parseInt(ticketTypeID)})
						);
					}

					this.editTicketType(ticketTypeData, saved => {
						if (!ticketTypeInfo.length) {
							ticketTypeInfo = $('<input/>', {
								type: 'hidden',
								name: ticketTypeDataName
							});
							ticketTypeInfo.appendTo(ticketTypeListItem);
						}
						ticketTypeInfo.val(JSON.stringify(saved));
					});
				});

			settings
				.findElement('[data-add-ticket-type]')
				.ev('click.ticketing', e => {
					e.preventDefault();
					this.editTicketType({ isNew: true }, async saved => {
						const typeID = this.nextNewTicketTypeID(target);
						saved.ID = typeID;
						saved.isNew = true;

						const ticketTypeName = `ticketing[ticketTypes][${typeID}]`;
						const itemTemplate = await getTemplate(
							'Ticketing.ticketTypeListItem'
						);
						const item = $.render(itemTemplate, saved);
						const dataInput = $('<input/>', {
							type: 'hidden',
							name: ticketTypeName,
							value: JSON.stringify(saved)
						});
						item.append(dataInput);
						target.findElement('.ticket-type-list').append(item);
						this.activateElements(target);
					});
				});

			settings
				.findElement('.ticketing-groups-add-btn')
				.ev('click.ticketing', e => {
					e.preventDefault();
					this.createGroup(target, data);
				});

			settings
				.findElement('.ticketing-priorities-add-btn')
				.ev('click.ticketing', e => {
					e.preventDefault();
					this.createPriority(target, data);
				});
		}

		const eventTypeEditorEl = target.findElement(
			'.event-type-editor-container'
		);
		if (eventTypeEditorEl.length) {
			let formDef = get(data, 'result.eventType.formDefinition');
			let eventTypeEditor = new FormEditor(formDef);
			eventTypeEditor.render(eventTypeEditorEl);

			target
				.findElement('form[name="ticketing-event-type"]')
				.ev('beforeSubmit.ticketing', e => {
					let input = $(e.currentTarget).find(
						'[name="eventFormDefinition"]'
					);
					if (!input.length) {
						input = $('<input/>', {
							type: 'hidden',
							name: 'eventFormDefinition'
						});
					}
					input.val(JSON.stringify(eventTypeEditor.getDefinition()));
					eventTypeEditorEl.append(input);
				});
		}
	}

	activateTicketEditTabs(target) {
		const tabs = target.find('.ticket-edit-tabs');
		if (tabs.length === 0) {
			return;
		}

		const tabHash = window.location.hash.substr(1) || window.Bootstrap.bootquery.defaultTab || null;
		if(tabHash) {
			const tab = target.findElement(`a[href="#${tabHash}"]`);
			if (tab.length) {
				tab.parent()
					.siblings()
					.find('.nav-link')
					.removeClass('active show');
				tab.addClass('active show');
				const tabContent = target.find(`#${tabHash}`);
				tabContent.siblings().removeClass('show active');
				tabContent.addClass('show active');
			}
		}

		tabs.find('.nav-link').ev('shown.bs.tab', e => {
			const path = window.location.pathname;
			const search = window.location.search;
			const hash = $(e.target).attr('href').substr(1);

			const historyObj = {
				controller: lowerFirst(window.Bootstrap.bootquery.controller),
				method: lowerFirst(window.Bootstrap.bootquery.method),
				search: window.location.search,
				hash: hash
			};
			let newUrl = `${path}${search}`;
			if(hash) {
				newUrl += `#${hash}`;
			}
			history.replaceState(historyObj, null, newUrl);
		});
	}

	activateTicketShares(target) {
		Api.get('/api/ticketing/groups', { limit: null }).then(groups => {
			this.allGroups = groups.map(group => {
				return { id: group.ID, text: group.name };
			});

			// Don't allow sharing if there's no-one to share with
			if (this.allGroups.length < 2) {
				target.findElement('.ticket-share').prop('hidden', true);
			}
		});
		const shareValueEl = $('<input/>', {
			type: 'hidden',
			name: 'shares',
			value: JSON.stringify({
				inserted: [],
				deleted: []
			})
		});
		shareValueEl.appendTo(target.findElement('#ticketing-edit-form'));

		this.shareValueEl = shareValueEl;
		target.findElement('.ticket-share-add-btn').ev('click.ticketing', e => {
			e.preventDefault();
			this.addShareRow(target);
		});
		target
			.findElement('.ticket-share-delete-btn')
			.ev('click.ticketing', e => {
				e.preventDefault();
				const row = $(e.currentTarget).closest('li');
				const rowID = row.data('ticketShareId');
				this.deleteShareRow(target, rowID);
			});
	}

	async addShareRow(target) {
		const shares = JSON.parse(this.shareValueEl.val());
		const rowID = `new${uuid()}`;
		shares.inserted.push({
			tmpID: rowID,
			ticketGroupID: this.allGroups[0].id
		});
		const row = $.render(await getTemplate('Ticketing.ticketShareRow'), {
			newRow: true,
			ID: rowID
		});
		row.appendTo(target.findElement('.ticket-share-list'));
		row.findElement('.ticket-share-delete-btn').ev('click.ticketing', e => {
			e.preventDefault();
			this.deleteShareRow(target, rowID);
		});

		const dropdown = row.find('.ticket-share-group-dropdown');
		dropdown.pickle({
			initialOptions: this.allGroups
		});
		dropdown.pickle('select', this.allGroups[0].id);
		dropdown.on('change.ticketing', ev => {
			const shares = JSON.parse(this.shareValueEl.val());
			const share = shares.inserted.find(share => share.tmpID === rowID);
			share.ticketGroupID = parseInt(dropdown.val());
			this.shareValueEl.val(JSON.stringify(shares));
		});
		row.findElement('.form-control').addClass('form-control-sm');

		this.shareValueEl.val(JSON.stringify(shares));
	}

	async deleteShareRow(target, rowID) {
		const shares = JSON.parse(this.shareValueEl.val());

		const insertedIdx = shares.inserted.findIndex(
			share => share.tmpID == rowID
		);
		if (insertedIdx !== -1) {
			shares.inserted.splice(insertedIdx, 1);
		} else {
			shares.deleted.push(parseInt(rowID));
		}

		target
			.find('.ticket-share-list')
			.find(`[data-ticket-share-id="${rowID}"]`)
			.remove();

		this.shareValueEl.val(JSON.stringify(shares));
	}

	activateTicketReminders(target) {
		const remindersValueEl = $('<input/>', {
			type: 'hidden',
			name: 'reminders',
			value: JSON.stringify({
				inserted: [],
				updated: [],
				deleted: []
			})
		});
		target.findElement('#ticketing-edit-form').append(remindersValueEl);
		this.remindersValueEl = remindersValueEl;

		target.findElement('.ticket-reminder-add-btn')
			.ev('click.ticketing', e => {
				e.preventDefault();
				this.addReminder(target);
			});
		target
			.findElement('.ticket-reminder-delete-btn')
			.ev('click.ticketing', e => {
				e.preventDefault();
				const row = $(e.currentTarget).closest('li');
				const rowID = row.data('ticketReminderId');
				this.deleteReminder(target, rowID);
			});
		target
			.findElement('.ticket-reminder-edit-btn')
			.ev('click.ticketing', e => {
				e.preventDefault();
				const row = $(e.currentTarget).closest('li');
				const rowID = row.data('ticketReminderId');
				this.editReminder(target, rowID);
			});
	}

	async addReminder(target) {
		const rowTemplate = await getTemplate('Ticketing.ticketReminderRow');
		const rowID = `new${uuid()}`;

		this.reminderEditor({
			isNew: true,
			ID: rowID
		}, data => {
			const row = $.render(rowTemplate, data);
			row.appendTo(target.findElement('.ticket-reminders-list'));
			row.findElement('.ticket-reminder-delete-btn').ev('click.ticketing', e => {
				e.preventDefault();
				this.deleteReminder(target, rowID);
			});
			row.findElement('.ticket-reminder-edit-btn').ev('click.ticketing', e => {
				e.preventDefault();
				this.editReminder(target, rowID);
			});
			const reminders = JSON.parse(this.remindersValueEl.val());
			reminders.inserted.push(data);
			this.remindersValueEl.val(JSON.stringify(reminders));
		});
	}

	deleteReminder(target, rowID) {
		const reminders = JSON.parse(this.remindersValueEl.val());

		const insertedIdx = reminders.inserted.findIndex(
			reminder => reminder.ID == rowID
		);
		if (insertedIdx !== -1) {
			reminders.inserted.splice(insertedIdx, 1);
		} else {
			reminders.deleted.push(parseInt(rowID));
		}

		target
			.find('.ticket-reminders-list')
			.find(`[data-ticket-reminder-id="${rowID}"]`)
			.remove();

		this.remindersValueEl.val(JSON.stringify(reminders));
	}

	editReminder(target, reminderID) {
		const reminderData = this.getReminder(reminderID);

		this.reminderEditor(reminderData, async (updated) => {
			this.updateReminder(reminderID, updated);
			const renderedRow = $.render(
				await getTemplate('Ticketing.ticketReminderRow'),
				updated
			);
			renderedRow.findElement('.ticket-reminder-delete-btn').ev('click.ticketing', e => {
				e.preventDefault();
				this.deleteReminder(target, reminderID);
			});
			renderedRow.findElement('.ticket-reminder-edit-btn').ev('click.ticketing', e => {
				e.preventDefault();
				this.editReminder(target, reminderID);
			});
			target.find(`.ticket-reminders-list [data-ticket-reminder-id="${reminderID}"]`)
				.replaceWith(renderedRow);
		});
	}

	getReminder(reminderID) {
		const reminders = JSON.parse(this.remindersValueEl.val());
		if (reminderID.toString().indexOf('new') === 0) {
			return reminders.inserted.find(
				inserted => inserted.ID === reminderID.toString()
			);
		} else {
			const reminderIntID = parseInt(reminderID);
			const updated = reminders.updated.find(
				updated => updated.ID === reminderIntID
			);
			if (updated) {
				return updated;
			} else {
				return window.Bootstrap.result.reminders.find(
					reminder => reminder.ID === reminderIntID
				);
			}
		}
	}

	updateReminder(reminderID, reminderData) {
		const reminders = JSON.parse(this.remindersValueEl.val());
		if (reminderID.toString().indexOf('new') === 0) {
			const idx = reminders.inserted.findIndex(
				inserted => inserted.ID === reminderID.toString()
			);
			reminders.inserted[idx] = reminderData;
		} else {
			const reminderIntID = parseInt(reminderID);
			const idx = reminders.updated.findIndex(
				updated => updated.ID === reminderIntID
			);
			if (idx === -1) {
				reminders.updated.push(reminderData);
			} else {
				reminders.updated[idx] = reminderData;
			}
		}
		this.remindersValueEl.val(JSON.stringify(reminders));
	}

	async reminderEditor(reminderData, onSave) {
		const currentType = this.ticketTypes.find(
			ticketType => ticketType.ID === this.formTicketTypeID()
		);
		console.log('Current type: ', currentType);
		const selectedState = reminderData.newState || null;

		if (!currentType.stateMap) {
			currentType.stateMap = await Api.get(
				`/api/ticketing/types/${currentType.ID}/states/`
			);
		}
		const states = cloneDeep(currentType.stateMap).map(state => {
			state.selected = state.ID === selectedState;
			return state;
		});
		console.log('States: ', states);

		const dateFormat = phpToMomentDateFormat(tr('format.datetime'));
		const data = cloneDeep(reminderData);
		data.date = moment(data.timestamp).format(dateFormat);
		const modal = $.render(
			await getTemplate('Ticketing.ticketReminderEditModal'),
			{
				...data,
				states
			}
		);

		const checkSubmit = () => {
			const data = getFormData(modal).reminder;
			const canSubmit = data.title.trim().length > 0 && data.date.trim().length > 0;
			modal.find('.save-btn').prop('disabled', !canSubmit);
		};
		modal.find('input[name="reminder[title]"]').ev('input', checkSubmit);
		modal.find('input[name="reminder[date]"]').ev('change', checkSubmit);
		checkSubmit();

		modal.find('.save-btn').ev('click.ticketing', e => {
			e.preventDefault();
			const formData = getFormData(modal).reminder;
			data.title = formData.title.trim();
			let newState = formData.newState;
			if (!newState || newState === 'null') {
				newState = null;
			} else {
				newState = parseInt(newState);
			}
			data.newState = newState;
			const date = formData.date.trim();
			const momentDate = moment(date, dateFormat);
			data.timestamp = momentDate.toISOString();
			delete data.date;

			modal.modal('hide');
			if (typeof(onSave) === 'function') {
				onSave(data);
			}
		});
		window.activateElements(modal);
		modal.modal('show');
		modal.on('hidden.bs.modal',  () => {
			modal.remove();
		});
	}

	waitForOriginateResponse(actionID, extension) {
		return new Promise((resolve, reject) => {
			// Reject this after a timeout
			// so we don't get left with unresolved promises over time
			// if we don't get the right event for whatever reason.
			// Hope no one will have more than 10min timeout on originate
			const timeoutHandle = setTimeout(reject, 10 * 60 * 1000);
			this.subscribeWebSocket(`OriginateResponse/${extension}`, resp => {
				if (resp.ActionID === actionID) {
					window.clearTimeout(timeoutHandle);
					resolve(resp);
				}
			});
		});
	}

	async activateClientInfoPopover(selectedOptionEl) {
		window.activatePopovers(selectedOptionEl);
		selectedOptionEl.ev('click.ticketing', e => {
			const $tgt = $(e.target);
			if (
				$tgt.is('[data-toggle="popover"]') ||
				$tgt.closest('[data-toggle="popover"]').length
			) {
				e.stopPropagation();
			}
		});
		selectedOptionEl
			.find('[data-toggle="popover"]')
			.on('inserted.bs.popover', async e => {
				const btn = $(e.currentTarget);
				if (btn.data('customerPopoverActivated')) {
					return;
				}
				const customerID = $(e.currentTarget).data('customerId');
				const customerData = await this.clientInfo(customerID);
				const infoContent = $.render(
					await getTemplate('Ticketing.customerInfoPopover'),
					customerData
				);
				popoverForTrigger($(e.currentTarget)).html(infoContent);
				btn.data('customerPopoverActivated', true);
			});
	}

	setEventValue(eventID, eventCard, data) {
		const inputName = `customEvents[${eventID}]`;
		let formDataEl = eventCard.findElement(`input[name="${inputName}"]`);
		if (!formDataEl.length) {
			formDataEl = $('<input/>', {
				type: 'hidden',
				name: inputName
			});
			formDataEl.appendTo(eventCard);
		}
		formDataEl.val(JSON.stringify(data));
	}

	async clientInfo(clientID) {
		if (
			typeof clientID !== 'string' ||
			clientID === 'null' ||
			clientID === ''
		) {
			return null;
		}

		const [type, ID] = clientID.split('_');
		let method = null;
		if (type === 'person') {
			method = 'personInfo';
		} else {
			method = 'companyInfo';
		}

		const info = await Api.get(`/api/phonebook/${method}/${ID}`);
		info.type = type;
		info[`is_${type}`] = true;
		return info;
	}

	activateMails(target) {
		target.findElement('#events [data-mail-event-id]').each((_idx, el) => {
			const mailID = parseInt(el.dataset.mailEventId);
			const mailEvent = window.Bootstrap.result.events.find(
				ev => ev.eventID === mailID && ev.type === 'mail'
			);
			this.renderMailEvent(el, mailEvent.data);
		});
	}

	renderMailEvent(target, message) {
		message.date = new Date(message.date);

		setTimeout(() => {
			new Vue({
				el: target,
				render: h =>
					h(MailEvent, {
						props: {
							message
						}
					})
			});
		}, 0);
	}

	async newMail(params = {}) {
		const mails = window.BootQuery.getModuleInstance('Mails');
		const staticParams = {
			availableAccounts: [],
			signatureTemplateData: {}
		};
		await Promise.all([
			mails.availableAccounts().then(
				accounts => staticParams.availableAccounts = accounts
			),
			mails.signatureTemplateData().then(
				sigData => staticParams.signatureTemplateData = sigData
			)
		]);

		const container = $('<div/>');
		$('#events').append(container);

		this.mailEditor = new Vue({
			el: container[0],
			render: h =>
				h(MailEditorModal, {
					props: {
						editorData: {
							...staticParams,
							...params
						}
					}
				})
		});

		this.mailEditorData = {
			inReplyToMessageID: params.inReplyToMessageID,
			forwardMessageID: params.forwardMessageID,
			threadID: params.threadID
		};
	}

	async replyMail(type, prevMessage) {
		const mails = window.BootQuery.getModuleInstance('Mails');
		const accounts = await mails.availableAccounts();
		const account = accounts.find(
			acc => acc.ID === prevMessage.accountID
		);
		const addresses = { to: [], cc: [], bcc: [] };

		const replyingToSelf = prevMessage.fromMailAddress === account.email;
		if (type === 'all') {
			if (!replyingToSelf) {
				addresses.to.push(prevMessage.fromMailAddress);
			}
			Object.keys(addresses).forEach(addressType => {
				prevMessage[addressType].forEach(address => {
					if (!replyingToSelf && address.address === account.email) {
						return;
					}
					addresses[addressType].push(address.address);
				});
			});
		} else {
			if (replyingToSelf) {
				prevMessage.to.forEach(address =>
					addresses.to.push(address.address)
				);
			} else {
				addresses.to.push(prevMessage.fromMailAddress);
			}
		}

		let subject = prevMessage.subject;
		const group = this.getSelectedGroup();
		if (group) {
			subject = this.formatSubject(group.mailSubjectFormat, subject);
		}
		if (!subject.toUpperCase().startsWith('RE:')) {
			subject = `Re: ${subject}`;
		}

		this.newMail({
			subject,
			...addresses,
			accountID: account.ID,
			fixedAccount: true,
			inReplyToMessageID: prevMessage.ID
		});
	}

	forwardMail(prevMessage) {
		let subject = prevMessage.subject;
		if (!subject.toUpperCase().startsWith('FWD:')) {
			subject = `Fwd: ${subject}`;
		}

		this.newMail({
			subject,
			forwardMessageID: prevMessage.ID
		});
	}

	destroyMailEditor() {
		if (!this.mailEditor) {
			return;
		}

		this.mailEditor.$destroy();
		this.mailEditor.$el.parentNode.removeChild(this.mailEditor.$el);
		this.mailEditor = null;
		this.mailEditorData = null;
	}

	async submitMailEditor(submitted) {
		const ticketID = window.Bootstrap.bootquery.parameters.ID;
		const extraData = this.mailEditorData;
		const { sentID } = await Api.post('/api/mails/sendMessage', {
			accountID: submitted.accountID,
			subject: submitted.subject,
			to: submitted.to,
			cc: submitted.cc,
			bcc: submitted.bcc,
			content: submitted.content,
			text: submitted.textContent,
			html: submitted.htmlContent,
			attachments: submitted.attachments,
			priority: submitted.priority,
			inReplyToMessageID: extraData.inReplyToMessageID,
			forwardMessageID: extraData.forwardMessageID
		});

		let groupID = $('[name="ticket[ticketGroupID]"]').val();
		if (groupID) {
			groupID = parseInt(groupID);
			const group = this.groups.find(group => group.ID === groupID);
			if (group && group.stateAfterAgentReply) {
				const stateSelect = $('select[name="ticket[stateID]"]');
				if (stateSelect.length) {
					stateSelect.pickle('select', group.stateAfterAgentReply);
				}
			}
		}


		const { message } = await Api.get(`/api/mails/message/${sentID}`);
		const eventEl = $('<div/>').append(
			$('<div/>', {
				class: 'mail-event',
				'data-mail-event-id': sentID
			})
		);
		if (ticketID === undefined) {
			// New ticket
			eventEl.append(
				$('<input/>', {
					name: `attachMail[${uuid()}]`,
					value: message.ID,
					type: 'hidden'
				})
			);
		} else if (!extraData.inReplyToMessageID) {
			// Don't do this if replying, because only the thread root mail
			// needs to be associated
			Api.post('/api/ticketing/addMailThreadToTicket', {
				ticketID,
				mailID: message.ID
			});
		}
		const $evList = $('#events .event-list');
		$evList
			.children()
			.last()
			.addClass('mb-3');
		$evList.append(eventEl);

		setTimeout(() => {
			this.renderMailEvent(eventEl.children('.mail-event')[0], message);
		}, 0);

		this.destroyMailEditor();
	}

	showChatHistoryModal(chatID) {
		const container = $('<div/>');
		$('#events').append(container);

		this.chatHistoryModal = new Vue({
			el: container[0],
			render: h =>
				h(ChatHistoryModal, {
					props: { chatID }
				})
		});
	}

	destroyChatHistoryModal() {
		if (!this.chatHistoryModal) {
			return;
		}

		this.chatHistoryModal.$destroy();
		this.chatHistoryModal.$el.parentNode.removeChild(
			this.chatHistoryModal.$el
		);
		this.chatHistoryModal = null;
	}

	async createGroup(target, data) {
		const groupSettingsCardTemplate = await getTemplate(
			'Ticketing.groupSettingsCard'
		);
		const ownData = this.findTicketingTab(data).data;
		const groupsPane = target.find('#ticketing-groups');
		const rowAddBtn = groupsPane.find('.ticketing-groups-add-btn');

		const newID = 'new' + this.nextNewGroupID(groupsPane);

		// Regex because otherwise only the first occurence is replaced...;
		const formDefJSON = JSON.stringify(ownData.newGroupFormBinding).replace(
			/\$groupID\$/g,
			newID
		);
		const newFormDef = JSON.parse(formDefJSON);

		let element = $(
			handlebarsRender(groupSettingsCardTemplate, {
				ID: newID,
				name: 'New group',
				form: newFormDef,
				expand: true
			})
		);

		let formEl = element.findElement(`[data-form="groups-${newID}"]`);
		formEl.form({
			formDefinition: newFormDef
		});

		element.insertBefore(rowAddBtn);
	}

	nextNewGroupID(target) {
		let maxID = 0;
		target.find('[data-form^="groups-new"]').each((i, form) => {
			let idstr = $(form)
				.attr('data-form')
				.match(/groups-new(\d+)/)[1];
			if (!idstr) {
				return;
			}
			maxID = Math.max(parseInt(idstr), maxID);
		});

		return maxID + 1;
	}

	async createPriority(target, data) {
		const prioritiesPane = target.find('#ticketing-priorities');
		const rowAddBtn = prioritiesPane.find('.ticketing-priorities-add-btn');

		const newID = 'new' + this.nextNewPriorityID(prioritiesPane);
		const element = $.render(
			await getTemplate('Ticketing.prioritySettingsCard'),
			{
				ID: newID,
				name: 'New priority',
				expand: true
			}
		);
		element.find('input[name$="[name]"]').ev('input.ticketing', e => {
			const name = $(e.currentTarget).val();
			element.find('.card-header > button').text(name);
		});
		element.insertBefore(rowAddBtn);
	}

	nextNewPriorityID(target) {
		let maxID = 0;
		target.find('[data-priority-card-id^="new"]').each((i, form) => {
			let idstr = $(form)
				.attr('data-priority-card-id')
				.match(/new(\d+)/)[1];
			if (!idstr) {
				return;
			}
			maxID = Math.max(parseInt(idstr), maxID);
		});

		return maxID + 1;
	}

	async editTicketType(data, onSave) {
		const ticketingData = this.findTicketingTab(window.Bootstrap).data;
		const allStates = cloneDeep(ticketingData.ticketStates);
		data.ticketStates = allStates.map(state => {
			if (data.enabledStates) {
				state.enabled = data.enabledStates.indexOf(state.ID) !== -1;
			} else {
				state.enabled = true;
			}
			return state;
		});
		const template = await getTemplate('Ticketing.ticketTypeEditModal');
		const settingsModal = $.render(template, data);
		const formEditor = new FormEditor(data.formDefinition);
		formEditor.render(
			settingsModal.find('#ticket-type-form-editor')
		);

		settingsModal
			.findElement('.save-btn')
			.ev('click', e => {
				e.preventDefault();
				const formData = getFormData(settingsModal);
				const enabledStates = Object
					.entries(formData.typeState)
					.reduce((enabled, [typeID, state]) => {
						if (state === 'true') {
							enabled.push(parseInt(typeID));
						}
						return enabled;
					}, []);

				const saved = {
					name: formData.name,
					formDefinition: formEditor.getDefinition().definition,
					enabledStates
				};
				settingsModal.modal('hide');
				onSave(saved);
			});
		settingsModal.on('hidden.bs.modal', () => {
			settingsModal.modal('dispose');
			settingsModal.remove();
		});
		settingsModal.modal('show');
		window.activateElements(settingsModal);
	}

	formatSubject(addStyle, subject, ticketID) {
		if (!ticketID) {
			ticketID = get(window.Bootstrap, 'result.ticket.ID');
		}
		if (!ticketID) {
			return subject; // No ID to add
		}

		const idText = `[Ticket#${ticketID}]`;
		if (subject.trim().length === 0) { // On new mail
			if (addStyle !== 'none') {
				subject = idText; // On new mail
			}
		} else if (!subject.includes(idText)) {
			if (addStyle === 'idLeft') {
				subject = `${idText} ${subject}`;
			} else if (addStyle === 'idRight') {
				subject = `${subject} ${idText}`;
			}
		}

		return subject;
	}

	getSelectedGroup() {
		const groupSelect = $('[name="ticket[ticketGroupID]"]');
		if (groupSelect.val() && groupSelect.val() !== '') {
			const selectedGroupOption = groupSelect.pickle(
				'option',
				groupSelect.val()
			);
			return selectedGroupOption.rowData;
		}
		return null;
	}

	nextNewTicketTypeID(target) {
		let maxID = 0;
		target.find('input[name^="ticketing[ticketTypes]"]').each((_index, form) => {
			let idstr = $(form)
				.attr('name')
				.match(/ticketing\[ticketTypes\]\[new(\d+)\]/)[1];
			if (idstr) {
				maxID = Math.max(parseInt(idstr), maxID);
			}
		});

		return 'new' + (maxID + 1);
	}

	findTicketingTab(data) {
		return find(data.result.tabs, {
			key: 'ticketing'
		});
	}

	async renderStatistics($statisticsContainer) {
		const statisticsContainer = $statisticsContainer[0];
		if(statisticsContainer.dataset.activated) {
			return; // Don't double-render
		}
		statisticsContainer.dataset.activated = true;
		this.component = new Vue({
			el: statisticsContainer,
			router: getStatisticsRouter(),
			render: h => h(StatisticsComponent)
		});
	}

	renderStatisticsRoute() {
		const target = $('<div/>', {id: 'ticketing-statistics-container'});
		$(window.targetElement).html(target);
		this.renderStatistics(target);
		$(document).trigger(
			'activateElements',
			[$(window.targetElement), window.Bootstrap]
		);
	}

	handleRoute(route) {
		if (route.startsWith('/ticketing/statistics')) {
			$(document).trigger(
				'renderController',
				[window.targetElement, window.Bootstrap]
			);
			this.renderStatisticsRoute();
			return;
		}
		throw new Error(`Don't know how to handle route: '${route}'`);
	}

	static canHandleRoute(route) {
		if (route.startsWith('/ticketing/statistics')) {
			return true;
		}
		return false;
	}
}
