module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<a href=\""
    + alias2(alias1((depth0 != null ? depth0.linkURL : depth0), depth0))
    + "\" data-notification-id="
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + " class=\"list-group-item list-group-item-action"
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.seen : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = container.invokePartial(helpers.concat.call(alias3,(depth0 != null ? depth0.module : depth0),".",(depth0 != null ? depth0.template : depth0),{"name":"concat","hash":{},"data":data}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " list-group-item-secondary";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div data-notification-id="
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.ID : depth0), depth0))
    + " class=\"list-group-item "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.seen : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    "
    + ((stack1 = container.invokePartial(helpers.concat.call(alias1,(depth0 != null ? depth0.module : depth0),".",(depth0 != null ? depth0.template : depth0),{"name":"concat","hash":{},"data":data}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.linkURL : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})