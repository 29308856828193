module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fa fa-plus\"></span>\n						"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.add_mail_account",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fa fa-edit\"></span>\n						"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_mail_account",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "								<select name=\"incomingProto\" id=\"incomingProto\" class=\"pickle\">\n									<option value=\"IMAP\">IMAP</option>\n									<option value=\"POP3\">POP3</option>\n								</select>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "								<input type=\"text\" class=\"form-control-plaintext\" readonly value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.incomingProto : depth0), depth0))
    + "\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "								<input class=\"form-control\" type=\"password\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.password : depth0), depth0))
    + "\" name=\"password\" id=\"password\">\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "								<button type=\"button\" class=\"btn btn-primary change-pass-btn\" data-toggle=\"collapse\" data-target=\"#mail-password-change-collapse\" id=\"password\">\n									<span class=\"fa fa-lock\"></span> Change password\n								</button>\n\n								<div class=\"collapse\" id=\"mail-password-change-collapse\">\n									<div class=\"card card-body border-primary\">\n										<input type=\"hidden\" name=\"passwordChanged\" value=\"false\">\n										<div class=\"form-group\">\n											<div class=\"row\">\n												<label class=\"col-12 col-form-label col-md-4 control-label text-left text-md-right\" for=\"newPassword\">\n													New password:\n												</label>\n												<div class=\"col-12 col-md-8\">\n													<input class=\"form-control\" type=\"password\" name=\"newPassword\" id=\"newPassword\" disabled>\n												</div>\n											</div>\n										</div>\n									</div>\n								</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "checked";
},"15":function(container,depth0,helpers,partials,data) {
    return " checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n	<div class=\"modal-dialog modal-lg\" role=\"document\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h5 class=\"modal-title\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNewAccount : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "				</h5>\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n					<span aria-hidden=\"true\">&times;</span>\n				</button>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"col-12\">\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingProto\">\n							Type:\n						</label>\n						<div class=\"col-12 col-md-9\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNewAccount : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"email\">\n							Email:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"email\" id=\"email\" name=\"email\" value=\""
    + alias3(alias2((depth0 != null ? depth0.email : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"username\">\n							Username:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"username\" name=\"username\" value=\""
    + alias3(alias2((depth0 != null ? depth0.username : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"password\">\n							Password:\n						</label>\n						<div class=\"col-12 col-md-9\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNewAccount : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingServer\">\n							Incoming server:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"incomingServer\" name=\"incomingServer\" value=\""
    + alias3(alias2((depth0 != null ? depth0.incomingServer : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingPort\">\n							Incoming port:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"number\" step=\"1\" id=\"incomingPort\" name=\"incomingPort\" value=\""
    + alias3(alias2((depth0 != null ? depth0.incomingPort : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingSSL\">\n							Incoming SSL:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"incomingSSL\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"incomingSSL\" name=\"incomingSSL\" value=\"true\" class=\"form-control\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.incomingSSL : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n								<label class=\"custom-control-label\" for=\"incomingSSL\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingServer\">\n							Outgoing server:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"outgoingServer\" name=\"outgoingServer\" value=\""
    + alias3(alias2((depth0 != null ? depth0.outgoingServer : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingPort\">\n							Outgoing port:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"number\" step=\"1\" id=\"outgoingPort\" name=\"outgoingPort\" value=\""
    + alias3(alias2((depth0 != null ? depth0.outgoingPort : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingSSL\">\n							Outgoing SSL:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"outgoingSSL\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"outgoingSSL\" name=\"outgoingSSL\" value=\"true\" class=\"form-control\"\n								 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.outgoingSSL : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> <label class=\"custom-control-label\" for=\"outgoingSSL\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingAuth\">\n							Outgoing auth:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"outgoingAuth\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"outgoingAuth\" name=\"outgoingAuth\" value=\"true\" class=\"form-control\"\n								 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.outgoingAuth : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> <label class=\"custom-control-label\" for=\"outgoingAuth\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"signature-edit\">\n							Signature:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<!-- Wrapped in an empty element so it doesn't fly out of the window -->\n							<div>\n								<div class=\"signature-edit\"></div>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias3(helpers.tr.call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-success save-btn\">\n					<span class=\"fa fa-check\"></span> "
    + alias3(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true})