import EventEmitter from 'events';
import Quill from 'BootQuery/Assets/js/quill';

export default class SignatureEditor extends EventEmitter {
	constructor(signature, availableTemplates = {}) {
		super();
		this.signature = signature;
		this.availableTemplates = availableTemplates;
	}

	async show(container) {
		this.editor = new Quill($(container).get(0), {
			placeholder: 'Signature...',
			readOnly: false,
			theme: 'snow',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],

					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'script': 'sub'}, { 'script': 'super' }],
					[{ 'indent': '-1'}, { 'indent': '+1' }],

					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

					[{ 'color': [] }, { 'background': [] }],
					[{ 'font': [] }],
					[{ 'align': [] }],
					['link', 'image'],
					['blockquote', 'code-block'],
					['clean'],
					[{'template': Object.values(this.availableTemplates)}]
				],
				template: this.templateNameMappings(),
				autoLinks: true
			}
		});
		this.editor.setContents(this.signature);
	}

	data() {
		return this.editor.getContents();
	}

	templateNameMappings() {
		return Object
			.entries(this.availableTemplates)
			.reduce((templates, [name, displayName]) => {
				templates[displayName] = name;
				return templates;
			}, {});
	}
}
