module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "true";
},"3":function(container,depth0,helpers,partials,data) {
    return "false";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"d-flex align-items-center\">\n        <span class=\"mx-3 fa fa-chevron-right\"></span>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"mr-2\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"list-group-item list-group-item-action d-flex justify-content-between align-items-center py-2 px-0\" data-parent-key=\""
    + alias2(alias1((depth0 != null ? depth0.parentKey : depth0), depth0))
    + "\" data-select-item=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n    <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "[deleted]\" value=\"false\">\n    <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "[new]\" value=\""
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1((depth0 != null ? depth0.isNew : depth0), depth0),{"name":"isNew","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n    <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "[parentKey]\" value=\""
    + alias2(alias1((depth0 != null ? depth0.parentKey : depth0), depth0))
    + "\">\n    <div class=\"d-flex align-items-center\">\n        <button type=\"button\" class=\"btn btn-sm btn-link text-danger select-list-delete-btn\">\n            <span class=\"far fa-trash-alt\"></span>\n        </button>\n    </div>\n    <div class=\"d-flex align-items-center\">\n        <input name=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "[name]\" type=\"text\" class=\"form-control form-control-sm\" value=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n    </div>\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.noSubitems : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true})