import phpToMoment from 'BootQuery/Assets/js/phpToMoment.js';

export default {
	install: function(Vue, _options) {
		Vue.mixin({
			filters: {
				formatDate(value, format) {
					return moment(value).format(phpToMoment(format));
				}
			}
		});
	}
};
