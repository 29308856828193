<template>
    <div class="row">
        <div
            v-for="extension in extensions"
            :key="extension.Extension"
            class="col-lg-12 col-md-12 agent-row mb-3"
            :class="showQueueCallers ? 'col-xl-12' : 'col-xl-6'"
            >
            <Extension :extension="extension" :ownExtension="ownExtension" />
        </div>
    </div>
</template>

<script>
import Extension from './Extension.vue';

export default {
	components: {
		Extension
	},
	props: {
		extensions: Object,
		ownExtension: String,
		showQueueCallers: Boolean
	}
};
</script>
