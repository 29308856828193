module.exports = {"name":"Wähler","button":{"call_number":(window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fullPhoneNumber","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.phoneNumber || (depth0 != null ? depth0.phoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"phoneNumber","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "Anrufen ";
  stack1 = ((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : alias2),(options={"name":"fullPhoneNumber","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.fullPhoneNumber) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : alias2),(options={"name":"fullPhoneNumber","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.fullPhoneNumber) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " anrufen";
},"useData":true}),"call":"Anrufen","hang_up":"Auflegen","put_on_hold":"In die Warteschleife legen","transfer":"Transfer","redial":"Wahlwiederholung","features":"Funktionen","dnd":"Bitte nicht stören","answer":"Antworten"},"label":{"pick_up":"Abnehmen","incoming_call":"Ankommender Anruf","in_call":"Im Gespräch","calling":"Anruf","idle":"Einsatzbereit","waiting_to_pick_up":"Wartet auf Anrufübernahme","ringing":"Klingelt","redirect_settings":"Umleitungen"},"state":{"available":"Erreichbar","away":"Abwesend","xa":"Längere Abwesenheit","unavailable":"Unerreichbar","dnd":"Bitte nicht stören"},"form":{"call_forward":"Weiterleitung","call_forward_unconditional":"Bedingungslose Weiterleitung","call_forward_no_answer":"Keine Antwort","call_forward_busy":"Besetzt","call_forward_away":"Abwesend","call_forward_xa":"Längere Abwesenheit"}}