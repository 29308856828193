module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n\n<form method=\"post\" action=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.form_url), depth0))
    + "\" id=\"ticketing-edit-form\">\n	<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.form_action), depth0))
    + "\">\n	<input type=\"hidden\" name=\"ticketTypeID\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.ticket_type), depth0))
    + "\">\n	<div class=\"card ticket-card\">\n		<div class=\"card-header\">\n			<ol class=\"breadcrumb bg-transparent m-0 p-0\">\n				<li class=\"breadcrumb-item\">\n					<a href=\"/ticketing/list\">Ticketing</a>\n				</li>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.parameters)) && stack1.ticketID),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "			</ol>\n		</div>\n		<div class=\"card-block\">\n			<div class=\"row no-gutters\">\n				<div class=\"col-12 col-xl-3 bg-light py-2 border-right ticket-first-pane\">\n					<div class=\"col-12 py-1 text-center\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.parameters)) && stack1.ticketID),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					</div>\n\n					<div data-form=\"ticketBasic1\">\n						"
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.ticketBasic1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n					<div data-form=\"ticketBasic2\">\n						"
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.ticketBasic2),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n					<hr>\n					<div class=\"form-group col ticket-reminders\">\n						<h6>\n							<strong>\n								<span class=\"fa fa-bell\"></span>\n								"
    + alias2(helpers.tr.call(alias3,"label.reminders",{"name":"tr","hash":{},"data":data}))
    + ":\n							</strong>\n						</h6>\n						<ul class=\"ticket-reminders-list\">\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.reminders : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</ul>\n						<div>\n							<a href=\"#\" class=\"ticket-reminder-add-btn\">\n								<span class=\"fas fa-plus\"></span> "
    + alias2(helpers.tr.call(alias3,"button.add",{"name":"tr","hash":{},"data":data}))
    + "\n							</a>\n						</div>\n					</div>\n					<hr>\n					<div class=\"form-group col ticket-share\">\n						<h6>\n							<strong>\n								<span class=\"fas fa-share-alt\"></span>\n								"
    + alias2(helpers.tr.call(alias3,"label.share_with_groups",{"name":"tr","hash":{},"data":data}))
    + ":\n							</strong>\n						</h6>\n						<ul class=\"ticket-share-list\">\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.ticket : stack1)) != null ? stack1.shares : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</ul>\n						<div>\n							<a href=\"#\" class=\"ticket-share-add-btn\">\n								<span class=\"fas fa-plus\"></span> "
    + alias2(helpers.tr.call(alias3,"button.add",{"name":"tr","hash":{},"data":data}))
    + "\n							</a>\n						</div>\n					</div>\n					<hr>\n					<div class=\"col\">\n						<div class=\"d-table\">\n							<div class=\"d-table-row\">\n								<div class=\"d-table-cell py-1 pr-2 font-weight-bold\">\n									"
    + alias2(helpers.tr.call(alias3,"label.created_by",{"name":"tr","hash":{},"data":data}))
    + ":\n								</div>\n								<div class=\"d-table-cell py-1 pl-2\">\n									"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.ticket : stack1)) != null ? stack1.createdByUserID : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "\n								</div>\n							</div>\n							<div class=\"d-table-row\">\n								<div class=\"d-table-cell py-1 pr-2 font-weight-bold\">\n									"
    + alias2(helpers.tr.call(alias3,"label.created_at",{"name":"tr","hash":{},"data":data}))
    + ":\n								</div>\n								<div class=\"d-table-cell py-1 pl-2\">\n									"
    + ((stack1 = helpers["with"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.ticket : stack1)) != null ? stack1.createdAt : stack1),{"name":"with","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "\n								</div>\n							</div>\n							<div class=\"d-table-row\">\n								<div class=\"d-table-cell py-1 pr-2 font-weight-bold\">\n									"
    + alias2(helpers.tr.call(alias3,"label.updated_at",{"name":"tr","hash":{},"data":data}))
    + ":\n								</div>\n								<div class=\"d-table-cell py-1 pl-2\">\n									"
    + ((stack1 = helpers["with"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.ticket : stack1)) != null ? stack1.updatedAt : stack1),{"name":"with","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n				<div class=\"col-12 col-xl-9 ticket-second-pane\">\n					<ul class=\"nav nav-tabs ticket-edit-tabs\">\n						<li class=\"nav-item\">\n							<a class=\"nav-link active\" data-toggle=\"tab\" href=\"#details\">\n								<span class=\"tab-icon\">\n									<span class=\"fa fa-edit\"></span>\n								</span>\n								<span class=\"tab-text\">\n									"
    + alias2(helpers.tr.call(alias3,"tab.details",{"name":"tr","hash":{},"data":data}))
    + "\n								</span>\n							</a>\n						</li>\n						<li class=\"nav-item\">\n							<a class=\"nav-link\" data-toggle=\"tab\" href=\"#events\">\n								<span class=\"tab-icon\">\n									<span class=\"fa fa-calendar-alt\"></span>\n								</span>\n								<span class=\"tab-text\">\n									"
    + alias2(helpers.tr.call(alias3,"tab.events",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary ticket-event-count\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.events : stack1)) != null ? stack1.length : stack1), depth0))
    + "</span>\n								</span>\n							</a>\n						</li>\n					</ul>\n					<div class=\"tab-content\">\n						<div class=\"tab-pane fade show active\" id=\"details\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.ticketDetails)) && stack1.fields),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "						</div>\n						<div class=\"tab-pane fade px-3 py-3\" id=\"events\">\n							<div class=\"d-flex flex-column\">\n								<div class=\"event-list mb-3\">\n									"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.events : stack1),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n								</div>\n								<div class=\"d-flex\">\n									<div class=\"dropdown ml-auto\">\n										<button type=\"button\" class=\"create-ticket btn btn-success dropdown-toggle\" data-toggle=\"dropdown\">\n											<span class=\"fa fa-plus\"></span> "
    + alias2(helpers.tr.call(alias3,"button.add_event",{"name":"tr","hash":{},"data":data}))
    + "\n										</button>\n										<div class=\"dropdown-menu dropdown-menu-right\">\n											<a href=\"#\" class=\"dropdown-item add-call-event\">\n												Call\n											</a>\n											<a href=\"#\" class=\"dropdown-item add-mail-event\">\n												Mail\n											</a>\n											<div class=\"dropdown-divider\"></div>\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.eventTypes : stack1),{"name":"each","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "										</div>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div class=\"card-footer d-flex\">\n"
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			<div class=\"save-button\">\n				<span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\"ticketing-edit-form\">\n				</span>\n				<button type=\"submit\" class=\"btn btn-success\" form=\"ticketing-edit-form\">\n					<span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.hasMultipleTypes),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				<li class=\"breadcrumb-item\">\n					<a href=\"/ticketing/list/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.typeSlug), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.overview), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.overviewName), depth0))
    + "</a>\n				</li>\n				<li class=\"breadcrumb-item\">\n					#"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.parameters)) && stack1.ticketID), depth0))
    + "\n				</li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "				<li class=\"breadcrumb-item\">\n					<a href=\"/ticketing/list/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.typeSlug), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.typeName), depth0))
    + "</a>\n				</li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "				<li class=\"breadcrumb-item\">\n					Novi ticket\n				</li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<h5 class=\"font-weight-bold\">\n							Ticket#"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.parameters)) && stack1.ticketID), depth0))
    + "\n						</h5>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "								"
    + ((stack1 = container.invokePartial(partials.ticketReminderRow,depth0,{"name":"ticketReminderRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "								"
    + ((stack1 = container.invokePartial(partials.ticketShareRow,depth0,{"name":"ticketShareRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.ticket : stack1)) != null ? stack1.createdByDisplayName : stack1), depth0))
    + " ";
},"16":function(container,depth0,helpers,partials,data) {
    return " - ";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " "
    + container.escapeExpression(helpers.format_date.call(alias1,depth0,helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + " ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "							<div data-form=\"ticketDetails\" class=\"py-3\">\n								"
    + ((stack1 = container.invokePartial(partials.form,((stack1 = ((stack1 = (data && data.root)) && stack1.forms)) && stack1.ticketDetails),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "							<div class=\"py-3 px-3\">\n								<strong>"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_ticket_fields",{"name":"tr","hash":{},"data":data}))
    + "</strong>\n							</div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(partials.ticketEvent,depth0,{"name":"ticketEvent","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "											"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isBuiltin : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<a href=\"#\" data-add-event-id=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" class=\"dropdown-item\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "			<a href=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.list_url), depth0))
    + "\" class=\"btn btn-link form-cancel mr-auto\">\n				<span class=\"fa fa-arrow-left\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.back_to_list",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n			";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "\n			<a href=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.list_url), depth0))
    + "\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n				<span class=\"fa fa-times\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})