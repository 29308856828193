<template>
    <div class="message">
        <div class="message-bubble" :class="direction ? 'message-bubble-left' : 'message-bubble-right'">
            <div class="message-content">
                <div class="message-text" v-if="text">{{text}}</div>
                <hr v-if="text && attachments.length">
                <div v-if="attachments.length" class="message-attachments">
                    <template v-if="isSending">
                        <UploadingAttachment
                            v-for="(attachment, index) in attachments"
                            :key="index"
                            :fileName="attachment.fileName"
                            :progress="attachment.progress"
                        />
                    </template>
                    <template v-else>
                        <MessageAttachment
                            v-for="attachment in attachments"
                            :key="attachment.ID"
                            :type="attachment.type"
                            :ID="attachment.ID"
                            :facebookURL="attachment.facebookURL"
                            :savedFilename="attachment.savedFilename"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageAttachment from './MessageAttachment.vue';
import UploadingAttachment from './UploadingAttachment.vue';

export default {
	components: {
		MessageAttachment,
		UploadingAttachment
	},
	props: {
		direction: Boolean,
		text: String,
		date: Date,
		attachments: Array,
		isSending: Boolean
	}
};
</script>

<style lang="scss" scoped>
.message {
	width: 100%;
	clear: both;
	margin-bottom: 15px;
	display: flex;
}
.message-bubble {
	background-color: #eeeeee;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	padding: 10px 15px;
	position: relative;
}

.message-bubble:before {
	content: "";
	width: 0px;
	height: 0px;
	top: 0px;
	position: absolute;
}

.message-bubble-left {
	margin-right: auto;
	margin-left: 14px;
}

.message-bubble-left:before {
	border-left: 8px solid transparent;
	border-right: 8px solid #eeeeee;
	border-top: 8px solid #eeeeee;
	border-bottom: 8px solid transparent;
	border-top-left-radius: 4px;
	left: -14px;
}

.message-bubble-right {
	margin-left: auto;
	margin-right: 14px;
}

.message-bubble-right:before {
	border-left: 8px solid #eeeeee;
	border-right: 8px solid transparent;
	border-top: 8px solid #eeeeee;
	border-bottom: 8px solid transparent;
	border-top-right-radius: 4px;
	right: -14px;
}

.message-text {
	white-space: pre-line;
}
</style>
