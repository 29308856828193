<template>
    <div class="card border-right" style="width: 12%; min-width: 150px;">
        <div class="card-header py-2 text-center">
            <button class="btn btn-sm btn-success mail-new-message-btn" @click="newMessage">
                <span class="fa fa-paper-plane"></span>
                {{ tr("Mails:button.compose") }}
            </button>
        </div>
        <div class="card-body px-2 py-2 bg-light">
            <div class="nav flex-column nav-pills">
                <router-link
                    v-for="account in allAccounts"
                    :key="account.ID"
                    :to="`/${account.ID}/threads/`"
                    tag="a"
                    :title="account.email"
                    class="nav-link account-list-item"
                    :class="{
                        active: account.ID === accountID,
                        'font-weight-bold': account.ID === 'all'
                    }"
                >{{account.email}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		accounts: Array
	},
	methods: {
		newMessage() {
			this.$emit('newMessage', {
				accountID: this.accountID === 'all' ? null : this.accountID
			});
		}
	},
	computed: {
		allAccounts() {
			const allAccount = {
				ID: 'all',
				email: '[ All ]'
			};
			return [allAccount].concat(this.accounts);
		},
		accountID() {
			const accountID = this.$route.params.accountID;
			if (accountID === 'all') {
				return accountID;
			} else {
				return parseInt(accountID);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.account-list-item {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    text-align: center;
}
</style>
