<template>
	<div>
		<TrackerSettingProgress
			name="Getting status"
			:done="stateVal.gotStatus"
		/>
		<TrackerSettingProgress
			name="Setting name"
			:done="stateVal.hasSetName"
		/>
		<TrackerSettingProgress
			name="Setting number 1"
			:done="stateVal.hasSetNumberA"
		/>
		<TrackerSettingProgress
			name="Setting number 2"
			:done="stateVal.hasSetNumberB"
		/>
		<TrackerSettingProgress
			name="Setting number 3"
			:done="stateVal.hasSetNumberC"
		/>
		<TrackerSettingProgress
			name="Setting password"
			:done="stateVal.hasSetPassword"
		/>
		<TrackerSettingProgress
			name="Setting fall detection"
			:done="stateVal.hasSetFallDetection"
		/>
		<TrackerSettingProgress
			name="Setting SMS alert"
			:done="stateVal.hasSetSmsAlert"
		/>
		<TrackerSettingProgress
			name="Setting call alert"
			:done="stateVal.hasSetCallAlert"
		/>
		<TrackerSettingProgress
			name="Setting lock"
			:done="stateVal.hasSetLock"
		/>
	</div>
</template>

<script>
import TrackerSettingProgress from './TrackerSettingProgress.vue';
export default {
	components: {
		TrackerSettingProgress
	},
	props: {
		trackerID: Number,
		settingsState: Object
	},
	data() {
		return {
			stateVal: {...this.settingsState},
			progressEventHandle: null
		};
	},
	methods: {
		onProgress(settingsProgress) {
			this.stateVal = settingsProgress;
		}
	},
	created() {
		const module = window.BootQuery.getModuleInstance('GsmTrackers');
		this.progressEventHandle = module.subscribeWebSocket(
			`gsmTrackers/trackers/${this.trackerID}/settingsProgress`,
			this.onProgress.bind(this)
		);
	},
	beforeDestroy() {
		if (this.progressEventHandle) {
			const module = window.BootQuery.getModuleInstance('GsmTrackers');
			module.unsubscribeWebSocket(this.progressEventHandle);
		}
	}
};
</script>

<style>

</style>
