module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"popover-content-element\" hidden><div>"
    + ((stack1 = container.lambda((depth0 != null ? depth0.htmlDescription : depth0), depth0)) != null ? stack1 : "")
    + "</div></div>\n	<sup class=\"fas fa-info-circle text-info\" style=\"cursor: pointer;\" data-toggle=\"popover\" data-trigger=\"click\" data-popover-content-element=\"true\" data-html=\"true\"></sup>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "	<sup class=\"fas fa-info-circle text-info\" data-toggle=\"tooltip\" title=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.description : depth0), depth0))
    + "\"></sup>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.htmlDescription : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})