module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    return "show";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"card\" data-priority-card-id=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n	<div class=\"card-header p-0\">\n		<button class=\"btn w-100 m-0 text-left "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.expand : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-toggle=\"collapse\" data-target=\"#priorities-collapse-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" type=\"button\">\n			"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n		</button>\n	</div>\n\n	<div id=\"priorities-collapse-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" class=\"collapse "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.expand : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"card-body\">\n            <input type=\"hidden\" name=\"ticketing[priorities]["
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "][$id]\" value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n			<div class=\"form-group row\">\n                <label class=\"col-12 col-form-label  col-md-2 col-form-label control-label text-left text-md-right\" for=\"ticketing[priorities]["
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "][name]\">\n                    <span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"label.name",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n                </label>\n                <div class=\"col-12 col-md-10\">\n                    <input name=\"ticketing[priorities]["
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "][name]\" value=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" class=\"form-control\" autocomplete=\"off\" type=\"text\">\n                </div>\n            </div>\n            <div class=\"form-group row\">\n                <label class=\"col-12 col-form-label  col-md-2 col-form-label control-label text-left text-md-right\">\n                    "
    + alias2(helpers.tr.call(alias3,"label.response_time",{"name":"tr","hash":{},"data":data}))
    + ":\n                </label>\n                <div class=\"col-12 col-md-10\">\n                    "
    + ((stack1 = container.invokePartial(partials["Ticketing.durationInput"],(depth0 != null ? depth0.responseIntervalParts : depth0),{"name":"Ticketing.durationInput","hash":{"prefix":helpers.array_to_path.call(alias3,"ticketing[priorities]",(depth0 != null ? depth0.ID : depth0),"responseTime",{"name":"array_to_path","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class=\"form-group row\">\n                <label class=\"col-12 col-form-label  col-md-2 col-form-label control-label text-left text-md-right\">\n                    "
    + alias2(helpers.tr.call(alias3,"label.resolution_time",{"name":"tr","hash":{},"data":data}))
    + ":\n                </label>\n                <div class=\"col-12 col-md-10\">\n                    "
    + ((stack1 = container.invokePartial(partials["Ticketing.durationInput"],(depth0 != null ? depth0.resolutionIntervalParts : depth0),{"name":"Ticketing.durationInput","hash":{"prefix":helpers.array_to_path.call(alias3,"ticketing[priorities]",(depth0 != null ? depth0.ID : depth0),"resolutionTime",{"name":"array_to_path","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n		</div>\n	</div>\n</div>";
},"usePartial":true,"useData":true})