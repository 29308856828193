<template>
	<div>
		<div v-if="loading" class="text-center">
			<span class="spinner-grow"></span>
		</div>
		<template v-else>
			<SelectField
				label="SIM"
				:options="accounts"
				valueProp="ID"
				textProp="displayName"
				v-model="values.smsAccountID"
				:required="true"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>

			<TextField
				v-if="isNew"
				label="Broj"
				name="phoneNumber"
				v-model="values.phoneNumber"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<div v-else class="form-group row">
				<div class="col-4">
					<strong>Broj:</strong>
				</div>
				<div class="col-8 text-left">
					{{formattedPhoneNumber}}
				</div>
			</div>

			<TextField
				label="Naziv"
				name="name"
				v-model="values.name"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<SelectField
				label="Osoba"
				name="personID"
				v-model="values.personID"
				valueProp="ID"
				textProp="fullName"
				:options="peopleOptions"
				:onSearch="searchPeople"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>

			<TextField
				label="Broj 1"
				name="numberA"
				v-model="values.numberA"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<TextField
				label="Broj 2"
				name="numberB"
				v-model="values.numberB"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<TextField
				label="Broj 3"
				name="numberC"
				v-model="values.numberC"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<TextField
				label="Password"
				name="password"
				v-model="values.password"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<CheckboxField
				label="Detekcija pada"
				name="fallDetection"
				v-model="values.fallDetection"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<CheckboxField
				label="Uzbuna SMS-om"
				name="smsAlert"
				v-model="values.smsAlert"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<CheckboxField
				label="Uzbuna pozivom"
				name="callAlert"
				v-model="values.callAlert"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
			<CheckboxField
				label="Zakljucaj"
				name="lock"
				v-model="values.lock"
				inline
				:labelSize="4"
				:inputSize="8"
				:disabled="disabled"
			/>
		</template>
	</div>
</template>

<script>
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';
import TextField from 'BootQuery/Assets/js/forms/fields/Text.vue';
import CheckboxField from 'BootQuery/Assets/js/forms/fields/Checkbox.vue';

import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { intersection } from 'lodash';

export default {
	components: {
		SelectField,
		TextField,
		CheckboxField
	},
	props: {
		isNew: Boolean,
		trackerID: Number,
		tracker: Object,
		disabled: Boolean
	},
	data() {
		return {
			loading: true,
			accounts: null,
			settings: null,
			values: null,
			formattedPhoneNumber: null,
			peopleOptions: []
		};
	},
	computed: {
		canSubmit() {
			if (!this.values) {
				return false;
			}
			if (!this.values.smsAccountID) {
				return false;
			}
			if (this.isNew && this.values.phoneNumber.length === 0) {
				return false;
			}

			if (!this.values.password.match(/^[0-9]{6}$/)) {
				return false;
			}

			return true;
		}
	},
	methods: {
		fetchData() {
			this.initSettings();
			Promise.all([
				this.fetchAccounts(),
				this.initPeople()
			]).then(() => {
				if (this.isNew) {
					this.values.smsAccountID = this.accounts[0].ID;
				}
				this.loading = false;
			});
		},
		async fetchAccounts() {
			const module = window.BootQuery.getModuleInstance('GsmTrackers');
			const accounts = await module.smsAccounts();
			this.accounts = accounts.map(account => {
				account.displayName = account.phoneNumber;
				if (account.name) {
					account.name = `${account.name} (${account.phoneNumber})`;
				}
				return account;
			});
		},
		initSettings() {
			if (this.isNew) {
				this.settings = {};
				this.values = {
					smsAccountID: null,
					phoneNumber: '',
					name: '',
					personID: null,
					numberA: '',
					numberB: '',
					numberC: '',
					password: '123456',
					fallDetection: true,
					smsAlert: true,
					callAlert: true,
					lock: false
				};
			} else {
				this.settings = this.tracker.settings;
				this.settings.personID = this.tracker.personID;
				this.settings.smsAccountID = this.tracker.smsAccountID;
				// Make a copy so we still have original values
				this.values = { ...this.tracker.settings };
				this.values.smsAccountID = this.tracker.smsAccountID;
				this.values.personID = this.tracker.personID;
				this.formattedPhoneNumber = this.tracker.formattedPhoneNumber;
			}
		},
		async searchPeople(search, loading) {
			loading(true);
			this.peopleOptions = await Api.get('/api/phonebook/people', {
				search,
				limit: 30
			});
			loading(false);
		},
		async initPeople() {
			this.peopleOptions = await Api.get('/api/phonebook/people', {
				limit: 30
			});
			if (this.values.personID) {
				const hasOwn = this.peopleOptions.some(
					person => person.ID === this.values.personID
				);
				if (!hasOwn) {
					const selected = await Api.get(
						`/api/phonebook/people/${this.values.personID}`
					);
					this.peopleOptions.push(selected);
				}
			}
		},
		getChanges() {
			const prev = {...this.settings};
			const current = {...this.values};
			if (!current.personID) {
				current.personID = null;
			}
			if (this.isNew) {
				// Just send everything when adding new tracker
				return current;
			} else {
				// Diff changes against previous ones when editing existing
				// tracker, because saving this stuff is slow
				const allKeys = intersection(
					Object.keys(prev),
					Object.keys(current)
				);
				return allKeys.reduce((diff, key) => {
					if (prev[key] !== current[key]) {
						diff[key] = current[key];
					}

					return diff;
				}, {});
			}
		}
	},
	created() {
		this.fetchData();
	},
	watch: {
		canSubmit: {
			immediate: true,
			handler(canSubmit) {
				this.$emit('update:canSubmit', canSubmit);
			}
		}
	}
};
</script>

<style>
</style>
