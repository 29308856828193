<template>
	<button :id="`gsm-${context}-tracker-${trackerID}-call-btn`" :class="buttonClass">
		<span class="fas fa-phone"></span>
		<bPopover
			:target="`gsm-${context}-tracker-${trackerID}-call-btn`"
			:trigger="['click', 'blur']"
			placement="left"
		>
			<a :href="`tel:${phoneNumber}`" @click.prevent="dial" class="btn btn-success btn-block">
				<span class="fas fa-phone"></span>
				Nazovi
			</a>
		</bPopover>
	</button>
</template>

<script>
import bPopover from 'bootstrap-vue/es/components/popover/popover';

export default {
	components: {
		bPopover
	},
	props: {
		trackerID: {
			type: Number,
			required: true
		},
		phoneNumber: {
			type: String,
			required: true
		},
		context: {
			type: String,
			required: true
		},
		buttonClass: {
			type: String,
			required: true
		}
	},
	methods: {
		dial() {
			const dialer = window.BootQuery.getModuleInstance('Dialer');
			dialer.dial(this.phoneNumber);
		}
	}
};
</script>

<style>

</style>
