import Field from './field';
import Select from './select';
import List from './list';

export {Field, Select, List};

export function constructField(optionsOrType) {
	let options = optionsOrType;
	if (typeof(optionsOrType) === 'string') {
		options = {
			type: optionsOrType,
			new: true
		};
	}

	let field = null;
	switch(options.type) {
	case 'select':
		field = new Select(options);
		break;
	case 'list':
		field = new List(options);
		break;
	default:
		field = new Field(options);
		break;
	}

	return field;
}