import {clone, endsWith, reduce, filter, isObjectLike, mapKeys, merge, lowerFirst} from 'lodash';
import urlParse from 'url-parse';

$.fn.findElement = function(selector) {
	return this.filter(selector).add(this.find(selector));
};

$.fn.contenteditableChangeEvent = function() {
	this.each(function(index, element) {
		$(element).on('focus', function() {
			$(element).data('contenteditablePrev', $(element).html());
		}).on('blur', function() {
			if ($(element).html() !== $(element).data('contenteditablePrev')) {
				$(element).trigger('change');
			}
		});
	});
	return this;
};

$.fromHTML = function(html) {
	return $(parseHTML(html));
};

$.render = function(template, data) {
	return $.fromHTML(handlebarsRender(template, data).trim());
};

$.fn.ev = function() {
	let args = Array.prototype.slice.call(arguments); // Arguments object to array
	let offArgs = args.slice(0, -1);
	return this
		.off.apply(this, offArgs)
		.on.apply(this, args);
};

// From http://stackoverflow.com/a/26658116
export function sessionID() {
	return /SESS\w*ID=([^;]+)/i.test(document.cookie) ? RegExp.$1 : false;
}


export function htmlDecode(input){
	var e = document.createElement('div');
	e.innerHTML = input;
	return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export function ucfirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function secToTime(sec)
{
	var sec_num = parseInt(sec, 10); // don't forget the second param
	var hours   = Math.floor(sec_num / 3600);
	var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	var seconds = sec_num - (hours * 3600) - (minutes * 60);

	if (hours   < 10) {hours   = '0' + hours;}
	if (minutes < 10) {minutes = '0' + minutes;}
	if (seconds < 10) {seconds = '0' + seconds;}
	// var time    = hours+':'+minutes+':'+seconds;
	var time    = minutes+':'+seconds;
	return time;
}

export function parseQueryString(queryStr) {
	return urlParse.qs.parse(queryStr);
}

export function parseURL(urlStr) {
	const parsed = urlParse(urlStr);
	parsed.paramsObj = parseQueryString(parsed.query);
	return parsed;
}

export function getParams(url) {
	return parseURL(url).paramsObj;
}

export function getURLComponents(url) {
	if (!url) {
		url = document.location.href;
	}
	let parsedURL = parseURL(url);
	let pathParts = filter(parsedURL.pathname.split('/'));

	return {
		controller: pathParts[0],
		method: pathParts.slice(1).join('/'),
		parameters: parsedURL.paramsObj,
		hash: parsedURL.hash
	};
}

export function isFunction(functionToCheck) {
	var getType = {};
	return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

export function formatTime(time)
{
	var hours   = Math.floor(time / 3600);
	var mins    = Math.floor(time / 60);
	var secs    = Math.floor(time - 60 * mins);

	if(hours < 10) hours = '0' + hours;
	if(mins < 10) mins   = '0' + mins;
	if(secs < 10) secs   = '0' + secs;

	return hours + ':' + mins + ':' + secs;
}

export function hasProperty(propertyHolder, propertyString) {
	if (typeof(propertyHolder) == 'function' || typeof(propertyHolder) == 'object') {
		var obj = propertyHolder;
		var parts = propertyString.split('.');
		for (var i = 0; i < parts.length; i++) {
			if (typeof(obj[parts[i]]) != 'undefined') {
				obj = obj[parts[i]];
			} else {
				break;
			}
		}

		if (i === parts.length) {
			return true;
		}
	}

	return false;
}

export function getOwnProperty(obj, path) {
	if (typeof(obj) !== 'function' && typeof(obj) !== 'object') {
		return;
	}
	var parts = path;
	if (typeof(parts) === 'string') {
		parts = path.split('.');
	}
	for (var i = 0; i < parts.length; i++) {
		var part = parts[i];
		if (part in obj) {
			obj = obj[part];
		} else {
			return;
		}
	}
	return obj;
}

export function setOwnProperty(obj, path, value) {
	if (typeof(obj) !== 'function' && typeof(obj) !== 'object') {
		return;
	}
	var parts = path;
	if (typeof(parts) === 'string') {
		parts = path.split('.');
	}
	var lastPart = parts.pop();
	var currentObj = obj;
	for (var i = 0; i < parts.length; i++) {
		var part = parts[i];
		if (!currentObj[part]) {
			currentObj[part] = {};
		}
		currentObj = currentObj[part];
	}
	currentObj[lastPart] = value;
}

export function generateQueryString() {
	var queryString = '';

	queryString += '/' + lowerFirst(window.Bootstrap.bootquery.controller);
	queryString += '/' + lowerFirst(window.Bootstrap.bootquery.method);
	queryString += '/?' + window.Bootstrap.bootquery.paramstr;

	return queryString;
}

// Stolen from: http://stackoverflow.com/a/38760984
export function microtime(getAsFloat) {
	var s, now, multiplier;

	if(typeof performance !== 'undefined' && performance.now) {
		now = (performance.now() + performance.timing.navigationStart) / 1000;
		multiplier = 1e6; // 1,000,000 for microseconds
	} else {
		now = (Date.now ? Date.now() : new Date().getTime()) / 1000;
		multiplier = 1e3; // 1,000
	}

	// Getting microtime as a float is easy
	if(getAsFloat) {
		return now;
	}

	// Dirty trick to only get the integer part
	s = now | 0;
	return (Math.round((now - s) * multiplier ) / multiplier ) + ' ' + s;
}

export function toUnderscoreCase(camelCase) {
	return camelCase.replace(/\.?([A-Z]+)/g, function (x, y) {
		return '_' + y.toLowerCase();
	}).replace(/^_/, '');
}

export function splitNestedInputName(inputName) {
	var nameParts = inputName.split(/[[\]]{1,2}/);
	if (nameParts.length > 1) {
		nameParts.splice(-1, 1);
	}
	return nameParts;
}

export function arrayToPath(args) {
	var flat = [];
	$.each(args, function(i, arg) {
		flat = flat.concat(arg);
	});
	var firstArg = flat.shift();
	var wrapped = $.map(flat, function(arg) {
		return '[' + arg + ']';
	});
	wrapped.unshift(firstArg);
	return wrapped.join('');
}

export function flattenFormData(formData) {
	return reduce(formData, function(result, value, key) {
		if (isObjectLike(value)) {
			var flat = flattenFormData(value);
			var splitKey = splitNestedInputName(key);
			flat = mapKeys(flat, function(value, flatKey) {
				var fullPath = splitKey.concat(splitNestedInputName(flatKey));
				return arrayToPath(fullPath);
			});
			result = merge(result, flat);
		} else {
			result[key] = value;
		}
		return result;
	}, {});
}

export function intersectionFromStart(arr1, arr2) {
	var shorterLength = Math.min(arr1.length, arr2.length);
	var lastMatched = -1;
	for (var i = 0; i < shorterLength; i++) {
		if (arr1[i] !== arr2[i]) {
			break;
		}
		lastMatched = i;
	}
	var ret = {
		commonMatch: [],
		arr1Remaining: clone(arr1),
		arr2Remaining: clone(arr2)
	};

	if (lastMatched !== -1) {
		ret.commonMatch = arr1.slice(0, lastMatched + 1);
		ret.arr1Remaining = arr1.slice(lastMatched + 1);
		ret.arr2Remaining = arr2.slice(lastMatched + 1);
	}
	return ret;
}

export function parsePartialName(partialName) {
	var parsed = {
		name: partialName,
		module: null,
		moduleIsMandatory: true
	};
	var parts = partialName.split('.');
	if (parts.length == 2) {
		if (endsWith(parts[0], '?')) {
			parsed.module = parts[0].substr(0, parts[0].length - 1);
			parsed.moduleIsMandatory = false;
		} else {
			parsed.module = parts[0];
		}
		parsed.name = parts[1];
	}
	return parsed;
}

export function popoverForTrigger(triggerEl) {
	return $($(triggerEl).data('bs.popover').tip);
}

export function parseHTML(html) {
	let template = document.createElement('template');
	template.innerHTML = html;
	return template.content.childNodes;
}

export function wsUrlBase() {
	const siteProto = window.location.protocol.match(/(.+):/)[1];
	const wsProto = siteProto === 'https' ? 'wss' : 'ws';
	const wsHost = window.location.host;
	return `${wsProto}://${wsHost}`;
}

export function formatFileSize(sizeBytes) {
	// If we only use this for mails, this will never have to be updated.
	// We still have 20MB as standard attachment limits today, what the heck
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	if (!sizeBytes) {
		// A special case for empty files. I'm sure someone will try to
		// upload one, they always do that kind of stuff
		return '0B';
	}

	// Math.log is a natural logarithm, this will get it to base 1000
	const unitIndex = Math.floor(Math.log(sizeBytes) / Math.log(1000));
	const unit = units[unitIndex] || 'WTF?';
	return (sizeBytes / Math.pow(1000, unitIndex)).toFixed(1) + unit;
}