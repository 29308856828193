module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<th class=\"rowselect-column\">\n	<div class=\"custom-control custom-checkbox text-center\">\n		<input type=\"checkbox\" class=\"custom-control-input checkbox-row-select-all\" id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-rowselect-all\">\n		<label class=\"custom-control-label\" for=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-rowselect-all\">&zwnj;</label>\n	</div>\n</th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.visible : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sortable : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<th style=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.style : depth0), depth0))
    + "\" class=\""
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["class"] : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "		</th>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "sort-th";
},"8":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0["class"] : depth0), depth0));
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,(depths[1] != null ? depths[1].sortlessParams : depths[1]),{"name":"encode_query_string","hash":{},"data":data}))
    + "&"
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "Sortby="
    + alias2(alias1((depth0 != null ? depth0.column : depth0), depth0))
    + "&"
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "Sortdir="
    + alias2(alias1((depth0 != null ? depth0.sortdir : depth0), depth0))
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.parameters : depth0)) != null ? stack1.limit : stack1), depth0),{"name":"parameters.limit","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&"
    + alias2(helpers.encode_filters.call(alias3,(depths[1] != null ? depths[1].filters : depths[1]),(depths[1] != null ? depths[1].tableName : depths[1]),{"name":"encode_filters","hash":{},"data":data}))
    + "\" class=\"sort\" data-target=\""
    + ((stack1 = helpers["if"].call(alias3,(depths[1] != null ? depths[1].targetSelector : depths[1]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\">\n					"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.sorticon : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&limit="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.parameters : depth0)) != null ? stack1.limit : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return container.escapeExpression(container.lambda((depths[1] != null ? depths[1].targetSelector : depths[1]), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    return ".paginated";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<span class=\""
    + alias2(alias1((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    return "						&nbsp;<span class=\"fa fa-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.sorticon : depth0), depth0))
    + " sorticon\"></span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "				"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<th style=\""
    + alias2(alias1((depth0 != null ? depth0.style : depth0), depth0))
    + "\" class=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0["class"] : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.unless.call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n		</th>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.icon : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "				<span class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>&nbsp;\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rowCheckbox : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.columns : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true})