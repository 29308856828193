<template>
	<div class="card">
		<div class="card-header text-center d-flex" :title="phoneNumber">
			<div>{{displayName}}</div>
			<div class="ml-auto">
				<SettingsButton
					:trackerID="trackerID"
					buttonClass="btn btn-link text-body btn-sm"
				/>
				<CallButton
					context="tracker"
					:trackerID="trackerID"
					:phoneNumber="phoneNumber"
					buttonClass="btn btn-link text-body btn-sm"
				/>
				<PersonButton
					context="tracker"
					:trackerID="trackerID"
					:personID="personID"
					buttonClass="btn btn-link text-body btn-sm"
				/>
				<LocationButton
					context="tracker"
					:trackerID="trackerID"
					buttonClass="btn btn-link text-body btn-sm"
				/>
			</div>
		</div>
		<div class="card-body">
			<h5>
				{{displayName}}
				<template v-if="formattedPhoneNumber">({{formattedPhoneNumber}})</template>
			</h5>
		</div>
	</div>
</template>

<script>
import SettingsButton from './actions/SettingsButton.vue';
import LocationButton from './actions/LocationButton.vue';
import CallButton from './actions/CallButton.vue';
import PersonButton from './actions/PersonButton.vue';

export default {
	components: {
		SettingsButton,
		LocationButton,
		CallButton,
		PersonButton
	},
	props: {
		trackerID: Number,
		trackerName: String,
		personName: String,
		personID: Number,
		phoneNumber: String,
		formattedPhoneNumber: String
	},
	computed: {
		displayName() {
			if (this.personID) {
				return this.personName;
			} else if (this.trackerName) {
				return this.trackerName;
			} else {
				return this.phoneNumber;
			}
		}
	}
};
</script>

<style>
</style>
