module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression;

  return ((stack1 = alias1((depth0 != null ? depth0.currentHtml : depth0), depth0)) != null ? stack1 : "")
    + "\n<hr>\n<div class=\"clickvox-quote-wrapper\">\n    On "
    + alias3(helpers.format_date.call(alias2,((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.date : stack1),helpers.tr.call(alias2,"format.datetime_s",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + " "
    + alias3(alias1(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.fromMailAddress : stack1), depth0))
    + " wrote:\n    <br>\n    <blockquote cite=\"mid:"
    + alias3(alias1(((stack1 = (depth0 != null ? depth0.previous : depth0)) != null ? stack1.messageID : stack1), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? depth0.previousHtml : depth0), depth0)) != null ? stack1 : "")
    + "</blockquote>\n</div>";
},"useData":true})