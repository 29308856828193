<template>
    <div class="html-message-content" ref="htmlContent" v-html="html"></div>
</template>

<script>

function constructButtonEl() {
	return document.createRange().createContextualFragment(`
        <button type="button" class="btn btn-outline-primary btn-sm" style="line-height: 1;">
            <span class="fa fa-ellipsis-h"></span>
        </button>
    `);
}

export default {
	props: {
		html: String
	},
	mounted() {
		const rootEl = this.$refs.htmlContent;
		const nodes = rootEl.querySelectorAll('.clickvox-quote-wrapper');
		nodes.forEach(el => {
			const btnEl = constructButtonEl();
			btnEl.firstElementChild.addEventListener('click', ev => {
				ev.preventDefault();
				$(el).collapse('toggle');
			});
			el.parentNode.insertBefore(btnEl, el);
			el.classList.add('collapse');
			$(el).collapse('hide');
		});
	}
};
</script>

<style lang="scss">
.html-message-content {
	overflow: hidden;
}
</style>
