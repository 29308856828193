module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<a href=\"/"
    + alias2(alias1((depth0 != null ? depth0.controller : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.method : depth0), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.params : depth0),{"name":"encode_query_string","hash":{},"data":data}))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.text : depth0), depth0))
    + "</a>\n";
},"useData":true})