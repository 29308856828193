module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				"
    + ((stack1 = container.invokePartial(partials["Mails.mailAccountListItem"],depth0,{"name":"Mails.mailAccountListItem","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"container-fluid py-3\">\n	<div class=\"mb-3 clearfix\">\n		<h4>E-mail accounts: </h4>\n		<ul class=\"list-group mail-accounts-list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.accounts : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</ul>\n		<button type=\"button\" class=\"btn btn-success float-right my-3\" data-add-mail-account>\n			<span class=\"fa fa-plus\"></span> "
    + container.escapeExpression(helpers.tr.call(alias1,"button.add_account",{"name":"tr","hash":{},"data":data}))
    + "\n		</button>\n	</div>\n\n	<div class=\"mb-3\" data-form=\"displaySettings\">\n		<h4>Display: </h4>\n		"
    + ((stack1 = container.invokePartial(partials.form,((stack1 = (depth0 != null ? depth0.forms : depth0)) != null ? stack1.displaySettings : stack1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	</div>\n</div>";
},"usePartial":true,"useData":true})