<template>
    <div class="customer-list d-flex flex-column">
        <div class="customer-list-pane">
            <div class="customer-list-label bg-light border-bottom flex-shrink px-2 py-2 small text-secondary">Aktivni chatovi</div>
            <div class="list-group list-group-flush">
                <ActiveChatItem
                    v-for="chat in currentCustomers"
                    :key="chat.ID"
                    @click.native.prevent="setActiveItem(chat.ID)"
                    :isSelected="activeItem === chat.ID"
                    :isFinished="chat.state === 'ended'"
                    :messages="chat.messages"
                    :customer="chat.customer"
                    :chatID="chat.ID"
                />
            </div>
        </div>
        <div class="customer-list-pane">
            <div class="customer-list-label bg-light border-bottom flex-grow px-2 py-2 small text-secondary">Na cekanju</div>
            <div class="list-group list-group-flush">
                <WaitingChatItem
                    v-for="chat in waitingCustomers"
                    :key="chat.ID"
                    @click.native.prevent="setActiveItem(chat.ID)"
                    :isSelected="activeItem === chat.ID"
                    :messages="chat.messages"
                    :customer="chat.customer"
                    :chatID="chat.ID"
                    :waitStart="chat.waitStart"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ActiveChatItem from './ActiveChatItem.vue';
import WaitingChatItem from './WaitingChatItem.vue';
export default {
	components: {
		ActiveChatItem,
		WaitingChatItem
	},
	props: {
		currentCustomers: {
			type: Array,
			required: true
		},
		waitingCustomers: {
			type: Array,
			default() {
				return [];
			}
		},
		activeItem: {}
	},
	methods: {
		lastMessage(messages) {
			if (!messages.length) {
				return null;
			}

			return messages[messages.length - 1];
		},
		setActiveItem(chatID) {
			console.log('Update active item to: ', chatID);
			this.$emit('update:activeItem', chatID);
		}
	}
};
</script>

<style lang="scss" scoped>
.customer-list-label {
  font-weight: bold;
}

.customer-list {
  height: 100%;
}

.customer-list-pane {
  // min-height: 110px;
  display: flex;
  flex-direction: column;

  > .list-group {
    overflow-y: auto;

    > .list-group-item:first-child {
      border-top: none;
    }
  }
}
</style>
