module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + " target=\"_blank\" class=\"noparse dropdown-item reporting-print-btn\">\n            <span class=\"fa fa-print\"></span>\n            &nbsp;Print\n        </a>\n        <div class=\"dropdown-divider\"></div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.url : depth0), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "href=\"/reporting/report/?controller="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "&method="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "&template="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.template), depth0))
    + "&format=html&table="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.table), depth0))
    + "&"
    + ((stack1 = helpers["with"].call(alias3,helpers.lookup.call(alias3,((stack1 = (data && data.root)) && stack1.tables),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.table),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.filters : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "&"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.parameters : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "filters%5B"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.table), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "%5D="
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "&";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "parameters%5B"
    + alias2(alias1((data && data.key), depth0))
    + "%5D="
    + alias2(alias1(depth0, depth0))
    + "&";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <a "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + " target=\"_blank\" class=\"noparse dropdown-item\">\n            <span class=\""
    + alias2(alias1((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>\n            &nbsp;"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n        </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "href=\"/reporting/report/?controller="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "&method="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "&template="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.template), depth0))
    + "&format="
    + alias2(alias1((depth0 != null ? depth0.format : depth0), depth0))
    + "&table="
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.table), depth0))
    + "&"
    + ((stack1 = helpers["with"].call(alias3,helpers.lookup.call(alias3,((stack1 = (data && data.root)) && stack1.tables),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.table),{"name":"lookup","hash":{},"data":data}),{"name":"with","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"btn-group\">\n    <button type=\"button\" class=\"btn btn-secondary dropdown-toggle\" data-toggle=\"dropdown\">\n        <span class=\"fa fa-chart-pie\"></span>\n    </button>\n    <div class=\"dropdown-menu\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Reporting)) && stack1.canPrint),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Reporting)) && stack1.options),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true})