module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"row queue-statistics\">\n	<div class=\"col-3\">\n		<div class=\"card mb-3\">\n			<div class=\"card-header\">"
    + alias2(helpers.tr.call(alias1,"label.waiting_call_count",{"name":"tr","hash":{},"data":data}))
    + "</div>\n			<div class=\"card-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: rgb(85, 85, 85);\" id=\"waiting-counter\">\n				"
    + alias2(alias3((depth0 != null ? depth0.WaitingCallCount : depth0), depth0))
    + "\n			</div>\n			<div class=\"card-footer\" style=\"padding: 0;\">\n				<table class=\"table table-striped table-bordered\" style=\"margin-bottom: 0;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</table>\n			</div>\n		</div>\n	</div>\n\n	<div class=\"col-3\">\n		<div class=\"card\">\n			<div class=\"card-header\">"
    + alias2(helpers.tr.call(alias1,"label.total_calls",{"name":"tr","hash":{},"data":data}))
    + "</div>\n			<div class=\"card-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: rgb(85, 85, 85);\" id=\"calls-counter\">\n				"
    + alias2(alias3((depth0 != null ? depth0.TotalCallCount : depth0), depth0))
    + "\n			</div>\n			<div class=\"card-footer\" style=\"padding: 0;\">\n				<table class=\"table table-striped table-bordered\" style=\"margin-bottom: 0;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</table>\n			</div>\n		</div>\n	</div>\n\n	<div class=\"col-3\">\n		<div class=\"card\">\n			<div class=\"card-header\">"
    + alias2(helpers.tr.call(alias1,"label.missed_calls",{"name":"tr","hash":{},"data":data}))
    + "</div>\n			<div class=\"card-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: #ee5f5b;\" id=\"missed-counter\">\n				"
    + alias2(alias3((depth0 != null ? depth0.MissedCallCount : depth0), depth0))
    + "\n			</div>\n			<div class=\"card-footer\" style=\"padding: 0;\">\n				<table class=\"table table-striped table-bordered\" style=\"margin-bottom: 0;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</table>\n			</div>\n		</div>\n	</div>\n\n	<div class=\"col-3\">\n		<div class=\"card\">\n			<div class=\"card-header\">"
    + alias2(helpers.tr.call(alias1,"label.efficiency",{"name":"tr","hash":{},"data":data}))
    + "</div>\n			<div class=\"card-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: rgb(85, 85, 85);\" id=\"efficiency-display\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.TotalCallCount : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.TotalCallCount : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n			<div class=\"card-footer\" style=\"padding: 0;\">\n				<table class=\"table table-striped table-bordered\" style=\"margin-bottom: 0;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depths[1] != null ? depths[1].result : depths[1])) != null ? stack1.queues : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</table>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div class=\"row queue-progresses\">\n	<div class=\"col-6\">\n		<div class=\"card\">\n			<div class=\"card-header\">\n				"
    + alias2(helpers.tr.call(alias1,"label.longest_waiting_time",{"name":"tr","hash":{},"data":data}))
    + "\n			</div>\n			<div class=\"card-body\">\n				<div class=\"progress\"  style=\"color: #000;\">\n                    <div class=\"progress-label\" style=\"font-weight: bold;\">\n						"
    + alias2(alias3((depth0 != null ? depth0.LongestWaitTime : depth0), depth0))
    + "\n                    </div>\n                    <div class=\"progress-bar progress-bar-success\" role=\"progressbar\" style=\"width: "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MaxWaitTimePercentage : stack1), depth0))
    + "%;\" id=\"longest-wait-time\">\n                    </div>\n          		</div>\n			</div>\n		</div>\n	</div>\n\n	<div class=\"col-6\">\n		<div class=\"card\">\n			<div class=\"card-header\">"
    + alias2(helpers.tr.call(alias1,"label.average_waiting_time",{"name":"tr","hash":{},"data":data}))
    + "</div>\n			<div class=\"card-body\">\n\n				<div class=\"progress\">\n                    <div class=\"progress-label\" style=\"font-weight: bold;\">\n						"
    + alias2(alias3((depth0 != null ? depth0.AverageWaitTime : depth0), depth0))
    + "\n                    </div>\n                    <div class=\"progress-bar progress-bar-success\" role=\"progressbar\" style=\"width: "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.AverageWaitTimePercentage : stack1), depth0))
    + "%;\" id=\"average-wait-time\">\n                    </div>\n          		</div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isObserved : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<tr>\n						<th class=\"text-right\" style=\"vertical-align: middle;\">\n							"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + ":\n						</th>\n						<td id=\"waiting-counter-"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\" style=\"width: 30%;\">\n							"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.WaitingCallCount : stack1), depth0))
    + "\n						</td>\n					</tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "					<tr>\n						<th class=\"text-center text-muted\" style=\"vertical-align: middle;\">\n							"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_queues_observed",{"name":"tr","hash":{},"data":data}))
    + "\n						</th>\n					</tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isObserved : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<tr>\n						<th class=\"text-right\" style=\"vertical-align: middle;\">\n							"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + ":\n						</th>\n						<td id=\"calls-counter-"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\" style=\"width: 30%;\">\n							"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.TotalCallCount : stack1), depth0))
    + "\n						</td>\n					</tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isObserved : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<tr>\n						<th class=\"text-right\" style=\"vertical-align: middle;\">\n							"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + ":\n						</th>\n						<td id=\"missed-counter-"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\" style=\"width: 30%;\">\n							"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MissedCallCount : stack1), depth0))
    + "\n						</td>\n					</tr>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "					"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.Efficiency : depth0), depth0))
    + "%\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "				-\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isObserved : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "					<tr>\n						<th class=\"text-right\" style=\"vertical-align: middle;\">\n							"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + ":\n						</th>\n						<td id=\"efficiency-display-"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\" style=\"width: 30%;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.TotalCallCount : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "						</td>\n					</tr>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "								"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.Efficiency : stack1), depth0))
    + "%\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "								-\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.statistics : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true})