export function processMessage(message) {
	message.date = new Date(message.date);
	return message;
}

export function processChat(chat) {
	chat.lastMessage = processMessage(chat.lastMessage);
	if (chat.messages) {
		chat.messages = chat.messages.map(processMessage);
	}
	return chat;
}