module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<span>\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(26, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "	"
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</span>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"popover-content-element\" hidden>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.firstName : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "	</div>\n\n	<a href=\"#\"\n		class=\"phone-number noparse\"\n		data-toggle=\"popover\"\n		data-placement=\"right\"\n		data-container=\"body\"\n		data-trigger=\"click\"\n		data-html=\"true\"\n		data-popover-content-element=\"true\"\n		data-original-title=\""
    + ((stack1 = container.invokePartial(partials.numberDetailsPopoverTitle,depth0,{"name":"numberDetailsPopoverTitle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\"\n		tabindex=\"1\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "	</a>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "			"
    + ((stack1 = container.invokePartial(partials.contactOptionsPopover,depth0,{"name":"contactOptionsPopover","hash":{"tableName":(depths[1] != null ? depths[1].tableName : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "			"
    + ((stack1 = container.invokePartial(partials.numberOptionsPopover,depth0,{"name":"numberOptionsPopover","hash":{"tableName":(depths[1] != null ? depths[1].tableName : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "				"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "				"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.companyID : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.personID : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<span class=\"fa fa-briefcase\"></span>\n					"
    + alias2(alias1((depth0 != null ? depth0.firstName : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.lastName : depth0), depth0))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-briefcase\"></span> "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.companyName : depth0), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.personID : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<span class=\"fa fa-user\"></span>\n					"
    + alias2(alias1((depth0 != null ? depth0.firstName : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.lastName : depth0), depth0))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return "						"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "						"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0));
},"29":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.phoneNumber : depth0), depth0));
},"31":function(container,depth0,helpers,partials,data) {
    return ", ";
},"33":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.context : depth0),{"name":"with","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.program(74, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<div class=\"popover-content-element\" hidden>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fullName : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.program(38, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "		</div>\n\n		<a href=\"#\"\n			class=\"phone-number noparse\"\n			data-toggle=\"popover\"\n			data-placement=\"right\"\n			data-container=\"body\"\n			data-trigger=\"click\"\n			data-html=\"true\"\n			data-popover-content-element=\"true\"\n			data-original-title=\""
    + ((stack1 = container.invokePartial(partials.numberDetailsPopoverTitle,depth0,{"name":"numberDetailsPopoverTitle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\"\n			tabindex=\"1\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.program(49, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "		</a>\n";
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "				"
    + ((stack1 = container.invokePartial(partials.contactOptionsPopover,depth0,{"name":"contactOptionsPopover","hash":{"tableName":(depths[1] != null ? depths[1].tableName : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.companyName : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.program(41, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "					"
    + ((stack1 = container.invokePartial(partials.contactOptionsPopover,depth0,{"name":"contactOptionsPopover","hash":{"tableName":(depths[1] != null ? depths[1].tableName : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "					"
    + ((stack1 = container.invokePartial(partials.numberOptionsPopover,depth0,{"name":"numberOptionsPopover","hash":{"tableName":(depths[1] != null ? depths[1].tableName : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-phone\"></span>\n					"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.phoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fa fa-phone\"></span>\n						"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\n";
},"49":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.companyID : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.program(55, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.personID : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fa fa-briefcase\"></span>\n						"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullName : depth0), depth0))
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fa fa-building\"></span> "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.companyName : depth0), depth0))
    + "\n";
},"55":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.personID : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.program(61, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"56":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].queue : depths[1]),{"name":"if","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.program(59, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "						"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullName : depth0), depth0))
    + "\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "							<span class=\"fa fa-user-circle\"></span>\n";
},"59":function(container,depth0,helpers,partials,data) {
    return "							<span class=\"fa fa-user\"></span>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.userID : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(68, data, 0),"data":data})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "							<span class=\"fa fa-user-circle\"></span>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullName : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(65, data, 0),"data":data})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    return "								"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullName : depth0), depth0))
    + "\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    return "								"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.username : depth0), depth0))
    + "\n							";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data})) != null ? stack1 : "");
},"69":function(container,depth0,helpers,partials,data) {
    return "								<span class=\"fa fa-phone\"></span>\n								"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.phoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"72":function(container,depth0,helpers,partials,data) {
    return "									<span class=\"fa fa-phone\"></span>\n									"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fullPhoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"77":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.personID : stack1),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.program(80, data, 0),"data":data})) != null ? stack1 : "")
    + "\n	<a href=\"#\">\n		<span class=\"fa fa-sitemap\"></span>&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.queue : depth0), depth0))
    + "\n	</a>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.personID : stack1),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.program(86, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"78":function(container,depth0,helpers,partials,data) {
    return "(";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.userID : stack1),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.program(81, data, 0),"data":data})) != null ? stack1 : "");
},"81":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.potentials : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.program(82, data, 0),"data":data})) != null ? stack1 : "");
},"82":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.phoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"84":function(container,depth0,helpers,partials,data) {
    return ")";
},"86":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.userID : stack1),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.program(87, data, 0),"data":data})) != null ? stack1 : "");
},"87":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.potentials : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.program(88, data, 0),"data":data})) != null ? stack1 : "");
},"88":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.phoneNumber : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.potentials : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.potentials : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.queue : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})