<template>
    <b-progress height="2rem" :max="max">
        <b-progress-bar :value="value" :variant="(value / max > 0.75) ? 'danger' : 'primary'">
            {{label}}
        </b-progress-bar>
    </b-progress>
</template>

<script>
import bProgress from 'bootstrap-vue/es/components/progress/progress';
import bProgressBar from 'bootstrap-vue/es/components/progress/progress-bar';

export default {
    components: {
        bProgress,
        bProgressBar
    },
    props: {
        value: Number,
        max: Number,
        label: String
    }
}
</script>

<style>

</style>
