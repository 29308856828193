<template>
	<div class="row">
		<div class="col">
			<strong>
				{{name}}:
			</strong>
		</div>
		<div class="col-auto">
			<span v-if="done" class="fas fa-check-circle text-success"></span>
			<div v-else class="spinner-border spinner-border-sm"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: String,
		done: Boolean
	}
};
</script>

<style>

</style>
