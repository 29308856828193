module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"col-12\" data-field-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n    <div class=\"row no-gutters\">\n        <div class=\"col-auto px-1 py-1\">\n            <input type=\"hidden\" name=\"use_in_campaign\" value=\"false\">\n            <div class=\"custom-control custom-checkbox\">\n                <input type=\"checkbox\" class=\"custom-control-input\" value=\"true\" id=\"use_in_campaign-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" name=\"use_in_campaign\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.use_in_campaign : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <label class=\"custom-control-label\" for=\"use_in_campaign-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">&zwnj;</label>\n            </div>\n        </div>\n        <div class=\"col-auto px-1 py-2 editable-field-drag-handle\">\n            <span class=\"fa fa-bars\"></span>\n        </div>\n        <div class=\"col px-1 editable-field-wrapper\"></div>\n        <div class=\"col-auto px-1 py-1\">\n            <button type=\"button\" class=\"btn btn-danger btn-sm editable-field-delete-btn\" "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.restrict_delete : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <span class=\"fa fa-trash\"></span>\n            </button>\n            <button type=\"button\" class=\"btn btn-secondary btn-sm editable-field-edit-btn\">\n                <span class=\"fa fa-cog\"></span>\n            </button>\n        </div>\n    </div>\n    </div>\n</div>";
},"useData":true})