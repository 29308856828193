module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"row border-bottom mb-2\">\n    <div class=\"col-4\">\n        <strong>"
    + alias2(helpers.tr.call(alias1,"label.caller",{"name":"tr","hash":{},"data":data}))
    + "</strong>\n    </div>\n    <div class=\"col-4\">\n        <strong>"
    + alias2(helpers.tr.call(alias1,"label.queue",{"name":"tr","hash":{},"data":data}))
    + "</strong>\n    </div>\n    <div class=\"col-4\">\n        <strong>"
    + alias2(helpers.tr.call(alias1,"label.waiting_time",{"name":"tr","hash":{},"data":data}))
    + "</strong>\n    </div>\n</div>\n"
    + ((stack1 = container.invokePartial(partials.queueCalls,depth0,{"name":"queueCalls","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})