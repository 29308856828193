import { render, staticRenderFns } from "./FacebookChat.vue?vue&type=template&id=3ba66ad5&scoped=true&"
import script from "./FacebookChat.vue?vue&type=script&lang=js&"
export * from "./FacebookChat.vue?vue&type=script&lang=js&"
import style0 from "./FacebookChat.vue?vue&type=style&index=0&id=3ba66ad5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba66ad5",
  null
  
)

component.options.__file = "FacebookChat.vue"
export default component.exports