import {get, set} from 'lodash';
import BootQueryModules from './bootquery-modules';

function requireAllModuleTemplates(requireContext) {
	return requireContext.keys().map(key => {
		const keyParts = key.split('/');
		const moduleName = keyParts[1];
		const templateModuleName = keyParts[3];
		const templateName = keyParts[4].replace(/\.handlebars$/, '');
		return {
			template: requireContext(key),
			moduleName,
			templateModuleName,
			templateName
		};
	});
}

function requireGlobalTemplates(requireContext) {
	return requireContext.keys().map(key => {
		const templateName = key.split('/')[1].replace(/\.handlebars$/, '');
		return {
			template: requireContext(key),
			moduleName: 'global',
			templateModuleName: 'global',
			templateName
		};
	});
}

const moduleTemplates = requireAllModuleTemplates(
	require.context('app/Modules/', true, /\.\/[^/]+\/Views\/[^/]+\/.+\.handlebars$/)
);
const globalTemplates = requireGlobalTemplates(
	require.context('app/Views/global/', true, /\.\/.*\.handlebars$/)
);
const templateList = moduleTemplates.concat(globalTemplates);

const byModule = templateList.reduce((byModule, template) => {
	const path = [template.moduleName, template.templateModuleName, template.templateName];
	set(byModule, path, template.template);
	return byModule;
}, {});
const moduleList = Object.keys(BootQueryModules.modules).concat(['global']);

function searchPathsForModule(moduleName) {
	if (moduleName === 'global') {
		const sortedModuleList = moduleList.sort((lhs, rhs) => {
			if (lhs === 'global') return -1;
			if (rhs === 'global') return 1;
			return BootQueryModules.modules[lhs].priority - BootQueryModules.modules[rhs].priority;
		});
		return sortedModuleList.map(module => `${module}.global`);
	} else {
		const overrides = Object.values(
			BootQueryModules.getOverridesList(moduleName, true)
		);
		const overridesSearchPaths = overrides.reduce((paths, overrider) => {
			paths.push(`${overrider}.global`);
			paths.push(`${overrider}.${moduleName}`);
			return paths;
		}, []);
		return overridesSearchPaths.concat(['global.global']);
	}
}

export default moduleList.reduce((templates, module) => {
	let searchPaths = searchPathsForModule(module);
	templates[module] = searchPaths.reduce((moduleTemplates, path) => {
		const listForPath = Object.keys(get(byModule, path, {}));
		listForPath.forEach(templateName => {
			if (!moduleTemplates[templateName]) {
				moduleTemplates[templateName] = get(byModule, `${path}.${templateName}`);
			}
		});
		return moduleTemplates;
	}, {});
	return templates;
}, {});