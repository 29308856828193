import VueRouter from 'vue-router';
import { trim } from 'lodash';

import AccountList from '../components/AccountList.vue';
import ThreadList from '../components/ThreadList.vue';
import ThreadView from '../components/ThreadView.vue';

// Export as factory function so we construct a new instance every time
// Otherwise, other app links won't refresh the router's internal stuff
// And it will think it's on another page
export default function getRouter() {
	const router = new VueRouter({
		mode: 'history',
		base: '/mails/',
		routes: [
			{
				path: '/:accountID/threads/:threadID?',
				components: {
					accountList: AccountList,
					threadList: ThreadList,
					threadView: ThreadView
				}
			}
		]
	});

	router.afterEach((to, _from) => {
		const method = trim(to.path, '/');
		window.Bootstrap.bootquery.method = method;
		window.Bootstrap.bootquery.parameters = to.query;
	});

	return router;
}
