import {
	EventEmitter
} from 'events';
import {
	isArray,
	isString
} from 'lodash';

export default class FilePicker extends EventEmitter {
	/**
	 *
	 * @param {Object} options File picker options object
	 * @param {boolean} [options.multiple=false] Allow selecting multiple files?
	 * @param {string|string[]} [options.accept=null] Allowed file types. Eg. .wav, image/png, video/*
	 */
	constructor(options = {}) {
		super();
		this.multiple = false;
		if (typeof (options.multiple) !== 'undefined') {
			this.multiple = options.multiple;
		}
		this.accept = null;
		if (typeof (options.accept) !== 'undefined') {
			if (isArray(options.accept)) {
				this.accept = options.accept;
			} else if (isString(options.accept)) {
				this.accept = [options.accept];
			} else {
				console.error('Invalid value for file accept: ', options.accept);
			}
		}

		this._didBlur = false;
		// We need a function reference in order to pass it to
		// document.removeEventListener and document.addEventListener,
		// but we also need to bind this so it doesn't change when called
		// as an event handler. In order to do that we keep bound function
		// references in this object so we can pass the same reference to both
		this._boundDocumentEvents = {
			windowBlurHandler: this._windowBlurHandler.bind(this),
			windowFocusHandler: this._windowFocusHandler.bind(this)
		};
		this._done = false;
		this.inputEl = null;
	}

	open() {
		this.inputEl = document.createElement('input');
		this.inputEl.setAttribute('type', 'file');
		if (this.multiple) {
			this.inputEl.setAttribute('multiple', '');
		}
		if (this.accept) {
			this.inputEl.setAttribute('accept', this.accept.join(','));
		}

		// We can't click it with display: none, so hide it like this
		this.inputEl.setAttribute(
			'style',
			'visibility: hidden; width: 0; height: 0; overflow: hidden;'
		);

		window.addEventListener(
			'focus',
			this._boundDocumentEvents.windowFocusHandler
		);
		window.addEventListener(
			'blur',
			this._boundDocumentEvents.windowBlurHandler
		);
		this.inputEl.addEventListener('change', ev => {
			if (this.inputEl.files.length > 0) {
				this._handleFiles();
			} else {
				this._handleCancel();
			}
		});
		document.body.appendChild(this.inputEl);
		this.inputEl.focus();
		this.inputEl.click();
	}

	_cleanup() {
		window.removeEventListener(
			'focus',
			this._boundDocumentEvents.windowFocusHandler
		);
		window.removeEventListener(
			'blur',
			this._boundDocumentEvents.windowBlurHandler
		);
		this.inputEl.parentNode.removeChild(this.inputEl);
		this.inputEl = null;
		this._done = true;
	}

	_windowFocusHandler() {
		if (!this._didBlur) {
			return;
		}

		// MS Edge seems to focus before change sometimes
		// So we just hope this ugly timeout is enough...
		setTimeout(() => {
			// Change has occured in the meantime, nothing to do here
			if (this._done) {
				return;
			}
			if (this.inputEl.files.length > 0) {
				this._handleFiles();
			} else {
				this._handleCancel();
			}
		}, 300);
	}

	_windowBlurHandler() {
		this._didBlur = true;
	}

	_handleCancel() {
		console.log('CANCEL');
		this.emit('cancel');
		this._cleanup();
	}

	_handleFiles() {
		console.log('FILES');
		this.emit('files', Array.from(this.inputEl.files));
		this._cleanup();
	}
}