module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<option value=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1((depth0 != null ? depth0.selected : depth0), depth0),{"name":"selected","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span class=\""
    + alias2(alias1((depth0 != null ? depth0.filter_icon : depth0), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.text : depth0), depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<select class=\"pickle disabled\" disabled name=\""
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "_disabled_"
    + alias2(alias1((depth0 != null ? depth0.index : depth0), depth0))
    + "\" data-width=\"100%\">\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1((depth0 != null ? depth0.options : depth0), depth0),{"name":"options","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</select>";
},"useData":true})