module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.rowClass : depth0), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "style=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.rowStyle : depth0), depth0))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a href=\""
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.row : stack1)) != null ? stack1.template : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\" class=\"text-body whole-row-action-anchor\" tabindex=\"-1\"></a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(helpers.lookup.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.row : stack1),"template",{"name":"lookup","hash":{},"data":data}),depth0,{"hash":{"row":(depth0 != null ? depth0.row : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.row : stack1)) != null ? stack1.method : stack1), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,helpers.lookupMap.call(alias3,(depth0 != null ? depth0.row : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.row : stack1)) != null ? stack1.params : stack1),{"name":"lookupMap","hash":{},"data":data}),{"name":"encode_query_string","hash":{},"data":data}));
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowCheckbox : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.hasRowActions : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.id_col : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                <input type=\"hidden\" name=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.tableName : stack1), depth0))
    + "-rowselect-"
    + alias1(helpers.lookup.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.row : depth0),((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.id_col : stack1),{"name":"lookup","hash":{},"data":data}))
    + "\" value=\"false\">\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <span class=\"text-nowrap\">\n"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.view : stack1),{"name":"with","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.edit : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </span>\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.method : depth0), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,helpers.lookupMap.call(alias3,(depths[1] != null ? depths[1].row : depths[1]),(depth0 != null ? depth0.params : depth0),{"name":"lookupMap","hash":{},"data":data}),{"name":"encode_query_string","hash":{},"data":data}))
    + "\" title=\""
    + alias2(helpers.tr.call(alias3,"label.view_entry",{"name":"tr","hash":{},"data":data}))
    + "\">\n                        <span class=\"fa fa-eye\"></span>\n                    </a>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.edit : stack1),{"name":"with","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        &nbsp;\n                        <a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? depth0.method : depth0), depth0))
    + "/?"
    + alias2(helpers.encode_query_string.call(alias3,helpers.lookupMap.call(alias3,(depths[1] != null ? depths[1].row : depths[1]),(depth0 != null ? depth0.params : depth0),{"name":"lookupMap","hash":{},"data":data}),{"name":"encode_query_string","hash":{},"data":data}))
    + "\" title=\""
    + alias2(helpers.tr.call(alias3,"label.edit_entry",{"name":"tr","hash":{},"data":data}))
    + "\">\n                            <span class=\"fa fa-edit\"></span>\n                        </a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        "
    + ((stack1 = container.invokePartial(helpers.lookup.call(alias1,(depth0 != null ? depth0.column : depth0),"type",{"name":"lookup","hash":{},"data":data}),helpers.lookupMap.call(alias1,(depth0 != null ? depth0.row : depth0),((stack1 = (depth0 != null ? depth0.column : depth0)) != null ? stack1.parameters : stack1),{"name":"lookupMap","hash":{},"data":data}),{"hash":{"tableName":((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.tableName : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.display_column : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(helpers.lookup.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.row : depth0),((stack1 = (depth0 != null ? depth0.column : depth0)) != null ? stack1.display_column : stack1),{"name":"lookup","hash":{},"data":data}))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(helpers.lookup.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.row : depth0),((stack1 = (depth0 != null ? depth0.column : depth0)) != null ? stack1.column : stack1),{"name":"lookup","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<td "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rowClass : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.rowStyle : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.row : depth0)) != null ? stack1.rowActions : stack1)) != null ? stack1.row : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.column : depth0)) != null ? stack1.type : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"usePartial":true,"useData":true,"useDepths":true})