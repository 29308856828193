module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "	<div class=\"card\" id=\"callcenter-display-card\">\n		<div class=\"card-header d-flex\">\n				<div class=\"btn-group ml-auto\">\n					<button type=\"button\" class=\"btn dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n							<span class=\"fa fa-eye\"></span> "
    + alias2(helpers.tr.call(alias1,"button.display",{"name":"tr","hash":{},"data":data}))
    + "\n					</button>\n\n					<div class=\"dropdown-menu observed-queues-dropdown dropdown-menu-right dropdown-menu-scrollable\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.queues : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						<div class=\"dropdown-item\">\n							<div class=\"custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0\">\n								<input type=\"checkbox\" name=\"showAllAgentsStatus\" id=\"showAllAgentsStatus\" class=\"custom-control-input\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.modules : depth0)) != null ? stack1.Callcenter : stack1)) != null ? stack1.showAllAgentsStatus : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n								<label class=\"custom-control-label\" for=\"showAllAgentsStatus\">"
    + alias2(helpers.tr.call(alias1,"label.show_all_agents",{"name":"tr","hash":{},"data":data}))
    + "</label>\n							</div>\n						</div>\n						<div class=\"dropdown-item\">\n							<div class=\"custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0\">\n								<input type=\"checkbox\" name=\"showQueueCallers\" id=\"showQueueCallers\" class=\"custom-control-input\" "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.modules : depth0)) != null ? stack1.Callcenter : stack1)) != null ? stack1.showQueueCallers : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n								<label class=\"custom-control-label\" for=\"showQueueCallers\">"
    + alias2(helpers.tr.call(alias1,"label.show_queue_callers",{"name":"tr","hash":{},"data":data}))
    + "</label>\n							</div>\n						</div>\n					</div>\n				</div>\n\n				<a href=\"/settings/userSettings/#setting-ccStatus\" class=\"btn btn-secondary ml-2\">\n					<span class=\"fa fa-cog\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias1,"button.settings",{"name":"tr","hash":{},"data":data}))
    + "\n				</a>\n			</div>\n\n			<div class=\"card-body\">\n				"
    + ((stack1 = container.invokePartial(partials.queueStatistics,depth0,{"name":"queueStatistics","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n				<div class=\"row\" id=\"extension-list\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.modules : depth0)) != null ? stack1.Callcenter : stack1)) != null ? stack1.showQueueCallers : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.queues : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "							<div class=\"dropdown-divider\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "								<div class=\"dropdown-item\">\n									<div class=\"custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0\">\n										<input type=\"checkbox\" name=\"observed_queues["
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "]\" id=\"observed_queues["
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "]\" class=\"custom-control-input\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isObserved : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-queue=\""
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "\">\n										<label class=\"custom-control-label\" for=\"observed_queues["
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "]\">"
    + alias2(alias1((depth0 != null ? depth0.Name : depth0), depth0))
    + "</span>\n									</div>\n								</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"col-6 col-md pt-3 pb-2\">\n							"
    + ((stack1 = container.invokePartial(partials.agentsTable,depth0,{"name":"agentsTable","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "						</div>\n						<div class=\"col-6 col-md pt-3 pb-2\">\n							"
    + ((stack1 = container.invokePartial(partials.queueCalls,depth0,{"name":"queueCalls","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "						</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"col-12 col-md pt-3 pb-2\">\n							"
    + ((stack1 = container.invokePartial(partials.agentsTable,depth0,{"name":"agentsTable","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "						</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})