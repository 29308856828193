module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<h6 class=\"row\">\n    <div class=\"col\">\n        "
    + ((stack1 = helpers.block.call(alias1,"icon",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <span class=\"notification-title\">\n            "
    + ((stack1 = helpers.block.call(alias1,"title",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </span>\n    </div>\n    <div class=\"col-auto\">\n        <small>\n            "
    + container.escapeExpression(helpers.format_date.call(alias1,(depth0 != null ? depth0.date : depth0),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "\n        </small>\n    </div>\n</h6>\n<div class=\"notification-body\">\n    "
    + ((stack1 = helpers.block.call(alias1,"body",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true})