<template>
    <div class="card">
        <div class="card-header">
            Stvar sa <strong>"{{formValues.nameThing}}"</strong>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6 col-md-4">
                    <div class="test-thing">Bok</div>
                    <TextField name="nameThing" label="Imeko" v-model.trim="formValues.nameThing" />
                    <NumberField name="numberThing" label="Brojceko" v-model="formValues.numberThing" />
                    <SelectField multiple name="selectThingOptions" label="Padalo" v-model="formValues.selectThing"
                    :options="[
                        {id: 1, label: 'One',   icon: 'fa fa-address-card'},
                        {id: 2, label: 'Two',   icon: 'fa fa-archive'},
                        {id: 3, label: 'Three', icon: 'fa fa-book'}
                    ]">
                        <template slot="selected-option" slot-scope="option">
                            <span :class="option.icon"></span> {{option.label}}
                        </template>
                        <template slot="option" slot-scope="option">
                            <span :class="option.icon"></span> {{option.label}}
                        </template>
                    </SelectField>
                    <SelectField name="drugoPadalo" v-model="formValues.drugoPadalo" label="Drugi padac" :options="secondOptions" />
                    <SelectField name="gosponCustomer" v-model="formValues.gosponCustomer" label="Customer" :options="customerOptions" :onSearch="searchCustomers" textProp="name" valueProp="typedID" :filterable="false" :onChange="onCustomerChange">
                        <template slot="selected-option" slot-scope="option">
                            <span :class="option.type === 'company' ? 'fa fa-building' : 'fa fa-id-card'"></span>
                            {{option.name}}
                            <button type="button" class="btn btn-sm btn-secondary py-0">
                                <span class="fa fa-eye"></span>
                            </button>
                        </template>
                        <template slot="option" slot-scope="option">
                            <template v-if="option.addNewOption">
                                <strong>+ Add new</strong>
                            </template>
                            <template v-else>
                                <span :class="option.type === 'company' ? 'fa fa-building' : 'fa fa-id-card'"></span>
                                {{option.name}}
                            </template>
                        </template>
                    </SelectField>
                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <input class="form-control" type="text" v-model="customerSetterVal">
                            </div>
                            <div class="col-auto">
                                <button type="button" class="btn btn-primary" @click="formValues.gosponCustomer = customerSetterVal">
                                    Set value
                                </button>
                            </div>
                        </div>
                    </div>
                    <DateTimeField name="dateTimeThing" label="Datumsko vremeko" v-model="formValues.dateTimeThing" />
                    <CheckboxField name="kvacalo" label="Kuplung" v-model="formValues.kvacalo" />
                    <TextareaField name="hrpaTeksta" label="Hrpa teksta" v-model="formValues.hrpaTeksta" />
                    <RichTextField name="bogatiTekstonja" label="Bogati tekst" v-model="formValues.bogatiTekstonja" format="html" />
                </div>
                <div class="col-sm-6 col-md-8">
                    <strong>Active tab: </strong> {{tabThingieActive}} <br>
                    <Tabs :activeTab.sync="tabThingieActive">
                        <Tab title="Ante" id="ante" icon="fa fa-edit">
                            <strong>Data: </strong>
                            <pre class="my-3"><code>{{jsonData}}</code></pre>
                        </Tab>
                        <Tab title="Pero" id="pero" icon="fa fa-calendar-alt" badgeContent="4">
                            <Quill @update:content="formValues.richContent = $event" @update:html="richContentHtml = $event" :text.sync="richContentText" />
                            <hr>
                            <h4>HTML: </h4>
                            <div class="card bg-light p-3" v-html="richContentHtml"></div>
                            <hr>
                            <h4>TEXT: </h4>
                            <div class="card bg-light p-3" style="white-space: pre-wrap;" v-html="richContentText"></div>
                        </Tab>
                        <Tab title="Formy" id="formy" icon="fas fa-clipboard-list">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Forma</h4>
                                    <div class="row">
                                        <div class="col-8 mx-auto">
                                            <TextField name="vrok" value="Ae" label="Aj utipkaj nes" />
                                            <ListField name="listas" v-model="formValues.listas" :newRowDefaults="{type: 1}">
                                                <template slot-scope="{row, parentPath}">
                                                    <div class="col-4">
                                                        <SelectField name="type" label="Tip broja" v-model="row.type" textProp="name" :parentPath="parentPath"
                                                            :options="[
                                                                {id: 1,  name: 'Telefon privat'},
                                                                {id: 2,  name: 'Centrala'},
                                                                {id: 3,  name: 'Mobilni posao'},
                                                                {id: 4,  name: 'Mobilni privat'},
                                                                {id: 5,  name: 'Fax'},
                                                                {id: 6,  name: 'Ostalo'},
                                                                {id: 7,  name: 'Telefon posao'},
                                                                {id: 8,  name: 'Besplatni broj'},
                                                                {id: 9,  name: 'Interni'},
                                                                {id: 10, name: 'Skraćeni broj'}
                                                            ]"
                                                        />
                                                    </div>
                                                    <div class="col-8">
                                                        <TextField name="number" label="Broj" v-model="row.number" :parentPath="parentPath" />
                                                    </div>
                                                </template>
                                            </ListField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextField from 'BootQuery/Assets/js/forms/fields/Text.vue';
import NumberField from 'BootQuery/Assets/js/forms/fields/Number.vue';
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';
import DateTimeField from 'BootQuery/Assets/js/forms/fields/DateTime.vue';
import CheckboxField from 'BootQuery/Assets/js/forms/fields/Checkbox.vue';
import TextareaField from 'BootQuery/Assets/js/forms/fields/Textarea.vue';
import Tabs from 'BootQuery/Assets/js/bootstrap-components/Tabs.vue';
import Tab from 'BootQuery/Assets/js/bootstrap-components/Tab.vue';
import Quill from 'BootQuery/Assets/js/quill/quill.vue';
import RichTextField from 'BootQuery/Assets/js/forms/fields/RichText.vue';
import ListField from 'BootQuery/Assets/js/forms/fields/List.vue';

import * as Api from 'BootQuery/Assets/js/apiRequest';

export default {
	components: {
		TextField,
		NumberField,
		SelectField,
		DateTimeField,
		CheckboxField,
		TextareaField,
		RichTextField,
		Tabs,
		Tab,
		Quill,
		ListField
	},
	data() {
		return {
			secondOptions: ['Bok', 'Ante', 'Momce'],
			customerOptions: [{
				typedID: 'person_19',
				type: 'person',
				name: 'Ante Antic'
			}, {
				typedID: 'company_10',
				type: 'company',
				name: 'Kompanija test'
			}, {
				addNewOption: true,
				typedID: '$new',
				type: 'new',
				name: 'New',
				$persist: true
			}],
			customerSetterVal: 'company_10',
			tabThingieActive: 'formy',
			richContentHtml: undefined,
			richContentText: 'Hello, world',
			formValues: {
				nameThing: 'Ante',
				numberThing: 12,
				selectThing: 2,
				dateTimeThing: '2020-02-11T08:30:00.000Z',
				kvacalo: false,
				hrpaTeksta: 'Bok,\nJa sam\nTekstov',
				drugoPadalo: 'Bok',
				gosponCustomer: 'person_19',
				richContent: {},
				bogatiTekstonja: '<h1>Bok</h1><p>Ljudovi</p>',
				listas: [
					{type: 3, number: '090 4321 123'},
					{type: 4, number: '090 1234 321'}
				]
			}
		};
	},
	methods: {
		searchCustomers(search, loading) {
			loading(true);
			Api.get('/api/ticketing/customers', {name: search}).then(res => {
				const customers = res.customers.map(customer => {
					customer.addNewOption = false;
					return customer;
				});
				const persistent = this.customerOptions.filter(option => option.$persist);
				this.customerOptions = customers.concat(persistent);
				loading(false);
			});
		},
		onCustomerChange(customerID, fullRow) {
			if (fullRow.addNewOption) {
				return true;
			}
		}
	},
	computed: {
		jsonData() {
			return JSON.stringify(this.formValues, null, 4);
		}
	}
};
</script>
