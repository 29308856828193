module.exports = {"label":{"no_result":"No result","filters":"Filters","refresh_type":"Refresh","refresh_manual":"Manual","refresh_auto":"Automatic","refresh":"Refresh data","refresh_up_to_date":"Data refreshed","edit_entry":"Edit entry","view_entry":"View entry","entry_details":"Entry details","back_to_list":"Back to list","or":"or","save_success":"Changes successfully saved","save_failure":"Failure at saving changes","actions":"Actions","processing":"In process","status":"Status","file":"File","view_data":"View data","select":"Select","10_results_per_page":"10 results per page","20_results_per_page":"20 results per page","30_results_per_page":"30 results per page","records_total":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "Total records: "
    + container.escapeExpression(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"count","hash":{},"data":data}) : helper)));
},"useData":true}),"unsaved_data":"Unsaved data","unsaved_data_body":"The form contains unsaved data. Would you like to save your changes?","delete_entry":"Delete entry","delete_entry_body":"Are you sure you want to delete the selected entry?","saved":"Saved","validation_error":"Invalid input","saving_in_progress":"Saving...","validation_in_progress":"Validating...","permission_read":"Read","permission_write":"Write","permission_delete":"Delete","permission_owner":"Owner","permission_group":"Group","permission_others":"Others","show_columns":"Show columns","add":"Add","days":"Days","hours":"Hours","minutes":"Minutes","edit":"Uredi","name":"Naziv","now":"Now"},"button":{"add":"Add","edit":"Edit","save":"Save","discard":"Discard","cancel":"Cancel","delete":"Delete","settings":"Settings","display":"Display","back":"Back","back_to_list":"Back to list","close":"Close","send":"Send","choose_file":"Choose file","download":"Download","upload":"[upload]"},"form":{"file":"File","type_separator":"Separator"},"filter":{"choose_filters":"Choose filters","from_date":"From (date)","to_date":"To (date)","from_time":"From (time)","to_time":"To (time)","created_from_date":"Created from date","created_to_date":"Created to date","created_from_time":"Created from time","created_to_time":"Created to time"},"pagination":{"previous":"Previous","next":"Next","indicator":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "Page "
    + alias4(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page","hash":{},"data":data}) : helper)))
    + " of "
    + alias4(((helper = (helper = helpers.pageCount || (depth0 != null ? depth0.pageCount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageCount","hash":{},"data":data}) : helper)));
},"useData":true}),"choose_page":"Choose page","button":{"go":"Go"},"placeholder":{"page_number":"Page number"}},"report":{"report":"Report","print":"Print report","export_excel":"Export to Excel","export_pdf":"Export to PDF","company":"Company","address":"Address","city":"City","identification_number":"OIB","generated_on":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "Report created on "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.date : stack1), depth0))
    + " at "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.time : stack1), depth0));
},"useData":true})},"format":{"date":"d.m.Y","time":"H:i:s","datetime":"d.m.Y. H:i","datetime_s":"d.m.Y H:i:s"},"error":{"permission_denied":"Permission denied","not_found":"Not found"},"calendar":{"mon_long":"Monday","tue_long":"Tuesday","wed_long":"Wednesday","thu_long":"Thursday","fri_long":"Friday","sat_long":"Saturday","sun_long":"Sunday","mon":"Mon","tue":"Tue","wed":"Wed","thu":"Thu","fri":"Fri","sat":"Sat","sun":"Sun","jan_long":"January","feb_long":"February","mar_long":"March","apr_long":"April","may_long":"May","jun_long":"June","jul_long":"July","aug_long":"August","sep_long":"September","oct_long":"October","nov_long":"November","dec_long":"December","jan":"Jan","feb":"Feb","mar":"Mar","apr":"Apr","may":"May","jun":"Jun","jul":"Jul","aug":"Aug","sep":"Sep","oct":"Oct","nov":"Nov","dec":"Dec"}}