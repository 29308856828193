<template>
    <a href="#" class="list-group-item border-bottom list-group-item-action" :class="{active: isSelected}">
        <div class="row">
            <div class="col text-truncate" style="overflow: hidden;">
                <span class="text-nowrap">
                    <strong>
                        <span>{{ customer.displayName }}</span>
                    </strong>
                </span>
            </div>
            <div class="col-auto">
                <span>
                    {{ relativeWaitTime }}
                </span>
            </div>
        </div>
        <div v-if="lastMessage">
            <div class="message-text text-truncate" v-html="lastMessage.content"></div>
        </div>
    </a>
</template>

<script>
export default {
	props: {
		messages: {
			type: Array,
			default() {
				return [];
			}
		},
		isSelected: {
			type: Boolean,
			default: false
		},
		chatID: {},
		waitStart: Date,
		customer: Object
	},
	data() {
		return {
			relativeWaitTime: null
		};
	},
	computed: {
		lastMessage() {
			if (this.messages.length) {
				return this.messages[this.messages.length - 1];
			}
			return null;
		}
	},
	methods: {
		refreshWaitTime() {
			this.relativeWaitTime = moment(this.waitStart).fromNow(true);
		}
	},
	created() {
		this.$options.timeRefresherInterval = window.setInterval(
			this.refreshWaitTime.bind(this),
			10000
		);
		this.refreshWaitTime();
	},
	destroyed() {
		if (this.$options.timeRefresherInterval) {
			window.clearInterval(this.$options.timeRefresherInterval);
		}
	}
};
</script>

<style lang="scss" scoped>
    .message-text {
        max-height: 3em;
    }
</style>
