import Module from 'BootQuery/Assets/js/module';
import { get, find, map, toLower, includes } from 'lodash';

// A sophisticated width calculation algorithm
const menuWidth = 200;
const slideBias = 20; // Sliding from the edge + small allowed offset

export default class Menu extends Module {
	constructor() {
		super();
		this.slideState = {};
		this.slideHandlers = {
			touchstart: this.onTouchStart.bind(this),
			touchmove: this.onTouchMove.bind(this),
			touchend: this.onTouchEnd.bind(this)
		};
		this.slideHandlersSet = false;
	}

	init(data) {
		super.init(data);

		if (data) {
			this.menuItems = get(data, 'modules.Menu.menus.main.main.items');
		}
		$(window).ev('resize.menu', this.postSizeChange.bind(this));
		$(window).ev('resize.menuSwitcher', this.moveTopNavContent.bind(this));
		$(document).ev('click.menu', e => {
			if (window.innerWidth > 960) {
				return; // Only on mobile
			}

			const $target = $(e.target);
			const clickedOutsideMenu = $target.is('#main-container') || $target.closest('#main-container').length;
			const clickedMenuItem = $target.is('.menu-container .nav-link') || $target.closest('.menu-container .nav-link').length;
			if (clickedOutsideMenu || clickedMenuItem) {
				this.toggleMenu(false);
			}
		});

		if (!this.targetEl) {
			this.targetEl = $('body');
		}
		this.moveTopNavContent();
		if (!this.getBody().is('.menu-folded, .menu-expanded')) {
			let state;
			if (window.innerWidth <= 960) {
				state = 'folded';
			} else {
				state = window.localStorage.getItem('menuState');
				if (!state) {
					state = this.isExpanded() ? 'expanded' : 'folded';
				}
			}

			if (state === 'folded' || state === 'expanded') {
				this.getBody().addClass(`menu-${state}`);
			}
		}
	}

	onTouchStart(ev) {
		// Paranoid
		const posX = Math.max(0, ev.changedTouches[0].clientX);
		const posY = Math.max(0, ev.changedTouches[0].clientY);
		if (posX > slideBias && !this.isExpanded()) {
			return;
		}
		this.slideState = {
			sliding: true,
			confirmed: false,
			initialX: posX,
			initialY: posY,
			currentX: posX
		};
	}

	onTouchMove(ev) {
		if (!this.slideState.sliding) {
			return;
		}

		const realPosX = ev.changedTouches[0].clientX;
		const realPosY = ev.changedTouches[0].clientY;

		// Detect vertical scrolling and stuff
		if (!this.slideState.confirmed) {
			const deltaY = Math.abs(realPosY - this.slideState.initialY);
			const deltaX = Math.abs(realPosX - this.slideState.initialX);
			if (deltaX < deltaY) {
				this.slideState = {};
				return;
			}
		}

		const posX = Math.max(0, Math.min(realPosX, menuWidth));
		this.slideState.currentX = posX;
		this.slideState.confirmed = true;

		const menuOffset = posX - menuWidth;
		$('body').addClass('menu-folding');
		$('.menu-container').css({
			transition: 'none',
			transform: `translateX(${menuOffset}px)`,
			willChange: 'transform'
		});
		$('#main-container').css({
			transition: 'none',
			transform: `translateX(${posX}px)`,
			willChange: 'transform'
		});
	}

	onTouchEnd(_ev) {
		if (!this.slideState.sliding) {
			return;
		}

		if (this.slideState.confirmed) {
			const isExpanded = this.slideState.currentX >= menuWidth / 2;
			this.toggleMenu(isExpanded);
		}
		$('.menu-container').css({
			transition: '',
			transform: '',
			willChange: ''
		});
		$('#main-container').css({
			transition: '',
			transform: '',
			willChange: ''
		});
		this.slideState = {};
	}

	activateElements(target, data) {
		if (!target.findElement('#content-wrapper').length) {
			target = $('body');
		}
		this.targetEl = target;
		target.findElement('.menu-container .nav-item').tooltip({
			placement: 'right',
			trigger: 'hover',
			boundary: 'window',
			template: `
				<div class="tooltip menu-tooltip" role="tooltip">
					<div class="arrow"></div>
					<div class="tooltip-inner"></div>
				 </div>`
		});
		target
			.findElement('.menu-toggle-btn')
			.off('click.menu')
			.on('click.menu', e => {
				e.preventDefault();
				e.stopPropagation();
				this.toggleMenu();
			});
		this.postSizeChange();
		this.moveTopNavContent();

		this.updateMenus(data);
	}

	updateMenus(data) {
		let controller = get(data, 'bootquery.controller');
		let method = get(data, 'bootquery.method');
		if (!controller || !method) {
			return;
		}
		controller = controller.toLowerCase();
		method = method.toLowerCase();
		let activeItem = find(this.menuItems, item => {
			if (!item.entry.controller) {
				console.warn('No controller for menu entry: ', item.entry);
				return false;
			}
			if (item.entry.controller.toLowerCase() !== controller) {
				return false; // Not the same controller
			}

			if (item.entry.methods) {
				const methods = map(item.entry.methods, toLower);
				return includes(methods, method);
			} else {
				return true;
			}
		});

		this.targetEl
			.findElement('.menu-container')
			.find('.nav-item')
			.removeClass('active');
		if (activeItem) {
			const selector = `[data-controller="${
				activeItem.entry.controller
			}"][data-method="${activeItem.entry.method}"]`;
			const $item = this.targetEl
				.findElement('.menu-container')
				.find(selector);
			$item.parent().addClass('active');
		}
	}

	isExpanded() {
		if (this.getBody().hasClass('menu-expanded')) {
			return true;
		} else if (this.getBody().hasClass('menu-folded')) {
			return false;
		}

		return window.innerWidth >= 1440;
	}

	postSizeChange() {
		let $toggleBtn = this.targetEl.findElement('.menu-toggle-btn');
		let isExpanded = this.isExpanded();
		$toggleBtn
			.children('.menu-toggle-icon-fold')
			.prop('hidden', !isExpanded);
		$toggleBtn
			.children('.menu-toggle-icon-expand')
			.prop('hidden', isExpanded);
		this.getBody()
			.find('.menu-container .nav-item')
			.tooltip(isExpanded ? 'disable' : 'enable');

		// Only handle touchy slidey events on mobile
		if (window.innerWidth <= 576 && !this.slideHandlersSet) {
			Object.entries(this.slideHandlers).forEach(([event, handler]) => {
				document.addEventListener(event, handler, { passive: true });
			});
			this.slideHandlersSet = true;
		} else if (window.innerWidth > 576 && this.slideHandlersSet) {
			Object.entries(this.slideHandlers).forEach(([event, handler]) => {
				document.removeEventListener(event, handler);
			});
			this.slideHandlersSet = false;
		}
	}

	moveTopNavContent() {
		let $wrongLocation;
		if (window.innerWidth > 576) {
			$wrongLocation = this.targetEl.findElement(
				'.menu-container > .navbar-nav > .nav-item.from-header'
			);
			$wrongLocation.removeClass('from-header px-3');
			$wrongLocation.prependTo(
				this.targetEl.findElement('.main-top-nav > .navbar-nav')
			);
		} else {
			$wrongLocation = this.targetEl.findElement(
				'.main-top-nav > .navbar-nav > .nav-item'
			);
			$wrongLocation.addClass('from-header px-3');
			$wrongLocation.prependTo(
				this.targetEl.findElement('.menu-container > .navbar-nav')
			);
		}
	}

	toggleMenu(newExpanded) {
		// If already in desired state do nothing, otherwise toggle
		if (
			typeof newExpanded !== 'undefined' &&
			newExpanded === this.isExpanded()
		) {
			return;
		}

		if (this.isExpanded()) {
			this.getBody().removeClass('menu-expanded menu-expanding');
			this.getBody().addClass('menu-folded menu-folding');
			window.localStorage.setItem('menuState', 'folded');
		} else {
			this.getBody().addClass('menu-expanded menu-expanding');
			this.getBody().removeClass('menu-folded menu-folding');
			window.localStorage.setItem('menuState', 'expanded');
		}
		setTimeout(() => {
			this.getBody().removeClass('menu-expanding menu-folding');
		}, 200);
		this.postSizeChange();
	}

	getBody() {
		let body = this.targetEl.findElement('body');
		if (!body.length) {
			body = this.targetEl.closest('body');
		}
		return body;
	}
}
