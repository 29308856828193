module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "						<tr>\n							<th style=\"background: #28a745; border-right-width: 1px; border-left-width: 1px; border-top-width: 1px; border-bottom-width: 0; border-style: solid; border-color: #1e7e34; padding: 0; color: #fafafa; border-top-left-radius: 4px; border-top-right-radius: 4px; padding: 10px;\">\n								Odgovoren poziv od <b>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.callerName : depth0), depth0))
    + "</b>\n							</th>\n						</tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "						<tr>\n							<th style=\"background: #dc3545; border-right-width: 1px; border-left-width: 1px; border-top-width: 1px; border-bottom-width: 0; border-style: solid; border-color: #dc3545; padding: 0; color: #fafafa; border-top-left-radius: 4px; border-top-right-radius: 4px; padding: 10px;\">\n								Propusten poziv od <b>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.callerName : depth0), depth0))
    + "</b>\n							</th>\n						</tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "Odgovoren";
},"7":function(container,depth0,helpers,partials,data) {
    return "Propusten";
},"9":function(container,depth0,helpers,partials,data) {
    return "									<tr>\n										<td align=\"center\" style=\"padding: 5px 10px;\">\n											<a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.clickvox_root : depth0), depth0))
    + "/calls/all/\" target=\"_blank\" style=\"background: #666666; border-radius: 4px; color: #fafafa; display: block; padding: 10px 15px; font-weight: bold; text-decoration: none;\" class=\"btn-normal\">\n												Vidi u ClickVOX-u &rArr;\n											</a>\n										</td>\n									</tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.0 Transitional//EN\" \"http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd\">\n<html xmlns=\"http://www.w3.org/1999/xhtml\">\n\n<head>\n	<meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\" />\n	<title>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</title>\n	<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" />\n\n	<style>\n	table td > a.btn-normal {\n		color: #fafafa !important;\n		border-radius: 4px;\n	}\n	table td > a.btn-normal:hover {\n		color: #ffffff !important;\n		background: #444444 !important;\n	}\n	</style>\n</head>\n\n<body style=\"font-family: sans-serif; color: #333333 !important;\">\n	<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\" style=\"font-family: sans-serif; color: #333333 !important;\">\n		<tr>\n			<td align=\"center\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n				<table border=\"0\" cellspacing=\"0\" cellpadding=\"0\" width=\"100%\" style=\"max-width: 800px;\">\n					<thead>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "					</thead>\n					<tbody>\n						<tr>\n							<td align=\"center\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\" style=\"background: #fafafa; border-left: 1px solid #dddddd; border-right: 1px solid #dddddd; border-bottom: 1px solid #dddddd; padding: 10px 15px;\">\n								<table>\n									<tr>\n										<td align=\"center\" style=\"padding: 5px 10px;\">\n											"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + " poziv sa <a href=\"tel:"
    + alias2(alias1((depth0 != null ? depth0.numberE164 : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.numberFormatted : depth0), depth0))
    + "</a>\n										</td>\n									</tr>\n									<tr>\n										<td align=\"center\" style=\"padding: 5px 10px;\">\n											Vrijeme: "
    + alias2(alias1((depth0 != null ? depth0.timestamp : depth0), depth0))
    + "\n										</td>\n									</tr>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.clickvox_root : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "								</table>\n							</td>\n						</tr>\n					</tbody>\n				</table>\n			</td>\n		</tr>\n	</table>\n</body>\n\n</html>";
},"useData":true})