module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<nav class=\"navbar navbar-expand sticky-top main-top-nav justify-content-between py-0\">\n	<button class=\"navbar-toggler menu-toggle-btn mobile-menu-toggle-btn\" type=\"button\">\n		<span class=\"navbar-toggler-icon\"></span>\n	</button>\n	<a class=\"navbar-brand my-2\" href=\"/\">\n		ClickVOX\n		<sup>\n			<img src=\"/app/assets/img/AsteriskGradient.png\" style=\"width: 0.5em;\">\n		</sup>\n	</a>\n\n"
    + ((stack1 = helpers.block.call(alias1,"header-middle",{"name":"block","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n	<ul class=\"navbar-nav my-2\">\n"
    + ((stack1 = helpers.block.call(alias1,"header-right",{"name":"block","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</nav>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"header-middle-container my-2\"></div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Menu)) && stack1.menus)) && stack1.header),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.template : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					"
    + ((stack1 = container.invokePartial(helpers.concat.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.key),".",(depth0 != null ? depth0.template : depth0),{"name":"concat","hash":{},"data":data}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "					<li class=\"nav-item dropdown\">\n						<a class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\" href=\"#\">\n							<span class=\"fa fa-user\"></span>\n							<span>&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</span>\n						</a>\n\n						<div class=\"dropdown-menu dropdown-menu-right\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n					</li>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<a class=\"dropdown-item\" href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.method : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "							</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "									<span class=\""
    + alias2(alias1((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span> "
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "									<span class=\""
    + alias1(container.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span> "
    + alias1(helpers.tr.call(alias2,helpers.concat.call(alias2,(depths[1] != null ? depths[1].module : depths[1]),":menu_entries.",(depth0 != null ? depth0.name : depth0),{"name":"concat","hash":{},"data":data}),{"name":"tr","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.report),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})