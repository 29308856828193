module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <form action=\"/callcenter/customMessages\" method=\"post\" id=\"cc-custom-messages-form\">\n        <table class=\"table\">\n            <thead>\n                <tr>\n                    <th class=\"border-top-0\">\n                        "
    + alias2(helpers.tr.call(alias1,"table_header.message_name",{"name":"tr","hash":{},"data":data}))
    + "\n                    </th>\n                    <th class=\"border-top-0\">\n                        "
    + alias2(helpers.tr.call(alias1,"table_header.message_playback",{"name":"tr","hash":{},"data":data}))
    + "\n                    </th>\n                    <th class=\"border-top-0\">\n                        "
    + alias2(helpers.tr.call(alias1,"table_header.message_active",{"name":"tr","hash":{},"data":data}))
    + "\n                    </th>\n                    <th class=\"border-top-0\"></th>\n                </tr>\n            </thead>\n            <tbody>\n                <tr>\n                    <td colspan=\"2\">\n                        "
    + alias2(helpers.tr.call(alias1,"label.message_deactivate",{"name":"tr","hash":{},"data":data}))
    + "\n                    </td>\n                    <td>\n                        <div class=\"custom-control custom-radio\">\n                            <input type=\"radio\" name=\"greeting-messages[selected-message]\" id=\"message-0\" class=\"custom-control-input\" value=\"0\" checked autocomplete=\"off\">\n                            <label class=\"custom-control-label\" for=\"message-0\">&zwj;</label>\n                        </div>\n                    </td>\n                    <td></td>\n                </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.result : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </tbody>\n            <tfoot>\n                <tr>\n                    <td colspan=\"2\">\n                        <a class=\"btn btn-outline-success noparse\" id=\"callcenter-record-new-message\" href=\"/dialer/dial/?extension="
    + alias2(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "&number=*31\">\n                            <span class=\"far fa-dot-circle\"></span> "
    + alias2(helpers.tr.call(alias1,"button.message_record",{"name":"tr","hash":{},"data":data}))
    + "\n                        </a>\n                    </td>\n                    <td colspan=\"2\" class=\"text-right\">\n                        <button class=\"btn btn-success\">\n                            <span class=\"fa fa-check\"></span>\n                            "
    + alias2(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                        </button>\n                    </td>\n                </tr>\n            </tfoot>\n        </table>\n    </form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "                <tr>\n                    <td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ID : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </td>\n                    <td>\n                        <button class=\"btn btn-secondary btn-sm custom-message-play\" type=\"button\">\n                            <span class=\"fa fa-play\"></span>\n                        </button>\n\n                        <audio class=\"custom-message-player\" style=\"display-none\">\n                            <source src=\"/api/files/customMessages/"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                        </audio>\n                    </td>\n                    <td>\n                        <div class=\"custom-control custom-radio\">\n                            <input type=\"radio\" name=\"greeting-messages[selected-message]\" id=\"message-"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" class=\"custom-control-input\" value=\""
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" autocomplete=\"off\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                            <label class=\"custom-control-label\" for=\"message-"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">&zwj;</label>\n                        </div>\n                    </td>\n                    <td>\n                        <button class=\"btn btn-danger btn-sm callcenter-delete-message\" type=\"button\" data-messageid="
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + ">\n                            <span class=\"fa fa-trash-alt\"></span>\n                        </button>\n                        <input type=\"hidden\" name=\"delete-message["
    + alias3(alias2((data && data.index), depth0))
    + "][$id]\" value=\""
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                        <input type=\"hidden\" name=\"delete-message["
    + alias3(alias2((data && data.index), depth0))
    + "][$deleted]\" value=\"false\">\n                    </td>\n                </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                        <div class=\"popover-content-element\" hidden>\n                            <div class=\"input-group\">\n                                <input type=\"hidden\" name=\"rename-message["
    + alias2(alias1((data && data.index), depth0))
    + "][$id]\" value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                                <input type=\"hidden\" name=\"rename-message["
    + alias2(alias1((data && data.index), depth0))
    + "][$renamed]\" value=\"false\">\n                                <input type=\"text\" name=\"rename-message["
    + alias2(alias1((data && data.index), depth0))
    + "][$name]\" class=\"form-element\" value=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\" placeholder=\""
    + alias2(alias1((depth0 != null ? depth0.path : depth0), depth0))
    + "\" autocomplete=\"off\">&nbsp;\n                                <button class=\"btn btn-sm btn-primary callcenter-rename-message\" data-messageID=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"><span class=\"fa fa-check\"></span></button>\n                            </div>\n                        </div>\n                        <span\n                            class=\"edit-note\"\n                            data-messageID=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"\n                            data-toggle=\"popover\"\n                            data-placement=\"right\"\n                            data-container=\"body\"\n                            data-trigger=\"click\"\n                            data-html=\"true\"\n                            data-popover-content-element=\"true\"\n                            data-original-title=\"<span class='fa fa-pencil-alt'></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"label.message_rename",{"name":"tr","hash":{},"data":data}))
    + "\"\n                            tabindex=\"1\"\n                        >\n                            <span class=\"fa fa-edit\"></span>&nbsp;\n                            <span class=\"note-name\" data-message-id=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                                "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                            </span>\n                        </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.path : depth0), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})