import Module from 'BootQuery/Assets/js/module.js';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { getTemplate } from 'BootQuery/Assets/js/BootQuery.js';

export default class DoNotCall extends Module {
	static formatStatus(status) {
		if (status.lastSync) {
			if (!status.lastSync.done) {
				status.syncInProgress = true;
			} else if (!status.lastSync.success) {
				status.syncError = true;
			} else {
				if (status.isFreshEnough) {
					status.syncOk = true;
				} else {
					status.syncNotSynced = true;
				}
			}
		} else {
			status.syncNotSynced = true;
		}
		return status;
	}

	init() {
		super.init();
		this.subscribeWebSocket(
			'doNotCall/syncStatus',
			this.onSyncUpdate.bind(this)
		);
	}

	activateElements(target) {
		this.bindSyncStatus(target);
	}

	bindSyncStatus(target) {
		target.findElement('.donotcall-sync-btn').ev('click.doNotCall', ev => {
			ev.preventDefault();
			const $btn = $(ev.currentTarget);
			if ($btn.prop('disabled')) {
				return;
			}
			$btn.prop('disabled', true);
			Api.post('/api/doNotCall/sync', {});
		});
	}

	async onSyncUpdate() {
		if ($('#doNotCall-sync-status').length === 0) {
			return;
		}

		const status = this.constructor.formatStatus(
			await Api.get('/api/doNotCall/syncStatus')
		);

		const statusTemplate = await getTemplate('DoNotCall.syncStatus');
		const renderedStatus = $.render(statusTemplate, status);
		this.bindSyncStatus(renderedStatus);
		$('#doNotCall-sync-status').replaceWith(renderedStatus);
	}
}