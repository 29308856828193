import uuid4 from 'uuid/v4';
import { merge } from 'lodash';
import Socket from './socket';
import {get as apiGet} from 'BootQuery/Assets/js/apiRequest.js';

export default class Datatable {
	constructor(table) {
		this.tablePartial = '';
		this.rowPartial = '';
		this.paginationPartial = '';
		this.controller = '';
		this.method = '';
		this.module = '';
		this.parameters = [];
		this.defaultSort = '';
		this.idColumn = '';
		this.table = table;
		this.tableName = '';
		this.parameters = {};
		this.eventList = [];
		this.refreshType = 'manual';
		this.newRows = [];
		this.boundEvents = {};

		if (window.socket) {
			if (window.socket.socket.readyState === Socket.SOCKET_STATE.OPEN) {
				this.__socketConnect(window.socket);
			}

			const reconnector = () => this.__socketConnect(window.socket);
			window.socket.on('connect', reconnector);
			this.boundEvents['connect'] = reconnector;
		}
	}

	static initDatatables(target, data) {
		if (!window.datatables) {
			window.datatables = {};
		}

		Object.entries(window.datatables)
			.forEach(([tableName, datatable]) => {
				if (data.tables && data.tables[tableName] && data.tables[tableName].datatable) {
					return;
				}
				datatable.__unload();
				delete window.datatables[tableName];
			});

		target.findElement('.datatable').each(function() {
			var table = $(this);
			var tableName = table.attr('id').replace('-table', '');

			if (data.tables[tableName] !== undefined && typeof data.tables[tableName].datatable == 'object') {
				return;
			}

			// Create a new datatable
			var datatable = new Datatable(table);

			data.tables[tableName].datatable = datatable;
			datatable.init(target, data);
			window.datatables[tableName] = datatable;
		});
	}

	init(target, data) {
		this.setDatatableParams(this.table);

		let datatable = this;
		let radioSelector = 'input.datatable-refresh-toggle[data-datatable-name="' + datatable.tableName + '"]';
		let refreshSelector = '.datatable-refresh-button[data-datatable-name="' + datatable.tableName + '"]';
		let btnSelector = `[data-table-action][data-table="${datatable.tableName}"]`;

		let checkboxSelector = '.datatable-column-display-checkbox[data-table="' + datatable.tableName + '"]';
		$(document).ev('change', checkboxSelector, (e) => {
			e.preventDefault();
			e.stopPropagation();

			const visibleColumns = {};
			$(`.datatable-column-display-checkbox[data-table="${datatable.tableName}"]`).each(
				(_index, checkbox) => {
					const columnKey = $(checkbox).data('key');
					visibleColumns[columnKey] = $(checkbox).prop('checked');
				}
			);
			setUserSetting(`Datatables.${datatable.module}.${datatable.tableName}.visibleColumns`, visibleColumns);

			// Re-render the table
			const table = data.tables[datatable.tableName];
			table.columns.forEach(function(column) {
				column.visible = visibleColumns[column.key];
			});
			table.columnCount = table.columns.filter(col => col.visible).length;
			if (table.rowCheckbox) {
				table.columnCount++;
			}

			getTemplate(datatable.tablePartial, datatable.module).then(function(tablePartial) {
				let renderData = data.tables[datatable.tableName];
				renderData.bootquery = window.Bootstrap.bootquery;
				renderTemplate(tablePartial, renderData, '#' + datatable.table.attr('id'), null);
			});
		});
		$(document).ev('click', '.datatable-dropdown>.dropdown-item', (e) => {
			e.preventDefault();
			e.stopPropagation();

			const row = $(e.currentTarget);
			const checkbox = row.find('input');
			checkbox.prop(
				'checked',
				!checkbox.prop('checked')
			);
			checkbox.trigger('change');
		});

		target.findElement(btnSelector).ev('click', (e) => {
			e.preventDefault();
			const action = $(e.currentTarget).data('tableAction');
			const $filterForm = $('#' + datatable.tableName + '-filter-form');
			$filterForm.data('submittedByAction', action);
			$filterForm.submit();
		});

		// If no events, there's nothing to do!
		if (this.eventList.length > 0) {
			this.socketToken = 'Datatable:' + uuid4();
			this.refreshUpdateStatus(data);

			$(document)
				.off('change', radioSelector)
				.on('change', radioSelector, function(e) {
					e.preventDefault();
					e.stopPropagation();

					if (this.checked) {
						datatable.refreshType = $(this).val();
						datatable.refreshUpdateStatus();
						datatable.displayNewRows();
					}
				});

			$(document)
				.off('click', refreshSelector)
				.on('click', refreshSelector, function(e) {
					e.preventDefault();
					e.stopPropagation();

					datatable.displayNewRows();
				});

			this.subscribe();
			this.bindEvents();
		} else {
			target.findElement(
				`.datatable-refresh[data-datatable-name="${this.tableName}"]`
			).remove();
		}
	}

	bindEvents() {
		if (window.socket && window.socket.socket.readyState === Socket.SOCKET_STATE.OPEN) {
			this.eventList.forEach(eventName => {
				const listener = ((data) => {
					if (data.for.indexOf(this.socketToken) !== -1) {
						this.refreshDatatable(data.data);
					}
				}).bind(this);
				window.socket.addListener(eventName, listener);
				this.boundEvents[eventName] = listener;
			});
		}
	}

	setDatatableParams(table) {
		let tableData = $(table).data();

		this.tablePartial = tableData.datatableTablePartial || 'table';
		this.rowPartial = tableData.datatableRowPartial || 'tableRow';
		this.paginationPartial = tableData.datatablePaginationPartial || 'pagination';
		this.controller = tableData.datatableController || window.Bootstrap.bootquery.controller;
		this.method = tableData.datatableMethod || window.Bootstrap.bootquery.method;
		this.module = tableData.datatableModule || null;
		this.parameters = tableData.datatableParameters || window.Bootstrap.bootquery.parameters || [];
		this.defaultSort = tableData.datatableDefaultsort;
		this.idColumn = tableData.datatableIdColumn || 'ID';

		if (tableData['datatableEvents']) {
			this.eventList = tableData['datatableEvents'].split(',');
		}

		this.table = table;
		this.tableName = table.attr('id').replace('-table', '');
	}

	getDatatableRefreshType() {
		return $('.datatable-refresh[data-datatable-name="' + this.tableName + '"')
			.find('input[type="radio"]:checked')
			.val();
	}

	refreshDatatable(data) {
		console.log('Refreshing datatable with data', data);
		let newRows = this.newRows;
		let parameters = $.extend(true, {}, this.parameters);

		parameters['limit'] = 1;

		if (data && data['ID'] && newRows.indexOf(data['ID']) === -1) {
			console.log('New row found:', data.ID);
			newRows.push(data['ID']);
		}

		this.refreshUpdateStatus(data);

		let refreshType = this.getDatatableRefreshType();
		let autorefresh = refreshType == 'auto';
		if (autorefresh) {
			this.displayNewRows();
		}
	}

	async refreshUpdateStatus(data = {}) {
		if (this.refreshType == 'auto') {
			$('.datatable-refresh-button')
				.removeClass('btn-primary')
				.addClass('btn-success')
				.addClass('disabled')
				.attr('disabled', 'disabled');
			$('.datatable-refresh-button .datatable-update-count').text('A');
		} else {
			console.log('Refresh data:', data);
			if(data && data.ID) {
				let isOurs = await this.checkRow(data.ID);

				if(isOurs) {
					$('.datatable-refresh-button')
						.removeClass('btn-success')
						.addClass('btn-primary')
						.removeClass('disabled')
						.removeAttr('disabled');
					$('.datatable-refresh-button .datatable-update-count').text(this.newRows.length || 0);
				} else {
					this.newRows = [];
				}
			} else {
				$('.datatable-refresh-button')
					.removeClass('btn-success')
					.addClass('btn-primary')
					.removeClass('disabled')
					.removeAttr('disabled');
				$('.datatable-refresh-button .datatable-update-count').text(this.newRows.length || 0);
			}
		}
	}

	renderRows(rows, callback) {
		var datatable = this;

		getTemplate([datatable.tablePartial, datatable.rowPartial], datatable.module).then(function(fetchedPartials) {
			var partial = fetchedPartials[datatable.rowPartial];
			var rendered = handlebarsRender(partial, { result: rows });

			if (typeof callback == 'function') {
				callback(rendered, true);
			}
		});
	}

	displayNewRows(table) {
		var limit = 10;
		var datatable = this;

		if (window.Bootstrap.bootquery.parameters) {
			limit = window.Bootstrap.bootquery.parameters.limit;
		}

		var parameters = {
			sortby: this.defaultSort,
			sortdir: 'desc',
			limit: limit,
			'datatable-refresh-type': this.refreshType
		};

		getJSON('get', datatable.controller, datatable.method, parameters, true, async (data) => {
			const template = await getTemplate(datatable.tablePartial, datatable.module);

			// Inject the bootquery object so the table can access controller and session info
			data.tables[datatable.tableName].bootquery = data.bootquery;
			const rendered = $.render(template, data.tables[datatable.tableName]);
			const renderedTable = rendered.findElement(`#${datatable.table.attr('id')}`);
			datatable.table.empty().append(renderedTable.html());
			window.activateElements(datatable.table.parents().last());
			for (let i in datatable.newRows) {
				$('tr[data-datatable-row-id="' + datatable.newRows[i] + '"]')
					.addClass('animate-row')
					.addClass('table-success');

				setTimeout(function() {
					$('tr.table-success').removeClass('table-success');
					setTimeout(function() {
						$('tr.table-success').removeClass('animate-row');
					});
				}, 2000);
			}

			datatable.newRows = [];
			datatable.refreshUpdateStatus(data);
		});
	}

	// Checks if this row should be currently shown
	async checkRow(rowID) {
		const data = await apiGet(`/${this.controller}/${this.method}.json`, this.parameters);
		let results = data.tables[this.tableName].result;

		console.log('Checking for ID', rowID);
		console.log(this.tableName, results);

		if(results) {
			const IDs = results.map(row => row.ID);
			let found = IDs.find((el) => { return el == rowID; });

			if(found) {
				console.log('Yup, we want this one!');
				return true;
			}
		}

		return false;

		// var parameters = {
		// 	limit: 1,
		// 	ID_eq: rowID,
		// 	sortby: 'callStart',
		// 	sortdir: 'desc'
		// };

		// getJSON('get', tableParams['controller'], tableParams['method'], parameters, true, data => {
		// 	if (
		// 		hasProperty(data, 'result.0.' + tableParams['idColumn']) &&
		// 		data['result'][0][tableParams['idColumn']] == rowID
		// 	) {
		// 		var tableRow = table.find('[data-datatable-row-id="' + rowID + '"]');
		// 		if (tableRow.length) {
		// 			this.renderRows(data.result, function(rendered, status) {
		// 				if (status) {
		// 					tableRow.replaceWith(rendered);
		// 				}
		// 			});
		// 		} else {
		// 			var newRows = datatable.newRows;
		// 			$.each(newRows, function(key, value) {
		// 				if (value['ID'] && value['ID'] == data['result'][0]['ID']) {
		// 					newRows[key] = data['result'][0];
		// 				}
		// 			});

		// 			datatable.newRows = newRows;
		// 		}
		// 	}
		// });
	}

	refreshTable() {
		var parameters = merge(this.parameters, {
			'datatable-refresh-type': this.refreshType
		});

		getJSON('get', this.controller, this.method, parameters, true, data => {
			getTemplate(this.tablePartial, this.module).then(template => {
				var tableData = data.tables[this.tableName];
				renderTemplate(template, tableData, '#' + this.table.attr('id'), status => {
					this.refreshUpdateStatus(data);
				});
			});
		});
	}

	__socketConnect(socket) {
		this.subscribe();
		this.bindEvents();
	}

	__unload() {
		this.unsubscribe();
	}

	subscribe() {
		if (this.eventList.length === 0) {
			return;
		}
		if (window.socket.socket.readyState !== undefined && window.socket.socket.readyState === WebSocket.OPEN) {
			window.socket.send('subscribe', {
				events: this.eventList,
				token: this.socketToken
			});
		}
	}

	unsubscribe() {
		Object.entries(this.boundEvents)
			.forEach(([eventName, listener]) => {
				window.socket.removeListener(eventName, listener);
			});

		if (
			this.eventList.length > 0 &&
			window.socket.socket.readyState === WebSocket.OPEN
		) {
			window.socket.send('unsubscribe', {
				events: this.eventList,
				token: this.socketToken
			});
		}
	}
}
