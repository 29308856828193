<template>
    <Modal large @close="cancel">
        <template slot="title">
            <span class="fas fa-comments"></span> Chat
        </template>

        <template v-if="messagesLoaded">
            <Message
                v-for="(message, index) in messages"
                :key="index"
                :direction="message.direction"
                :content="message.content"
                :timestamp="message.timestamp"
            />
        </template>
        <div v-else class="text-center">
            <span class="fas fa-spinner fa-spin"></span>
        </div>

        <template slot="footer">
            <button
                type="button"
                class="btn btn-link text-danger ml-auto"
                @click.prevent="cancel"
            >{{ tr("button.close") }}</button>
        </template>
    </Modal>
</template>

<script>
import Modal from 'BootQuery/Assets/js/bootstrap-components/Modal.vue';
import Message from 'app/Modules/CustomerChat/Assets/components/Message.vue';
import { get as apiGet } from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		Modal,
		Message
	},
	props: {
		chatID: Number
	},
	data() {
		return {
			messages: null,
			messagesLoaded: false
		};
	},
	created() {
		apiGet(`/api/customerChat/chats/${this.chatID}/messages`).then(messages => {
			this.messagesLoaded = true;
			this.messages = messages.map(message => {
				message.timestamp = new Date(message.timestamp);
				return message;
			});
		});
	},
	methods: {
		getTicketingModule() {
			return window.BootQuery.getModuleInstance('Ticketing');
		},
		cancel() {
			this.getTicketingModule().destroyChatHistoryModal();
		}
	}
};
</script>
