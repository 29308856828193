import Module from 'BootQuery/Assets/js/module';
import SignatureEditor from './signatureEditor';
import { find } from 'lodash';
import { getTemplate, getFormData } from 'BootQuery/Assets/js/BootQuery.js';
import Vue from 'BootQuery/Assets/js/vue.js';
import getRouter from './router.js';
import MailsComponent from '../components/Mails.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default class Mails extends Module {
	init(_data) {
		super.init();
		$(document).ev(
			'renderController.mails',
			() => {
				if (this.component) {
					this.component.$destroy();
					this.component = null;
				}
			}
		);
		this.signatureTemplateData();
		this.availableAccounts();

		this.subscribeWebSocket('mails/messageSent', info => {
			this.emit('messageSent', info);
		});

		$(window.document).ev('settingsChanged.mails', (_e, changed) => {
			if (changed.Mails && changed.Mails.changed) {
				this.onSettingsChange(changed.Mails.changed);
			}
		});
	}

	onSettingsChange(changes) {
		// Update cached display settings if present
		if (changes.displaySettings && this._displaySettings) {
			this._displaySettings = {
				...this._displaySettings,
				...changes.displaySettings
			};
		}
	}

	async signatureTemplateData() {
		if (!this._signatureTemplateData) {
			this._signatureTemplateData = await Api.get(
				'/api/mails/signatureTemplateData'
			);
		}
		return this._signatureTemplateData;
	}

	async availableAccounts() {
		if (!this._availableAccounts) {
			this._availableAccounts = await Api.get(
				'/api/mails/availableAccounts'
			);
		}
		return this._availableAccounts;
	}

	async canView() {
		if (!this._canView) {
			this._canView = await Api.get('/api/mails/canView');
		}
		return this._canView;
	}

	async displaySettings() {
		if(!this._displaySettings) {
			this._displaySettings = await Api.get('/api/mails/displaySettings');
		}
		return this._displaySettings;
	}

	activateElements(target, _data) {
		const $mailsContainer = target.findElement('#mails-container');
		if ($mailsContainer.length) {
			this.renderMailInterface($mailsContainer);
		}

		target.findElement('[data-edit-mail-account]').ev('click.mails', e => {
			e.preventDefault();
			const btn = $(e.currentTarget);
			const mailAccountListItem = btn.closest('.list-group-item');
			const accountID = btn.data('mailAccountId');
			const accountDataName = `mailAccounts[${accountID}]`;
			let accountData = null;
			console.log('Clicky', mailAccountListItem);
			console.log(
				'Sel: ',
				`input[type="hidden"][name="${accountDataName}"]`
			);
			let mailAccountInfo = mailAccountListItem.findElement(
				`input[type="hidden"][name="${accountDataName}"]`
			);
			if (mailAccountInfo.length) {
				console.log('Mail account info: ', mailAccountInfo);
				accountData = JSON.parse(mailAccountInfo.val());
			} else {
				const mailsTab = find(window.Bootstrap.result.tabs, {
					key: 'mails'
				});
				// Find uses strict matching so accountID needs to be converted to int.
				// Not parsed to int before because the selector will sometimes be new1, new2, etc
				// for new mail accounts which never end up in data sent from server-side
				accountData = find(mailsTab.data.accounts, {
					ID: parseInt(accountID)
				});
			}
			this.editMailAccount(accountData, saved => {
				if (!mailAccountInfo.length) {
					mailAccountInfo = $('<input/>', {
						type: 'hidden',
						name: accountDataName
					});
					mailAccountInfo.appendTo(mailAccountListItem);
				}
				mailAccountInfo.val(JSON.stringify(saved));
			});
		});

		target.findElement('[data-add-mail-account]').ev('click.mails', e => {
			e.preventDefault();
			this.editMailAccount({ isNewAccount: true }, async saved => {
				console.log('Saved new: ', saved);
				const accountID = this.nextNewAccountID(target);
				saved.ID = accountID;
				saved.isNewAccount = true;

				const accountDataName = `mailAccounts[${accountID}]`;
				const itemTemplate = await getTemplate(
					'Mails.mailAccountListItem'
				);
				const item = $.render(itemTemplate, saved);
				const dataInput = $('<input/>', {
					type: 'hidden',
					name: accountDataName,
					value: JSON.stringify(saved)
				});
				item.append(dataInput);
				target.findElement('.mail-accounts-list').append(item);
				this.activateElements(target);
			});
		});
	}

	async renderMailInterface($mailsContainer) {
		const mailsContainer = $mailsContainer[0];
		if(mailsContainer.dataset.activated) {
			return; // Don't double-render
		}
		mailsContainer.dataset.activated = true;
		this.component = new Vue({
			el: mailsContainer,
			router: getRouter(),
			render: h => h(MailsComponent)
		});
	}

	renderMailsRoute() {
		const target = $('<div/>', {id: 'mails-container'});
		$(window.targetElement).html(target);
		this.renderMailInterface(target);
		$(document).trigger(
			'activateElements',
			[$(window.targetElement), window.Bootstrap]
		);
	}

	async editMailAccount(data, onSave) {
		const template = await getTemplate('Mails.accountSettingsModal');
		const settingsModal = $.render(template, data);
		const signatureEditor = new SignatureEditor(data.signature || {}, {
			'agent.firstName': 'Ime',
			'agent.lastName': 'Prezime'
		});
		signatureEditor.show(settingsModal.findElement('.signature-edit'));

		settingsModal
			.findElement('#mail-password-change-collapse')
			.ev('show.bs.collapse.mails', e => {
				$(e.currentTarget)
					.findElement('#newPassword')
					.prop('disabled', false);
				$(e.currentTarget)
					.findElement('[name="passwordChanged"]')
					.val('true');
			});
		settingsModal
			.findElement('#mail-password-change-collapse')
			.ev('hidden.bs.collapse.mails', e => {
				$(e.currentTarget)
					.findElement('#newPassword')
					.prop('disabled', true);
				$(e.currentTarget)
					.findElement('[name="passwordChanged"]')
					.val('false');
			});
		settingsModal.findElement('.save-btn').ev('click', e => {
			e.preventDefault();
			const saved = Object.assign(
				{},
				{
					incomingProto: data.incomingProto
				},
				getFormData(settingsModal)
			);
			saved.incomingPort = parseInt(saved.incomingPort);
			saved.incomingSSL = saved.incomingSSL === 'true';
			saved.outgoingPort = parseInt(saved.outgoingPort);
			saved.outgoingSSL = saved.outgoingSSL === 'true';
			saved.outgoingAuth = saved.outgoingAuth === 'true';
			saved.passwordChanged = saved.passwordChanged === 'true';
			saved.signature = signatureEditor.data();
			settingsModal.modal('hide');
			onSave(saved);
		});
		settingsModal.on('hidden.bs.modal', () => {
			settingsModal.modal('dispose');
			settingsModal.remove();
		});
		settingsModal.modal('show');
		window.activateElements(settingsModal);
	}

	nextNewAccountID(target) {
		let maxID = 0;
		target.find('input[name^="mailAccounts"]').each((_index, form) => {
			let idstr = $(form)
				.attr('name')
				.match(/mailAccounts\[new(\d+)\]/)[1];
			if (idstr) {
				maxID = Math.max(parseInt(idstr), maxID);
			}
		});

		return 'new' + (maxID + 1);
	}

	handleRoute(route) {
		if (route.startsWith('/mails')) {
			$(document).trigger(
				'renderController',
				[window.targetElement, window.Bootstrap]
			);
			this.renderMailsRoute();
			return;
		}
		throw new Error(`Don't know how to handle route: '${route}'`);
	}

	static canHandleRoute(route) {
		if (route.startsWith('/mails')) {
			return true;
		}
		return false;
	}
}
