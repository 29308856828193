module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.phoneNumber : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return "<br>\n<a href='/dialer/dial/?number="
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0),{"name":"fullPhoneNumber","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0),{"name":"fullPhoneNumber","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "' class='btn btn-success dialer-dial nofollow' style='display: inline-block; width: 100%; text-align: left;' data-number='"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0),{"name":"fullPhoneNumber","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0),{"name":"fullPhoneNumber","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "'><span class='glyphicon glyphicon-earphone'></span>&nbsp;"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.call_number",{"name":"tr","hash":{},"data":data}))
    + "</a>\n";
},"useData":true})