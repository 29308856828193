<template>
    <div ref="quill"></div>
</template>

<script>
import Quill from './index.js';
import {isEqual} from 'lodash';

export default {
	props: {
		content: Object,
		html: String,
		text: String,
		disabled: Boolean
	},
	data() {
		return {
			quill: null,
			contentJSON: this.content
		};
	},
	mounted() {
		this.quill = new Quill(this.$refs.quill , {
			readOnly: false,
			theme: 'snow',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],

					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'script': 'sub'}, { 'script': 'super' }],

					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

					[{ 'color': [] }, { 'background': [] }],
					[{ 'font': [] }],
					[{ 'align': [] }],
					['link', 'image'],
					['blockquote', 'code-block'],
					['clean']
				],
				autoLinks: true
			}
		});
		if (this.contentJSON) {
			this.quill.setContents(this.contentJSON);
			this.$emit('update:html', this.getHtml());
			this.$emit('update:text', this.getText());
		} else if (this.html) {
			this.quill.pasteHTML(this.html);
			this.$emit('update:content', this.quill.getContents());
			this.$emit('update:text', this.getText());
		} else if (this.text) {
			this.quill.insertText(0, this.text);
			this.$emit('update:content', this.quill.getContents());
			this.$emit('update:html', this.getHtml());
		}

		this.quill.on('text-change', delta => {
			this.contentJSON = this.quill.getContents();
			this.$emit('update:content', this.contentJSON);
			this.$emit('update:html', this.getHtml());
			this.$emit('update:text', this.getText());
		});

		this.quill.enable(!this.disabled);
	},
	methods: {
		getHtml() {
			return this.$refs.quill.firstChild.innerHTML;
		},
		getText() {
			return this.$refs.quill.firstChild.innerText;
		},
		getJson() {
			return this.quill.getContents();
		},
		focus() {
			this.quill.focus();
		}
	},
	watch: {
		content(newContent) {
			if (!isEqual(this.contentJSON, newContent)) {
				this.contentJSON = newContent;
				this.quill.setContents(this.contentJSON);
			}
		},
		disabled(isDisabled) {
			this.quill.enable(!isDisabled);
		}
	}
};
</script>
