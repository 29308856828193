import Module from 'BootQuery/Assets/js/module';
import Vue from 'BootQuery/Assets/js/vue.js';
import GsmTrackersComponent from '../components/GsmTrackers.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default class GsmTrackers extends Module {
	constructor() {
		super();
		this._alerts = null;
	}

	init() {
		super.init();
		this.alerts();
		this.subscribeWebSocket(
			'gsmTrackers/alert',
			this.onAlert.bind(this)
		);
		this.subscribeWebSocket(
			'gsmTrackers/alertResolved',
			this.onAlertResolved.bind(this)
		);
	}

	smsAccounts() {
		return Api.get('/api/sms/accounts');
	}

	trackers() {
		return Api.get('/api/gsmTrackers/trackers');
	}

	/**
	 * Fetches list of active alerts, grouped by tracker
	 */
	async alerts() {
		if (this._alerts === null) {
			const alerts = await Api.get('/api/gsmTrackers/alerts/active');
			this._alerts = alerts.reduce((groups, alert) => {
				this._groupAlert(groups, alert);
				return groups;
			}, []);
			this.refreshMenuAlertCounter();
		}
		return this._alerts;
	}

	onAlert(alert) {
		this._groupAlert(this._alerts, alert);
		this.refreshMenuAlertCounter();
	}

	onAlertResolved(alertInfo) {
		const groupIdx = this._alerts.findIndex(
			group => group.trackerID === alertInfo.trackerID
		);
		if (groupIdx !== -1) {
			const group = this._alerts[groupIdx];
			group.alerts = group.alerts.filter(
				alert => alert.ID !== alertInfo.alertID
			);
			if (group.alerts.length === 0) {
				this._alerts.splice(groupIdx, 1);
			}
		}
		this.refreshMenuAlertCounter();
	}

	_groupAlert(groups, alert) {
		let group = groups.find(
			group => group.trackerID === alert.trackerID
		);
		if (!group) {
			group = {
				trackerID: alert.trackerID,
				trackerName: alert.trackerName,
				personID: alert.personID,
				personName: alert.personName,
				phoneNumber: alert.phoneNumber,
				alerts: []
			};
			groups.push(group);
		}
		group.alerts.push(alert);
		return groups;
	}

	refreshMenuAlertCounter() {
		const menuItem = $(
			'.menu-container .nav-item > .nav-link[data-controller="gsmTrackers"]'
		);
		const menuItemBadge = menuItem.find('.menu-item-notification-counter');
		const count = this.alerts ? this._alerts.length : 0;
		menuItemBadge.text(count);
		menuItemBadge.prop('hidden', count === 0);
	}

	activateElements(target, _data) {
		const $container = target.findElement('#gsm-trackers-container');
		if ($container.length) {
			this.renderGsmTrackers($container);
		}
	}

	renderGsmTrackers($container) {
		const container = $container[0];
		if(container.dataset.activated) {
			return; // Don't double-render
		}
		container.dataset.activated = true;
		setTimeout(() => {
			this.component = new Vue({
				el: container,
				render: h => h(GsmTrackersComponent)
			});
		}, 0);
	}


	static canHandleRoute(route) {
		return route.startsWith('/gsmTrackers/trackers');
	}

	handleRoute(route) {
		if (route.startsWith('/gsmTrackers/trackers')) {
			$(document).trigger(
				'renderController',
				[window.targetElement, window.Bootstrap]
			);
			const tgt = $('<div/>', {
				id: 'gsm-trackers-container'
			});
			$(window.targetElement).html(tgt);
			this.renderGsmTrackers(tgt);
			$(document).trigger(
				'activateElements',
				[$(window.targetElement), window.Bootstrap]
			);
		} else {
			throw new Error(`Don't know how to handle route '${route}'`);
		}
	}
}