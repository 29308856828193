import Handlebars from 'handlebars/runtime';
import handlebarsLayouts from 'handlebars-layouts';
handlebarsLayouts.register(Handlebars);

import {get, mapValues} from 'lodash';
import phpToMomentDateFormat from 'BootQuery/Assets/js/phpToMoment.js';
import {arrayToPath} from 'app/assets/js/util.js';

window.newGetTemplate = function (name, module, moduleMandatory = false) {
	let nameParts = name.split('.');
	if (nameParts.length === 2) {
		module = nameParts[0];
		name = nameParts[1];
		if (module.endsWith('?')) {
			module = module.slice(0, -1);
			moduleMandatory = false;
		} else {
			moduleMandatory = true;
		}
	}
	if (!module) {
		module = 'global';
	}
	let fullName = `${module}.${name}`;
	let template = get(window.templates, fullName);
	if (!template && !moduleMandatory) {
		template = get(window.templates, `global.${name}`);
	}

	if (!template) {
		throw `Unable to get template ${name} for module ${module}`;
	}
	if (module) {
		template.__moduleName = module;
	}
	template.__templateName = name;
	return template;
};

const handlebarsResolvePartial = Handlebars.VM.resolvePartial;
Handlebars.VM.resolvePartial = function (partial, content, options) {
	if (!partial) {
		let moduleName = get(options, 'data.root.__moduleName');

		partial = window.newGetTemplate(options.name, moduleName);
		if (!partial) {
			throw `Partial resolver failed to load: ${options.name}`;
		}
	}
	return handlebarsResolvePartial.call(this, partial, content, options);
};

const handlebarsInvokePartial = Handlebars.VM.invokePartial;
Handlebars.VM.invokePartial = function (partial, context, options) {
	if (!partial) {
		partial = Handlebars.VM.resolvePartial(partial, context, options);
	}
	if (partial.__moduleName) {
		options.data.__moduleName = partial.__moduleName;
	}
	return handlebarsInvokePartial.call(this, partial, context, options);
};

const lookupMap = function lookupMapHelper(lookupIn, lookupArr) {
	if (!lookupIn) {
		return;
	}
	if (typeof(lookupArr) === 'object') {
		return mapValues(lookupArr, function(value, key) {
			return lookupMap(lookupIn, value);
		});
	} else {
		if (typeof(lookupArr) === 'string') {
			var key = null;
			var lastCharIndex = lookupArr.length - 1;

			if (lookupArr.length > 2 && lookupArr[0] === '$' && lookupArr[lastCharIndex] === '$') {
				key = lookupArr.substring(1, lastCharIndex);
			}
			if (key) {
				return lookupIn[key];
			} else {
				return lookupArr;
			}
		} else {
			return lookupArr;
		}
	}
};
Handlebars.registerHelper('lookupMap', lookupMap);
Handlebars.registerHelper('encode_query_string', function encodeQueryStringHelper(params) {
	return serialize(params);
});

Handlebars.registerHelper('tr', function trHelper(...params) {
	const ctx = params.pop();

	const moduleHint =
		get(ctx, 'data.__moduleName') ||
		get(ctx, 'data.root.__moduleName') ||
		'global';

	const tmpl = tr(params.join('.'), moduleHint);
	if (typeof(tmpl) === 'function') {
		return handlebarsRender(tmpl, this);
	} else {
		return tmpl;
	}
});

Handlebars.registerHelper('format_date', function formatDateHelper(date, format, sourceFormat) {
	if (!date) {
		return null;
	}

	if (format) {
		format = phpToMomentDateFormat(format);
	} else {
		format = 'L. LTS';
	}

	let momentDate = null;
	if(sourceFormat) {
		momentDate = moment(date, phpToMomentDateFormat(sourceFormat));
	} else {
		momentDate = moment(date);
		momentDate.locale(window.Bootstrap.bootquery.session.locale.slice(0, window.Bootstrap.bootquery.session.locale.indexOf('_')));
	}
	return momentDate.format(format);
});

Handlebars.registerHelper('format_time', function formatDateHelper(time, format, sourceFormat) {
	if (!time) {
		return null;
	}
	var fromFormat;

	if(sourceFormat) {
		fromFormat = phpToMomentDateFormat(sourceFormat);
		return moment(time, fromFormat).formatPHP(format);
	} else {
		var momentDate = moment(time, 'HH:mm:ss');
		momentDate.locale(window.Bootstrap.bootquery.session.locale.slice(0, window.Bootstrap.bootquery.session.locale.indexOf('_')));

		return momentDate.format('HH:mm:ss');
	}
});

Handlebars.registerHelper('encode_filters', function encodeFiltersHelper(filters, tableName) {
	var components = [];
	var prefix = '';
	if (tableName) {
		prefix = tableName + '-';
	}
	for (var k in filters) {
		var filter = filters[k];
		var key = prefix + filter.key;
		if (filter.value) {
			components.push(key + '=' + encodeURIComponent(filter.value));
		}
	}
	return components.join('&');
});
Handlebars.registerHelper('array_to_path', function() {
	var args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
	return arrayToPath(args);
});
Handlebars.registerHelper('concat', function() {
	var args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
	return args.join('');
});

Handlebars.registerHelper('resolveAssetName', function(assetName) {
	return window.build_manifest[assetName] || `[no_build_info_for_${assetName}]`;
});
Handlebars.registerHelper('render_string', function() {
	throw new Error('render_string helper only available server-side');
});

window.Handlebars = Handlebars;
export default Handlebars;