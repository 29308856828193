<template>
    <div class="input-group bg-light">
		<div class="input-group-prepend border-top">
			<button class="btn btn-link" @click.prevent="pickFile">
				<span class="fas fa-paperclip"></span>
			</button>
		</div>
        <input class="form-control border-bottom-0" type="text" v-model="text" @keyup.enter="submit">
        <div class="input-group-append">
            <button class="btn btn-success rounded-0" :disabled="disabled" @click.prevent="submit">
                <span class="fas fa-arrow-right"></span>
            </button>
        </div>
    </div>
</template>

<script>
import FilePicker from 'BootQuery/Assets/js/filePicker.js';

export default {
	data() {
		return {
			text: ''
		};
	},
	methods: {
		submit() {
			if (!this.disabled) {
				this.$emit('submit', {
					text: this.text.trim()
				});
				this.text = '';
			}
		},
		pickFile() {
			const picker = new FilePicker();
			picker.open();
			picker.on('files', files => {
				// We didn't allow multiselect, so always 1 file
				const file = files[0];
				this.$emit('submit', {
					attachment: file
				});
			});
		}
	},
	computed: {
		disabled() {
			return this.text.trim().length === 0;
		}
	}
};
</script>

