module.exports = {"name":"Biranje","button":{"call_number":(window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fullPhoneNumber","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.phoneNumber || (depth0 != null ? depth0.phoneNumber : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"phoneNumber","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "Nazovi ";
  stack1 = ((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : alias2),(options={"name":"fullPhoneNumber","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.fullPhoneNumber) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.fullPhoneNumber || (depth0 != null ? depth0.fullPhoneNumber : depth0)) != null ? helper : alias2),(options={"name":"fullPhoneNumber","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.fullPhoneNumber) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true}),"call":"Nazovi","hang_up":"Prekini poziv","put_on_hold":"Stavi na čekanje","transfer":"Preusmjeri","redial":"Biraj ponovo","features":"Funkcije","dnd":"Ne smetaj","answer":"Odgovori"},"label":{"pick_up":"Javljanje","incoming_call":"Dolazni poziv","in_call":"U pozivu","calling":"Pozivanje","idle":"Spreman","waiting_to_pick_up":"Čekanje na javljanje","ringing":"Zvonjenje","redirect_settings":"Preusmjerenja","cant_call_without_extension":"Ne možete zvati bez aktivnog lokala"},"state":{"available":"Dostupan","away":"Odsutan","xa":"Produljeno odsutan","unavailable":"Nedostupan","dnd":"Ne smetaj"},"form":{"call_forward":"Preusmjerenja","call_forward_unconditional":"Bezuvjetno preusmjerenje","call_forward_no_answer":"Ne odgovara","call_forward_busy":"Zauzet","call_forward_away":"Odsutan","call_forward_xa":"Produljeno odsutan"}}