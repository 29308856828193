export function processMessage(message) {
	message.date = new Date(message.date);
	return message;
}

export function processThread(thread) {
	thread.firstMessageDate = new Date(thread.firstMessageDate);
	thread.lastMessageDate = new Date(thread.lastMessageDate);
	if (thread.messages) {
		thread.messages = thread.messages.map(processMessage);
	}
	return thread;
}
