module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <li class=\""
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1((depth0 != null ? depth0.active : depth0), depth0),{"name":"active","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"margin-top: 0px;\">\n                                <a href=\"/"
    + alias2(alias1((depth0 != null ? depth0.href : depth0), depth0))
    + "/"
    + ((stack1 = container.invokePartial(partials.encode_filters,depth0,{"name":"encode_filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\" style=\"border-radius: 2px 2px 0px 0px;\">\n                                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.icon : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                    <span class=\"visible-lg visible-md\" style=\"display: inline-block !important; position: relative; margin-left: 10px;\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</span>\n                                </a>\n                            </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    return "<span class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "-->\n                    <tr>\n                        <td style=\"text-align: center;\">\n                            <a href=\"/voicemails/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?extensionID_eq="
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" title=\""
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_entry",{"name":"tr","hash":{},"data":data}))
    + "\" data-toggle=\"tooltip\" data-placement=\"bottom\" class=\"btn btn-default\">\n                                <span class=\"glyphicon glyphicon-edit\"></span>\n                            </a>\n                        </td>\n                        <td>"
    + alias2(alias1((depth0 != null ? depth0.extension : depth0), depth0))
    + "</td>\n                    </tr>\n                <!--";
},"8":function(container,depth0,helpers,partials,data) {
    return "-->\n                    <tr><td colspan=\"9\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data}))
    + "</td></tr>\n                <!--";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<form action=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.method : stack1), depth0))
    + "/\" method=\"get\" class=\"form form-horizontal\" id=\"filter-form\">\n    <div class=\"section-head\">\n        <div class=\"row section-title\">\n            <span class=\"glyphicon glyphicon-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.icon : stack1), depth0))
    + "\"></span>&nbsp;&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n            <span class=\"minimize-toggle glyphicon glyphicon-minus\"></span>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-12\">\n                <div class=\"col-md-12 col-lg-12\">\n                    <ul class=\"nav nav-tabs\" id=\"myTab\" style=\"padding-right: 0;\">\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                        <li class=\"pull-right\">\n                            "
    + ((stack1 = container.invokePartial(partials.limitSelector,depth0,{"name":"limitSelector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </li>\n\n                        <li class=\"pull-right\">\n                            <div>\n                                <a href=\"/voicemails/\" class=\"btn btn-info\">\n                                    <span class=\"glyphicon glyphicon-th-list\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.back_to_list",{"name":"tr","hash":{},"data":data}))
    + "\n                                </a>\n                                &nbsp;\n                            </div>\n                        </li>\n                    </ul>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>\n<div class=\"row\">\n    <div class=\"col-md-12 datatable\">\n        <table class=\"table table-striped table-hover datatable\" id=\"voicemailssettings-table\">\n            <thead>\n                <tr>\n                    "
    + ((stack1 = container.invokePartial(partials.tableheader,depth0,{"name":"tableheader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </tr>\n            </thead>\n            <tbody>\n                <!--"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.result : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "-->\n            </tbody>\n        </table>\n        "
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true})