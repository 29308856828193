module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "	<input type=\"hidden\" name=\"missed\" id=\"missed\" value=\"${missedCount}\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<form action=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.method : stack1), depth0))
    + "/\" method=\"get\" class=\"form form-horizontal\" id=\"filter-form\">\n\n	<div class=\"section-head\">\n\n		<div class=\"row section-title\">\n			<span class=\"glyphicon glyphicon-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.icon : stack1), depth0))
    + "\"></span>&nbsp;&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n		</div>\n\n		<div class=\"row\">\n\n			<div class=\"col-md-12\">\n\n				<div class=\"col-md-12 col-lg-12\">\n\n					<ul class=\"nav nav-tabs\" id=\"myTab\" style=\"padding-right: 0;\">\n\n						"
    + ((stack1 = container.invokePartial(partials.tabs,depth0,{"name":"tabs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n						<li class=\"pull-right "
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.parameters : stack1), depth0),{"name":"bootquery.parameters","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " \">\n							<a href=\"#\" role=\"tab\" id=\"filter-toggle\" style=\"margin-right: 0;\" class=\"noparse\">\n								<span class=\"glyphicon glyphicon-filter\"></span>\n								&nbsp;"
    + alias2(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.filters",{"name":"tr","hash":{},"data":data}))
    + "&nbsp;\n								<span class=\"glyphicon glyphicon-chevron-left pull-right collapse-indicator\"></span>\n							</a>\n						</li>\n\n						<li class=\"pull-right\">\n							"
    + ((stack1 = container.invokePartial(partials.limitSelector,depth0,{"name":"limitSelector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "						</li>\n					</ul>\n\n					<div class=\"tab-content\">\n\n						<div class=\"tab-pane active\">\n\n							"
    + ((stack1 = container.invokePartial(partials.filters,depth0,{"name":"filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n						</div>\n\n					</div>\n\n				</div>\n\n			</div>\n\n		</div>\n\n		<div class=\"row data-state\">\n			"
    + ((stack1 = container.invokePartial(partials.refreshTypeToggle,depth0,{"name":"refreshTypeToggle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n			<div class=\"col-xs-12 col-md-6 text-right\">\n				"
    + ((stack1 = container.invokePartial(partials.reportDropdown,depth0,{"name":"reportDropdown","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</div>\n		</div>\n\n	</div>\n\n</form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "active";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		"
    + ((stack1 = container.invokePartial(partials.reportHeader,depth0,{"name":"reportHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n		<table class=\"report-content repeat-header\">\n			<thead>\n				<tr>\n					"
    + ((stack1 = container.invokePartial(partials.tableheader,depth0,{"name":"tableheader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</tr>\n			</thead>\n\n			<tbody>\n			"
    + ((stack1 = container.invokePartial(partials.callRow,depth0,{"name":"callRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n			<!--"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.result : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "-->\n			</tbody>\n		</table>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "-->\n				<tr>\n					<td colspan=\"7\">\n						Nema rezultata\n					</td>\n				</tr>\n			<!--";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<table class=\"table table-striped\" id=\"allcalls-table\" data-datatable-row-partial=\"callRow\" data-datatable-limit=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.resultdata : depth0)) != null ? stack1.limit : stack1), depth0))
    + "\" data-datatable-events=\"CallEnd\">\n\n			<thead>\n				<tr>\n					"
    + ((stack1 = container.invokePartial(partials.tableheader,depth0,{"name":"tableheader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</tr>\n\n			</thead>\n\n			<tbody>\n\n			"
    + ((stack1 = container.invokePartial(partials.queueMissedCallRow,depth0,{"name":"queueMissedCallRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n				<!--"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.result : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "-->\n\n			</tbody>\n\n			<tfoot>\n				<!-- "
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.resultdata : depth0),{"name":"with","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " -->\n\n			</tfoot>\n		</table>\n\n		"
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "-->\n					<tr><td></td><td colspan=\"5\">Nema rezultata</td></tr>\n				<!--";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return " -->\n				<tr style=\"font-weight: bold;\">\n\n					<td></td>\n					<td colspan=\"3\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.count : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "					</td>\n\n					<td style=\"text-align: right;\">\n						"
    + alias2(helpers.tr.call(alias1,"label.total_calltime",{"name":"tr","hash":{},"data":data}))
    + ":\n					</td>\n\n					<td>\n						"
    + alias2(container.lambda((depth0 != null ? depth0.callTimeTotal : depth0), depth0))
    + "\n					</td>\n\n				</tr>\n				<!-- ";
},"13":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.total_calls",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "							"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_calls",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n\n	<div class=\"col-md-12\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n	</div>\n\n</div>\n";
},"usePartial":true,"useData":true})