module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<progress max=\""
    + alias2(alias1((depth0 != null ? depth0.count : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? depth0.used : depth0), depth0))
    + "\" id=\"completion-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"></progress>\n&nbsp;<label for=\"completion-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.used : depth0), depth0))
    + " / "
    + alias2(alias1((depth0 != null ? depth0.count : depth0), depth0))
    + "</label>\n";
},"useData":true})