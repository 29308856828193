module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.success : depth0), depth0),{"name":"success","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.failure : depth0), depth0),{"name":"failure","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                                    <div class=\"col-md-10 col-md-offset-1 alert alert-success\">Promjene uspješno spremljene</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                                    <div class=\"col-md-10 col-md-offset-1 alert alert-danger\">Greška pri spremanju promjena</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.mailboxForm : depth0), depth0),{"name":"mailboxForm","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + ((stack1 = container.invokePartial(partials.form,depth0,{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "                                <div class=\"pull-left\">\n                                    <a href=\"/voicemails/mailboxes/\" class=\"btn btn-info\">\n                                        <span class=\"glyphicon glyphicon-chevron-left\"></span>&nbsp;Povratak na listu\n                                    </a>\n                                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                                <div class=\"pull-right\">\n                                    <a href=\"/phonebook/companies/\" class=\"btn btn-danger\" data-dismiss=\"modal\">\n                                        <span class=\"glyphicon glyphicon-remove\"></span>&nbsp;Odustani\n                                    </a>\n                                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"content-wrapper\">\n\n<div class=\"container-fluid\" id=\"main-container\">\n\n    "
    + ((stack1 = container.invokePartial(partials.header,depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"row-fluid\">\n\n        "
    + ((stack1 = container.invokePartial(partials.menu,depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"col-md-12 col-lg-12 bq-content bq-default\">\n            \n            <div class=\"panel panel-default\">\n\n                <div class=\"panel-heading\" id=\"voicemails-mailbox-header\">\n\n                    <span class=\"glyphicon glyphicon-edit\"></span>&nbsp;Uredi \n\n                </div>\n\n                <div class=\"panel-body\">\n\n                    <div id=\"voicemails-mailbox-container\">\n\n                        <form id=\"voicemails-mailbox-form\" class=\"form-horizontal\" action=\"/voicemails/addMailbox\" method=\"post\" role=\"form\">\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.bootquery : depth0), depth0),{"name":"bootquery","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                            <input type=\"hidden\" name=\"action\" value=\"update\">\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.form : depth0), depth0),{"name":"form","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                        </form>\n\n                    </div>\n\n                </div>\n\n                <div class=\"panel-footer\">\n                    <div class=\"row\">\n                        <div class=\"col-xs-12\" style=\"float: left;\">\n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.isModal : stack1), depth0),{"name":"bootquery.isModal","hash":{},"fn":container.noop,"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                                <div class=\"save-button pull-right\">\n                                    <button type=\"submit\" class=\"btn btn-success pull-right\" form=\"voicemails-mailbox-form\">\n                                        <span class=\"glyphicon glyphicon-ok\"></span>&nbsp;Spremi\n                                    </button>\n\n                                    <span class=\"save-status pull-right\" style=\"padding: 7px 15px;\" data-form=\"phonebook-modify-company-form\">\n                                        \n                                    </span>\n                                </div>\n\n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.isModal : stack1), depth0),{"name":"bootquery.isModal","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n                        </div>\n                    </div>\n                </div>\n\n            </div>\n\n        </div>\n\n    </div>\n\n</div>\n\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true})