module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<span class=\"fa fa-sitemap\"></span>&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.queue : depth0), depth0))
    + "\n	"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "(";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<span>\n		<div class=\"popover-content-element\" hidden>\n			<div class=\"text-center\">\n				<span class=\"fa fa-lg fa-spinner fa-spin\"></span>\n			</div>\n		</div>\n		<a href=\"#\" class=\"contact-number-link noparse\" data-toggle=\"popover\" data-container=\"body\" data-trigger=\"click\" data-popover-content-element=\"true\" data-html=\"true\" data-person-id=\""
    + alias2(alias1((depth0 != null ? depth0.personID : depth0), depth0))
    + "\" data-company-id=\""
    + alias2(alias1((depth0 != null ? depth0.companyID : depth0), depth0))
    + "\" data-user-id=\""
    + alias2(alias1((depth0 != null ? depth0.userID : depth0), depth0))
    + "\" data-phone-number=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].context : depths[1])) != null ? stack1.phoneNumber : stack1), depth0))
    + "\" data-number-e164=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].context : depths[1])) != null ? stack1.numberE164 : stack1), depth0))
    + "\" title=\"&lt;span class=&quot;fas fa-phone&quot;&gt;&lt;/span&gt; "
    + alias2(alias1(((stack1 = (depths[1] != null ? depths[1].context : depths[1])) != null ? stack1.phoneNumber : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isUser : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "			"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n		</a>\n	</span>\n	"
    + ((stack1 = helpers.unless.call(alias3,(data && data.last),{"name":"unless","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "				<span class=\"fas fa-user\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isPerson : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "				<span class=\"fas fa-briefcase\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCompany : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "				<span class=\"fas fa-building\"></span>\n			";
},"13":function(container,depth0,helpers,partials,data) {
    return " ili ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.phoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "		<span>\n			<div class=\"popover-content-element\" hidden>\n				<div class=\"text-center\">\n					<span class=\"fa fa-lg fa-spinner fa-spin\"></span>\n				</div>\n			</div>\n			<a href=\"#\" class=\"number-link noparse\"  data-toggle=\"popover\" data-container=\"body\" data-trigger=\"click\" data-popover-content-element=\"true\" data-html=\"true\" data-phone-number=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.phoneNumber : stack1), depth0))
    + "\" data-number-e164=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.numberE164 : stack1), depth0))
    + "\" title=\"&lt;span class=&quot;fas fa-phone&quot;&gt;&lt;/span&gt; "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.phoneNumber : stack1), depth0))
    + "\">\n				<span class=\"fas fa-phone\"></span>\n				"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.phoneNumber : stack1), depth0))
    + "\n			</a>\n		</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    return ")";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.queue : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.context : depth0)) != null ? stack1.contacts : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.queue : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true})