import { render, staticRenderFns } from "./ConversationArea.vue?vue&type=template&id=34e9bf95&scoped=true&"
import script from "./ConversationArea.vue?vue&type=script&lang=js&"
export * from "./ConversationArea.vue?vue&type=script&lang=js&"
import style0 from "./ConversationArea.vue?vue&type=style&index=0&id=34e9bf95&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e9bf95",
  null
  
)

component.options.__file = "ConversationArea.vue"
export default component.exports