module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "print";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	        "
    + ((stack1 = container.invokePartial(partials.menu,depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"content-wrapper\">\n\n	<div class=\"container "
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1), depth0),{"name":"bootquery.report","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"main-container\">\n\n	    "
    + ((stack1 = container.invokePartial(partials.header,depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n	    <div class=\"row\">\n\n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.report : stack1), depth0),{"name":"bootquery.report","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n	        <div class=\"col-md-12 col-lg-12 bq-content bq-default paginated\">\n            	"
    + ((stack1 = container.invokePartial(partials.queueMissedCallsTable,depth0,{"name":"queueMissedCallsTable","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	        </div>\n\n	    </div>\n\n	</div>\n\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})