module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "text-muted";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"fas fa-times\"></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#\" class=\"text-danger ticket-reminder-delete-btn\">\n            <span class=\"fas fa-times\"></span>\n        </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "reminder-done";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"fas fa-edit\"></span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#\" class=\"ticket-reminder-edit-btn\">\n            <span class=\"fas fa-edit\"></span>\n        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<li class=\"ticket-reminder row "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.done : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-ticket-reminder-id=\""
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n    <div class=\"col-auto\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.done : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"col "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.done : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <strong>"
    + alias3(helpers.format_date.call(alias1,(depth0 != null ? depth0.timestamp : depth0),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "</strong> <br>\n        "
    + alias3(alias2((depth0 != null ? depth0.title : depth0), depth0))
    + "\n    </div>\n\n    <div class=\"col-auto\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.done : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</li>";
},"useData":true})