module.exports = {"label":{"no_result":"Nema rezultata","filters":"Filteri","refresh_type":"Ažuriranje","refresh_manual":"Ručno osvježavanje","refresh_auto":"Automatsko osvježavanje","refresh":"Osvježavi podatke","refresh_up_to_date":"Podatci ažurni","edit_entry":"Uredi zapis","view_entry":"Pregled zapisa","entry_details":"Detalji zapisa","back_to_list":"Povratak na popis","or":"ili","save_success":"Promjene uspješno spremljene","save_failure":"Greška pri spremanju promjena","actions":"Operacije","processing":"U tijeku","status":"Status","file":"Datoteka","view_data":"Pregled podataka","select":"Odaberi","10_results_per_page":"10 rezultata po stranici","20_results_per_page":"20 rezultata po stranici","30_results_per_page":"30 rezultata po stranici","records_total":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "Ukupno zapisa: "
    + container.escapeExpression(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"count","hash":{},"data":data}) : helper)));
},"useData":true}),"unsaved_data":"Nespremljeni podatci","unsaved_data_body":"Podaci nisu spremljeni. Želite li spremiti podatke?","delete_entry":"Obriši stavku","delete_entry_body":"Jeste li sigurni da želite obrisati izabranu stavku?","saved":"Spremljeno","validation_error":"Netočan unos","saving_in_progress":"Spremanje...","validation_in_progress":"Provjera...","permission_read":"Čitanje","permission_write":"Pisanje","permission_delete":"Brisanje","permission_owner":"Vlasnik","permission_group":"Grupa","permission_others":"Drugi","show_columns":"Prikaži stupce","add":"Dodaj","days":"Dani","hours":"Sati","minutes":"Minute","edit":"Uredi","name":"Naziv","now":"Sada","label":"Naziv","mandatory":"Obavezno","readonly":"Samo za čitanje","edit_items":"Uređivanje stavki"},"button":{"add":"Dodaj","edit":"Uredi","save":"Spremi","discard":"Nemoj spremiti","cancel":"Odustani","delete":"Obriši","settings":"Postavke","display":"Prikaz","back":"Natrag","back_to_list":"Povratak na popis","close":"Zatvori","send":"Pošalji","choose_file":"Izaberi datoteku","download":"Preuzimanje","upload":"Upload"},"form":{"file":"Datoteka","edit_items":"Uređivanje stavki","new_field":"Novo polje","type_text":"Tekst","type_number":"Broj","type_date":"Datum","type_dropdown":"Padajući izbornik","type_list":"Lista","type_textarea":"Slobodni tekst","type_richtext":"Formatirani tekst","type_checkbox":"Kvačica","type_separator":"Separator"},"filter":{"choose_filters":"Odaberite filtere","from_date":"Od (datum)","to_date":"Do (datum)","from_time":"Od (vrijeme)","to_time":"Do (vrijeme)","created_from_date":"Kreiran od (datum)","created_to_date":"Kreiran do (datum)","created_from_time":"Kreiran od (vrijeme)","created_to_time":"Kreiran do (vrijeme)"},"pagination":{"previous":"Prethodna","next":"Sljedeća","indicator":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "Stranica "
    + alias4(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page","hash":{},"data":data}) : helper)))
    + " od "
    + alias4(((helper = (helper = helpers.pageCount || (depth0 != null ? depth0.pageCount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageCount","hash":{},"data":data}) : helper)));
},"useData":true}),"choose_page":"Odaberi stranicu","button":{"go":"Idi"},"placeholder":{"page_number":"Broj stranice"}},"report":{"report":"Izvještaj","print":"Ispis izvještaja","export_excel":"Izvoz u Excel","export_pdf":"Izvoz u PDF","company":"Tvrtka","address":"Adresa","city":"Grad","identification_number":"OIB","generated_on":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "Izvještaj generiran "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.date : stack1), depth0))
    + " u "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.time : stack1), depth0));
},"useData":true})},"format":{"date":"d.m.Y.","time":"H:i:s","datetime":"d.m.Y. H:i","datetime_s":"d.m.Y. H:i:s"},"error":{"permission_denied":"Zabranjen pristup","not_found":"Nije pronađjeno"},"calendar":{"mon_long":"Ponedjeljak","tue_long":"Utorak","wed_long":"Srijeda","thu_long":"Četvrtak","fri_long":"Petak","sat_long":"Subota","sun_long":"Nedjelja","mon":"Pon","tue":"Uto","wed":"Sri","thu":"Čet","fri":"Pet","sat":"Sub","sun":"Ned","jan_long":"Siječanj","feb_long":"Veljača","mar_long":"Ožujak","apr_long":"Travanj","may_long":"Svibanj","jun_long":"Lipanj","jul_long":"Srpanj","aug_long":"Kolovoz","sep_long":"Rujan","oct_long":"Listopad","nov_long":"Studeni","dec_long":"Prosinac","jan":"Sij","feb":"Velj","mar":"Ožu","apr":"Tra","may":"Svi","jun":"Lip","jul":"Srp","aug":"Kol","sep":"Ruj","oct":"Lis","nov":"Stu","dec":"Pro"}}