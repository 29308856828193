module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-plus\"></span>\n					"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.add_reminder",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-edit\"></span>\n					"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_reminder",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<option\n								value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"\n								data-html-text=\"&lt;span class=&quot;badge badge-dark&quot; style=&quot;background-color: "
    + alias2(alias1((depth0 != null ? depth0.color : depth0), depth0))
    + ";&quot;&gt;"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "&lt;/span&gt;\"\n								"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n							>\n								"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n							</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n	<div class=\"modal-dialog\" role=\"document\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h5 class=\"modal-title\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "				</h5>\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n					<span aria-hidden=\"true\">&times;</span>\n				</button>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"form-group row \">\n                    <label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"reminder-title\">\n                        "
    + alias2(helpers.tr.call(alias1,"form.title",{"name":"tr","hash":{},"data":data}))
    + ":\n                    </label>\n                    <div class=\"col-12 col-md-8\">\n                        <input type=\"text\" name=\"reminder[title]\" class=\"form-control\" value=\""
    + alias2(alias3((depth0 != null ? depth0.title : depth0), depth0))
    + "\" id=\"reminder-title\">\n                    </div>\n                </div>\n				<div class=\"form-group row\">\n                    <label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"reminder-date\">\n                        "
    + alias2(helpers.tr.call(alias1,"form.time",{"name":"tr","hash":{},"data":data}))
    + ":\n                    </label>\n                    <div class=\"col-12 col-md-8\">\n						<div class=\"input-group date datetimepicker\">\n							<div class=\"input-group-prepend\">\n								<span class=\"input-group-text\">\n									<span class=\"far fa-calendar-alt\"></span>\n								</span>\n							</div>\n							<input class=\"form-control\" type=\"text\" name=\"reminder[date]\" value=\""
    + alias2(alias3((depth0 != null ? depth0.date : depth0), depth0))
    + "\" id=\"reminder-date\">\n						</div>\n                    </div>\n                </div>\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-4 col-form-label control-label text-left text-md-right\" for=\"reminder-new-state\">\n                        "
    + alias2(helpers.tr.call(alias1,"form.state_after_reminder",{"name":"tr","hash":{},"data":data}))
    + ":\n                    </label>\n                    <div class=\"col-12 col-md-8\">\n						<select class=\"pickle\" name=\"reminder[newState]\" id=\"reminder-new-state\">\n							<option id=\"null\">&nbsp;</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.states : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</select>\n                    </div>\n				</div>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias2(helpers.tr.call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-success save-btn\" disabled>\n					<span class=\"fa fa-check\"></span> "
    + alias2(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true})