module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"badge badge-primary\">\n                    New\n                </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<li href=\"#\" class=\"list-group-item py-2\">\n    <div class=\"row\">\n        <div class=\"col\">\n            "
    + alias2(alias1((depth0 != null ? depth0.email : depth0), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNewAccount : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"col-auto\">\n            <button type=\"button\" class=\"edit-btn btn btn-sm btn-primary\" data-edit-mail-account data-mail-account-id=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n                <span class=\"fa fa-cog\"></span>\n            </button>\n        </div>\n    </div>\n</li>";
},"useData":true})