module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_hidden : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(25, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<fieldset id=\""
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "-filter_"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" style=\""
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.filter_active : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n			<div class=\"input-group "
    + alias2(alias1((depth0 != null ? depth0.input_group_class : depth0), depth0))
    + "\">\n				<span class=\"input-group-prepend\">\n					<span class=\"input-group-text\">\n						"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.has_addon : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + ":\n					</span>\n				</span>\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.is_dropdown : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"input-group-append\">\n					<button type=\"button\" class=\"btn btn-xs btn-danger filters-cancel-button\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"fa fa-times\"></span>\n					</button>\n					<button type=\"submit\" class=\"btn btn-xs btn-success filters-add-button\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"fa fa-check\"></span>\n					</button>\n				</div>\n			</div>\n\n		</fieldset>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "display: none;";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.addon_icon : depth0), depth0))
    + "\"></span>&nbsp;";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "					<select class=\"filters-pickle\" name=\""
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.multiple : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<option value=\"\"></option>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.options : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					</select>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"10":function(container,depth0,helpers,partials,data) {
    return "multiple";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "							<option value=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n								<span class=\""
    + alias2(alias1((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.text : depth0), depth0))
    + "\n							</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_checkbox : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.program(18, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<input type=\"checkbox\" value=\"true\" name=\""
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" disabled>\n";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "					<input type=\""
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "\" class=\"form-control "
    + alias2(alias1((depth0 != null ? depth0.input_class : depth0), depth0))
    + "\" name=\""
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\" value=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-autocomplete=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.autocomplete : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.data_attributes : depth0),{"name":"each","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " autocomplete=\"off\" style=\"border-top-right-radius: 0px; border-bottom-right-radius: 0px;\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n				";
},"19":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.value : depth0), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.autocomplete : depth0), depth0));
},"23":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "data-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\"";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<input type=\"hidden\" name=\""
    + alias2(alias1((depths[1] != null ? depths[1].tableName : depths[1]), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" value=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.value : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<fieldset id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-picker\">\n	<div class=\"input-group\">\n		<div style=\"display: flex; flex: 1 1 auto;\">\n			<select class=\"filter-selector form-control\" style=\"height: 39px;\" multiple=\"\"></select>\n		</div>\n		<span class=\"input-group-append\">\n			<button href=\"#\" class=\"btn btn-secondary filters-clear-button\" id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-filters-clear\" type=\"button\">\n				<span class=\"fa fa-times\"></span>\n				<span class=\"d-none d-sm-inline\">&nbsp;Clear</span>\n			</button>\n		</span>\n	</div>\n</fieldset>\n\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.filters : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true})