import { arrayToPath } from 'app/assets/js/util.js';
import { mapValues } from 'lodash';

const parseSize = function(size) {
	const isValidSize = size => {
		if (typeof size !== 'string' && typeof size !== 'number') {
			return false;
		}
		const sizeInt = parseInt(size);
		return sizeInt >= 1 && sizeInt <= 12;
	};
	if (typeof size === 'string' || typeof size === 'number') {
		if (!isValidSize(size)) {
			return null;
		}
		return { xs: size }; // col-{size}, propagates to all sizes
	} else if (typeof size === 'object') {
		const validPrefixes = ['xs', 'sm', 'md', 'lg', 'xl'];
		const invalidPrefixes = Object.keys(size).filter(
			prefix => !validPrefixes.includes(prefix)
		);
		if (invalidPrefixes.length) {
			return null;
		}

		const invalidSizes = Object.values(size).filter(
			size => !isValidSize(size)
		);
		if (invalidSizes.length) {
			return null;
		}

		return mapValues(size, size => parseInt(size));
	} else {
		return null;
	}
};

const sizeToClasses = function(size) {
	const sizeObj = parseSize(size);
	if (!sizeObj) {
		return [];
	}
	return Object.entries(sizeObj).reduce((classes, [prefix, size]) => {
		const classPrefix = prefix === 'xs' ? 'col' : `col-${prefix}`;
		classes.push(`${classPrefix}-${size}`);
		return classes;
	}, []);
};

export default {
	props: {
		value: {},
		name: String,
		label: String,
		disabled: Boolean,
		required: Boolean,
		hasError: Boolean,
		inline: Boolean,
		labelSize: {
			validator: size => parseSize(size) !== null
		},
		inputSize: {
			validator: size => parseSize(size) !== null
		},
		parentPath: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	computed: {
		fieldId() {
			if (!this.name) {
				return null;
			}

			return this.fullFieldPath.join('-');
		},
		fieldName() {
			return arrayToPath(this.fullFieldPath);
		},
		fullFieldPath() {
			if (this.parentPath && this.parentPath.length) {
				return this.parentPath.concat([this.name]);
			} else if (this.name && this.name.length) {
				return [this.name];
			} else {
				return [];
			}
		},
		labelSizeClasses() {
			if (this.labelSize) {
				return sizeToClasses(this.labelSize);
			} else if (this.inline) {
				return ['col-12 col-sm-2'];
			} else {
				return ['col-12'];
			}
		},
		inputSizeClasses() {
			if (this.inputSize) {
				return sizeToClasses(this.inputSize);
			} else if (this.inline) {
				return ['col-12 col-sm-10'];
			} else {
				return ['col-12'];
			}
		}
	}
};
