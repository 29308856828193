<template>
    <a href="#" class="list-group-item border-bottom list-group-item-action" :class="{active: isSelected}">
        <div class="row">
            <div class="col text-truncate" style="overflow: hidden;">
                <span class="text-nowrap small">
                    <strong>
                        <span :class="directionArrowClass"></span>
                        <span>{{customer.displayName}}</span>
                    </strong>
                </span>
            </div>
            <div class="col-auto">
                <span v-if="isFinished" class="badge badge-danger">Finished</span>
                <span v-else>
                    {{ relativeLastMessageTime }}
                </span>
            </div>
        </div>
        <div v-if="lastMessage">
            <div class="message-text text-truncate" v-html="lastMessage.content"></div>
        </div>
    </a>
</template>

<script>
export default {
	props: {
		isFinished: Boolean,
		messages: Array,
		customer: Object,
		isSelected: {
			type: Boolean,
			default: false
		},
		chatID: Number
	},
	data() {
		return {
			relativeLastMessageTime: null,
			timeRefresherInterval: null
		};
	},
	methods: {
		refreshLastMessageTime() {
			if (this.lastMessage) {
				this.relativeLastMessageTime = moment(this.lastMessage.timestamp).fromNow();
			} else {
				this.relativeLastMessageTime = null;
			}
		}
	},
	created() {
		this.timeRefresherInterval = window.setInterval(
			this.refreshLastMessageTime.bind(this),
			10000
		);
		this.refreshLastMessageTime();
	},
	destroyed() {
		if (this.timeRefresherInterval) {
			window.clearInterval(this.timeRefresherInterval);
		}
	},
	computed: {
		lastMessage() {
			if (this.messages.length) {
				return this.messages[this.messages.length - 1];
			}
			return null;
		},
		directionArrowClass() {
			if (this.lastMessage) {
				let className = 'fa fa-arrow-';
				className += this.lastMessage.direction ? 'right' : 'left';
				return className;
			} else {
				return '';
			}
		}
	}
};
</script>

<style lang="scss" scoped>
    .message-text {
        max-height: 3em;
    }
</style>
