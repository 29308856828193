module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isDue : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <span class=\"badge badge-danger\">\n            "
    + container.escapeExpression(helpers.format_date.call(alias1,(depth0 != null ? depth0.dueTime : depth0),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "\n        </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        "
    + container.escapeExpression(helpers.format_date.call(alias1,(depth0 != null ? depth0.dueTime : depth0),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    -\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.dueTime : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})