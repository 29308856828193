import {lowerFirst, setWith, get, has, upperFirst, forEach, some, assign, reduce, isArray, clone, flow, pickBy, mapKeys, throttle} from 'lodash';
import refreshFilters from 'app/assets/js/filters.js';
import {popoverForTrigger, getURLComponents, splitNestedInputName, flattenFormData, toUnderscoreCase} from 'app/assets/js/util.js';
import createModal from 'app/assets/js/modals.js';
import {Spinner} from 'spin.js';
import Socket from './socket.js';
import { wsUrlBase } from '../../../app/assets/js/util.js';
import * as Api from './apiRequest.js';

window.window.targetElement = '.bq-content';
window.isModal = false;

// serialize: serializes an object into query string
export function serialize(obj) {
	return $.param(obj);
}

/* ---------------------------- */
/*      Utility functions		*/
/* ----------------------------	*/

// Parse links and append a hashbang before the rest of the URL
export function parseLinks() {
	const noParseHrefRegex = /^(?:#|^\w+:)/; // Match #anchor and proto: links
	$(document).ev('click', 'a[href]:not(.noparse, [data-toggle], [download])', e => {
		// Don't do anything if another listener already handled the click
		const originalEv = e.originalEvent || e; // Unwrap jQuery-wrapped event
		if (originalEv.defaultPrevented) {
			return;
		}

		const elem = $(e.currentTarget);
		const link = elem.attr('href');

		// Don't do anything if target specified (let the browser open in new window)
		if (elem.attr('target') && elem.attr('target') !== '_self') {
			return;
		}

		// Modals
		if (elem.is('a[data-modal-link]')) {
			e.preventDefault();

			const properties = elem.data();
			properties.modalSummoner = elem;
			createModal(properties);
			return;
		}

		// Let the browser handle #anchor and proto: links
		if (link.match(noParseHrefRegex)) {
			return;
		}
		e.preventDefault();

		const isMac = navigator.platform.toUpperCase().indexOf('MAC') !== -1;
		if (e.which === 2 || e.ctrlKey || (e.metaKey && isMac)) {
			window.open(link, '_blank');
			return;
		}

		var logoutUrlStart = '/user/logout';

		if(elem.data('target')) {
			window.targetElement = elem.data('target');
		} else if(elem.closest('.result-pagination').length > 0) {
			window.targetElement = '.paginated';
		} else if (link.substring(0, logoutUrlStart.length) === logoutUrlStart) {
			window.targetElement = '#content-wrapper';
		} else {
			window.targetElement = '.bq-content';
		}

		if(elem.closest('.modal').length == 0 || elem.data('target')) {
			var historyObj = {
				controller: lowerFirst(window.Bootstrap.bootquery.controller),
				method: lowerFirst(window.Bootstrap.bootquery.method),
				parameters: window.Bootstrap.bootquery.parameters
			};

			if(historyObj != history.state && elem.hasClass('nofollow') == false) {
				history.pushState(historyObj, null, link);
			} else {
				window.targetElement = '.embedable';
			}

			if(elem.closest('.modal-body').length > 0) {
				elem.closest('.modal').modal('hide');
			}
		} else {
			window.targetElement = '.embedable';
		}

		bootstrap(e, 'load');
	});
}

export function setTargetLoadingState(targetElement, isLoading) {
	var target = $(targetElement);
	if (isLoading) {
		target.css({
			opacity: 0.5,
			pointerEvents: 'none'
		});
		target.each(function() {
			window.spinner.spin(this);
		});
	} else {
		if (window.spinner.el && $(window.spinner.el).closest(target).length) {
			window.spinner.stop();
		}
		target.css({
			opacity: '',
			pointerEvents: ''
		});
	}
}

/**
 * Calls getAutocomplete on server.
 * @param  {String}  table            Name of the table to query
 * @param  {Array}   filters          Filters to pass to the query
 * @param  {Object}  params           Parameter to pass to the query
 * @param  {Function(result, status)} callback function to call when done. Status is boolean.
 * @return {Promise}
 */
export function getAutocomplete(controller, table, filters, params) {
	if (!controller) {
		controller = window.Bootstrap.bootquery.controller;
	}
	return Api.post('/api/getSelectOptions', {
		controller,
		filters,
		params,
		tableName: table
	});
}

export function tr(fullText, moduleName = 'global') {
	const locale = window.Bootstrap.bootquery.locale;
	if (!window.Locales[locale]) {
		console.warn('Selected locale not found: ', locale);
		return fullText;
	}

	// Module can be explicitly specified in tr string
	const moduleSpec = fullText.split(':');
	let requestedModule = moduleName;
	let pathStr = fullText;
	if(moduleSpec.length > 1) {
		requestedModule = moduleSpec[0];
		pathStr = moduleSpec[1];
	}
	const path = pathStr.split('.');
	const searchPaths = [
		[requestedModule, ...path],
		['global', ...path]
	];

	for (let searchPath of searchPaths) {
		const translated = get(window.Locales[locale], searchPath);
		if (translated) {
			return translated;
		}
	}

	console.warn('Unable to find translation string for', fullText, 'in module', requestedModule);
	return fullText;
}

export function loadModules(data) {
	if(has(data, 'modules')) {
		forEach(data.modules, function(module, name) {
			const {controller, method} = data.bootquery;
			const route = `${controller}/${method}`.toLowerCase();
			if (route === 'user/login' && !module.activate_on_login_screen) {
				return;
			}
			const instance = window.BootQuery.getModuleInstance(name);
			if (instance && !instance._initialised) {
				instance.init(data);
				instance._initialised = true;
			}
		});
	}
}

export function activatePopovers(target) {
	target.findElement('*[data-toggle="popover"]').ev('click', function(e) {
		e.preventDefault();
	})
		.popover({
			content: function() {
				if ($(this).data('popover-content-element')) {
					var $contentElement = $(this).parent().children('.popover-content-element');
					$(this).one('hidden.bs.popover', function(e) {
						popoverForTrigger(this).find('.popover-body').children().appendTo($contentElement);
					});
					return $contentElement.children();
				} else {
					return $(this).data('popover-content');
				}
			}
		})
		.ev('inserted.bs.popover', function(e) {
			popoverForTrigger(this).addClass('popover-opening');
		})
		.ev('shown.bs.popover', function() {
			var $popover = popoverForTrigger(this);
			$popover.removeClass('popover-opening');
			$popover.find('[data-dismiss=popover]').ev('click.popoverDismiss', function(e) {
				$(this).closest('.popover').popover('hide');
			});
		});


	$(document).add('body').ev('click.popoverDismiss', function(e) {
		var $tgt = $(e.target);
		var $popovers = $('.popover');
		if ($tgt.is('[data-trigger=focus]')) {
			$popovers = $popovers.not(popoverForTrigger($tgt));
		}
		if ($popovers.length) {
			if ($tgt.is('a, button') || $tgt.closest('a, button').length) {
				$popovers.not('.popover-opening').popover('hide');
			} else {
				var $clickedPopover = $tgt.closest($popovers);
				$popovers.not($clickedPopover).not('.popover-opening').popover('hide');
			}
		}
	});
}

export function activateElements(target, data) {
	if (typeof target == 'undefined' || target == null) {
		target = 'body';
	}
	target = $(target);

	data = (data && data.bootquery) ? data : window.Bootstrap;

	refreshFilters(target, data);

	target.findElement('.print-btn').addClass('noparse').off('click').on('click', function(e) {
		e.stopPropagation();
		e.preventDefault();

		var iframe = $('<iframe>', {
			src: $('.print-btn').attr('href')
		}).appendTo('body');
		iframe.hide();
		iframe.get(0).contentWindow.print();
	});

	target.findElement('.delete-action').off('click').on('click', function(e) {
		e.stopPropagation();
		e.preventDefault();
		var originalEvent = e;
		var deleteTriggerer = $(e.currentTarget);
		var modal = $('.delete-action-modal');
		$(modal).modal('show');
		$(modal).find('.delete-action-cancel').off('click').on('click', function(e) {
			e.preventDefault();
			$(modal).modal('hide');
		});

		$(modal).find('.delete-action-confirm').off('click').on('click', function(e) {
			e.preventDefault();
			e.stopPropagation();

			if (deleteTriggerer.is('button, input')) {
				var form = null;
				if (deleteTriggerer.attr('form')) {
					form = $('form[id="' + deleteTriggerer.attr('form') + '"]');
				}
				if (!form) {
					form = deleteTriggerer.closest('form');
				}
				$(deleteTriggerer).trigger('submit');
				if ($(deleteTriggerer).attr('name')) {
					$(form).append('<input type="hidden" name="' + $(deleteTriggerer).attr('name') + '" />');
				}
				submitForm(form, data);
				$(modal).modal('hide');
			} else if ($(deleteTriggerer).is('a[href]')) {
				navigate(originalEvent);
			}

			return false;
		});
	});

	// Popovers, they suck by default
	activatePopovers(target);

	target.findElement('*[data-toggle="tooltip"]').tooltip();

	target.findElement('.pickle').each(function() {
		var $el = $(this);
		if ($el.data('pickleSource')) {
			var params = flow(
				val => pickBy(val, (_value, key) => key.indexOf('pickle') === 0),
				val => mapKeys(val, (_value, key) => {
					var withoutPrefix = key.replace(/^pickle/, '');
					return lowerFirst(withoutPrefix);
				})
			)($el.data());

			$el.pickle({
				results: function(searchString, results, callback) {
					var $el = $(this);
					if ($el.data('isQuerying')) {
						return;
					}
					$el.data('currentSearchString', searchString);
					if ($el.data('lastSearchString') === searchString) {
						$el.data('isQuerying', false);
						callback(results, searchString.length);
						return;
					}

					const fetchParams = assign(params, {
						filters: [],
						value: $el.val()
					});
					if (searchString.length) {
						fetchParams.filters.push({
							'key': fetchParams.textColumn+'_like_fulltext',
							'value': searchString
						});
						if (fetchParams.sortby && !fetchParams.sort) {
							fetchParams.sortby = {
								$similarity_fulltext: {
									column: fetchParams.textColumn,
									value: searchString
								}
							};
						}
					}
					$el.data('isQuerying', true);
					Api.get('/api/getSelectOptions', {
						controller: data.bootquery.controller,
						...fetchParams
					}).then(results => {
						const options = results.map(option => {
							return {
								id: option[data.idColumn],
								text: option[data.textColumn],
								rowData: option
							};
						});
						$el.data('isQuerying', false);
						$el.data('lastSearchString', searchString);
						callback(options, searchString.length);
						if ($el.data('currentSearchString', searchString) !== $el.data('lastSearchString')) {
							$el.pickle('research');
						}
					});
				}
			});
		} else {
			$el.pickle();
		}
	});

	$.jMaskGlobals = {
		maskElements: 'input,td,span,div',
		dataMaskAttr: '*[data-mask]',
		dataMask: false,
		watchInterval: 300,
		watchInputs: false,
		watchDataMask: false,
		byPassKeys: [9, 16, 17, 18, 36, 37, 38, 39, 40, 91],
		translation: {
			'0': {pattern: /\d/},
			'9': {pattern: /\d/, optional: true},
			'#': {pattern: /\d/, recursive: true},
			'A': {pattern: /[a-zA-Z0-9]/},
			'S': {pattern: /[a-zA-Z]/}
		}
	};

	// Date and time pickers
	$.fn.activateDateTimePicker = function(perTypeDefaults) {
		return $(this).each(function() {
			var element = $(this);
			var input = element.findElement('input');

			if(element.is('.datepicker')) {
				let format='DD.MM.YYYY';
				if(element.hasClass('json')) {
					format = 'DD. MM. YYYY.';
				}
				// input.inputmask('date', {
				// 	inputFormat: 'dd.mm.yyyy',
				// 	seperator: '.',
				// 	placeholder: '__.__.____'
				// });

				input.daterangepicker({
					'singleDatePicker': true,
					'showDropdowns': true,
					'autoApply': true,
					'locale': {
						'format': format,
						'separator': ' - ',
						'applyLabel': 'Apply',
						'cancelLabel': 'Cancel',
						'fromLabel': 'From',
						'toLabel': 'To',
						'customRangeLabel': 'Custom',
						'weekLabel': 'W',
						'daysOfWeek': [
							tr('calendar.sun'),
							tr('calendar.mon'),
							tr('calendar.tue'),
							tr('calendar.wed'),
							tr('calendar.thu'),
							tr('calendar.fri'),
							tr('calendar.sat')
						],
						'monthNames': [
							tr('calendar.jan_long'),
							tr('calendar.feb_long'),
							tr('calendar.mar_long'),
							tr('calendar.apr_long'),
							tr('calendar.may_long'),
							tr('calendar.jun_long'),
							tr('calendar.jul_long'),
							tr('calendar.aug_long'),
							tr('calendar.sep_long'),
							tr('calendar.oct_long'),
							tr('calendar.nov_long'),
							tr('calendar.dec_long')
						],
						'firstDay': 1,
						'buttonClasses': 'btn'
					},
					'linkedCalendars': false,
					'showCustomRangeLabel': false
				}, function(start, end, label) {
					console.log('New date range selected: \' + start.format(\'YYYY-MM-DD\') + \' to \' + end.format(\'YYYY-MM-DD\') + \' (predefined range: \' + label + \')');
				});
			} else if(element.is('.datetimepicker')) {
				// input.inputmask('datetime', {
				// 	inputFormat: 'dd.mm.yyyy. HH:MM',
				// 	seperator: '.',
				// 	placeholder: '__.__.____. __:__',
				// 	clearIncomplete: true
				// });

				input.daterangepicker({
					singleDatePicker: true,
					showDropdowns: true,
					autoApply: true,
					timePicker: true,
					timePicker24Hour: true,
					autoUpdateInput: true,
					'locale': {
						'format': 'DD.MM.YYYY. HH:mm',
						'separator': ' - ',
						// 'applyLabel': 'Apply',
						// 'cancelLabel': 'Cancel',
						'fromLabel': 'From',
						'toLabel': 'To',
						'customRangeLabel': 'Custom',
						'weekLabel': 'W',
						'daysOfWeek': [
							tr('calendar.sun'),
							tr('calendar.mon'),
							tr('calendar.tue'),
							tr('calendar.wed'),
							tr('calendar.thu'),
							tr('calendar.fri'),
							tr('calendar.sat')
						],
						'monthNames': [
							tr('calendar.jan_long'),
							tr('calendar.feb_long'),
							tr('calendar.mar_long'),
							tr('calendar.apr_long'),
							tr('calendar.may_long'),
							tr('calendar.jun_long'),
							tr('calendar.jul_long'),
							tr('calendar.aug_long'),
							tr('calendar.sep_long'),
							tr('calendar.oct_long'),
							tr('calendar.nov_long'),
							tr('calendar.dec_long')
						],
						'firstDay': 1,
						'buttonClasses': 'btn'
					},
					'linkedCalendars': false,
					'showCustomRangeLabel': false
				}, function(start, end, label) {
					console.log('New date range selected: \' + start.format(\'YYYY-MM-DD\') + \' to \' + end.format(\'YYYY-MM-DD\') + \' (predefined range: \' + label + \')');
				});
			} else if(element.is('.timepicker') || element.is('.durationpicker')) {
				$(input).inputmask('datetime', {
					inputFormat: 'HH:MM:ss',
					seperator: ':',
					placeholder: '__:__:__',
					clearIncomplete: true
				});
			}
		});
	};

	target.findElement('input[type="time"]').attr('type', 'text').addClass('timepicker');
	target.findElement('.datetimepicker').activateDateTimePicker({format: 'DD.MM.YYYY. HH:mm'});
	target.findElement('.timepicker').activateDateTimePicker({format: 'HH:mm:ss'});
	target.findElement('.datepicker').activateDateTimePicker({format: 'DD.MM.YYYY.'});
	target.findElement('.durationpicker').activateDateTimePicker({format: 'HH:mm:ss', useCurrent: false});
	target.findElement('.form-datetime').inputmask('datetime', {
		inputFormat: 'dd.mm.yyyy. HH:MM',
		seperator: '.',
		placeholder: '__.__.____. __:__',
		clearIncomplete: true
	});

	var dateElements = target.findElement('.timepicker, .datetimepicker, .durationpicker, .datepicker');
	dateElements.off('dp.show').on('dp.show', function(e) {
		var element = $(e.currentTarget);
		var minElementName = element.find('input').data('date-min-value-of');
		var maxElementName = element.find('input').data('date-max-value-of');

		if (minElementName && minElementName.length) {
			var minInput = $('input[name="' + minElementName + '"]');
			var min = minInput.val();

			if (min && min.length) {
				var minFormat = minInput.closest('.input-group').data('DateTimePicker').format();
				var minDate = moment(min, minFormat);
				element.data('DateTimePicker').minDate(minDate);
			} else {
				element.data('DateTimePicker').minDate(false);
			}
		}

		if (maxElementName && maxElementName.length) {
			var maxInput = $('input[name="' + maxElementName + '"]');
			var max = maxInput.val();

			if (max && max.length) {
				var maxFormat = maxInput.closest('.input-group').data('DateTimePicker').format();
				var maxDate = moment(max, maxFormat);
				element.data('DateTimePicker').maxDate(maxDate);
			} else {
				element.data('DateTimePicker').maxDate(false);
			}
		}
	});

	target.findElement('textarea').on('keyup', function() {
		this.style.overflow = 'hidden';
		this.style.height = 0;
		this.style.height = this.scrollHeight + 'px';
	});

	// Data table selections
	$(document).ev('click', '.datatable > tbody > tr', function(e) {
		if ($(this).hasClass('checkbox-row-select')) {
			return;
		}
		e.stopPropagation();
		let checkbox = $(this).find('input[name*="rowselect"]');
		let btns = $([]);
		let tableID = $(this).closest('table').attr('id');
		if (tableID.indexOf('-') !== -1) {
			let tableName = tableID.split('-')[0];
			btns = $(this)
				.closest('table')
				.find(`[data-table-action][data-table="${tableName}"]`);
		}

		if(e.shiftKey) {
			var firstPos = $('tr').index(this);
			var secondPos = firstPos;
			var lastClicked = $('tr.table-active.lastClicked');

			if(lastClicked.length > 0) {
				if($('tr').index(lastClicked) > $('tr').index(this)) {
					secondPos = $('tr').index(lastClicked);
				} else {
					firstPos = $('tr').index(lastClicked);
				}
			}

			if(e.ctrlKey === false) {
				$('tr.table-active').removeClass('table-active');
			}

			$('tr').slice(firstPos, secondPos + 1).addClass('table-active');
		} else if(e.ctrlKey) {
			$(this).toggleClass('table-active');
		} else {
			$('tr.table-active').removeClass('table-active');
			$(this).addClass('table-active');
		}

		if($(this).hasClass('table-active')) {
			$('tr.lastClicked').removeClass('lastClicked');
			$(this).addClass('lastClicked');
		}

		if(checkbox.length > 0) {
			$('.datatable > tbody > tr').find('input[name*=rowselect]').val('false');
			$('.datatable > tbody > tr.table-active').each(function() {
				$(this).find('input[name*=rowselect]').val('true');
			});
		}
		btns.prop('disabled', !checkbox.length);

		$(document).trigger('datatableSelection', [$('.datatable > tbody > tr.table-active')]);
	});

	$(document).ev('change', '.datatable input[type=checkbox].checkbox-row-select-all', e => {
		const table = $(e.currentTarget).closest('table');
		const checked = $(e.currentTarget).prop('checked');
		const checkboxen = table.find('tbody > tr.checkbox-row-select > td.row-select-col input[type=checkbox]');
		checkboxen.prop('checked', checked).trigger('change');
	});

	$(document).ev('click', 'tr.checkbox-row-select > td.row-select-col', e => {
		if (e.target === e.currentTarget) {
			$(e.currentTarget).find('input[type=checkbox]').click();
		}
	});

	$(document).ev('change', 'tr.checkbox-row-select > td.row-select-col input[type=checkbox]', (e) => {
		const checkbox = $(e.currentTarget);
		const table = checkbox.closest('table');
		const checkboxen = table.find('tr.checkbox-row-select > td.row-select-col input[type=checkbox]');
		const checkedCheckboxen = checkboxen.filter(':checked');
		const btns = table.find('[data-table-action]');
		btns.prop('disabled', checkedCheckboxen.length === 0);

		if (checkboxen.length > 0) {
			const checkAll = table.find('input[type=checkbox].checkbox-row-select-all');
			checkAll.prop(
				'checked',
				checkedCheckboxen.length === checkboxen.length
			);
		}
	});

	// Result limits
	$(document).ev('change', '.limit-selector', function(e) {
		$(this).closest('form').submit();
	});

	registerFormHandler(target, data);
	window.initDatatables(target, data);

	forEach(data.forms, function(form, formName) {
		var selector = 'form[data-form="'+formName+'"]';
		var $formEl = target.findElement(selector);
		if (!$formEl.length) {
			$formEl = target.findElement('[data-form="' + formName + '"]');
		}
		if ($formEl.length) {
			$formEl.form({formDefinition: form});
		} else {
			console.warn('Tried to activate form ' + formName + ', didn\'t find element, selector was: ' + selector);
		}
	});

	$(document).trigger('activateElements', [target, data]);
}

// Look for target data in the clicked link and set refresh target to the referenced element
export function setTarget(e) {
	if(e) {
		var targetData = $(e.target).data('target');

		if(!$(targetData).length) {
			window.isModal = targetData === 'modal';
			window.target = $(this).closest('.bq-content');

			if(!window.target) {
				targetData = '.bq-default';
			} else {
				targetData = '.bq-content';
			}
		} else {
			$('.bq-target').removeClass('bq-target');
			$(targetData).addClass('bq-target');
			targetData = '.bq-target';
			window.isModal = false;
		}
		window.targetElement = targetData;
	}
}

export function setFormSaveStatus(formElement, status, customText, customColorClass) {
	var id = $(formElement).attr('id') || '';
	var saveStatusElement = $(formElement).find('.save-status').add($('.save-status[data-form="' + id + '"]'));
	saveStatusElement.prop('hidden', false);
	let statusText;
	switch (status) {
	case 'saving':
		statusText = tr('label.saving_in_progress');
		if ($(formElement).data('status-text-saving')) {
			statusText = $(formElement).data('status-text-saving');
		}
		saveStatusElement.html(statusText);
		break;

	case 'validating':
		saveStatusElement.html(tr('label.validation_in_progress'));
		break;

	case 'saved':
		statusText = tr('label.saved');
		if ($(formElement).data('status-text-saved')) {
			statusText = $(formElement).data('status-text-saved');
		}
		saveStatusElement.html(
			`<span class="text-success">${statusText}</span>`
		);
		break;

	case 'validation-error':
		saveStatusElement.html(
			`<span class="text-danger">${tr('label.validation_error')}</span>`
		);
		break;

	case 'custom':
		saveStatusElement.html(
			`<span class="${customColorClass}">${customText}</span>`
		);
		break;

	default:
		saveStatusElement.html('');
		saveStatusElement.prop('hidden', true);
		break;
	}

	const doingSomething = status === 'saving' || status === 'validating';
	const btn = saveStatusElement.siblings('button[type=submit]');
	btn.prop('disabled', doingSomething);
	if (doingSomething) {
		btn.findElement('.fa.fa-check')
			.removeClass('fa fa-check')
			.addClass('spinner-border spinner-border-sm');
	} else {
		btn.findElement('.spinner-border')
			.removeClass('spinner-border spinner-border-sm')
			.addClass('fa fa-check');
	}
}

export function getFormData(formElement) {
	var inputElements = $(formElement).find('input, textarea, select').not(':disabled');
	var formData = {};
	inputElements.each(function(index, input) {
		var name = $(input).attr('name');
		var type = $(input).attr('type');
		var value = $(input).val();

		if (!name) {
			return;
		}
		if ((type === 'checkbox' || type === 'radio') && !$(input).is(':checked')) {
			return;
		}
		if ($(input).is('select') && value === 'null') {
			value = null;
		}
		if(type === 'file') {
			value = $(input).data('tmpName');
		}

		var namePath = splitNestedInputName(name);
		setWith(formData, namePath, value, Object);
	});
	return formData;
}

export function setFormData(formElement, data) {
	var $formElement = $(formElement);
	var flattened = flattenFormData(data);
	forEach(flattened, function(value, elName) {
		var $input = $formElement.find('[name="' + elName + '"]');
		if ($input.is('select')) {
			$input.pickle('select', value);
		} else if ($input.is('[type=checkbox]')) {
			var $theCheckboxOne = $input.filter('[type=checkbox]');
			$theCheckboxOne.prop('checked', value === 'true');
		} else {
			$input.val(value);
		}
	});
}

export function submitForm(formElement, data) {
	$(formElement).trigger('beforeSubmit');
	var url         = window.url.parse($(formElement).attr('action'), true);
	var action      = url.pathname.split('/').filter(part => part !== '');
	var controller  = action.shift();
	var method      = action.join('/');
	var type        = $(formElement).attr('method');
	var parameters  = url.query;

	parameters = $.extend(parameters, getFormData(formElement));

	if ($(formElement).is('.filter-form')) {
		const action = $(formElement).data('submittedByAction');
		if (action) {
			$(formElement).removeData('submittedByAction');
			let tableName = $(formElement).data('table');
			let tableEl = $('#'+tableName+'-table');
			parameters = $.extend(parameters, getFormData(tableEl));
			type = 'POST';
			parameters[`${tableName}-action`] = action;
		}
	}

	// Get response JSON
	getJSON(type, controller, method, parameters, true, function (formdata) {
		printPHPDebugs(formdata);
		var queryString = '';
		var formID = $(formElement).attr('id') || '';
		if (!formdata) {
			return;
		}
		if (formdata.error) {
			console.error(formdata.error);
			if (formID && formID.length) {
				setFormSaveStatus($('form#' + formID), 'custom', tr('error.' + toUnderscoreCase(formdata.error.type)), 'text-danger');
			}
			return;
		}

		var module = null;
		var embedded = false;
		var modal = false;

		// For GET requests, create a query string from parameters
		if(type == 'get') {
			queryString = '?' + $.param(formdata.bootquery.parameters);
		} else {
			queryString = '';
			if (action.length) {
				queryString = url.search;
			}
		}

		if ($(formElement).closest('.modal').length) {
			modal = true;
			embedded = true;
		}

		// If form destination is the same as the current page find any alert elements and show them
		if(formdata.bootquery && data.bootquery && formdata.bootquery.controller == data.bootquery.controller && formdata.bootquery.method == data.bootquery.method && !formdata.bootquery.target) {
			if(formdata.bootquery.success == true) {
				$('.alert-success').show();
			} else {
				$('.alert-danger').show();
			}
		}

		if (formdata.bootquery.submit_info && formdata.bootquery.submit_info.success == true) {
			$(formElement).trigger({
				type: 'succesfull-submit',
				form: formElement,
				formdata: parameters,
				submit_info: formdata.bootquery.submit_info
			});
		}

		var selectToUpdate = $(formElement).closest('.modal').data('modal-select-update');

		if (selectToUpdate && modal && has(formdata, 'bootquery.submit_info.success')) {
			if (formdata.bootquery.submit_info.success) {
				var formInsertID = formdata.bootquery.submit_info.mainFormID;
				var selectToUpdateElement = $(formElement).closest('.modal').data('modalSummoner').closest('.form-group').find('select[name*="' + selectToUpdate + '"]');
				var controlData = $(selectToUpdateElement).data('controlData');

				if (controlData.table && controlData.option_text && controlData.option_value) {
					getAutocomplete(
						formdata.bootquery.controller,
						controlData.table,
						[{key: controlData.option_value + '_eq', value: formInsertID}],
						{limit: 1},
					).then(function(data, status) {
						if (status && data.length > 0) {
							var text = data[0][controlData.option_text];
							var id = data[0][controlData.option_value];
							selectToUpdateElement.pickle('option', id, {id: id, text: text, row: data[0]});
							selectToUpdateElement.pickle('select', id);
						} else {
							console.error('Unable to retrieve new option');
						}
					});
				}
			}
		}

		// Default target to render in
		window.targetElement = '.bq-content';

		if ($(formElement).is('.filter-form') && $(formElement).closest('.datatable').length) {
			window.targetElement = '.datatable';
		}

		// If we had a data-target attribute in the form, respect the target and reload into the referenced container
		if(formdata && modal) {
			window.targetElement = $(formElement).closest('.modal').data('modal-target');
		} else if(formdata && formdata.bootquery.target) {
			window.targetElement = formdata.bootquery.target;
		}

		// Update the history entry
		if(!embedded) {
			if(!(lowerFirst(formdata.bootquery.controller) == lowerFirst(data.bootquery.controller) && lowerFirst(formdata.bootquery.method) == lowerFirst(data.bootquery.method) && type == 'post')) {
				let currentURLHash = window.location.hash;

				console.warn('Handling form!');
				console.warn('queryString: ' + queryString);
				var historyObj = {
					controller: lowerFirst(formdata.bootquery.controller),
					method: lowerFirst(formdata.bootquery.method),
					parameters: formdata.bootquery.parameters,
					hash: currentURLHash
				};

				if(historyObj != history.state) {
					history.pushState(historyObj, null, '/' + formdata.bootquery.controller + '/' + formdata.bootquery.method + '/' + queryString + currentURLHash);
				}
			}
		} else if(modal) {
			$(formElement).closest('.modal').on('hidden.bs.modal', function(e) {
				$(e.target).remove();
			});

			$(formElement).closest('.modal').modal('hide');
		}

		// Render the result
		renderControllerFromData(formdata, module,
			function() {
				if (formID && formID.length) {
					setFormSaveStatus($('form#' + formID), 'saved');
				}
			},

			function()  {
				if (formID && formID.length) {
					setFormSaveStatus($('form#' + formID), 'error');
				}
			}
		);

		$(formElement).trigger('submitted', formdata);

		// Refresh the filters
		if(typeof window.refreshFilters === 'function')
		{
			refreshFilters(formdata);
		}
	});
}

// Register a handler for form submission
export function registerFormHandler(target, data) {
	// TODO: find real source, attempted hack:
	data.bootquery.controller = lowerFirst(data.bootquery.controller);

	$(target).off('submit', 'form:not(#login-form)').on('submit', 'form:not(#login-form)', function onFormSubmit(e) {
		e.stopPropagation();
		e.preventDefault();

		var form = $(e.currentTarget).closest('form');
		setFormSaveStatus(form, 'saving');
		submitForm(form, data);
		return false;
	});
}

export function setUserSetting(name, value) {
	name = name.replace(/\./g, '/');
	return $.ajax({
		url: '/api/userSettings/' + name,
		type: 'PUT',
		data: JSON.stringify(value)
	}).fail(err => console.error(`Failed to set user setting '${name}' to `, value, ', error: ', err));
}

export function getUserSetting(name) {
	name = name.replace(/\./g, '/');
	return $.get('/api/userSettings/' + name)
		.fail(err => console.error(`Failed to get user setting ${name}, error: `, err));
}

export function getApplicationSetting(settingName, async, callback) {
	var setting;
	$.ajax({
		type: 'get',
		async: !!async,
		dataType: 'json',
		timeout: 10000,
		url: '/api/applicationSettings/'+settingName
	}).done(function(value) {
		setting = value;

		if (typeof(callback) == 'function') {
			callback(value);
		}
	});

	return setting;
}

export function getMonitorJSON(method, params) {
	return $.ajax({
		type: 'get',
		async: true,
		dataType: 'json',
		timeout: 10000,
		url: '/api/asterisk/' + method,
		data: params
	});
}

// Get JSON from a controller
export function getJSON(requestType, controller, method, parameters, is_async, callback_func) {
	var ret = null;
	var uriParts = [controller, method].filter(part => !!part);
	$.ajax({
		type: requestType,
		async: true,
		dataType: 'json',
		//timeout: 10000,
		url: '/' + uriParts.join('/') + '.json',
		data: {paramsJSON: JSON.stringify(parameters)},
		beforeSend: function(x) {
			if(x && x.overrideMimeType) {
				x.overrideMimeType('application/json;charset=UTF-8');
			}
		}
	}).done(function(data, textStatus, jqXHRs) {
		if(typeof data === 'string') {
			data = $.parseJSON(data);
		}

		ret = data;
		printPHPDebugs(ret);

		if (isFunction(callback_func)) {
			callback_func(ret);
		}
	}).fail(function(jqXHR, textStatus, errorThrown) {
		console.error('Error while getting JSON for ' + controller + '/' + method + ':' + textStatus + ' : ' + errorThrown);
		console.log('jqXHR: ', jqXHR);
		if (jqXHR.responseJSON) {
			printPHPDebugs(jqXHR.responseJSON);
			console.error('Error: ', jqXHR.responseJSON);

			ret = jqXHR;
			if (isFunction(callback_func)) {
				callback_func(jqXHR.responseJSON);
			}
		} else if ($.parseHTML(jqXHR.responseText)) {
			console.warn('Got HTML instead of JSON, assuming wrong return type from the server.');
			console.warn(jqXHR.responseText);
			$('body').html(jqXHR.responseText);
			$('body').css({opacity: 1.0});
		}
	});

	return ret;

}

export function printPHPDebugs(data) {
	if (!data || !data._php_debug) {
		return;
	}

	forEach(data._php_debug, function(debug) {
		console.log(`%c[PHP] ${debug.file}:${debug.line}: `, 'font-weight: bold; color: #777BB4;', ...debug.content);
	});
}

export function loginCheck() {
	var bootquery = window.Bootstrap.bootquery;
	var methodAllowed = some(window.Bootstrap.modules, function(module) {
		if (!module.login_exceptions) {
			return false;
		}
		return some(module.login_exceptions, function(exception) {
			if (exception.controller.toLowerCase() === bootquery.controller.toLowerCase() &&
				exception.method.toLowerCase() === bootquery.method.toLowerCase()) {
				return true;
			}
			return false;
		});
	});

	if (methodAllowed) {
		return;
	}

	$.ajax({
		type: 'get',
		async: true,
		dataType: 'json',
		url: '/api/sessionCheck'
	})
		.done(function (data, textStatus, jqXHR) {
			if (!data.isLoggedIn) {
				window.location.reload(true);
			}
		})
		.fail(function (jqXHR, textStatus, errorThrown) {
			console.warn('Failed to check if logged in: ' + textStatus + ': ' + errorThrown);
			console.warn(jqXHR);
		});
}

export function getTemplate(templateName, moduleName) {
	if (!moduleName) {
		moduleName = window.Bootstrap.bootquery.moduleName;
	}

	if (isArray(templateName)) {
		const loaded = reduce(templateName, (templates, template) => {
			templates[template] = newGetTemplate(template, moduleName);
			return templates;
		}, {});
		return Promise.resolve(loaded);
	} else {
		let template = newGetTemplate(templateName, moduleName);
		return Promise.resolve(template);
	}
}

export function handlebarsRender(template, data, usePartials) {
	try {
		data = clone(data);
		if (typeof(template) !== 'function') {
			throw new Error('Can only render pre-compiled templates. Tried to render: '+template);
		}

		if (template.__moduleName) {
			if (!data) {
				data = {};
			}
			data.__moduleName = template.__moduleName;
		}
		return template(data);
	} catch (err) {
		console.error('Failed to render template for', data, err);
		return;
	}
}

// Render the template with data into target container (if specified) and return rendered HTML.
export function renderTemplate(template, data, target, callback) {
	var rendered;
	try {
		rendered = handlebarsRender(template, data, true);
	} catch (err) {
		// console.error("Failed to render template " + template + " for " + data.bootquery.controller + "/" + data.bootquery.method);
		console.error(err);
		return;
	}

	var newElement = null;

	if (target && typeof target != 'undefined' && target != null) {
		let parsed = (new DOMParser()).parseFromString(rendered, 'text/html');
		rendered = $(parsed);
		if(target == '.modal-body' && rendered.findElement('.bq-content').length) {
			newElement = rendered.findElement('.bq-content');
		} else if(rendered.findElement(target).length) {
			newElement = rendered.findElement(target);
		} else {
			if(data && data.bootquery) {
				console.error('Failed to render template for ' + data.bootquery.controller + '/' + data.bootquery.method + ' into element ' + target);
			} else {
				console.error('Failed to render template', rendered, 'into element', target, 'with data', data);
			}
			return;
		}
	}

	if (newElement) {
		rendered = newElement.contents();
	}

	if (target && typeof target != 'undefined' && target != null) {
		if (data && data.bootquery && data.bootquery.isModal) {
			target = '.modal ' + target;
		}

		var tgt = $(target);
		if (tgt) {
			rendered = $(rendered);
			setTargetLoadingState(tgt, true);
			tgt.removeClass('paginated');
			if (newElement.hasClass('paginated')) {
				tgt.addClass('paginated');
			}

			if(target == '.bq-content' || target == 'body') {
				loadModules(data);
			}
			activateElements(rendered, data);

			tgt.html(rendered);
			setTargetLoadingState(tgt, false);

			if (typeof(callback) == 'function') {
				callback(true);
			}
		} else {
			if(data && data.bootquery) {
				console.error('Failed to render template for ' + data.bootquery.controller + '/' + data.bootquery.method + ' into element ' + target);
			} else {
				console.error('Failed to render template ' + template + ' into element ' + target);
			}

			console.error('Target specified, but not found');
			if (typeof(callback) == 'function') {
				callback(false);
			}
		}
	}

	return rendered;
}

// Render the controller
export function renderController(requestType, controller, method, parameters, module, onSuccess, onError)
{
	const route = `/${lowerFirst(controller)}/${method}`;
	const handled = window.BootQuery.handleRouteManually(route, {
		controller,
		method,
		parameters
	});
	if (handled) {
		setTargetLoadingState(window.targetElement, false);
	} else {
		setTargetLoadingState(window.targetElement, true);
		getJSON(requestType, controller, method, parameters, true, function (data) {
			renderControllerFromData(data, module, onSuccess, onError);
		});
	}
}

export function dateToSourceFormat(input) {
	let dateTimePickerElement = $(input).closest('.input-group');
	let format = dateTimePickerElement.data('DateTimePicker').format();
	let value = $(input).val();

	if (!value.length || value == 'null') {
		return value;
	}

	let date = moment(value, format);

	let targetFormat;
	if (dateTimePickerElement.is('.datepicker')) {
		targetFormat = 'DD.MM.YYYY.';
	} else if (dateTimePickerElement.is('.timepicker, .durationpicker')) {
		targetFormat = 'HH:mm:ss';
	} else if (dateTimePickerElement.is('.datetimepicker')) {
		targetFormat = 'DD.MM.YYYY. HH:mm:ss';
	}

	if (targetFormat) {
		return date.format(targetFormat);
	} else {
		return value;
	}
}

export function renderControllerFromData(data, module, onSuccess, onError)
{
	$('#php-debugs').empty();
	if (typeof(module) === 'undefined') {
		module = upperFirst(data.bootquery.controller); // Not sure what to do really
	}
	if (data.bootquery.controller.toLowerCase() === 'user' && data.bootquery.method.toLowerCase() === 'login') {
		window.targetElement = '#content-wrapper';
	}

	var historyObj = {
		controller: lowerFirst(window.Bootstrap.bootquery.controller),
		method: lowerFirst(window.Bootstrap.bootquery.method),
		parameters: window.Bootstrap.bootquery.parameters
	};
	window.Bootstrap = data;

	var template = data.bootquery.template || data.bootquery.method;

	var loadInto = window.targetElement;
	var shouldRedirect = true;
	if (data.bootquery.isModal) {
		loadInto = '.modal ' + window.targetElement;
		shouldRedirect = false;
	}
	setTargetLoadingState(loadInto, true);

	if (shouldRedirect && data.redirectedTo) {
		var queryString = serialize(data.redirectedTo.params);
		if (queryString.length) {
			queryString = '?' + queryString;
		}
		var newUrl = '/' + data.redirectedTo.controller + '/' + data.redirectedTo.method + '/' + queryString;
		history.replaceState(historyObj, null, newUrl);
	}

	getTemplate(template, module).then((template) => {
		renderTemplate(template, data, window.targetElement, function(status) {
			if (status === true) {
				registerFormHandler($('body').find('form:first'), data);
				if (typeof(onSuccess) == 'function') {
					onSuccess(data);
				}

				$(document).trigger(
					'renderController',
					[window.targetElement, data]
				);
			} else {
				if (typeof(onError) == 'function') {
					onError(data);
				}
			}
		});
	});
}

export function navigate(event, callback) {
	var newWindow = false;
	var isModal = false;
	var url, route;

	if(event.type == 'click') {
		if($(event.currentTarget).hasClass('nofollow')) return;

		if ($(event.currentTarget).is('[data-dismiss="modal"]')) {
			return;
		}

		if($(event.currentTarget).closest('.modal').length > 0) {
			window.targetElement = '.embedable';
			isModal = true;
		}

		url = $(event.target).attr('href');

		if(typeof url == 'undefined') {
			url = $(event.target).closest('a').attr('href');
		}

		route = getURLComponents(url);

		if (event.ctrlKey || (event.metaKey && navigator.platform.toUpperCase().indexOf('MAC') != -1)) {
			newWindow = true;
		}

		if (event.which == 2) {
			newWindow = true;
		}
	} else {
		route = getURLComponents();
	}

	if (newWindow) {
		window.open(url, '_blank');
	} else {
		callback = callback || activateElements;
		var loadInto = window.targetElement;
		if (isModal) {
			loadInto = '.modal ' + window.targetElement;
		}
		setTargetLoadingState(loadInto, true);

		if (!route.controller) {
			route.controller = '';
			route.method = '';
		}
		renderController(
			'get',
			route.controller,
			route.method,
			route.parameters,
			upperFirst(route.controller)
		);
	}
}

export function checkFormChanged(target) {
	var forms = $(target).find('form:not(.filter-form, .datatable-form)');
	if ($(target).is('form:not(.filter-form, .datatable-form)')) {
		$(forms).add(target);
	}

	var hasChanges = false;
	$(forms).each(function() {
		if ($(this).data('formChanged')) {
			hasChanges = true;
			return false;
		}

		$(this).find('input, textarea').each(function() {
			var defaultValue = $(this).attr('data-default-value');

			if ($(this).is(':disabled')) {
				return;
			}

			if ($(this).is('[type="checkbox"]')) {
				// TODO: Find out what goes here
			} else {
				if (typeof(defaultValue) == 'undefined') {
					defaultValue = $(this).prop('defaultValue');
				}

				var value = $(this).val();
				if ($(this).closest('.timepicker, .datepicker, .durationpicker, .datetimepicker').length) {
					value = dateToSourceFormat(this);
				}

				if (value != defaultValue) {
					hasChanges = true;
					return false;
				}
			}
		});
	});

	return hasChanges;
}

// Changing page
export function bootstrap(event, callback) {
	event.preventDefault();

	if ($(event.currentTarget).closest('.modal').length) {
		navigate(event, callback);
		return;
	}

	var hasChanges = checkFormChanged('body');
	if (hasChanges) {
		if($('.custom-modal').length == 0) {
			var warningModal =

			$(	'<div class="custom-modal modal fade" tabindex="-1" role="dialog" aria-hidden="true"' +
				'	style="z-index: 10000">' +
				'	<div class="modal-dialog">' +
				'	<div class="modal-content panel panel-default">' +
				'	<div class="modal-header panel-heading">' +
				'		Nespremljeni podaci <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>' +
				'	</div>' +
				'	<div class="modal-body">' +
				'		<strong>Podaci nisu spremljeni. Želite li spremiti podatke?</strong>' +
				'	</div>' +
				'	<div class="modal-footer">' +
				'		<button class="btn btn-default custom-modal-cancel" data-dismiss="modal"><span class="icomoon icomoon-cancel-circle"></span> Odustani</button>' +
				'		<button class="btn btn-danger custom-modal-no" data-dismiss="modal"><span class="icomoon icomoon-remove2"></span> Nemoj spremiti</button>' +
				'		<button class="btn btn-success custom-modal-yes" data-dismiss="modal"><span class="icomoon icomoon-disk"></span> Spremi</button>' +
				'	</div>' +
				'	</div>' +
				'	</div>' +
				'</div>');

			$('body').append(warningModal);
		}

		$('.custom-modal').modal('show');

		$('.custom-modal-yes').off('click').on('click', function(e) {
			$('.custom-modal').modal('hide');
			$('body').find('form').submit();
		});

		$('.custom-modal-cancel').off('click').on('click', function(e) {
			/*var historyObj = {
				controller: lowerFirst(window.Bootstrap.bootquery.controller),
				method: lowerFirst(window.Bootstrap.bootquery.method),
				parameters: window.Bootstrap.bootquery.parameters
			};

			if(historyObj != history.state) {
				window.history.pushState(historyObj, null, generateQueryString());
			}*/
			$('.custom-modal').modal('hide');
		});

		$('.custom-modal-no').off('click').on('click', function(e) {
			$('.custom-modal').modal('hide');
			navigate(event, callback);
		});
	} else {
		navigate(event, callback);
	}
}

export function getSpinnerDefaults() {
	return {
		lines: 12,
		length: 0,
		width: 15,
		radius: 33,
		scale: 1.0,
		corners: 1,
		color: '#000000',
		fadeColor: 'transparent',
		speed: 1.0,
		rotate: 0,
		animation: 'spinner-line-fade-quick',
		direction: 1,
		className: 'spinner',
		top: '50%',
		left: '50%',
		shadow: '0 0 1px #FFFFFF55',
		position: 'fixed'
	};
}
$(function() {
	window.socket = new Socket(`${wsUrlBase()}/ws`);
	// Should be enough for all modules and datatables,
	// if we hit this, it might really be a bug
	window.socket.setMaxListeners(64);

	//Page fully loaded
	setTarget(null);
	window.target = $('.bq-default');	// Load into the default container

	var route = getURLComponents();

	window.spinner = new Spinner(getSpinnerDefaults());

	window.build_manifest = window.Bootstrap.build_manifest;

	moment.updateLocale('en', {
		week: {dow: 1}
	});

	if (typeof(window.Bootstrap) != 'undefined') {
		printPHPDebugs(window.Bootstrap);
		initWithData(window.Bootstrap);
	} else {
		getJSON('get', route.controller, route.method, route.parameters, true, function(data) {
			window.Bootstrap = data;
			initWithData(data);
		});
	}

	$('#initial_json').remove();

	window.onpopstate = function(e) {
		bootstrap(e, 'load');
	};
});

function onUserActivity() {
	Api.post('/api/userActivity', {});
}

export function initWithData(data) {
	parseLinks();
	loadModules(data);
	activateElements('body', data);
	setInterval(loginCheck, 60*1000);

	const userActivityCb = throttle(onUserActivity, 60*1000);
	document.addEventListener('click', () => userActivityCb());
	document.addEventListener('keydown', () => userActivityCb());
}
