import moment from 'moment';

export default function phpToMomentDateFormat(format) {
	if (!format || typeof(format) !== 'string') {
		return null;
	}

	var formatMap = {
		d: 'DD',
		D: 'ddd',
		j: 'D',
		l: 'dddd',
		N: 'E',
		S: function() {
			return '[' + this.format('Do').replace(/\d*/g, '') + ']';
		},
		w: 'd',
		z: function() {
			return this.format('DDD') - 1;
		},
		W: 'W',
		F: 'MMMM',
		m: 'MM',
		M: 'MMM',
		n: 'M',
		t: function() {
			return this.daysInMonth();
		},
		L: function() {
			return this.isLeapYear() ? 1 : 0;
		},
		o: 'GGGG',
		Y: 'YYYY',
		y: 'YY',
		a: 'a',
		A: 'A',
		B: function() {
			var thisUTC = this.clone().utc(),
				// Shamelessly stolen from http://javascript.about.com/library/blswatch.htm
				swatch = ((thisUTC.hours() + 1) % 24) + (thisUTC.minutes() / 60) + (thisUTC.seconds() / 3600);
			return Math.floor(swatch * 1000 / 24);
		},
		g: 'h',
		G: 'H',
		h: 'hh',
		H: 'HH',
		i: 'mm',
		s: 'ss',
		u: '[u]', // not sure if moment has this
		e: '[e]', // moment does not have this
		I: function() {
			return this.isDST() ? 1 : 0;
		},
		O: 'ZZ',
		P: 'Z',
		T: '[T]', // deprecated in moment
		Z: function() {
			return parseInt(this.format('ZZ'), 10) * 36;
		},
		c: 'YYYY-MM-DD[T]HH:mm:ssZ',
		r: 'ddd, DD MMM YYYY HH:mm:ss ZZ',
		U: 'X'
	};
	let formatEx = /[dDjlNSwzWFmMntLoYyaABgGhHisueIOPTZcrU]/g;
	return format.replace(formatEx, function(phpStr) {
		return formatMap[phpStr];
	});
}

moment.fn.formatPHP = function(format) {
	return this.format(phpToMomentDateFormat(format));
};