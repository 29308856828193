module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden listform-row-hidden-editable";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return " col-sm-9 col-md-10";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <input type=\"hidden\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),(depth0 != null ? depth0.$rowIndex : depth0),"$id",{"name":"array_to_path","hash":{},"data":data}))
    + "\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.editable : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " value=\""
    + alias2(container.lambda((depth0 != null ? depth0.$id : depth0), depth0))
    + "\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <div class=\"col\">\n                    <div class=\"row\">\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].editable : depths[1]),{"name":"unless","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                            "
    + ((stack1 = container.invokePartial(partials.form,depth0,{"name":"form","hash":{"plain":(depths[1] != null ? depths[1].plain : depths[1]),"disabled":true,"nested":true,"parentPath":helpers.array_to_path.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.parentPath : depth0),(depths[1] != null ? depths[1].$rowIndex : depths[1]),{"name":"array_to_path","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                            "
    + ((stack1 = container.invokePartial(partials.form,depth0,{"name":"form","hash":{"plain":(depths[1] != null ? depths[1].plain : depths[1]),"nested":true,"parentPath":helpers.array_to_path.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.parentPath : depth0),(depths[1] != null ? depths[1].$rowIndex : depths[1]),{"name":"array_to_path","hash":{},"data":data})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.readonly : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"col-xs-12 col-sm-3 col-md-2\">\n        <div class=\"row\">\n            <label class=\"col-12 col-form-label\">&zwj;</label>\n            <div class=\"col-12\">\n                <div class=\"btn-group btn-block\">\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.$is_new_row : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditableReplacedRow : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "                        <button type=\"button\" class=\"listform-button-remove btn btn-danger col-6\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),(depth0 != null ? depth0.$rowIndex : depth0),"$deleteBtn",{"name":"array_to_path","hash":{},"data":data}))
    + "\">\n                            <input hidden type=\"checkbox\" name=\""
    + alias2(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),(depth0 != null ? depth0.$rowIndex : depth0),"$deleted",{"name":"array_to_path","hash":{},"data":data}))
    + "\" value=true>\n                            <span class=\"fa fa-trash-alt\"></span>\n                        </button>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                            <button type=\"button\" class=\"listform-button-cancel-edit btn btn-secondary col-6\">\n                                <span class=\"fa fa-times\"></span>\n                            </button>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                            <button type=\"button\" class=\"listform-button-edit btn btn-primary col-6\" name=\""
    + container.escapeExpression(helpers.array_to_path.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),(depth0 != null ? depth0.$rowIndex : depth0),"$editBtn",{"name":"array_to_path","hash":{},"data":data}))
    + "\">\n                                <span class=\"fa fa-pencil-alt\"></span>\n                            </button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                        <button type=\"button\" class=\"listform-button-newrow-remove btn btn-danger col-6 pull-right\">\n                            <span class=\"fa fa-trash-alt\"></span>\n                        </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"row listform-row listform-row-form "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_hidden_editable_row : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-listform-row-id=\""
    + container.escapeExpression(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),(depth0 != null ? depth0.$rowIndex : depth0),"$id",{"name":"array_to_path","hash":{},"data":data}))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_hidden_editable_row : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n    <div class=\"col-12"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.plain : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " \">\n        <div class=\"row\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.$id : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.fields : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.plain : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true})