<template>
    <div class="card">
        <div class="card-header">
            <ol class="breadcrumb bg-transparent m-0 p-0">
                <li class="breadcrumb-item">
                    <a href="/customerChat/chat">
                        <span class="fas fa-comments"></span>
                        {{tr ("CustomerChat:label.client_chat") }}
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a href="/customerChat/history">
                        <span class="fas fa-history"></span>
                        {{tr ("CustomerChat:label.client_chat_history") }}
                    </a>
                </li>
                <li class="breadcrumb-item active">
                    <span class="fas fa-eye"></span>
                    {{ tr("CustomerChat:label.client_chat_history_view") }}
                </li>
            </ol>
        </div>
        <div class="card-body bg-light border-bottom">
            <div class="row">
                <div class="col-3">
                    <strong>Agent: </strong>
                    {{chat.agentDisplayName}}
                </div>
                <div class="col-3">
                    <strong>Klijent: </strong>
                    {{chat.customerDisplayName}}
                </div>
                <div class="col-3">
                    <strong>Datum: </strong>
                    {{chat.startDate | formatDate( tr('format.datetime') ) }}
                </div>
                <div class="col-3">
                    <strong>Broj poruka: </strong>
                    {{chat.messageCount}}
                </div>
            </div>
            <div v-if="chat.hasFeedback">
                <hr>
                <strong>Feedback klijenta: </strong><br>
                <table class="feedback-table">
                    <tr>
                        <td
                            v-for="(rating, idx) in feedback.ratings"
                            :key="idx"
                        >
                            <strong>{{rating.name}}: </strong>
                            {{rating.score || '-'}}
                        </td>
                    </tr>
                    <tr>
                        <td :colspan="feedback.ratings.length">
                            <strong>Komentar: </strong>
                            {{feedback.comment}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card-body">
            <div ref="messageArea">
                <Message
                    v-for="(message, index) in messages"
                    :key="index"
                    :direction="message.direction"
                    :content="message.content"
                    :timestamp="message.timestamp"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Message from './Message.vue';

export default {
	components: {
		Message
	},
	props: {
		messages: Array,
		customer: Object,
		agent: Object,
		state: String,
		chat: Object,
		feedback: Object,
		chatID: {}
	},
	methods: {
		scrollToBottom() {
			this.$nextTick(() => {
				const messageArea = this.$refs.messageArea;
				messageArea.scrollTo(0, messageArea.scrollHeight);
			});
		}
	},
	mounted() {
		this.scrollToBottom();
	}
};
</script>

<style lang="scss" scoped>
.feedback-table tr > td {
    border-top: 1px solid #888;
}
.feedback-table tr:last-child > td {
    border-bottom: 1px solid #888;
}
.feedback-table td {
    padding: 5px 10px;
}
.feedback-table td {
    border-left: 1px solid #888;
}
.feedback-table td:last-child {
    border-right: 1px solid #888;
}
</style>