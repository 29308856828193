import Module from 'BootQuery/Assets/js/module';
import { formatTime } from 'app/assets/js/util.js';
import { getTemplate } from 'BootQuery/Assets/js/BootQuery.js';

export default class Player extends Module {
	constructor() {
		super();
		this.currentTrack = {type: 'audio'};
		this.currentTime = 0;
		this.audioPlayer = null;
		this.videoPlayer = null;
		this.player = null;
	}

	activateElements(target, data) {
		target.findElement('a[data-recording]').ev('click.player', (e) => {
			e.preventDefault();

			let type = $(e.currentTarget).data('recording');
			let id   = $(e.currentTarget).data('recordingId');

			this.currentTrack = {
				type: type,
				id: id
			};

			if($('#player:visible').length === 0) {
				$('#player').removeClass('d-none').addClass('d-flex');
			}

			if(this.currentTrack.type === 'audio') {
				$(this.videoPlayer).removeClass('d-inline').addClass('d-none');
			}

			if(this.currentTrack.type === 'audio') {
				this.player = this.audioPlayer;

			} else if(this.currentTrack.type === 'video') {
				this.player = this.videoPlayer;
			}

			$(this.player).children('source').attr('src', `/api/files/recording/${id}`);
			this.player.load();

			$(this.player).ev('loadedmetadata', (e) => {
				$('#player-total-time').text(formatTime(this.audioPlayer.duration));
			});

			$(this.player).ev('play', (_e) => {
				$('#player').findElement('span.fa-play').removeClass('fa-play').addClass('fa-pause');
			});

			$(this.player).ev('pause', (_e) => {
				$('#player').findElement('span.fa-pause').removeClass('fa-pause').addClass('fa-play');
			});

			$(this.player).ev('ended', (_e) => {
				$('#player').findElement('span.fa-pause').removeClass('fa-pause').addClass('fa-play');
			});

			$(this.player).ev('volumechange', (e) => {
				if(e.currentTarget.volume < 0.5 && e.currentTarget.volume > 0.0) {
					$('#player').findElement('#player-button-volume-control-toggle > span')
						.removeClass('fa-volume-up')
						.removeClass('fa-volume-mute')
						.addClass('fa-volume-down');
				} else if(e.currentTarget.volume == 0.0) {
					$('#player').findElement('#player-button-volume-control-toggle > span')
						.removeClass('fa-volume-up')
						.removeClass('fa-volume-down')
						.addClass('fa-volume-mute');
				} else {
					$('#player').findElement('#player-button-volume-control-toggle > span')
						.removeClass('fa-volume-mute')
						.removeClass('fa-volume-down')
						.addClass('fa-volume-up');
				}
			});
		});

		if ($('#player').length === 0) {
			getTemplate('player', 'Player').then((template) => {
				let rendered = $.render(template, data);

				this.audioPlayer = rendered.findElement('#audio-player')[0];
				this.videoPlayer = rendered.findElement('#video-player')[0];

				this.player = this.audioPlayer; // Assume audio

				rendered.findElement('#player-close').ev('click.player', (e) => {
					e.preventDefault();

					$('#player').removeClass('d-flex').addClass('d-none');
				});

				rendered.findElement('#player-button-play').ev('click.player', (e) => {
					e.preventDefault();

					if(this.player.paused) {
						this.player.play();
					} else {
						this.player.pause();
						$(e.currentTarget).findElement('span.fa-pause').removeClass('fa-pause').addClass('fa-play');
					}
				});

				rendered.findElement('#player-button-play.fa-pause').ev('click.player', (e) => {
					e.preventDefault();

					this.player.pause();
				});

				rendered.findElement('#player-progress').slider({
					formatter: (value) => {
						if(this.player.duration) {
							let durationSec = this.player.duration;

							let secsPerSection = durationSec / 100;
							let time = value * secsPerSection;

							return formatTime(time);
						} else {
							return ('00:00:00');
						}
					}
				});

				rendered.findElement('#player-volume')
					.slider().ev('change.player', (e) => {
						const volume = e.value.newValue;
						this.player.volume = volume / 100;

						window.localStorage.setItem('Player.volume', volume / 100);
					});

				rendered.findElement('#player-button-volume-control-toggle').ev('click.player', (e) => {
					e.preventDefault();

					if(this.audioPlayer.volume > 0.0) {
						this.audioPlayer.volume = 0.0;
						this.videoPlayer.volume = 0.0;
						$('#player-volume').slider('disable');
					} else {
						this.audioPlayer.volume = window.localStorage.getItem('Player.volume');
						this.videoPlayer.volume = window.localStorage.getItem('Player.volume');
						$('#player-volume').slider('enable');
					}
				});

				rendered.findElement('#player-progress').ev('change.player', (slideEvt) => {
					let durationSec = this.player.duration;
					let percent = durationSec / 100;

					this.player.currentTime = percent * slideEvt.value.newValue;
				});

				$(this.audioPlayer).ev('timeupdate', (e) => {
					this.bindTimeupdate(rendered, this.audioPlayer);
				});

				$(this.videoPlayer).ev('timeupdate', (e) => {
					this.bindTimeupdate(rendered, this.videoPlayer);
				});

				$('body').append(rendered);
			});
		} else {
			$('#player:visible').removeClass('d-flex').addClass('d-none');
		}
	}

	bindTimeupdate(target, player) {
		let progressSec = player.currentTime;
		let durationSec = player.duration;
		let progress = formatTime(progressSec);
		let percentage = 1 / (durationSec / 100) * progressSec;

		target.findElement('#player-current-time').text(progress);
		target.findElement('#player-progress').slider('setValue', percentage);
	}
}
