<template>
	<div class="card thread-list border-right">
		<div class="card-header px-2 py-2 d-flex">
			<div class="input-group input-group-sm mb-1" v-if="showAccountList && $mq !== 'xl'">
				<select
					class="custom-select account-selector"
					@change="onAccountDropdownChange"
					autocomplete="off"
				>
					<option value="all" :selected="accountID === 'all'">[ All ]</option>
					<option
						v-for="account in accounts"
						:key="account.email"
						:selected="accountID === account.ID"
						:value="account.ID"
					>{{account.email}}</option>
				</select>
			</div>

			<div class="search-row-wrapper">
				<button
					v-if="!showAccountList || $mq !== 'xl'"
					class="btn btn-sm btn-success mail-new-message-btn"
					@click="newMessage"
					:title="tr('Mails:button.compose')"
				>
					<span class="fa fa-paper-plane"></span>
				</button>
				<input
					type="text"
					placeholder="Search"
					v-model="filtersVal.searchString"
					class="form-control form-control-sm mx-1 search-input"
				>
				<button class="btn btn-sm btn-secondary mx-1 text-nowrap" title="Filter" id="mail-filter-btn">
					<span class="fa fa-filter"></span>
					<span v-if="filterCount" class="badge badge-pill badge-light">{{filterCount}}</span>
				</button>
				<bPopover target="mail-filter-btn" placement="bottom" :trigger="['click', 'blur']">
					<template slot="title">
						<span class="fas fa-filter"></span>Filteri
					</template>
					<bFormGroup label="E-mail adresa:">
						<div class="row">
							<div class="col">
								<bFormSelect v-model="filtersVal.address.direction" :options="addressDirections"></bFormSelect>
							</div>
							<div class="col">
								<bFormInput v-model="filtersVal.address.address"></bFormInput>
							</div>
						</div>
					</bFormGroup>
					<bFormCheckbox v-model="filtersVal.hasAttachment">Ima privitak</bFormCheckbox>
					<bFormCheckbox v-model="filtersVal.isHighPriority">Visoki prioritet</bFormCheckbox>
					<bFormGroup label="Ima ticket:">
						<div class="row">
							<div class="col">
								<bFormSelect v-model="filtersVal.hasTicket" :options="hasTicketOptions"></bFormSelect>
							</div>
						</div>
					</bFormGroup>
				</bPopover>
			</div>
		</div>
		<div class="list-group list-group-flush thread-list" :class="{loading: loadingThreads}">
			<ThreadListEditorItem
				v-for="editor in visibleEditors"
				:key="editor.uuid"
				:subject="editor.subject"
				:toAddresses="editor.to"
				:preview="editor.textContent.trim().substr(0, 100)"
				:uuid="editor.uuid"
			/>
			<ThreadListItem
				v-for="thread in threadsVal"
				:key="thread.rootID"
				:rootID="thread.rootID"
				:lastMessageDate="thread.lastMessageDate"
				:firstMessageDate="thread.firstMessageDate"
				:lastMessageSubject="thread.subject"
				:lastMessageDirection="thread.direction"
				:lastMessageFromMailAddress="thread.fromMailAddress"
				:lastMessageToMailAddresses="thread.lastMessageAddresses"
				:lastMessagePriority="thread.lastMessagePriority"
				:preview="thread.preview"
				:messageCount="thread.messageCount"
				:hasTicket="thread.hasTicket"
				:isSeen="thread.seen"
				@click="selectThread(thread.rootID)"
			/>
			<div class="list-group-item text-center" v-if="canLoadMore">
				<button
					type="button"
					class="btn btn-primary load-more-mails-button"
					:disabled="isLoadingMore"
					@click.prevent="fetchMore"
				>
					<template v-if="isLoadingMore">
						<div class="spinner-border spinner-border-sm"></div>
					</template>
					<template v-else>
						<span class="fa fa-arrow-down"></span>
						{{ tr("Mails:button.load_more") }}
					</template>
				</button>
			</div>
			<div v-if="loadingThreads" class="thread-loading-indicator">
				<div class="spinner-grow" style="width: 2rem;"></div>
			</div>
		</div>
	</div>
</template>

<script>
import ThreadListItem from './ThreadListItem.vue';
import ThreadListEditorItem from './ThreadListEditorItem.vue';
import bPopover from 'bootstrap-vue/es/components/popover/popover';
import bFormGroup from 'bootstrap-vue/es/components/form-group/form-group';
import bFormSelect from 'bootstrap-vue/es/components/form-select/form-select';
import bFormInput from 'bootstrap-vue/es/components/form-input/form-input';
import bFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox';

import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { processThread } from '../js/util.js';
import { cloneDeep, isEqual } from 'lodash';

export default {
	components: {
		ThreadListItem,
		ThreadListEditorItem,
		bPopover,
		bFormGroup,
		bFormSelect,
		bFormInput,
		bFormCheckbox
	},
	props: {
		threads: Array,
		editors: Array,
		accounts: Array,
		filters: Object,
		showAccountList: Boolean
	},
	data() {
		return {
			loadingThreads: true,
			canLoadMore: false,
			isLoadingMore: false,
			editorID: null,
			addressDirections: [
				{ text: 'From/To', value: null },
				{ text: 'From', value: 'from' },
				{ text: 'To', value: 'to' }
			],
			hasTicketOptions: [
				{ text: '', value: null },
				{ text: 'Ima', value: 'true' },
				{ text: 'Nema', value: 'false' }
			]
		};
	},
	computed: {
		threadsVal: {
			get() {
				return this.threads;
			},
			set(val) {
				this.$emit('update:threads', val);
			}
		},
		filtersVal: {
			get() {
				return this.filters;
			},
			set(val) {
				this.$emit('update:filters', val);
			}
		},
		filterCount() {
			let count = 0;

			if (this.filters.hasAttachment) {
				count++;
			}
			if (this.filters.isHighPriority) {
				count++;
			}
			if (this.filters.hasTicket !== null) {
				count++;
			}
			if (
				this.filters.address.address &&
				this.filters.address.address.trim().length
			) {
				count++;
			}

			return count;
		},
		accountID() {
			const accountID = this.$route.params.accountID;
			if (accountID === 'all') {
				return accountID;
			} else {
				return parseInt(accountID);
			}
		},
		threadID() {
			const threadID = this.$route.params.threadID;
			if (!threadID) {
				return null;
			}

			if (threadID.strpos('editor-') === 0) {
				return threadID;
			} else {
				return parseInt(threadID);
			}
		},
		visibleEditors() {
			return this.editors.filter(editor => {
				if (editor.threadID) {
					return false;
				}
				if (this.accountID === 'all' || !editor.accountID) {
					return true;
				}
				return editor.accountID === this.accountID;
			});
		}
	},
	methods: {
		async fetchThreads(params) {
			const defaults = {
				filters: this.filters
			};
			if (this.accountID !== 'all') {
				defaults.accountID = this.accountID;
			}

			let threads = await Api.get('/api/mails/threads/', {
				...defaults,
				...params
			});
			threads = threads.map(processThread);
			return threads;
		},
		async fetchMore() {
			if (this.isLoadingMore) {
				return;
			}
			this.isLoadingMore = true;
			const threads = await this.fetchThreads({
				limit: 31,
				offset: this.threadsVal.length
			});
			this.canLoadMore = threads.length > 30;
			this.threadsVal.push(...threads.slice(0, 30));

			this.isLoadingMore = false;
		},
		async refreshFiltered() {
			if (this.loadingThreads) {
				return;
			}

			const prevFilters = cloneDeep(this.filters);
			this.loadingThreads = true;
			const threads = await this.fetchThreads({
				limit: 31,
				offset: 0
			});
			this.canLoadMore = threads.length > 30;
			this.threadsVal = threads.slice(0, 30);
			this.loadingThreads = false;

			if (!isEqual(prevFilters, this.filters)) {
				this.refreshFiltered();
			}
		},
		newMessage() {
			this.$emit('newMessage');
		},
		onAccountDropdownChange($ev) {
			const accountID = $ev.target.value;
			this.$router.push(`/${accountID}/threads/`);
		}
	},
	created() {
		this.fetchThreads({ limit: 31, offset: 0 }).then(threads => {
			this.canLoadMore = threads.length > 30;
			this.threadsVal = threads.slice(0, 30);
			this.loadingThreads = false;
		});
	},
	watch: {
		filters: {
			handler() {
				this.refreshFiltered();
			},
			deep: true
		},
		accountID() {
			this.refreshFiltered();
		}
	}
};
</script>

<style lang="scss" scoped>
.thread-list {
	position: relative;
}

.thread-loading-indicator {
	position: absolute;
	z-index: 1000;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	text-align: center;
	padding: 15px;
	background: rgba(0, 0, 0, 0.1);
	color: #444;
}

.thread-list {
	min-height: 0;
	height: 100%;
	overflow-y: auto;
}

.thread-list.loading {
	opacity: 0.9;
}

.account-selector {
	flex-basis: 100%;
	text-align: center;
}

.card-header {
	flex-wrap: wrap;
}

.search-input {
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
	min-width: 100px;
}

.search-row-wrapper {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
}
</style>
