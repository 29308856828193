import {forEach, map, has, clone, isArray, isObject} from 'lodash';
import { tr } from 'BootQuery/Assets/js/BootQuery.js';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

function activateFilterPickle(filterElement, data) {
	if (!filterElement.attr('name'))  {
		return;
	}
	var nameParts = filterElement.attr('name').split('-');
	if (nameParts.length < 2) {
		return;
	}
	var tableName = nameParts.shift();
	var filterName = nameParts.join('-');
	var tableInfo = data.tables[tableName];
	var filterInfo = $.grep(tableInfo.filters, function(filter) {
		return filter.key == filterName;
	});

	if (!has(filterInfo, '0.link.source')) {
		filterElement.pickle();
		return;
	}

	filterInfo = filterInfo[0];
	filterElement.pickle({
		multiple: filterInfo.multiple,
		results: function(searchTerm, results, callback) {
			var element = $(this);
			if (element.data('isQuerying')) {
				return;
			}
			element.data('isQuerying', true);

			var params = {
				sortby: filterInfo.link.textColumn,
				limit: 100,
				value: filterElement.val(),
				idColumn: filterInfo.link.idColumn,
				textColumn: filterInfo.link.textColumn,
				source: filterInfo.link.source
			};

			let filters = [];
			if (filterInfo.link.filters) {
				// Clone, otherwise search term will stay in there
				// even after removing it, because it's a reference
				filters = clone(filterInfo.link.filters);
			}
			if (searchTerm.length && searchTerm.trim().length) {
				const key = filterInfo.link.textColumn + '_like_ci';
				if (isArray(filters)) {
					filters.push({
						key,
						value: searchTerm
					});
				} else if (isObject(filters)) {
					filters[key] = searchTerm;
				} else {
					console.error('Weird filter format: ', filters);
					throw new Error('Weird filter format');
				}
			}
			params.filters = filters;
			Api.get('/api/getSelectOptions', {
				controller: data.bootquery.controller,
				...params
			}).then(results => {
				const options  = results.map(option => {
					return {
						id: option[filterInfo.link.idColumn],
						text: option[filterInfo.link.textColumn]
					};
				});
				element.data('isQuerying', false);
				callback(options, searchTerm.length);
			});
		}
	});
}

export default function refreshFilters(target, data) {
	target = $(target);
	if (!data || !data.tables) {
		return;
	}

	forEach(data.tables, function(table, tableName) {
		let format = (option) => {
			let addon_icon = '';
			if (option.addon_icon) {
				addon_icon = option.addon_icon;
			}
			return `<span class="${addon_icon}"></span>&nbsp;&nbsp;${option.name}`;
		};

		let filters = table.filters;
		let filterSelector = target.findElement(`#${tableName}-picker .filter-selector`);
		filterSelector.pickle({
			multiple: true,
			searchable: false,
			placeholder: tr('filter.choose_filters'),
			results: function (searchTerm, inResults, callback) {
				let results = [];

				$.each(filters, function() {
					if (this.is_hidden) {
						return;
					}

					let formattedText = format(this);
					let option = {
						id: this.key,
						text: formattedText,
						icon: this.addon_icon,
						persist: true
					};

					if (typeof(this.value) !== 'undefined' && this.value !== null && this.value !== '') {
						if(this.display_value == 'true' || this.display_value == 1) this.display_value = 'Da';
						if(this.display_value == 'false' || this.display_value == 0) this.display_value = 'Ne';
						option.text = formattedText + ': ' + this.display_value;
					}
					results.push(option);
				});
				callback(results);
			}
		});
		filterSelector.pickle('refresh');
		let usedFilters = $.grep(filters, function(filter) {
			return filter.value && !filter.is_hidden;
		});
		let selectedKeys = map(usedFilters, 'key');
		forEach(selectedKeys, function(key) {
			filterSelector.pickle('select', key);
		});

		target.findElement('#' + tableName + '-filters-clear').prop('disabled', usedFilters.length == 0);
		filterSelector.on('select', function(e) {
			e.preventDefault();
			e.stopPropagation();
			let id = e.value;

			const escapedTableName = $.escapeSelector(tableName);
			const escapedId = $.escapeSelector(id);
			let fieldset = $(`#${escapedTableName}-filter_${escapedId}`);
			let input = fieldset.show().find('select, input').first();
			fieldset.find('button').prop('disabled', false);

			input.data('initial-value', input.val());
			if (!input.val()) {
				filterSelector.data('noSubmitOnUnselect', true);
				filterSelector.pickle('unselect', id);
				filterSelector.data('noSubmitOnUnselect', false);
			}
			input.prop('disabled', false);
			if (input.is('.filters-pickle')) {
				activateFilterPickle(input, data);
				input.pickle('disabled', false);
			}
			input.focus();
			input.ev('keypress.filters', ev => {
				if (ev.key === 'Enter') {
					ev.preventDefault();
					input.closest('form').submit();
				}
			});
			$(`#${tableName}-picker`).hide();
		});

		filterSelector.off('unselect').on('unselect', function(e) {
			if (filterSelector.data('noSubmitOnUnselect')) {
				return;
			}
			let inputName = tableName + '-' + e.value;
			let form = $(e.currentTarget).closest('form');
			form.find('input[name="' + inputName + '"], select[name="' + inputName + '"]')
				.val('')
				.prop('disabled', true);
			form.submit();
		});

		target.findElement('.filters-cancel-button').off('click').on('click', function(e) {
			let activeInput = $('fieldset[id^="' + tableName + '-filter_"]:visible').find('input');
			activeInput.val(activeInput.data('initial-value'));
			$('fieldset[id^="' + tableName + '-filter_"]').hide();
			$(`#${tableName}-picker`).show();
			$('.filter-selector').pickle('refresh');
		});

		target.findElement(`#${tableName}-picker .pickle-container`).off('click', '.pickle-tag').on('click', '.pickle-tag', function(e) {
			e.preventDefault();
			e.stopPropagation();

			let id = $(e.currentTarget).data('pickle-value');
			$('fieldset[id^="' + tableName + '-filter_"]').hide();
			const escapedTableName = $.escapeSelector(tableName);
			const escapedId = $.escapeSelector(id);
			let field = $(`#${escapedTableName}-filter_${escapedId}`);
			field.show();
			const input = field.find('select, input').first();
			if (input.is('.filters-pickle')) {
				activateFilterPickle(input, data);
			} else {
				input.data('initial-value', input.val());
				input.focus();
				input.ev('keypress.filters', ev => {
					if (ev.key === 'Enter') {
						ev.preventDefault();
						input.closest('form').submit();
					}
				});
			}
			$(`#${tableName}-picker`).hide();
		});

		target.findElement('#' + tableName + '-filters-clear').off('click').on('click', function(e) {
			e.stopPropagation();
			e.preventDefault();

			let filtersForm = $(e.currentTarget).closest('#' + tableName + '-filter-form');
			filtersForm.find('fieldset input, fieldset select').val('').prop('disabled', true);
			filtersForm.submit();
		});

		target.findElement('#' + tableName + '-filter-form').off('submit submit.filter-submit').on('submit.filter-submit', function(e) {
			e.stopPropagation();
			e.preventDefault();
			console.log('Submit!');

			let filtersForm = $(e.currentTarget);
			submitForm(filtersForm, data);

			return false;
		});
	});
}