module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"card h-100\">\n"
    + ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.result : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "        <div class=\"card-header\">\n            <span class=\"fa fa-cog\"></span>\n            "
    + alias2(helpers.tr.call(alias1,helpers.concat.call(alias1,"label.",((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.which_settings),{"name":"concat","hash":{},"data":data}),{"name":"tr","hash":{},"data":data}))
    + "\n        </div>\n        <div class=\"card-block settings-card-block\">\n            <form method=\"POST\" action=\"/settings/"
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.which_settings), depth0))
    + "\" id=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.which_settings), depth0))
    + "-form\" class=\"h-100\">\n                <input type=\"hidden\" name=\"action\" value=\"update\">\n                <div class=\"row no-gutters h-100 settings-container-row\">\n                    <div class=\"col-12 col-md-3\">\n                        <div class=\"list-group settings-menu-list-group h-100\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"col-12 col-md-9\">\n                        <div class=\"tab-content\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n            </form>\n        </div>\n        <div class=\"card-footer d-flex\">\n            <div class=\"save-button ml-auto\">\n                <span class=\"save-status\" style=\"padding: 7px 15px;\" data-form=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.which_settings), depth0))
    + "-form\" hidden=\"\">\n                </span>\n                <button type=\"submit\" class=\"btn btn-success\" form=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.which_settings), depth0))
    + "-form\" data-form=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.which_settings), depth0))
    + "-form\">\n                    <span class=\"fa fa-check\"></span>&nbsp;Spremi\n                </button>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <a href=\"#setting-"
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\" class=\"list-group-item list-group-item-action "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-toggle=\"list\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "active";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <div id=\"setting-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.key : depth0), depth0))
    + "\" class=\"tab-pane fade "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                "
    + ((stack1 = container.invokePartial(helpers.lookup.call(alias1,depth0,"view",{"name":"lookup","hash":{},"data":data}),(depth0 != null ? depth0.data : depth0),{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "show active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{"bq_content_class":"bq-settings"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})