module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-plus\"></span>\n					"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.add_ticket_type",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"fa fa-edit\"></span>\n					"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_ticket_type",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "								<input type=\"hidden\" name=\"typeState["
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "]\" value=\"false\">\n								<div class=\"custom-form custom-checkbox\">\n									<input type=\"checkbox\" class=\"custom-control-input\" name=\"typeState["
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "]\" id=\"typeState-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" value=\"true\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.enabled : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n									<label class=\"custom-control-label\" for=\"typeState-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n										<span class=\"badge badge-dark w-100\" style=\"background-color: "
    + alias2(alias1((depth0 != null ? depth0.color : depth0), depth0))
    + ";\">\n											"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n										</span>\n									</label>\n								</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n	<div class=\"modal-dialog modal-lg\" role=\"document\" style=\"max-width: none; width: 90%;\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h5 class=\"modal-title\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "				</h5>\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n					<span aria-hidden=\"true\">&times;</span>\n				</button>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"form-group row\">\n					<label class=\"col-12 col-form-label col-md-1 col-form-label control-label text-left text-md-right\" for=\"ticket-type-name\">\n						"
    + alias2(helpers.tr.call(alias1,"label.name",{"name":"tr","hash":{},"data":data}))
    + ":\n					</label>\n					<div class=\"col-12 col-md-11\">\n						<input name=\"name\" id=\"ticket-type-name\" value=\""
    + alias2(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\" class=\"form-control\" type=\"text\">\n					</div>\n				</div>\n				<ul class=\"nav nav-tabs\">\n					<li class=\"nav-item\">\n						<a href=\"#type-fields\" class=\"nav-link active\" data-toggle=\"tab\">\n							"
    + alias2(helpers.tr.call(alias1,"tab.fields",{"name":"tr","hash":{},"data":data}))
    + "\n						</a>\n					</li>\n					<li class=\"nav-item\">\n						<a href=\"#type-states\" class=\"nav-link\" data-toggle=\"tab\">\n							"
    + alias2(helpers.tr.call(alias1,"tab.states",{"name":"tr","hash":{},"data":data}))
    + "\n						</a>\n					</li>\n				</ul>\n				<div class=\"tab-content\">\n					<div class=\"tab-pane fade show active\" id=\"type-fields\">\n						<div id=\"ticket-type-form-editor\"></div>\n					</div>\n					<div class=\"tab-pane fade\" id=\"type-states\">\n						<div class=\"container-fluid py-3\">\n							<div class=\"col-12\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ticketStates : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias2(helpers.tr.call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-success save-btn\">\n					<span class=\"fa fa-check\"></span> "
    + alias2(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true})