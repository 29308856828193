module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "		<div class=\"card\">\n			<div class=\"card-header\">\n				<span class=\"fas fa-building\"></span> "
    + alias2(helpers.tr.call(alias1,"label.view_company",{"name":"tr","hash":{},"data":data}))
    + "\n			</div>\n			<div class=\"card-body\">\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.result : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n			<div class=\"card-block\">\n				<ul class=\"nav nav-tabs px-2\">\n					<li class=\"nav-item\">\n						<a href=\"#locations\" data-toggle=\"tab\" class=\"nav-link active\">\n							"
    + alias2(helpers.tr.call(alias1,"tab.locations",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary\">"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.locations : stack1)) != null ? stack1.resultdata : stack1)) != null ? stack1.count : stack1), depth0))
    + "</span>\n						</a>\n					</li>\n					<li class=\"nav-item\">\n						<a href=\"#departments\" data-toggle=\"tab\" class=\"nav-link\">\n							"
    + alias2(helpers.tr.call(alias1,"tab.departments",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary\">"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.departments : stack1)) != null ? stack1.resultdata : stack1)) != null ? stack1.count : stack1), depth0))
    + "</span>\n						</a>\n					</li>\n					<li class=\"nav-item\">\n						<a href=\"#employees\" data-toggle=\"tab\" class=\"nav-link\">\n							"
    + alias2(helpers.tr.call(alias1,"tab.employees",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary\">"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.employees : stack1)) != null ? stack1.resultdata : stack1)) != null ? stack1.count : stack1), depth0))
    + "</span>\n						</a>\n					</li>\n					<li class=\"nav-item\">\n						<a href=\"#events\" data-toggle=\"tab\" class=\"nav-link\">\n							"
    + alias2(helpers.tr.call(alias1,"tab.events",{"name":"tr","hash":{},"data":data}))
    + " <span class=\"badge badge-secondary\">"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.events : stack1)) != null ? stack1.resultdata : stack1)) != null ? stack1.count : stack1), depth0))
    + "</span>\n						</a>\n					</li>\n				</ul>\n				<div class=\"tab-content\">\n					<div id=\"locations\" class=\"tab-pane fade show active\">\n						<div class=\"card m-3\">\n"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.locations : stack1),{"name":"with","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n					<div id=\"departments\" class=\"tab-pane fade\">\n						<div class=\"card m-3\">\n"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.departments : stack1),{"name":"with","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n					<div id=\"employees\" class=\"tab-pane fade\">\n						<div class=\"card m-3\">\n"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.employees : stack1),{"name":"with","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n					<div id=\"events\" class=\"tab-pane fade\">\n						<div class=\"card m-3\">\n"
    + ((stack1 = helpers["with"].call(alias1,((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.events : stack1),{"name":"with","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						</div>\n					</div>\n				</div>\n			</div>\n			<div class=\"card-footer d-flex\">\n				<a href=\"/phonebook/companies/\" class=\"btn btn-link\">\n					<span class=\"fa fa-arrow-left\"></span> "
    + alias2(helpers.tr.call(alias1,"button.back_to_list",{"name":"tr","hash":{},"data":data}))
    + "\n				</a>\n				<a href=\"/phonebook/modifyCompany/?companyID="
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.ID : stack1), depth0))
    + "\" class=\"btn btn-primary ml-auto\">\n					<span class=\"fa fa-pencil-alt\"></span> "
    + alias2(helpers.tr.call(alias1,"button.edit",{"name":"tr","hash":{},"data":data}))
    + "\n				</a>\n			</div>\n		</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<div class=\"row pb-3\">\n					<div class=\"col-12\">\n						<h3>\n							<span class=\"fa fa-building\"></span>\n							"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n							<small class=\"text-muted d-inline-block\">"
    + alias2(alias1((depth0 != null ? depth0.formattedAddress : depth0), depth0))
    + "</small>\n						</h3>\n					</div>\n				</div>\n				<div class=\"row\">\n					<div class=\"col-12 col-md-6 col-lg-4 pb-3 pb-md-0\">\n						<div class=\"row\">\n							<div class=\"col-auto\">\n								<span class=\"fa fa-phone\"></span>\n							</div>\n							<div class=\"col\">\n								<table class=\"table-sm\">\n									<tbody>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.phoneNumbers : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "									</tbody>\n								</table>\n							</div>\n						</div>\n					</div>\n					<div class=\"col-12 col-md-6 col-lg-4\">\n						<div class=\"row\">\n							<div class=\"col-auto\">\n								<span class=\"fa fa-envelope\"></span>\n							</div>\n							<div class=\"col\">\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.emails : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n				</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "										<tr>\n											<th style=\"width: 1%; white-space: nowrap; text-align: right;  padding-left: 0;\">\n												"
    + alias2(alias1((depth0 != null ? depth0.numberType : depth0), depth0))
    + ":\n											</th>\n											<td>\n												<a href=\"tel:"
    + alias2(alias1((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.fullPhoneNumber : depth0), depth0))
    + "</a>\n											</td>\n										</tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "											<span class=\"text-muted\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data}))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "								<div class=\"p-1\">\n									<a href=\"mailto:"
    + alias2(alias1((depth0 != null ? depth0.email : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.email : depth0), depth0))
    + "</a>\n								</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "								<span class=\"text-muted\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data}))
    + "</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "								<div class=\"card-body\">\n									"
    + ((stack1 = container.invokePartial(partials.sectionHead,depth0,{"name":"sectionHead","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "								</div>\n								"
    + ((stack1 = container.invokePartial(partials.table,depth0,{"name":"table","hash":{"small":true,"noBottomMargin":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "								<div class=\"card-footer\">\n									"
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","hash":{"small":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "								</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})