module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "			<a class=\"dropdown-item\" href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.controller : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entry : depth0)) != null ? stack1.method : stack1), depth0))
    + "\">\n				<span class=\""
    + alias2(alias1((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span> "
    + alias2(helpers.tr.call(alias3,helpers.concat.call(alias3,(depth0 != null ? depth0.module : depth0),":menu_entries.",(depth0 != null ? depth0.name : depth0),{"name":"concat","hash":{},"data":data}),{"name":"tr","hash":{},"data":data}))
    + "\n			</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "			<span id=\"callcenter-header-menu\">\n				"
    + ((stack1 = container.invokePartial(partials["Callcenter.headerMenuContent"],depth0,{"name":"Callcenter.headerMenuContent","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<li class=\"nav-item dropdown\">\n	<a class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\" href=\"#\">\n		<span class=\"fa fa-tv\"></span>\n		<span>&nbsp;Callcenter</span>\n	</a>\n\n	<div class=\"dropdown-menu dropdown-menu-right\" id=\"callcenter-header-dropdown\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["with"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.modules)) && stack1.Callcenter),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</li>";
},"usePartial":true,"useData":true})