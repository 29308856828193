<template>
	<div class="card border-right" style="width: 24%; min-width: 250px;">
		<div class="card-header py-2 text-center">
			<button class="btn btn-sm btn-success" @click.prevent="startNewMessage" :disabled="newSelected">
				<span class="fa fa-paper-plane"></span>
				Nova poruka
			</button>
		</div>
		<div class="card-block">
			<template v-if="loaded">
				<ConversationListItem
					v-for="conversation in sortedConversations"
					:key="conversation.phoneNumber"
					:conversation="conversation"
					:active="conversation.phoneNumber === selectedPhoneNumber"
				/>
			</template>
			<div v-else class="text-center">
				<span class="spinner-grow m-3"></span>
			</div>
		</div>
	</div>
</template>

<script>
import ConversationListItem from './ConversationListItem.vue';

import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		ConversationListItem
	},
	props: {
		conversations: Array
	},
	data() {
		return {
			loaded: false
		};
	},
	computed: {
		sortedConversations() {
			return this.conversations.slice().sort(
				(lhs, rhs) => rhs.lastMessageDate - lhs.lastMessageDate
			);
		},
		selectedPhoneNumber() {
			const number = this.$route.params.phoneNumber;
			return number && number !== 'new' ? number : null;
		},
		newSelected() {
			return this.$route.params.phoneNumber === 'new';
		}
	},
	methods: {
		startNewMessage() {
			this.$router.push('/conversations/new');
		},
		onMessage(message) {
			const existing = this.conversations.find(
				conv => conv.phoneNumber === message.otherPartyPhoneNumber
			);
			if (existing) {
				this.$set(existing, 'lastMessage', message);
				this.$set(existing, 'lastMessageDate', new Date(message.date));
			} else {
				Api.get('/api/sms/otherParties').then(convs =>
					this.$emit('update:conversations', convs)
				);
			}
		},
		async fetchConversations() {
			const conversations = await Api.get('/api/sms/otherParties');
			return conversations.map(conv => {
				conv.lastMessageDate = new Date(conv.lastMessageDate);
				return conv;
			});
		}
	},
	created() {
		this.fetchConversations().then(conversations => {
			this.loaded = true;
			this.$emit('update:conversations', conversations);
		});
	}
};
</script>

<style scoped lang="scss">
</style>
