module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(partials.formEditorSelectItemsCol,depth0,{"name":"formEditorSelectItemsCol","hash":{"isLastSelect":(data && data.last)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row no-gutters\">\n    <div class=\"col\">\n        <div class=\"card-group\">\n            "
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selects : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <div class=\"col col-auto px-1 py-1\">\n        <button type=\"button\" class=\"btn btn-success btn-sm add-select-btn\">\n            <span class=\"fa fa-plus\"></span>\n        </button>\n    </div>\n</div>";
},"usePartial":true,"useData":true})