module.exports = {"label":{"no_result":"Keine Resultate","filters":"Filter","refresh_type":"Aktualisieren","refresh_manual":"Manuell","refresh_auto":"Automatisch","refresh":"Daten aktualisieren","refresh_up_to_date":"Daten aktualisiert","edit_entry":"Eintrag bearbeiten","view_entry":"Eintrag ansehen","entry_details":"Eintragdetails","back_to_list":"Zurück zur Liste","or":"oder","save_success":"Änderungen erfolgreich gespeichert","save_failure":"Fehler bei Speicherung der Änderungen","actions":"Aktionen","processing":"In Bearbeitung","status":"Status","file":"Datei","view_data":"Datenübersicht","select":"Auswählen","10_results_per_page":"10 Ergebnisse pro Seite","20_results_per_page":"20 Ergebnisse pro Seite","30_results_per_page":"30 Ergebnisse pro Seite","records_total":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "Einträge gesamt: "
    + container.escapeExpression(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"count","hash":{},"data":data}) : helper)));
},"useData":true}),"unsaved_data":"Ungespeicherte Daten","unsaved_data_body":"Das Formular enthält ungespeicherte Daten. Möchten Sie die Änderungen speichern?","delete_entry":"Eintrag löschen","delete_entry_body":"Sind Sie sicher, dass Sie den gewählen Eintrag löschen möchten?","saved":"Gespeichert","validation_error":"Ungültige Eingabe","saving_in_progress":"Speicherung...","validation_in_progress":"Validierung...","permission_read":"Lesen","permission_write":"Schreiben","permission_delete":"Löschen","permission_owner":"Eigentümer","permission_group":"Gruppe","permission_others":"Andere","show_columns":"Spalten zeigen","add":"Hinzufügen","days":"[days]","hours":"[hours]","minutes":"[minutes]","edit":"[edit]","name":"[name]","now":"[now]"},"button":{"add":"Hinzufügen","edit":"Bearbeiten","save":"Speichern","discard":"Widerrufen","cancel":"Abbrechen","delete":"Löschen","settings":"Einstellungen","display":"Zeigen","back":"Zurück","back_to_list":"Zurück zur Liste","close":"Schliessen","send":"Senden","choose_file":"Datei wählen","download":"Herunterladen","upload":"[upload]"},"form":{"file":"Datei"},"filter":{"choose_filters":"Filter wählen","from_date":"Von (Datum)","to_date":"Bis (Datum)","from_time":"Von (Zeit)","to_time":"Bis (Zeit)","created_from_date":"[created_from_date]","created_to_date":"[created_to_date]","created_from_time":"[created_from_time]","created_to_time":"[created_to_time]"},"pagination":{"previous":"Vorherige Seite","next":"Nächste Seite","indicator":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "Seite "
    + alias4(((helper = (helper = helpers.page || (depth0 != null ? depth0.page : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"page","hash":{},"data":data}) : helper)))
    + " von "
    + alias4(((helper = (helper = helpers.pageCount || (depth0 != null ? depth0.pageCount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageCount","hash":{},"data":data}) : helper)));
},"useData":true}),"choose_page":"Seite wählen","button":{"go":"Idi"},"placeholder":{"page_number":"Seite"}},"report":{"report":"Bericht","print":"Berichtausdruck","export_excel":"Export in Excel","export_pdf":"Export in PDF","company":"Firma","address":"Adresse","city":"Stadt","identification_number":"OIB","generated_on":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "Bericht erstellt am "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.date : stack1), depth0))
    + " um "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.time : stack1), depth0));
},"useData":true})},"format":{"date":"d.m.Y","time":"H.i.s","datetime":"d.m.Y. H:i","datetime_s":"d.m.Y H:i:s"},"error":{"permission_denied":"Zugriff verweigert","not_found":"Nicht gefunden"},"calendar":{"mon_long":"Montag","tue_long":"Dienstag","wed_long":"Mittwoch","thu_long":"Donnerstag","fri_long":"Freitag","sat_long":"Samstag","sun_long":"Sonntag","mon":"Mo","tue":"Di","wed":"Mi","thu":"Do","fri":"Fr","sat":"Sa","sun":"So","jan_long":"Januar","feb_long":"Februar","mar_long":"März","apr_long":"April","may_long":"Mai","jun_long":"Juni","jul_long":"Juli","aug_long":"August","sep_long":"September","oct_long":"Oktober","nov_long":"November","dec_long":"Dezember","jan":"Jan","feb":"Feb","mar":"Mär","apr":"Apr","may":"Mai","jun":"Jun","jul":"Jul","aug":"Aug","sep":"Sep","oct":"Oct","nov":"Nov","dec":"Dez"}}