import Module from 'BootQuery/Assets/js/module';
import {get, forEach, lowerFirst} from 'lodash';

export default class Settings extends Module {
	activateElements(target, data) {
		let tabs = get(data, 'result.tabs');
		if (!tabs) {
			return;
		}

		const tabHash = window.location.hash;
		if(tabHash) {
			const tab = target.findElement(`a[href="${tabHash}"]`);
			if (tab.length) {
				tab.siblings('a').removeClass('active show');
				tab.addClass('active show');
				const tabContent = target.find(tabHash);
				tabContent.siblings().removeClass('show active');
				tabContent.addClass('show active');
			}
		}

		target.findElement('#userSettings-form')
			.off('submitted.settings')
			.on('submitted.settings', (e, data) => {
				let $form = $('#userSettings-form');
				let errors = get(data, 'result.errors');
				let changed = get(data, 'result.changed');

				if (changed) {
					$(document).trigger('userSettingsChanged', changed);
				}
				if (errors && Object.entries(errors).length) {
					setFormSaveStatus($form, 'validation-error');
					$(document).trigger('onUserSettingsErrors', errors);
				}
			});

		target.findElement('#settings-form')
			.off('submitted.settings')
			.on('submitted.settings', (e, data) => {
				let changed = get(data, 'result.changed');
				if (changed) {
					$(document).trigger('settingsChanged', changed);
				}
			});

		forEach(tabs, tab => {
			if (!get(tab, 'data.forms')) {
				return;
			}
			forEach(tab.data.forms, (form, formName) => {
				let $settingsEl = null;
				if (form.elementSelector) {
					$settingsEl = target.findElement(form.elementSelector);
				} else {
					$settingsEl = target.findElement(`.tab-pane#setting-${tab.key}`);
				}
				$settingsEl.form({formDefinition: form});
			});
		});

		target.findElement('a[data-toggle="list"]').on('shown.bs.tab', function (e) {
			let hash = $(e.target).attr('href').substr(1);

			let historyObj = {
				controller: lowerFirst(window.Bootstrap.bootquery.controller),
				method: lowerFirst(window.Bootstrap.bootquery.method),
				hash: hash
			};

			let newUrl;
			if (window.window.Bootstrap.bootquery.controller) {
				newUrl = '/' + window.Bootstrap.bootquery.controller + '/' + window.Bootstrap.bootquery.method + '/';
			}

			if(hash) {
				newUrl += '#' + hash;
			}

			history.replaceState(historyObj, null, newUrl);
		});
	}
}