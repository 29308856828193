module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    			    <div class=\"panel-heading\" id=\"delete-heading\"><span class=\"glyphicon glyphicon-earphone\"></span>&nbsp;Izbriši korisnika</div>\n\n    			    <div class=\"panel-body\">\n               \n                        Jeste li sigurni da želite izbrisati korisnika <strong>"
    + alias2(alias1((depth0 != null ? depth0.username : depth0), depth0))
    + "</strong>?\n\n                        <form method=\"post\" action=\"/user/delete\">\n\n                        <input type=\"hidden\" name=\"ID\" value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n\n                        <div class=\"pull-right\">\n\n                            <a href=\"/user/userlist/\" class=\"btn btn-info\"><span class=\"glyphicon glyphicon-remove\"></span> Ne</a>\n                            <button type=\"submit\" class=\"btn btn-warning\"><span class=\"glyphicon glyphicon-ok\"></span> Da</button>\n\n                        </div>\n\n                    </div>\n\n                    <div class=\"panel-footer\">\n\n                        <form method=\"post\" action=\"/user/delete\">\n\n                        <input type=\"hidden\" name=\"ID\" value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n\n                        <div class=\"pull-right\">\n\n                            <a href=\"/user/userlist/\" class=\"btn btn-info\"><span class=\"glyphicon glyphicon-remove\" data-dismiss=\"modal\"></span> Ne</a>\n                            <button type=\"submit\" class=\"btn btn-warning\"><span class=\"glyphicon glyphicon-ok\"></span> Da</button>\n\n                        </div>\n\n                        <div class=\"clearfix\"></div>\n\n                        </form>\n\n                    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"content-wrapper\">\n\n    <div class=\"container\" id=\"main-container\">\n\n        "
    + ((stack1 = container.invokePartial(partials.header,depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"row\">\n\n            "
    + ((stack1 = container.invokePartial(partials.menu,depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"col-md-12 col-lg-12 bq-content bq-default\">\n                \n                <div class=\"panel panel-default\">\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.result : depth0), depth0),{"name":"result","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    	        </div>\n\n            </div>\n\n        </div>\n\n    </div>\n\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})