import Module from 'BootQuery/Assets/js/module';
import Vue from 'BootQuery/Assets/js/vue.js';
import CustomerChat from '../components/CustomerChat.vue';
import ChatHistory from '../components/ChatHistory.vue';
import { getUserSetting } from 'BootQuery/Assets/js/BootQuery.js';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import Socket from 'BootQuery/Assets/js/socket';
import { wsUrlBase } from 'app/assets/js/util';
import TicketPicker from 'app/Modules/Ticketing/Assets/js/TicketPicker.js';
import SoundNewCustomer from '../sounds/new_customer.mp3';
import SoundMessage from 'app/assets/sounds/short.mp3';

export default class Chat extends Module {
	init(_data) {
		super.init();
		this.messageSound = new Audio(SoundMessage);
		this.newCustomerSound = new Audio(SoundNewCustomer);
		console.log('Initialising chat');

		this.loaded = false;
		this.chatSocket = null;
		this.component = null;

		this.getGroups().then(groups => {
			this.groups = groups;
			if (this.groups.length) {
				this.getToken().then(async token => {
					this.token = token;
					this.openSocket();
					this.chats = await this.getChats();
					this.loaded = true;
					this.emit('loaded');
					this.updateChatNotifier();
				});
			} else {
				this.chats = [];
				this.loaded = true;
				this.emit('loaded');
				return;
			}
		});
	}

	openSocket() {
		this.chatSocket = new Socket(`${wsUrlBase()}/customerChatService/ws`);
		this.chatSocket.on('connect', () => {
			this.chatSocket.send('agentAuth', { token: this.token });
		});
		this.chatSocket.on('chatAccepted', ev => {
			const acceptedChat = this.chats.find(chat => chat.ID === ev.chatID);
			if (!acceptedChat) {
				return;
			}
			acceptedChat.agentID = ev.agentID;
			acceptedChat.state = 'active';
			this.updateChatNotifier();
		});
		this.chatSocket.on('newCustomer', ev => {
			const chat = this.processChat(ev);
			this.chats.push(chat);
			this.updateChatNotifier();
			this.newCustomerSound.play();
		});
		this.chatSocket.on('message', ev => {
			this.messageSound.play();
			const chat = this.chats.find(chat => chat.ID === ev.chatID);
			if (!chat) {
				return;
			}

			const message = this.processMessage(ev);
			chat.messages.push(message);
		});
		this.chatSocket.on('chatEnd', ev => {
			const chatIndex = this.chats.findIndex(
				chat => chat.ID === ev.chatID
			);
			if (chatIndex === -1) {
				return;
			}

			const chat = this.chats[chatIndex];
			if (chat.agentID === window.Bootstrap.bootquery.session.userID) {
				chat.endDate = new Date();
				chat.state = 'ended';
			} else {
				this.chats.splice(chatIndex, 1);
			}
			this.updateChatNotifier();
		});
		this.chatSocket.on('authResult', ev => {
			if (!ev.success) {
				console.error('Chat auth failed!');
				return;
			}
			this.sendGroupLogins();
		});
	}

	updateChatNotifier() {
		const menuItem = $(
			'.menu-container .nav-item > .nav-link[data-controller="customerChat"]'
		);
		const menuItemBadge = menuItem.find('.menu-item-notification-counter');
		const waitingCount = this.chats.filter(chat => chat.state === 'waiting')
			.length;
		menuItemBadge.text(waitingCount);
		menuItemBadge.prop('hidden', waitingCount === 0);
	}

	async sendGroupLogins() {
		const loggedInGroups = this.groups
			.filter(group => group.loggedIn)
			.map(group => group.ID);
		this.chatSocket.send('loginGroups', { groups: loggedInGroups });
	}

	async getToken() {
		const resp = await Api.post('/api/customerChat/getAgentToken', {});
		if (!resp.success) {
			throw `Error getting agent token: ${resp.error}`;
		}
		return resp.token;
	}

	async getChats() {
		const chats = await Api.get('/customerChatService/api/chats');
		return chats.map(this.processChat.bind(this));
	}

	processChat(chat) {
		if (!chat.agentID) {
			// Computed properties fucky with undefineds in this case
			chat.agentID = null;
		}
		chat.waitStart = new Date(chat.waitStart);
		chat.messages = chat.messages.map(this.processMessage.bind(this));
		return chat;
	}

	processMessage(message) {
		message.timestamp = new Date(message.timestamp);
		return message;
	}

	async getGroups() {
		const availableGroups = await this.availableGroups();
		if (!availableGroups || !availableGroups.length) {
			return [];
		}
		let loggedInGroups = await getUserSetting(
			'CustomerChat.loggedInGroups'
		);

		if (loggedInGroups) {
			// PHP semi-randomly decides to return strings here sometimes...
			loggedInGroups = loggedInGroups.map(groupID => parseInt(groupID));
		} else {
			loggedInGroups = [];
		}

		return availableGroups.map(group => {
			group.loggedIn = loggedInGroups.includes(group.ID);
			return group;
		});
	}

	availableGroups() {
		return Api.get('/api/customerChat/availableGroups');
	}

	activateElements(target, data) {
		const $chatContainer = target.findElement('#customer-chat-container');
		if ($chatContainer.length) {
			this.renderChatInterface($chatContainer);
		}

		target
			.findElement('.customerchat-groups-add-btn')
			.ev('click.customerchat', e => {
				e.preventDefault();
				this.createGroup(target, data);
			});

		const $historyContainer = target.findElement('#customer-chat-history');
		if ($historyContainer.length) {
			this.renderHistoryInterface(
				$historyContainer,
				window.Bootstrap.result
			);
		}

		target
			.findElement('.assign-chat-to-ticket-btn')
			.ev('click.customerchat', e => {
				e.preventDefault();
				const chatID = parseInt($(e.currentTarget).data('chatId'));
				this.openTicketPicker(chatID);
			});
	}

	async renderChatInterface($chatContainer) {
		const chatContainer = $chatContainer[0];
		setTimeout(() => {
			this.component = new Vue({
				el: chatContainer,
				render: h => h(CustomerChat)
			});
		}, 0);
	}

	async renderHistoryInterface($historyContainer, data) {
		const historyContainer = $historyContainer[0];
		data.messages = data.messages.map(message =>
			this.processMessage(message)
		);
		setTimeout(() => {
			this.component = new Vue({
				el: historyContainer,
				render: h => h(ChatHistory, { props: data })
			});
		}, 0);
	}

	async createGroup(target, data) {
		const groupSettingsCardTemplate = await getTemplate(
			'CustomerChat.groupSettingsCard'
		);
		const ownData = this.findTab(data).data;
		const groupsPane = target.find('#customerchat-groups');
		console.log('Groups pane: ', groupsPane);
		const rowAddBtn = groupsPane.find('.customerchat-groups-add-btn');
		console.log('Row add btn: ', rowAddBtn);

		const newID = 'new' + this.nextNewGroupID(groupsPane);

		// Regex because otherwise only the first occurence is replaced...;
		const formDefJSON = JSON.stringify(ownData.newGroupFormBinding).replace(
			/\$groupID\$/g,
			newID
		);
		const newFormDef = JSON.parse(formDefJSON);

		let element = $.render(groupSettingsCardTemplate, {
			ID: newID,
			name: 'New group',
			form: newFormDef,
			expand: true
		});

		let formEl = element.findElement(
			`[data-form="customerchat-groups-${newID}"]`
		);
		formEl.form({
			formDefinition: newFormDef
		});

		element.insertBefore(rowAddBtn);
	}

	nextNewGroupID(target) {
		let maxID = 0;
		target
			.find('[data-form^="customerchat-groups-new"]')
			.each((i, form) => {
				let idstr = $(form)
					.attr('data-form')
					.match(/customerchat-groups-new(\d+)/)[1];
				if (!idstr) {
					return;
				}
				maxID = Math.max(parseInt(idstr), maxID);
			});

		return maxID + 1;
	}

	findTab(data) {
		return data.result.tabs.find(tab => tab.key === 'customerChat');
	}

	async openTicketPicker(chatID) {
		const picker = new TicketPicker({
			title: 'Pridruzi chat ticketu'
		});
		picker.on('submit', async data => {
			await Api.post('/api/ticketing/addChatSessionToTicket', {
				chatID,
				ticketID: data.ticketID
			});
			picker.close();
			const ticketRow = $.render(await getTemplate('chatTicketRow'), {
				ticketID: data.ticketID,
				ticketTitle: data.ticketTitle
			});
			const chatRow = $(`[data-datatable-row-id="${chatID}"]`);
			const ticketList = chatRow.find('.chat-tickets');
			ticketList.append(ticketRow);
			chatRow.find('.chat-tickets-popover-btn').prop('hidden', false);
		});
	}
}
