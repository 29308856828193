module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "	<a href=\"/phonebook/viewPerson?personID="
    + alias2(alias1((depth0 != null ? depth0.customerPersonID : depth0), depth0))
    + "\">\n		<span class=\"fa fa-building\"></span>\n		"
    + alias2(alias1((depth0 != null ? depth0.customerPersonName : depth0), depth0))
    + "\n	</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.customerCompanyID : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "	<a href=\"/phonebook/viewCompany?companyID="
    + alias2(alias1((depth0 != null ? depth0.customerCompanyID : depth0), depth0))
    + "\">\n		<span class=\"fa fa-id-card\"></span>\n		"
    + alias2(alias1((depth0 != null ? depth0.customerCompanyName : depth0), depth0))
    + "\n	</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " -\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.customerPersonID : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})