module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "-new-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.index : depth0), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "data-live-search=\"true\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " -->\n		<!-- "
    + ((stack1 = container.invokePartial(partials.option,depth0,{"name":"option","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " -->\n		<option value=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\"><span class=\""
    + alias2(alias1((depth0 != null ? depth0.filter_icon : depth0), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.text : depth0), depth0))
    + "</option>\n	<!-- ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing, alias3=container.escapeExpression;

  return "<select class=\"pickle "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.disabled : depth0), depth0),{"name":"disabled","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.disabled : depth0), depth0),{"name":"disabled","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " name=\""
    + alias3(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.index : depth0), depth0),{"name":"index","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.search : depth0), depth0),{"name":"search","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-width=\"100%\" data-ismandatory=\""
    + alias3(alias1((depth0 != null ? depth0.isMandatory : depth0), depth0))
    + "\">\n	<!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.options : depth0), depth0),{"name":"options","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " -->\n</select>";
},"usePartial":true,"useData":true})