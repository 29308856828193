import { render, staticRenderFns } from "./CallCenter.vue?vue&type=template&id=6503d58a&scoped=true&"
import script from "./CallCenter.vue?vue&type=script&lang=js&"
export * from "./CallCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6503d58a",
  null
  
)

component.options.__file = "CallCenter.vue"
export default component.exports