<template>
  <div>
    <div class="row">
        <StatisticsBox
        :title="tr('Callcenter:label.waiting_call_count')"
        :value="queueStatisticsSummary.waitingCallCount.toString()"
        :queues="formattedQueues"
        queueValueKey="WaitingCallCount"
        />

        <StatisticsBox
        :title="tr('Callcenter:label.total_calls')"
        :value="queueStatisticsSummary.totalCallCount.toString()"
        :queues="formattedQueues"
        queueValueKey="TotalCallCount"
        />

        <StatisticsBox
        :title="tr('Callcenter:label.missed_calls')"
        :value="queueStatisticsSummary.missedCallCount.toString()"
        :queues="formattedQueues"
        queueValueKey="MissedCallCount"
        />

        <StatisticsBox
        :title="tr('Callcenter:label.efficiency')"
        :value="queueStatisticsSummary.efficiency.toString()"
        :queues="formattedQueues"
        queueValueKey="EfficiencyFormatted"
        />
    </div>
    <div class="row">
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    {{ tr('Callcenter:label.average_waiting_time') }}
                </div>
                <div class="card-body">
                    <StatisticsBar
                    :value="averageWaitTimeSecs"
                    :max="maxAverageWaitTimeSecs"
                    :label="queueStatisticsSummary.avgWaitTime"
                    />
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">
                    {{ tr('Callcenter:label.longest_waiting_time') }}
                </div>
                <div class="card-body">
                    <StatisticsBar
                    :value="longestWaitTimeSecs"
                    :max="maxLongestWaitTimeSecs"
                    :label="queueStatisticsSummary.longestWaitTime"
                    />
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import StatisticsBox from './StatisticsBox.vue';
import StatisticsBar from './StatisticsBar.vue';

export default {
	components: {
		StatisticsBox,
		StatisticsBar
	},
	props: {
		queues: Array
	},
	computed: {
		queueStatisticsSummary() {
			let totalCallCount   = 0;
			let missedCallCount  = 0;
			let waitingCallCount = 0;
			let efficiency       = 0.0;
			let totalWaitTime    = 0;
			let longestWaitTime  = 0;
			let maxWaitTime      = 0;
			let avgWaitTime      = 0;

			this.queues.forEach(queue => {
				if(!queue.isObserved) {
					return;
				}

				totalCallCount   += queue.Summary.TotalCallCount;
				missedCallCount  += queue.Summary.MissedCallCount;
				waitingCallCount += queue.Summary.WaitingCallCount;

				totalWaitTime += moment.duration(queue.Summary.TotalWaitTime).asSeconds();

				if(moment.duration(queue.Summary.LongestWaitTime).asSeconds() > maxWaitTime) {
					maxWaitTime = moment.duration(queue.Summary.LongestWaitTime).asSeconds();
				}
			});

			longestWaitTime = moment.utc(maxWaitTime * 1000).format('HH:mm:ss');

			if (totalCallCount) {
				avgWaitTime = moment.utc((totalWaitTime / totalCallCount) * 1000).format('HH:mm:ss');
				efficiency = (totalCallCount - missedCallCount) / totalCallCount;
			} else {
				avgWaitTime = '00:00:00';
			}

			return {
				efficiency: (efficiency * 100).toFixed(2)+'%',
				totalCallCount,
				missedCallCount,
				waitingCallCount,
				totalWaitTime,
				maxWaitTime,
				longestWaitTime,
				avgWaitTime
			};
		},
		formattedQueues() {
			return this.queues
				.filter(queue => queue.isObserved)
				.map(queue => {
					queue.Summary.EfficiencyFormatted = (queue.Summary.Efficiency * 100).toFixed(2)+'%';
					return queue;
				});
		},
		maxAverageWaitTimeSecs() {
			return 60;
		},
		averageWaitTimeSecs() {
			console.log(moment.duration(this.queueStatisticsSummary.avgWaitTime).asSeconds());
			return moment.duration(this.queueStatisticsSummary.avgWaitTime).asSeconds();
		},
		maxLongestWaitTimeSecs() {
			return 15;
		},
		longestWaitTimeSecs() {
			return this.queueStatisticsSummary.maxWaitTime;
		}
	}
};
</script>
