import { render, staticRenderFns } from "./Extension.vue?vue&type=template&id=299f2561&scoped=true&"
import script from "./Extension.vue?vue&type=script&lang=js&"
export * from "./Extension.vue?vue&type=script&lang=js&"
import style0 from "./Extension.vue?vue&type=style&index=0&id=299f2561&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "299f2561",
  null
  
)

component.options.__file = "Extension.vue"
export default component.exports