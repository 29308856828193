<template>
    <div class="row">
        <div :class="this.vertical ? tabSizeClass : 'col-12'">
            <ul :class="navClass">
                <li v-for="tab in tabs"
                    :key="tab.id"
                    class="nav-item"
                    :href="tab.href"
                    @click.prevent="activeTabValue = tab.id"
                >
                    <a :href="tab.href" class="nav-link" :class="tab.id === activeTabDefaulted ? 'active' : ''">
                        <slot name="tab" v-bind:tab="tab">
                            <span v-if="tab.icon" :class="tab.icon"></span>
                            {{tab.title}}
                            <span v-if="tab.badgeContent" :class="`badge badge-${tab.badgeVariation}`">
                                {{tab.badgeContent}}
                            </span>
                        </slot>
                    </a>
                </li>
            </ul>
        </div>
        <div :class="this.vertical ? contentSizeClass : 'col-12'">
            <div class="tab-content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		activeTab: String,
		pills: {
			default: false,
			type: Boolean
		},
		fill: {
			default: false,
			type: Boolean
		},
		justify: {
			default: false,
			type: Boolean
		},
		vertical: {
			default: false,
			type: Boolean
		},
		tabSize: {
			default: 3,
			type: [Number, Object]
		},
		contentSize: {
			default: 9,
			type: [Number, Object]
		}
	},
	data() {
		return {
			activeTabValue: null,
			tabs: []
		};
	},
	created() {
		this.tabs = this.$children;
	},
	computed: {
		activeTabDefaulted() {
			if (this.activeTabValue) {
				return this.activeTabValue;
			} else if (this.tabs.length) {
				return this.tabs[0].id;
			} else {
				return null;
			}
		},
		navClass() {
			let navClass = `nav nav-${this.pills ? 'pills' : 'tabs'}`;
			if (this.vertical) {
				navClass += ' flex-column';
			}
			if (this.justify) {
				navClass += ' nav-justified';
			}
			if (this.fill) {
				navClass += ' nav-fill';
			}
			return navClass;
		},
		tabSizeClass() {
			return `col-${this.tabSize}`;
		},
		contentSizeClass() {
			return `col-${this.contentSize}`;
		}
	},
	watch: {
		activeTabDefaulted(activeTabId) {
			this.tabs.forEach(tab => {
				tab.active = tab.id === activeTabId;
			});
			this.$emit('update:activeTab', activeTabId);
		},
		activeTab: {
			immediate: true,
			handler(newActiveTab) {
				this.activeTabValue = newActiveTab;
			}
		}
	}
};
</script>

<style>

</style>
