module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<li class=\"nav-item\">\n    <a class=\"nav-link"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" href=\"/"
    + alias3(alias2((depth0 != null ? depth0.href : depth0), depth0))
    + "/?"
    + alias3(alias2((depths[1] != null ? depths[1].params : depths[1]), depth0))
    + "\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.icon : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n        <span class=\"tab-text\">"
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "</span>\n    </a>\n</li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"4":function(container,depth0,helpers,partials,data) {
    return "<span class=\"tab-icon\"><span class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + "\"></span>&nbsp;";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true})