module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "		<option value=\"10\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.limit10 : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias1,"label.10_results_per_page",{"name":"tr","hash":{},"data":data}))
    + "</option>\n		<option value=\"20\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.limit20 : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias1,"label.20_results_per_page",{"name":"tr","hash":{},"data":data}))
    + "</option>\n		<option value=\"30\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.limit30 : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(helpers.tr.call(alias1,"label.30_results_per_page",{"name":"tr","hash":{},"data":data}))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div>\n	<select id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-limit-selector\" class=\"pickle limit-selector\" data-searchable=\"false\" name=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "Limit\">\n"
    + ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.resultdata : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</select>\n</div>\n";
},"useData":true})