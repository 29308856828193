import { tr } from 'BootQuery/Assets/js/BootQuery';

export default {
	install: function (Vue, _options) {
		Vue.mixin({
			methods: {
				tr(trString) {
					return tr(trString);
				}
			}
		});
	}
};