import {mapValues, sortBy, pickBy, forEach, clone, merge, isArray} from 'lodash';
import Sortable from 'sortablejs';
import * as Fields from './fields';

export default class FormEditor {
	constructor(options) {
		this.options = options || {};
		if (isArray(this.options)) {
			this.options = {};
		}

		if (!this.options.fields) {
			this.options.fields = {};
		}
		if (isArray(this.options.fields)) {
			this.options.fields = {};
		}

		this.options.fields = mapValues(this.options.fields, (field, id) => {
			field.id = id;
			return this.constructField(field);
		});
	}

	async render(target) {
		let rendered = handlebarsRender(await getTemplate('formEditor'), {});
		this.el = $(rendered);
		this.availableFields = this.el.find('.formbuilder-available-fields');
		this.form = this.el.find('.formbuilder-form');

		let order = this.options.order || [];
		let ordered = Object.entries(this.options.fields).map(pair => merge(pair[1], {key: pair[0]}));
		if (order.length) {
			ordered = sortBy(ordered, field => order.indexOf(field.key));
		}
		forEach(ordered, (field) => {
			field.render()
				.then(rendered => this.form.append(rendered));
		});

		$(target).html(this.el);

		this.availableFields.find('a')
			.off('click.formEditor')
			.on('click.formEditor', e => e.preventDefault());
		this.fieldsSortable = Sortable.create(this.availableFields.get(0), {
			group: {
				name: 'formbuilder',
				pull: 'clone',
				put: false,
				revertClone: false
			},
			sort: false,
			dataIdAttr: 'data-form-type',
			animation: 150
		});
		this.formSortable = Sortable.create(this.form.get(0), {
			group: {
				name: 'formbuilder',
				put: true,
				pull: false
			},
			handle: '.editable-field-drag-handle',
			dataIdAttr: 'data-field-id',
			onAdd: async evt => {
				const type = evt.item.dataset.formType;
				let field = this.constructField(type);
				this.options.fields[field.id] = field;
				$(evt.item).replaceWith(await field.render());
			},
			onEnd: (evt, _originalEvt) => {
				setTimeout(() => { // Because jQuery is a fuckhead
					let elem = $(evt.item);
					let id = elem.data('fieldId');
					let options = this.options.fields[id].options;

					forEach(options, (val, key) => {
						if(elem.findElement(`input[type="checkbox"][name="${key}"]`).length) {
							elem.findElement(`input[type="checkbox"][name="${key}"]`).prop('checked', val);
							return;
						}

						if(elem.findElement(`input[type="radio"][name="${key}"][value="${val}"]`).length) {
							elem.findElement(`input[type="radio"][name="${key}"][value="${val}"]`).prop('checked', true);
							return;
						}

						if(elem.findElement(`input[type="text"][name="${key}"]`).length) {
							elem.findElement(`input[type="text"][name="${key}"]`).val(val);
							return;
						}
					});
				}, 0);
			},
			animation: 150
		});
	}

	constructField(field) {
		return Fields.constructField(field);
	}

	getDefinition() {
		let newOpts = clone(this.options);
		let fields = mapValues(newOpts.fields, field => field.getDefinition());
		fields = pickBy(fields, (item) => {
			if (item.deleted && item.new) {
				return false;
			}
			return true;
		});
		newOpts.fields = fields;
		newOpts.order = this.formSortable.toArray();
		return {definition: newOpts};
	}
}