<template>
	<div class="attachment bg-white p-1 px-2 rounded border">
		<a :href="`/api/mails/messages/${messageID}/attachments/${ID}`" target="_blank" class="open-link">
			<div class="attachment-icon">
				<span class="fas fa-file"></span>
			</div>
			<div class="attachment-info px-1">
				<div class="info-filename" :title="filename">
					<strong>{{filename}}</strong>
				</div>
				<div class="info-size text-muted">
					<small>{{size | formatFileSize}}</small>
				</div>
			</div>
		</a>
		<div class="attachment-actions">
			<a :href="`/api/mails/messages/${messageID}/attachments/${ID}?download=true`" download>
				<span class="fas fa-download"></span>
			</a>
		</div>
	</div>
</template>

<script>
import { formatFileSize } from 'app/assets/js/util.js';

export default {
	props: {
		messageID: Number,
		ID: Number,
		filename: String,
		size: Number,
		type: String
	},
	filters: {
		formatFileSize
	}
};
</script>

<style lang="scss" scoped>
.attachment {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	position: relative;
    align-items: center;
}

.open-link {
    display: flex;
    overflow: hidden;
}

.attachment-actions {
    padding-left: 0.5rem;
}

.attachment-icon {
	flex: 0 1 auto;
	display: inline-flex;
	align-items: center;

	> span {
		font-size: 1.6em;
	}
}

.attachment-info {
	flex: 1 1 auto;
	line-height: 1.1;
	overflow: hidden;

	> .info-filename {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.attachment-actions {
	flex: 0 1 auto;

    > * {
        vertical-align: middle;
    }
}
</style>
