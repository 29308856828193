module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"fa fa-save text-green\" style=\"padding-left: 5px;\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"fa fa-exclamation-triangle text-red\" style=\"padding-left: 5px;\"></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"fa fa-check text-green\" style=\"min-width: 16px;\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"fa fa-times text-red\" style=\"min-width: 16px;\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"fa fa-arrow-right text-blue\" style=\"padding-left: 5px;\"></span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"fa fa-arrow-right text-orange\" style=\"padding-left: 5px;\"></span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataVerified : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "&nbsp;\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.originateAttemptResult : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})