<template>
	<span>
		<button :class="buttonClass" title="Postavke" @click="openModal">
			<span class="fas fa-cog"></span>
		</button>
		<Modal v-if="modalVisible" @close="closeModal">
			<template slot="title">
				<span class="fas fa-cog"></span>
				Postavke
			</template>

			<template>
				<div class="text-center" v-if="loading">
					<div class="spinner-grow"></div>
				</div>
				<template v-else>
					<TrackerSettingsProgress
						v-if="savingSettings"
						:trackerID="trackerID"
						:settingsState="tracker.settings"
					/>
					<TrackerSettings
						v-else
						ref="trackerSettings"
						:trackerID="trackerID"
						:tracker="tracker"
						:disabled="submittingSettings"
						@update:canSubmit="$ev => canSubmit = $ev"
					/>
				</template>
			</template>

			<template slot="footer">
				<button
					type="button"
					class="btn btn-link text-danger"
					@click="closeModal"
				>Odustani</button>
				<button
					v-if="!savingSettings"
					type="button"
					class="btn btn-success"
					@click="saveSettings"
					:disabled="!canSubmit || submittingSettings"
				>
					<span v-if="submittingSettings" class="fas fa-spin fa-spinner"></span>
					<span v-else class="fa fa-check"></span>
					Spremi
				</button>
			</template>
		</Modal>
	</span>
</template>

<script>
import Modal from 'BootQuery/Assets/js/bootstrap-components/Modal.vue';
import TrackerSettings from '../TrackerSettings.vue';
import TrackerSettingsProgress from '../TrackerSettingsProgress.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		Modal,
		TrackerSettings,
		TrackerSettingsProgress
	},
	props: {
		trackerID: {
			type: Number,
			required: true
		},
		buttonClass: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			modalVisible: false,
			canSubmit: false,
			submittingSettings: false,
			savingSettings: false,
			loading: true,
			tracker: null
		};
	},
	methods: {
		openModal() {
			this.init();
			this.modalVisible = true;
		},
		closeModal() {
			this.modalVisible = false;
		},
		async saveSettings() {
			this.submittingSettings = true;
			const changes = this.$refs.trackerSettings.getChanges();
			if (Object.keys(changes).length) {
				const status = await Api.patch(
					`/api/gsmTrackers/trackers/${this.trackerID}`,
					changes
				);
				this.submittingSettings = false;

				if (status.changingSettings) {
					this.init();
				} else {
					this.closeModal();
				}
			} else {
				this.submittingSettings = false;
				this.closeModal();
			}

		},
		async init() {
			this.loading = true;
			this.tracker = await Api.get(
				`/api/gsmTrackers/trackers/${this.trackerID}`
			);

			this.savingSettings = false;
			const settings = this.tracker.settings;
			for (let key in settings) {
				const val = settings[key];
				if (key.startsWith('hasSet') && val === false) {
					this.savingSettings = true;
					break;
				}
			}
			this.loading = false;
		}
	}
};
</script>

<style>
</style>
