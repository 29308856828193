import { EventEmitter } from 'events';

export default class FileUpload extends EventEmitter {
	constructor(file, url = '/api/upload') {
		super();
		this.file = file;
		this.url = url;
	}

	start() {
		this.xhr = new XMLHttpRequest();
		const fd = new FormData();

		this.xhr.open('POST', this.url, true);
		this.xhr.onreadystatechange = () => {
			if (this.xhr.readyState == 4) {
				const statusCode = this.xhr.status;
				// Success?
				if (statusCode >= 200 && statusCode < 300) {
					const uploadInfo = JSON.parse(this.xhr.response)[0];
					this.done = true;
					if (this.progress < 100) {
						this.progress = 100;
						this.emit('progress', 100);
					}
					this.emit('done', uploadInfo);
					this.xhr = null;
				} else {
					this.emit('error', this.xhr.response);
				}
			}
		};
		this.xhr.upload.addEventListener('progress', ev => {
			if (ev.lengthComputable) {
				const percentage = Math.round((ev.loaded * 100) / ev.total);
				this.progress = percentage;
				this.emit('progress', percentage);
			}
		});
		fd.append(this.file.name, this.file);
		this.xhr.send(fd);
	}

	cancel() {
		if (this.xhr) {
			this.xhr.abort();
		}
		this.emit('cancel');
	}
}