<template>
    <div
        class="editor-attachments"
        @dragover="onDragStart"
        @dragenter="onDragStart"
        @dragleave="onDragEnd"
        @dragend="onDragEnd"
        @drop="onDrop"
    >
        <div class="card" :class="dropAreaClass">
            <div class="py-1 px-2">
                <small class="text-muted">
                    {{ tr("Mails:label.attachments") }}
                    <template v-if="attachments.length">({{totalSize | formatFileSize}})</template>
                </small>
            </div>
            <div class="card-body">
                <div class="row">
                    <div
                        class="col-auto attachment-wrapper"
                        v-for="(attachment, index) in attachments"
                        :key="index"
                    >
                        <Attachment
                            :filename="attachment.filename"
                            :type="attachment.type"
                            :size="attachment.size"
                            :file="attachment.file"
                            @done="data => onAttachmentUploaded(attachment, data)"
                            @remove="removeAttachment(index)"
                        />
                    </div>
                    <div class="col-auto ml-auto">
                        <button
                            type="button"
                            class="btn btn-success attachment-add-btn"
                            @click.prevent="openPicker"
                        >
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Attachment from './EditorAttachment.vue';
import { formatFileSize } from 'app/assets/js/util.js';

export default {
	components: {
		Attachment
	},
	props: {
		attachments: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			fileHovering: false
		};
	},
	methods: {
		addFiles(fileList) {
			Array.from(fileList).forEach(file => {
				this.attachments.push({
					filename: file.name,
					type: file.type,
					size: file.size,
					uploadData: null,
					file
				});
			});
		},
		openPicker() {
			const inputEl = document.createElement('input');
			inputEl.setAttribute('type', 'file');
			inputEl.setAttribute('multiple', '');
			inputEl.setAttribute('style', 'visibility: hidden; width: 0; height: 0;');
			inputEl.addEventListener('change', ev => {
				this.addFiles(inputEl.files);
				inputEl.parentNode.removeChild(inputEl);
			});
			this.$el.appendChild(inputEl);
			inputEl.click();
		},
		onAttachmentUploaded(attachment, uploadData) {
			attachment.uploadData = uploadData;
			attachment.file = null;
		},
		removeAttachment(index) {
			this.attachments.splice(index, 1);
		},
		onDragStart(ev) {
			ev.preventDefault();
			this.fileHovering = true;
		},
		onDragEnd(ev) {
			ev.preventDefault();
			this.fileHovering = false;
		},
		onDrop(ev) {
			ev.preventDefault();
			if (ev.dataTransfer.files) {
				this.addFiles(ev.dataTransfer.files);
			}
			this.fileHovering = false;
		}
	},
	computed: {
		totalSize() {
			return this.attachments.reduce((totalSize, attachment) => {
				totalSize += attachment.size;
				return totalSize;
			}, 0);
		},
		dropAreaClass() {
			return this.fileHovering ? 'border-primary bg-white' : 'bg-light';
		}
	},
	filters: {
		formatFileSize
	}
};
</script>

<style lang="scss" scoped>
.attachment-wrapper {
    max-width: 300px;
}

.card {
    border-style: dashed;
    border-width: 2px;
}
</style>
