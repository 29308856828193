module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<form action=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/\" method=\"get\" class=\"form form-horizontal filter-form\" id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-filter-form\">\n\n		<div class=\"section-head\">\n\n			<div class=\"row section-title\">\n				<span class=\"glyphicon glyphicon-"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.icon), depth0))
    + "\"></span>&nbsp;&nbsp;"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.title), depth0))
    + "\n			</div>\n\n			<div class=\"row\">\n				<div class=\"col-md-12\">\n					<div class=\"col-md-12 col-lg-12\">\n						<ul class=\"nav nav-tabs\" id=\"myTab\" style=\"padding-right: 0;\">\n\n							"
    + ((stack1 = container.invokePartial(partials.tabs,(data && data.root),{"name":"tabs","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n							<li class=\"pull-right "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.parameters : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " \">\n								<a href=\"#\" role=\"tab\" id=\""
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-filter-toggle\" style=\"margin-right: 0;\" class=\"noparse filter-toggle\">\n									<span class=\"glyphicon glyphicon-filter\"></span> &nbsp;"
    + alias2(helpers.tr.call(alias3,"label.filters",{"name":"tr","hash":{},"data":data}))
    + "&nbsp;\n									<span class=\"glyphicon glyphicon-chevron-left pull-right collapse-indicator\"></span>\n								</a>\n							</li>\n\n							<li class=\"pull-right\">\n								"
    + ((stack1 = container.invokePartial(partials.limitSelector,depth0,{"name":"limitSelector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</li>\n						</ul>\n\n						<div class=\"tab-content\">\n							<div class=\"tab-pane active\">\n								"
    + ((stack1 = container.invokePartial(partials.filters,depth0,{"name":"filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n\n			<div class=\"row data-state\">\n				"
    + ((stack1 = container.invokePartial(partials.refreshTypeToggle,depth0,{"name":"refreshTypeToggle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n				<div class=\"col-xs-12 col-md-6 text-right\">\n					"
    + ((stack1 = container.invokePartial(partials.reportDropdown,depth0,{"name":"reportDropdown","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "				</div>\n			</div>\n\n		</div>\n\n	</form>\n\n	<table class=\"table table-hover table-sticky-header datatable\" id=\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.tableName : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<thead>\n			"
    + ((stack1 = container.invokePartial(partials.tableheader,depth0,{"name":"tableheader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</thead>\n\n		<tbody>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.result : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "		</tbody>\n\n		<tfoot>\n"
    + ((stack1 = helpers["with"].call(alias3,(depth0 != null ? depth0.resultdata : depth0),{"name":"with","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		</tfoot>\n	</table>\n\n	"
    + ((stack1 = container.invokePartial(partials.pagination,depth0,{"name":"pagination","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-table";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				"
    + ((stack1 = container.invokePartial(partials.callRow,depth0,{"name":"callRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "				<tr>\n					<td></td>\n					<td colspan=\"5\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data}))
    + "</td>\n				</tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "			<tr style=\"font-weight: bold;\">\n\n				<td></td>\n				<td colspan=\"3\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.count : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "				</td>\n\n				<td style=\"text-align: right;\">\n					"
    + alias2(helpers.tr.call(alias1,"label.total_calltime",{"name":"tr","hash":{},"data":data}))
    + ":\n				</td>\n				<td>\n					"
    + alias2(container.lambda((depth0 != null ? depth0.callTimeTotal : depth0), depth0))
    + "\n				</td>\n			</tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "						"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.calls_total",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "						"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_calls",{"name":"tr","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.tables : depth0)) != null ? stack1.calls : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})