module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.personID : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "		<a href=\"/phonebook/viewPerson/?personID="
    + alias2(alias1((depth0 != null ? depth0.personID : depth0), depth0))
    + "\">\n			<span class=\"fa fa-id-card\"></span> "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n		</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.companyID : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "		<a href=\"/phonebook/viewCompany/?companyID="
    + alias2(alias1((depth0 != null ? depth0.companyID : depth0), depth0))
    + "\">\n				<span class=\"fa fa-building\"></span> "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n		</a>\n	";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.queueID : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"fas fa-sitemap\"></span>\n		"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.queueName : depth0), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "		"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.formattedPhoneNumber : depth0), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.potentials : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true})