module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"callcenter-user-status-cell\" data-userid=\""
    + alias2(alias1((depth0 != null ? depth0.userID : depth0), depth0))
    + "\">\n    <span class=\"text-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.color : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.settings : depth0)) != null ? stack1.icon : stack1), depth0))
    + "\"></span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\n    <small><span class=\"text-muted\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.message : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</span></small>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.message : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.context : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})