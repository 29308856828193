export function set(key, value) {
	window.localStorage.setItem(key, JSON.stringify(value));
}

export function get(key, defaultValue = null) {
	const value = window.localStorage.getItem(key);
	if (value === null || value === undefined) {
		return defaultValue;
	} else {
		return JSON.parse(value);
	}
}

export function keys() {
	return Object.keys(window.localStorage);
}