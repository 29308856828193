module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"alert alert-danger\" role=\"alert\">\n                            Pogrešno korisničko ime i/ili lozinka\n                        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div id=\"content-wrapper\">\n    <div class=\"container nomenu mr-auto ml-auto\" id=\"main-container\">\n        <div class=\"col-12 col-md-8 col-lg-6 pt-3 mr-auto ml-auto bq-content bq-default\">\n            <div class=\"card\">\n                <div class=\"card-header\">\n                    <span class=\"fa fa-sign-in-alt\"></span>&nbsp;Prijava\n                </div>\n\n                <div class=\"card-body\">\n                    <form id=\"login-form\" class=\"form-horizontal\" action=\"/user/login\" method=\"post\" role=\"form\">\n                        <div class=\"form-group row\">\n                            <label for=\"username\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">"
    + alias2(helpers.tr.call(alias1,"User:form.username",{"name":"tr","hash":{},"data":data}))
    + ":</label>\n                            <div class=\"col-12 col-md-8\">\n                                <input class=\"form-control\" type=\"text\" name=\"username\" placeholder=\"Korisničko ime\" autofocus />\n                            </div>\n                        </div>\n                        <div class=\"form-group row\">\n                            <label for=\"password\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">"
    + alias2(helpers.tr.call(alias1,"User:form.password",{"name":"tr","hash":{},"data":data}))
    + ":</label>\n                            <div class=\"col-12 col-md-8\">\n                                <input class=\"form-control\" type=\"password\" name=\"password\" maxlength=\"255\" placeholder=\"Lozinka\" />\n                            </div>\n                        </div>\n                        <div class=\"form-group row\" id=\"login-extension-placeholder\"></div>\n                        <div class=\"form-group row\">\n                            <label for=\"stayLoggedIn\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">"
    + alias2(helpers.tr.call(alias1,"User:form.stayLoggedIn",{"name":"tr","hash":{},"data":data}))
    + ":</label>\n                            <div class=\"col-12 col-md-8\">\n                                <div class=\"custom-control custom-checkbox\">\n                                    <input type=\"checkbox\" class=\"custom-control-input\" name=\"stayLoggedIn\" id=\"stayLoggedIn\">\n                                    <label class=\"custom-control-label\" for=\"stayLoggedIn\">&nbsp;</label>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"form-group row\">\n                            <div class=\"col-12 col-md-8 ml-auto\">\n                                <button type=\"submit\" class=\"form-control btn btn-primary\">Prijava</button>\n                            </div>\n                        </div>\n                        <div class=\"form-group row\">\n                            <div class=\"col-12 col-md-8 ml-auto text-right\">\n                                <a href=\"/user/requestRecovery\">"
    + alias2(helpers.tr.call(alias1,"User:form.password_recovery",{"name":"tr","hash":{},"data":data}))
    + "</a>\n                            </div>\n                        </div>\n                        <input type=\"hidden\" name=\"r\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.r : stack1), depth0))
    + "\">\n                    </form>\n\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias3(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.failed : stack1), depth0),{"name":"bootquery.failed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})