<template>
    <div class="form-group">
        <h4>Grupe:</h4>
        <div class="custom-control custom-checkbox" v-for="group in groups" :key="group.ID">
            <input
                type="checkbox"
                class="custom-control-input"
                :id="`customerChatGroupEnable${group.ID}`"
                :disabled="disabled"
                v-model="groupEnablement[group.ID]"
            >
            <label class="custom-control-label" :for="`customerChatGroupEnable${group.ID}`">{{group.name}}</label>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		groups: Array,
		disabled: Boolean
	},
	created() {
		this.groupEnablement = this.groups.reduce((groups, group) => {
			groups[group.ID] = group.loggedIn;
			return groups;
		}, {});
	},
	data() {
		return {
			groupEnablement: []
		};
	},
	methods: {
		getData() {
			const loggedInGroups = Object.entries(this.groupEnablement)
				.reduce( (enabledGroups, [groupID, isEnabled]) => {
					if (isEnabled) {
						enabledGroups.push(parseInt(groupID));
					}
					return enabledGroups;
				}, []);
			return {
				loggedInGroups
			};
		}
	}
};
</script>

<style>
</style>
