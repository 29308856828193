module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "fa-microphone";
},"5":function(container,depth0,helpers,partials,data) {
    return "fa-microphone-slash";
},"7":function(container,depth0,helpers,partials,data) {
    return "opacity: 0.5";
},"9":function(container,depth0,helpers,partials,data) {
    return "fa-video";
},"11":function(container,depth0,helpers,partials,data) {
    return "fa-video-slash";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "	<div class=\"ml-auto\">\n		<div class=\"popover-content-element\" hidden>\n			<span class=\"call-tickets\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tickets : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</span>\n		</div>\n		<button type=\"button\" data-toggle=\"popover\" class=\"btn btn-link btn-sm py-0 call-tickets-popover-btn\"\n		 data-popover-content-element=\"true\" data-html=\"true\" "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.tickets : depth0)) != null ? stack1.length : stack1),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> <span class=\"fa fa-list-alt\"></span>\n		</button>\n	</div>\n\n	<div class=\"dropdown\">\n		<a href=\"#\" data-toggle=\"dropdown\" class=\"ml-1 px-2 text-dark float-right\">\n			<span class=\"fa fa-ellipsis-v\"></span>\n		</a>\n		<div class=\"dropdown-menu dropdown-menu-right\">\n			<h6 class=\"dropdown-header font-weight-bold\">\n				<span class=\"fas fa-list-alt\"></span> Ticket\n			</h6>\n			<a class=\"dropdown-item\" href=\"/ticketing/create/?fromCallID="
    + alias3(alias2((depth0 != null ? depth0.callID : depth0), depth0))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.direction : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n				<span class=\"fa fa-plus\"></span> Kreiraj novi\n			</a>\n			<a class=\"dropdown-item noparse assign-call-to-ticket-btn\" href=\"#\" data-call-id=\""
    + alias3(alias2((depth0 != null ? depth0.callID : depth0), depth0))
    + "\">\n				<span class=\"fa fa-link\"></span> Pridruži postojećem\n			</a>\n		</div>\n	</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "					"
    + ((stack1 = container.invokePartial(partials["Calls.callTicketRow"],depth0,{"name":"Calls.callTicketRow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.calleeEntity : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "&defaultClientID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.calleeEntity : depth0)) != null ? stack1.type : stack1), depth0))
    + "_"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.calleeEntity : depth0)) != null ? stack1.ID : stack1), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.callerEntity : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "&defaultClientID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.callerEntity : depth0)) != null ? stack1.type : stack1), depth0))
    + "_"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.callerEntity : depth0)) != null ? stack1.ID : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"d-flex ml-auto\">\n	<a href=\"#\" class=\"calls-recording-audio\" data-recording=\"audio\" data-recording-id=\""
    + alias2(alias1((depth0 != null ? depth0.audioRecordingID : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.audioRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n		<span class=\"fas "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.audioRecordingID : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + " text-secondary\" style=\"padding-left: 5px; min-width: 25px; display: inline-block; text-align: center; "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.audioRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n	</a>\n\n	<a href=\"#\" class=\"calls-recording-video\" data-recording=\"video\" data-recording-id=\""
    + alias2(alias1((depth0 != null ? depth0.videoRecordingID : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.videoRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n		<span class=\"fas "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.videoRecordingID : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + " text-secondary\" style=\"padding-left: 5px; "
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.videoRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n	</a>\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.ticketingEnabled : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true})