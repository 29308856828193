module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">"
    + alias2(helpers.tr.call(alias1,"label.edit",{"name":"tr","hash":{},"data":data}))
    + "</h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <div class=\"modal-body\">\n				Put shit here\n            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias2(helpers.tr.call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-success bulk-ticketing-change-modal-save\">\n					<span class=\"fa fa-check\"></span> "
    + alias2(helpers.tr.call(alias1,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n				</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true})