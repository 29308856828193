module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    return "show";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"card\">\n	<div class=\"card-header p-0\">\n		<button class=\"btn w-100 m-0 text-left "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.expand : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-toggle=\"collapse\" data-target=\"#groups-collapse-"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" type=\"button\">\n			"
    + alias3(alias2((depth0 != null ? depth0.name : depth0), depth0))
    + "\n		</button>\n	</div>\n\n	<div id=\"groups-collapse-"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" class=\"collapse "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.expand : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-settings-form=\"groups-"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" data-form=\"groups-"
    + alias3(alias2((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n		<div class=\"card-body\">\n			"
    + ((stack1 = container.invokePartial(partials.form,(depth0 != null ? depth0.form : depth0),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>";
},"usePartial":true,"useData":true})