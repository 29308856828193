module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"text-left\">\n        <span class=\"p-2\">\n            <span class=\"font-weight-bold\">"
    + alias2(helpers.tr.call(alias1,"label.calls_total",{"name":"tr","hash":{},"data":data}))
    + ":</span> "
    + alias2(container.lambda((depth0 != null ? depth0.count : depth0), depth0))
    + "\n        </span>\n        \n        <span class=\"p-2 ml-3\">\n            <span class=\"font-weight-bold\">"
    + alias2(helpers.tr.call(alias1,"label.total_calltime",{"name":"tr","hash":{},"data":data}))
    + ":</span> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.callTimeTotal : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </span>\n\n        <span class=\"p-2 ml-3\">\n            <span class=\"font-weight-bold\">"
    + alias2(helpers.tr.call(alias1,"label.total_queuetime",{"name":"tr","hash":{},"data":data}))
    + ":</span> "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.queueTimeTotal : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.callTimeTotal : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    return "00:00:00";
},"6":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.queueTimeTotal : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.resultdata : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true})