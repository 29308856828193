module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.bootquery : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<form id=\""
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-modify-form\" class=\"form-horizontal\" action=\""
    + alias2(alias1((depth0 != null ? depth0.form_url : depth0), depth0))
    + "\" method=\"post\" role=\"form\" data-form=\""
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "\">\n			<div class=\"card embedable\">\n\n				<div class=\"card-header stick-top\" id=\"userform-header\">\n					<span class=\"fa fa-pencil-alt\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"label.edit_entry",{"name":"tr","hash":{},"data":data}))
    + "\n				</div>\n\n				<div class=\"card-body\">\n					<div id=\""
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-modify-container\">\n						<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1((depth0 != null ? depth0.form_action : depth0), depth0))
    + "\" />\n						<div class=\"col-12 mx-auto\" style=\"max-width: 1000px;\">\n							<div class=\"row\">\n								"
    + ((stack1 = container.invokePartial(partials.form,helpers.lookup.call(alias3,((stack1 = (data && data.root)) && stack1.forms),(depth0 != null ? depth0.edit_what : depth0),{"name":"lookup","hash":{},"data":data}),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = helpers.unless.call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					<div class=\"save-button\">\n						<span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\""
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-modify-form\">\n						</span>\n						<button type=\"submit\" class=\"btn btn-success\" form=\""
    + alias2(alias1((depth0 != null ? depth0.edit_what : depth0), depth0))
    + "-modify-form\">\n							<span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n						</button>\n					</div>\n				</div>\n			</div>\n		</form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "					<a href=\""
    + alias1(container.lambda((depth0 != null ? depth0.list_url : depth0), depth0))
    + "\" class=\"btn btn-link form-cancel mr-auto\">\n						<span class=\"fa fa-arrow-left\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.back",{"name":"tr","hash":{},"data":data}))
    + "\n					</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "					<a href=\""
    + alias1(container.lambda((depth0 != null ? depth0.list_url : depth0), depth0))
    + "\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n						<span class=\"fa fa-times\"></span>&nbsp;"
    + alias1(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n					</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true})