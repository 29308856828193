import Inputmask from 'inputmask';

export default {
	install: function(Vue, options) {
		Vue.directive('mask', {
			bind: function(el, binding) {
				Inputmask(binding.value).mask(el);
			}
		});
	}
};