module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                <option "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " value=\""
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.extension : depth0), depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"form-group row\">\n    <label for=\"extension\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">"
    + container.escapeExpression(helpers.tr.call(alias1,"form.extension",{"name":"tr","hash":{},"data":data}))
    + ":</label>\n    <div class=\"col-12 col-md-8\">\n        <select name=\"extension\" id=\"extension\" class=\"form-control pickle\">\n            <option value=\"0\"></option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.extensions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n    </div>\n</div>";
},"useData":true})