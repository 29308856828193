module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = container.invokePartial(partials.head,depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<body>\n    <div id=\"content-wrapper\">\n        <div class=\"container nomenu mr-auto ml-auto\" id=\"main-container\">\n            <div id=\"php-debugs\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0._php_debug : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n            <div class=\"col-12 col-md-10 col-lg-8 pt-3 mr-auto ml-auto bq-content bq-default\">\n                <div class=\"card\">\n                    <div class=\"card-header\">\n                        <span class=\"fa fa-key\"></span>&nbsp;"
    + container.escapeExpression(helpers.tr.call(alias1,"form.password_recovery",{"name":"tr","hash":{},"data":data}))
    + "\n                    </div>\n\n                    <div class=\"card-body\">\n                        Link za oporavak lozinke poslan na odabranu e-mail adresu.\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</body>\n"
    + ((stack1 = container.invokePartial(partials.end,depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})