import Parchment from 'parchment';

class SignatureMarker extends Parchment.Embed {
	static create(value) {
		const node = super.create(value);
		node.dataset.markerType = value;
		return node;
	}

	static value(node) {
		return node.dataset.markerType;
	}

	length() {
		return 1;
	}
}

SignatureMarker.blotName = 'signature-marker';
SignatureMarker.tagName = 'span';
SignatureMarker.className = 'signature-marker';

export default SignatureMarker;