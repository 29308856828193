module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a href='/phonebook/add/?insert="
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.CallerPhoneNumber : depth0), depth0))
    + "' class='btn btn-primary' style='width: 100%;'>\n        <span class='far fa-save'></span>&nbsp;Spremi u imenik\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <span class='glyphicon glyphicon-user'></span>&nbsp;<a href='/phonebook/view/personID_eq="
    + alias2(alias1((depth0 != null ? depth0.ContactID : depth0), depth0))
    + "' style='color: white;'>"
    + alias2(alias1((depth0 != null ? depth0.CallerIDName : depth0), depth0))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.ContactID : depth0), depth0),{"name":"ContactID","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.ContactID : depth0), depth0),{"name":"ContactID","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<br/>\n<br/>\n<a href='/dialer/dial/?number="
    + container.escapeExpression(alias1((depth0 != null ? depth0.CallerPhoneNumber : depth0), depth0))
    + "' class='btn btn-success dialer-dial' style='width: 100%;'>\n    <span class='glyphicon glyphicon-earphone'></span>&nbsp;Nazovi\n</a>\n";
},"useData":true})