<template>
    <div class="form-group row">
        <slot name="label">
            <label class="col-form-label" :class="labelSizeClasses" v-if="label && label.length" :for="fieldId">{{label}}:</label>
        </slot>
        <slot name="control">
            <div :class="inputSizeClasses">
                <input
                    ref="input"
                    type="email"
                    class="form-control"
                    :id="fieldId"
                    :name="fieldName"
                    :disabled="disabled"
                    :placeholder="placeholder"
                />
            </div>
        </slot>
    </div>
</template>

<script>
import fieldProps from 'BootQuery/Assets/js/forms/fields/mixins/fieldProps.js';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { isEqual } from 'lodash';

const REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';

export default {
	mixins: [fieldProps],
	props: {
		value: Array,
		placeholder: String
	},
	data() {
		return {
			lastSearch: '',
			isSearching: false,
			inputValue: this.value ? [...this.value] : []
		};
	},
	methods: {
		search(search, callback) {
			this.lastSearch = search;
			if (!search.length) {
				this.isSearching = false;
				return callback([]);
			}
			if (this.isSearching) { // Be patient if already waiting for search results. Server doesn't like us spamming.
				return;
			}
			this.isSearching = true;
			Api.get('/api/mails/autocompleteAddresses', { search })
				.then(results => {
					this.isSearching = false;
					// Search again if search string changed while searching
					if (this.lastSearch !== search) {
						this.search.bind(this)(this.lastSearch, callback);
					} else {
						callback(results);
					}
				});
		},
		renderItem(item, escape) {
			let icon = '';
			if (item.thingType === 'person') {
				icon = 'far fa-id-card';
			} else if (item.thingType === 'company') {
				icon = 'far fa-building';
			}

			const label = escape(item.name || item.email);
			const caption = item.name ? escape(`<${item.email}>`) : '';
			return `<div>
				<strong class="px-2">
					${icon ? `<span class="${icon}"></span>` : ''}
					${label}
				</strong>
				${caption ? `&nbsp;<span class="text-muted">${caption}</span>` : ''}
			</div>`;
		},
		setErrorStatus(hasError) {
			const selectize = this.$refs.input.selectize;
			selectize.$wrapper[0].classList.toggle('has-error', hasError);
		}
	},
	mounted() {
		this.$refs.input.value = this.inputValue.join(',');
		$(this.$refs.input).selectize({
			persist: false,
			maxItems: null,
			openOnFocus: false,
			loadThrottle: null,
			plugins: ['remove_button'],
			valueField: 'email',
			labelField: 'name',
			searchField: ['name', 'email'],
			render: {
				item: this.renderItem,
				option: this.renderItem
			},
			load: this.search.bind(this),
			create(input) {
				if ((new RegExp('^' + REGEX_EMAIL + '$', 'i')).test(input)) {
					return {email: input};
				}
				let match = input.match(new RegExp('^([^<]*)<' + REGEX_EMAIL + '>$', 'i'));
				if (match) {
					return {
						email: match[2],
						name: match[1].trim()
					};
				}
				return false;
			}
		}).on('change', () => {
			this.inputValue = this.$refs.input.value
				.split(',')
				.map(mail => mail.trim())
				.filter(mail => mail.length);
			this.$emit('input', this.inputValue);
		});

		this.setErrorStatus(this.hasError);
	},
	watch: {
		disabled(newDisabled) {
			if (newDisabled) {
				this.$refs.input.selectize.disable();
			} else {
				this.$refs.input.selectize.enable();
			}
		},
		value(newVal) {
			if (!isEqual(this.inputValue, newVal)) {
				const selectize = this.$refs.input.selectize;

				newVal.forEach(opt => selectize.createItem(opt, false));
				selectize.setValue(newVal);
			}
		},
		hasError(hasError) {
			this.setErrorStatus(hasError);
		}
	}
};
</script>
