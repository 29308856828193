export default {
	install: function (Vue, _options) {
		Vue.mixin({
			mounted() {
				const rootEl = this.$root.$el;
				if (rootEl && rootEl.setAttribute) {
					rootEl.setAttribute('data-vue-boundary', 'true');
				}
			},
		});
	}
};