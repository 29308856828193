import Module from 'BootQuery/Assets/js/module.js';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import {isArray, sum, get, set, find} from 'lodash';
import {renderController} from 'BootQuery/Assets/js/BootQuery';
import {getURLComponents} from 'app/assets/js/util';
import SoundNotification from 'app/assets/sounds/long.mp3';

export default class Notifications extends Module {
	init(data) {
		super.init(data);
		this.notificationSound = new Audio(SoundNotification);

		if (Notification.permission === 'default') {
			Notification.requestPermission();
		}

		const userID = get(data, 'bootquery.session.userID');
		if (!userID) {
			return;
		}
		this.menus = get(data, 'modules.Menu.menus.main.main.items');
		this.element = null;
		this.notifications = null;
		this.unseenPerModule = {};
		this.notificationsLoaded = false;
		this.getNotifications().then(notifications => {
			this.notifications = notifications.notifications;
			this.unseenPerModule = notifications.unseenCountPerModule;
			this.notificationsLoaded = true;
			this.renderNotifications();
			this.subscribeWebSocket(`notification/user/${userID}`, this.onNotification.bind(this));
		});
	}

	activateElements(target, data) {
		this.renderNotifications(target);
	}

	getNotifications() {
		return new Promise((resolve, reject) => {
			Api.get('/api/notifications/list').then(data => {
				resolve(data);
			}).catch(err => {
				console.error('Unable to get notifications. Error: ', err);
				reject(err);
			});
		});
	}

	async renderNotifications(target = 'body') {
		if (!this.notificationsLoaded) {
			return;
		}

		target = $(target);
		if (!target.find('.notifications-menu').length) {
			return;
		}
		this.element = target.find('.notifications-menu-item');

		if (this.notifications.length) {
			const notificationTemplate = await getTemplate('notificationItem');
			const toRender = this.notifications.filter(notification => !notification.toRender);
			const renderedNotifications = toRender.map(notification => {
				return $.render(notificationTemplate, notification);
			});
			this.element.find('.notification-list').prepend(renderedNotifications);
			this.element.find('.no-notifications-indicator').prop('hidden', true);
		}

		this.element.find('.spinner').prop('hidden', true);
		this.element.find('.notifications').prop('hidden', false);

		this.refreshUnseen();

		this.element.find('.notification-list > .list-group-item').ev('click.notifications', e => {
			const notificationID = parseInt($(e.currentTarget).data('notificationId'));
			this.markAsSeen(notificationID);
		});
		this.element.find('.mark-all-as-seen-button').ev('click.notifications', e => {
			e.preventDefault();
			e.stopPropagation();
			this.markAllAsSeen();
		});
	}

	async onNotification(notification) {
		console.log('New notification: ', notification);
		this.notifications.unshift(notification);

		const notificationTemplate = await getTemplate('notificationItem');
		const renderedNotification = $.render(notificationTemplate, notification);

		renderedNotification.ev('click.notifications', e => {
			const notificationID = parseInt($(e.currentTarget).data('notificationId'));
			this.markAsSeen(notificationID);
		});
		renderedNotification.prependTo(this.element.find('.notification-list'));
		renderedNotification.hide();
		renderedNotification.slideDown();

		this.element.find('.no-notifications-indicator').prop('hidden', true);

		const unseenCounterPath = [notification.module, notification.notificationType];
		if (get(this.unseenPerModule, unseenCounterPath) === undefined) {
			set(this.unseenPerModule, unseenCounterPath, 0);
		}

		this.unseenPerModule[notification.module][notification.notificationType]++;
		this.refreshUnseen();

		if (!document.hasFocus() && Notification.permission !== 'denied') {
			const title = renderedNotification.findElement('.notification-title').text().trim();
			const body = renderedNotification.findElement('.notification-body').text().trim();

			if (!title.length) {
				console.warn('No title for notification, not triggering Web Notification');
				return;
			}

			this.notificationSound.play();
			const webNotification = new Notification(title, {body});
			webNotification.onclick = () => {
				this.markAsSeen(notification.ID);
				if (notification.linkURL) {
					const routeParts = getURLComponents(notification.linkURL);
					const historyObject = {
						controller: routeParts.controller,
						method: routeParts.method,
						parameters: routeParts.parameters
					};
					window.history.pushState(historyObject, null, notification.linkURL);
					renderController('get', routeParts.controller, routeParts.method, routeParts.parameters, routeParts.moduleName);
				}
			};
		}
	}

	/**
	 * Total number of unseen notifications (sum of all modules)
	 * @returns {number}
	 */
	unseen() {
		const allEvents = Object.values(this.unseenPerModule)
			.map(events => Object.values(events));

		return Object.values(allEvents).reduce((totalUnseen, unseen) => {
			return totalUnseen + sum(unseen);
		}, 0);
	}

	/**
	 * Get unseen notifications for given module, grouped by type.
	 * @param {string} moduleName Module name, case-insensitive
	 * @returns {Object.<string, number>} Object in the form of {notificationType: unseenNotificationCount}
	 */
	unseenForModule(moduleName) {
		const unseenPair = Object.entries(this.unseenPerModule).find(([currentModule]) => {
			return currentModule.toLowerCase() === moduleName.toLowerCase();
		});
		if (unseenPair !== undefined) {
			return unseenPair[1];
		}
		return {};
	}

	/**
	 * Calculate total unseen events for given module.
	 * Can optionally filter only some events
	 * @param {string} moduleName Module name, case-insensitive.
	 * @param {string[]} [events] Events which are included in sum. If not provided, all events are used.
	 * @returns {number}
	 */
	totalUnseenForModule(moduleName, eventTypes) {
		const unseen = this.unseenForModule(moduleName);
		const shouldFilter = isArray(eventTypes);
		return Object.entries(unseen)
			.reduce((total, [type, count]) => {
				if (!shouldFilter || eventTypes.includes(type)) {
					total += count;
				}
				return total;
			}, 0);
	}

	refreshUnseen() {
		const unseen = this.unseen();
		// Total counter, on notifications dropdown button
		const counterEl = this.element.find('.unseen-notification-counter');
		counterEl.text(unseen);
		if (unseen === 0) {
			counterEl.prop('hidden', true);
		} else {
			counterEl.prop('hidden', false);
		}

		// Menu items, per module
		$('body').find('.menu-container .nav-item > .nav-link[data-controller]').each((index, itemEl) => {
			itemEl = $(itemEl);
			const moduleName = itemEl.data('controller');
			if (!moduleName) {
				return;
			}

			const itemOrder = parseInt(itemEl.data('order'));
			const wholeItem = find(this.menus, menuItem => menuItem.order === itemOrder);

			let eventTypes = null;
			if (wholeItem && isArray(get(wholeItem, 'entry.event_types'))) {
				eventTypes = wholeItem.entry.event_types;
			}

			const counterEl = itemEl.find('.menu-item-notification-counter');
			const currentUnseen = this.totalUnseenForModule(moduleName, eventTypes);

			counterEl.text(currentUnseen);
			counterEl.prop('hidden', currentUnseen === 0);
		});
		this.element.find('.mark-all-as-seen-button').prop('disabled', unseen === 0);
	}

	markAsSeen(notificationID) {
		const notification = find(this.notifications, {ID: notificationID});
		if (notification.seen) {
			return;
		}
		notification.seen = true;
		if (get(this.unseenPerModule, [notification.module, notification.notificationType])) {
			this.unseenPerModule[notification.module][notification.notificationType]--;
		}
		this.element.find(`.notification-list .list-group-item[data-notification-id=${notificationID}]`)
			.removeClass('list-group-item-secondary');
		this.refreshUnseen();
		return Api.post('/api/notifications/markAsSeen', {notification: notificationID});
	}

	markAllAsSeen() {
		this.unseenPerModule = {};
		this.element.find('.notification-list > .list-group-item')
			.removeClass('list-group-item-secondary');
		this.refreshUnseen();
		return Api.post('/api/notifications/markAllAsSeen', {});
	}
}