module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"card "
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"card-body\">\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.data : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "mb-3";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "				<div class=\"card-title\">\n					<strong>\n						<span class=\"fa fa-phone\"></span>\n						"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.direction : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.answered : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "					</strong>\n					<span class=\"text-muted float-right d-flex\">\n						<span class=\"text-muted\">"
    + alias2(helpers.format_date.call(alias1,(depths[1] != null ? depths[1].timestamp : depths[1]),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "</span>\n						<div class=\"dropdown\">\n							<a href=\"#\" class=\"px-2 ml-3 text-dark float-right\" data-toggle=\"dropdown\">\n								<span class=\"fa fa-ellipsis-v\"></span>\n							</a>\n							<div class=\"dropdown-menu dropdown-menu-right\">\n								<a href=\"#\" class=\"dropdown-item\" data-edit-custom-event-id=\""
    + alias2(alias3((depths[1] != null ? depths[1].eventID : depths[1]), depth0))
    + "\" data-edit-custom-event-type=\""
    + alias2(alias3((depths[1] != null ? depths[1].type : depths[1]), depth0))
    + "\">\n									<span class=\"fa fa-pencil-alt\"></span> "
    + alias2(helpers.tr.call(alias1,"button.edit",{"name":"tr","hash":{},"data":data}))
    + "\n								</a>\n								<a href=\"#\" class=\"dropdown-item text-danger\" data-delete-custom-event-id=\""
    + alias2(alias3((depths[1] != null ? depths[1].eventID : depths[1]), depth0))
    + "\"\n								 data-delete-custom-event-type=\""
    + alias2(alias3((depths[1] != null ? depths[1].type : depths[1]), depth0))
    + "\">\n									<span class=\"far fa-trash-alt\"></span> "
    + alias2(helpers.tr.call(alias1,"button.delete",{"name":"tr","hash":{},"data":data}))
    + "\n								</a>\n							</div>\n						</div>\n					</span>\n				</div>\n				<div class=\"card-subtitle mb-2 d-flex\">\n					<div class=\"col\">\n						"
    + ((stack1 = container.invokePartial(partials.ticketingNumberDisplay,depth0,{"name":"ticketingNumberDisplay","hash":{"queueID":null,"formattedPhoneNumber":(depth0 != null ? depth0.callerPhoneNumber : depth0),"potentials":(depth0 != null ? depth0.callerContacts : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "						&nbsp;&nbsp;<span class=\"fa fa-long-arrow-alt-right\"></span>&nbsp;&nbsp;\n						"
    + ((stack1 = container.invokePartial(partials.ticketingNumberDisplay,depth0,{"name":"ticketingNumberDisplay","hash":{"queueName":(depth0 != null ? depth0.queueName : depth0),"queueID":(depth0 != null ? depth0.queueID : depth0),"formattedPhoneNumber":(depth0 != null ? depth0.calleePhoneNumber : depth0),"potentials":(depth0 != null ? depth0.calleeContacts : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n					<div class=\"col-auto\">\n						<a href=\"#\" class=\"calls-recording-audio\" data-recording=\"audio\" data-recording-id=\""
    + alias2(alias3((depth0 != null ? depth0.audioRecordingID : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.audioRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n							<span class=\"fas "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.audioRecordingID : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " text-secondary\" style=\"padding-left: 5px; min-width: 25px; display: inline-block; text-align: center; "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.audioRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n						</a>\n\n						<a href=\"#\" class=\"calls-recording-video\" data-recording=\"video\" data-recording-id=\""
    + alias2(alias3((depth0 != null ? depth0.videoRecordingID : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.videoRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n							<span class=\"fas "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.videoRecordingID : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " text-secondary\" style=\"padding-left: 5px; "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.videoRecordingID : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n						</a>\n					</div>\n				</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].formBinding : depths[1]),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.outgoing_call",{"name":"tr","hash":{},"data":data}));
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.incoming_call",{"name":"tr","hash":{},"data":data}));
},"9":function(container,depth0,helpers,partials,data) {
    return "							<span class=\"badge badge-success\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.answered_call",{"name":"tr","hash":{},"data":data}))
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "							<span class=\"badge badge-danger\">"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"label.missed_call",{"name":"tr","hash":{},"data":data}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"15":function(container,depth0,helpers,partials,data) {
    return "fa-microphone";
},"17":function(container,depth0,helpers,partials,data) {
    return "fa-microphone-slash";
},"19":function(container,depth0,helpers,partials,data) {
    return "opacity: 0.5";
},"21":function(container,depth0,helpers,partials,data) {
    return "fa-video";
},"23":function(container,depth0,helpers,partials,data) {
    return "fa-video-slash";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "					<hr>\n					<div class=\"custom-event-plain-form\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.stillBinding : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.program(28, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "					</div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "							<div class=\"text-center w-100\">\n								<span class=\"fa fa-spinner fa-spin\"></span>\n							</div>\n";
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "							"
    + ((stack1 = container.invokePartial(partials.form,(depths[1] != null ? depths[1].formBinding : depths[1]),{"name":"form","hash":{"plain":true,"readonly":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_mail : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.program(33, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "	<div class=\"mail-event "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-mail-event-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.eventID : depth0), depth0))
    + "\"></div>\n";
},"33":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_chat : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.program(37, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"card "
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-customer-chat-event-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.eventID : depth0), depth0))
    + "\">\n		<div class=\"card-body\">\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.data : depth0),{"name":"with","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n";
},"35":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<div class=\"card-title\">\n					<strong>\n						<span class=\"fas fa-comments\"></span>\n						Chat s "
    + alias2(alias1((depth0 != null ? depth0.customerDisplayName : depth0), depth0))
    + "\n					</strong>\n					<span class=\"text-muted float-right d-flex\">\n						<span class=\"text-muted\">"
    + alias2(helpers.format_date.call(alias3,(depths[1] != null ? depths[1].timestamp : depths[1]),helpers.tr.call(alias3,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "</span>\n					</span>\n				</div>\n				<div class=\"card-subtitle mb-2\">\n					Grupa: <strong>"
    + alias2(alias1((depth0 != null ? depth0.groupName : depth0), depth0))
    + "</strong>,\n					broj poruka: <strong>"
    + alias2(alias1((depth0 != null ? depth0.messageCount : depth0), depth0))
    + "</strong>,\n					trajanje: <strong>"
    + alias2(alias1((depth0 != null ? depth0.chatDuration : depth0), depth0))
    + "</strong>\n				</div>\n				<hr>\n				<div class=\"text-center\">\n					<button type=\"button\" class=\"btn btn-primary customer-chat-event-view-btn\">\n						<span class=\"fas fa-eye\"></span> "
    + alias2(helpers.tr.call(alias3,"button.display",{"name":"tr","hash":{},"data":data}))
    + "\n					</button>\n				</div>\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_custom : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(44, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"card "
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"card-body\">\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.data : depth0),{"name":"with","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n";
},"39":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<div class=\"card-title\">\n					<strong>\n						"
    + alias2(alias1((depth0 != null ? depth0.eventTypeName : depth0), depth0))
    + "\n					</strong>\n					<div class=\"float-right d-flex\">\n						<span class=\"text-muted\">"
    + alias2(helpers.format_date.call(alias3,(depths[1] != null ? depths[1].timestamp : depths[1]),helpers.tr.call(alias3,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "</span>\n						<div class=\"dropdown\">\n							<a href=\"#\" class=\"px-2 ml-3 text-dark float-right\" data-toggle=\"dropdown\">\n								<span class=\"fa fa-ellipsis-v\"></span>\n							</a>\n							<div class=\"dropdown-menu dropdown-menu-right\">\n								<a href=\"#\" class=\"dropdown-item\" data-edit-custom-event-id=\""
    + alias2(alias1((depths[1] != null ? depths[1].eventID : depths[1]), depth0))
    + "\" data-edit-custom-event-type=\""
    + alias2(alias1((depths[1] != null ? depths[1].type : depths[1]), depth0))
    + "\">\n									<span class=\"fa fa-pencil-alt\"></span> "
    + alias2(helpers.tr.call(alias3,"button.edit",{"name":"tr","hash":{},"data":data}))
    + "\n								</a>\n								<a href=\"#\" class=\"dropdown-item text-danger\" data-delete-custom-event-id=\""
    + alias2(alias1((depths[1] != null ? depths[1].eventID : depths[1]), depth0))
    + "\"\n								 data-delete-custom-event-type=\""
    + alias2(alias1((depths[1] != null ? depths[1].type : depths[1]), depth0))
    + "\">\n									<span class=\"far fa-trash-alt\"></span> "
    + alias2(helpers.tr.call(alias3,"button.delete",{"name":"tr","hash":{},"data":data}))
    + "\n								</a>\n							</div>\n						</div>\n					</div>\n				</div>\n				<div class=\"custom-event-plain-form\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.stillBinding : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.program(42, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "				</div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "						<div class=\"text-center w-100\">\n							<span class=\"fa fa-spinner fa-spin\"></span>\n						</div>\n";
},"42":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "						"
    + ((stack1 = container.invokePartial(partials.form,(depths[1] != null ? depths[1].formBinding : depths[1]),{"name":"form","hash":{"plain":true,"readonly":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_change : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "	<div class=\"card "
    + ((stack1 = helpers.unless.call(alias1,(data && data.last),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"card-body\">\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.data : depth0),{"name":"with","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "				<div class=\"card-title\">\n					<strong>\n						<span class=\"fas fa-edit\"></span>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.userID : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.program(49, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "					</strong>\n					<div class=\"float-right d-flex\">\n						<span class=\"text-muted\">"
    + container.escapeExpression(helpers.format_date.call(alias1,(depths[1] != null ? depths[1].timestamp : depths[1]),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "</span>\n					</div>\n				</div>\n				<div class=\"change-event-plain-form\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.stateName : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.priorityID : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.ticketGroupID : stack1),{"name":"if","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.assignedToUserID : stack1),{"name":"if","hash":{},"fn":container.program(59, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.customerPersonID : stack1),{"name":"if","hash":{},"fn":container.program(61, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.customerCompanyID : stack1),{"name":"if","hash":{},"fn":container.program(63, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.reportMethodID : stack1),{"name":"if","hash":{},"fn":container.program(65, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.reportedAt : stack1),{"name":"if","hash":{},"fn":container.program(70, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].detailsFormBinding : depths[1]),{"name":"if","hash":{},"fn":container.program(72, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    return "						Korisnik <strong>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.userName : depth0), depth0))
    + "</strong> modificirao je ticket\n";
},"49":function(container,depth0,helpers,partials,data) {
    return "						Ticket je automatski modificiran\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Naziv: </strong>\n							</div>\n							<div class=\"col-10\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.title : stack1), depth0))
    + "</div>\n						</div>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Status: </strong>\n							</div>\n							<div class=\"col-10\">\n								<span class=\"badge badge-dark\" style=\"background-color: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.stateColor : stack1), depth0))
    + ";\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.stateName : stack1), depth0))
    + "</span>\n							</div>\n						</div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Prioritet: </strong>\n							</div>\n							<div class=\"col-10\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.priorityName : stack1), depth0))
    + "</div>\n						</div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Prioritet: </strong>\n							</div>\n							<div class=\"col-10\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.ticketGroup : stack1), depth0))
    + "</div>\n						</div>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Agent: </strong>\n							</div>\n							<div class=\"col-10\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.assignedToDisplayName : stack1), depth0))
    + "</div>\n						</div>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Klijent: </strong>\n							</div>\n							<div class=\"col-10\">\n								<a href=\"/phonebook/viewPerson/?personID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.customerPersonID : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.customerPersonName : stack1), depth0))
    + "</a>\n							</div>\n						</div>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Klijent: </strong>\n							</div>\n							<div class=\"col-10\">\n								<a href=\"/phonebook/viewPerson/?personID="
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.customerCompanyID : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.customerCompanyName : stack1), depth0))
    + "</a>\n							</div>\n						</div>\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Način zaprimanja: </strong>\n							</div>\n							<div class=\"col-10\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isSystem : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.program(68, data, 0),"data":data})) != null ? stack1 : "")
    + "							</div>\n						</div>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "									"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"report_method",((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.reportMethodName : stack1),{"name":"tr","hash":{},"data":data}))
    + "\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "									"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.reportMethodName : stack1), depth0))
    + "\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "						<div class=\"row\">\n							<div class=\"col-2\">\n								<strong>Vrijeme zaprimanja: </strong>\n							</div>\n							<div class=\"col-10\">\n								"
    + container.escapeExpression(helpers.format_date.call(alias1,((stack1 = (depth0 != null ? depth0.changes : depth0)) != null ? stack1.reportedAt : stack1),helpers.tr.call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data}),{"name":"format_date","hash":{},"data":data}))
    + "\n							</div>\n						</div>\n";
},"72":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "						<h6><strong>Detalji: </strong></h6>\n						"
    + ((stack1 = container.invokePartial(partials.form,(depths[1] != null ? depths[1].detailsFormBinding : depths[1]),{"name":"form","hash":{"plain":true,"readonly":true},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_call : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(30, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})