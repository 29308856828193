module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "								"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MaxWaitTime : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "								00:00\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "									"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MaxWaitTime : stack1), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "									00:00\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "								"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.AverageWaitTime : stack1), depth0))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "									"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.AverageWaitTime : stack1), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "	<div class=\"row queue-statistics\">\n		<div class=\"col-xs-12 col-sm-6 col-md-3 info-panel\">\n			<div class=\"panel panel-default\">\n				<div class=\"panel-heading\" style=\"font-size: 1.5em; text-align: center;\">"
    + alias2(helpers.tr.call(alias1,"label.waiting_call_count",{"name":"tr","hash":{},"data":data}))
    + "</div>\n				<div class=\"panel-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: rgb(85, 85, 85);\" id=\"waiting-counter\">\n					"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.WaitingCallCount : stack1), depth0))
    + "\n				</div>\n			</div>\n		</div>\n\n		<div class=\"col-xs-12 col-sm-6 col-md-3 info-panel\">\n			<div class=\"panel panel-default\">\n				<div class=\"panel-heading\" style=\"font-size: 1.5em; text-align: center;\">"
    + alias2(helpers.tr.call(alias1,"label.total_calls",{"name":"tr","hash":{},"data":data}))
    + "</div>\n				<div class=\"panel-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: rgb(85, 85, 85);\" id=\"calls-counter\">\n					"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.TotalCallCount : stack1), depth0))
    + "\n				</div>\n			</div>\n		</div>\n\n		<div class=\"col-xs-12 col-sm-6 col-md-3 info-panel\">\n			<div class=\"panel panel-default\">\n				<div class=\"panel-heading\" style=\"font-size: 1.5em; text-align: center;\">"
    + alias2(helpers.tr.call(alias1,"label.missed_calls",{"name":"tr","hash":{},"data":data}))
    + "</div>\n				<div class=\"panel-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: #ee5f5b;\" id=\"missed-counter\">\n					"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MissedCallCount : stack1), depth0))
    + "\n				</div>\n			</div>\n		</div>\n\n		<div class=\"col-xs-12 col-sm-6 col-md-3 info-panel\">\n			<div class=\"panel panel-default\">\n				<div class=\"panel-heading\" style=\"font-size: 1.5em; text-align: center;\">"
    + alias2(helpers.tr.call(alias1,"label.efficiency",{"name":"tr","hash":{},"data":data}))
    + "</div>\n				<div class=\"panel-body\" style=\"font-size: 3em; text-align: center; font-weight: bold; color: rgb(85, 85, 85);\" id=\"efficiency-display\">\n					"
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.Efficiency : stack1), depth0))
    + "\n				</div>\n			</div>\n		</div>\n\n		<div class=\"col-xs-12 col-sm-6 info-panel\">\n			<div class=\"panel panel-default\">\n				<div class=\"panel-heading\" style=\"font-size: 1.5em; text-align: center;\">"
    + alias2(helpers.tr.call(alias1,"label.longest_waiting_time",{"name":"tr","hash":{},"data":data}))
    + "</div>\n				<div class=\"panel-body\" >\n\n					<div class=\"progress\">\n                        <div class=\"progress-label\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MaxWaitTime : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <div class=\"progress-bar progress-bar-success\" role=\"progressbar\" style=\"width: "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MaxWaitTimePercentage : stack1), depth0))
    + "%;\" id=\"longest-wait-time\">\n							<div class=\"progress-label\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.MaxWaitTime : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n              		</div>\n\n				</div>\n			</div>\n		</div>\n\n		<div class=\"col-xs-12 col-sm-6 info-panel\">\n			<div class=\"panel panel-default\">\n				<div class=\"panel-heading\" style=\"font-size: 1.5em; text-align: center;\">"
    + alias2(helpers.tr.call(alias1,"label.average_waiting_time",{"name":"tr","hash":{},"data":data}))
    + "</div>\n				<div class=\"panel-body\">\n\n					<div class=\"progress\">\n                        <div class=\"progress-label\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.AverageWaitTime : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <div class=\"progress-bar progress-bar-success\" role=\"progressbar\" style=\"width: "
    + alias2(alias3(((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.AverageWaitTimePercentage : stack1), depth0))
    + "%;\" id=\"average-wait-time\">\n							<div class=\"progress-label\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.Summary : depth0)) != null ? stack1.AverageWaitTime : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n              		</div>\n\n				</div>\n			</div>\n		</div>\n	</div>\n";
},"useData":true})