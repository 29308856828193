<template>
	<div>
		<div class="form-group">
			<input type="text" placeholder="Search trackers" class="form-control" v-model="searchInput">
		</div>
		<div v-if="trackers.length" class="row">
			<div v-for="tracker in trackers" :key="tracker.ID" class="col-12 col-sm-6 col-md-4 col-lg-3">
				<Tracker
					:trackerID="tracker.ID"
					:trackerName="tracker.settings.name"
					:personName="tracker.personName"
					:personID="tracker.personID"
					:phoneNumber="tracker.phoneNumber"
					:formattedPhoneNumber="tracker.formattedPhoneNumber"
				/>
			</div>
		</div>
		<div v-else class="text-center">
			<div class="text-muted">
				No trackers added
			</div>
		</div>
	</div>
</template>

<script>
import Tracker from './Tracker.vue';

export default {
	components: {
		Tracker
	},
	props: {
		trackers: Array
	},
	data() {
		return {
			searchInput: ''
		};
	}
};
</script>

<style>
</style>
