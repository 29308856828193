<template>
    <SelectField
        v-model="valueProp"
        valueProp="ID"
        textProp="title"
        :options="tickets"
        :onSearch="searchTickets"
				:required="true"
				:filterable="false"
    >
		<template slot="selected-option" slot-scope="option">
			<template v-if="option && option.ID">
				<strong>#{{option.ID}}</strong>&nbsp;-&nbsp;{{option.title}}
			</template>
		</template>
		<template slot="option" slot-scope="option">
			<strong>#{{option.ID}}</strong>&nbsp;-&nbsp;{{option.title}}
		</template>
	</SelectField>
</template>

<script>
import SelectField from 'BootQuery/Assets/js/forms/fields/Select.vue';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		SelectField
	},
	props: {
		value: Number
	},
	data() {
		return {
			valueValue: this.value || null,
			tickets: []
		};
	},
	methods: {
		searchTickets(search, loading) {
			loading(true);
			Api.get('/api/ticketing/tickets', { title: search }).then(res => {
				this.tickets = res;
				loading(false);
			});
		},
	},
	computed: {
		valueProp: {
			get() {
				return this.valueValue;
			},
			set(val) {
				this.valueValue = val;
				this.$emit('input', val);
			}
		}
	},
	watch: {
		value(val) {
			this.valueValue = val;
		}
	},
	created() {
		Api.get('/api/ticketing/tickets').then(res => this.tickets = res);
	}
};
</script>

<style>
</style>
