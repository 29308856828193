import {get, forEach, find, filter, flatten, map, remove} from 'lodash';
import Module from 'BootQuery/Assets/js/module';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import {popoverForTrigger} from 'app/assets/js/util.js';

export default class Dialer extends Module {
	async init(data) {
		super.init();
		this.callStatusEl = null;
		this.callStatusDropdownContent = null;
		this.canCall = false;
		this.localExtension = get(data, 'bootquery.session.extension');
		this.availableExtensions = await Api.get('/api/availableExtensions');
		this.activeChannel = {};
		this.otherChannels = [];
		this.menus = get(data, 'modules.Menu.menus');
		this.activeCallID = null;

		if (this.localExtension) {
			this.subscribeWebSocket(`PeerStatusChanged/${this.localExtension}`, this.onPeerStatusChanged.bind(this));
			this.subscribeWebSocket(`CallUpdate/${this.localExtension}`, this.onCallUpdate.bind(this));
			this.subscribeWebSocket(`CallEnd/${this.localExtension}`,    this.onCallEnd.bind(this));

			const extensionStatus = await Api.get(
				`/api/extensionStatus/${this.localExtension}`
			);
			if (extensionStatus) {
				this.extensionStatus = this.processPeerStatus(extensionStatus);
				this.lastExtensionStatus = this.extensionStatus;
				this.callTimer = null;

				let target = 'body';
				let $target = $(target);
				this.callStatusEl = null;
				const template = await getTemplate('Dialer.callStatus');
				this.callStatusEl = $(handlebarsRender(template, {
					availableExtensions: this.availableExtensions,
					localExtension: this.localExtension,
					localExtensionStatus: this.extensionStatus,
					menus: this.menus,
					activeCallID: this.activeCallID,
					activeChannel: this.activeChannel,
					otherChannels: this.otherChannels
				}));

				this.callCounterEl = this.callStatusEl.find('.dialer-counter-section');
				if (!this.callTimer) {
					this.callCounterEl.prop('hidden', true);
				}
				this.onPeerStatusChanged(this.extensionStatus);

				$target.findElement('.header-middle-container, .callstatus-nav').replaceWith(this.callStatusEl);

				this.bindEvents(target);
			}
		}
	}

	async activateElements(target, _data) {
		if (target.findElement('body, #content-wrapper').length) {
			let el = target.findElement('.callstatus-nav');
			if (el.length) {
				this.callStatusEl = el;
			} else {
				this.renderCallStatus(target);
			}
		}

		this.dialButton = $('#dialer-call-button');
		this.numberInput = $('#dialer-number-to-dial');

		this.bindEvents(target);
	}

	async onPeerStatusChanged(data) {
		if(!this.callStatusEl) {
			return;
		}

		if (!data.Extension || data.Extension !== this.localExtension) {
			return;
		}

		data = this.processPeerStatus(data);

		this.lastExtensionStatus = this.extensionStatus;
		this.extensionStatus = data;

		let template = await getTemplate('Dialer.callStatus');
		let renderData = {
			availableExtensions: this.availableExtensions,
			localExtension: this.localExtension,
			localExtensionStatus: this.extensionStatus,
			menus: this.menus,
			activeCallID: this.activeCallID,
			activeChannel: this.activeChannel,
			otherChannels: this.otherChannels
		};

		let rendered = $(handlebarsRender(template, renderData));

		this.callStatusEl.find('.callstatus-nav').replaceWith(rendered.find('.callstatus-nav'));

		this.callCounterEl = this.callStatusEl.find('.dialer-counter-section');
		this.bindEvents();
		this.emit('render', renderData, this.callStatusEl);

		if (data.Status === 'InUse' || data.Status === 'Ringing' || data.Status === 'Busy') {
			this.startTimer();
		} else {
			this.stopTimer();
		}
	}

	async onCallUpdate(data) {
		this.activeCallID = data.ID || undefined;

		let renderData = {
			activeCallID: this.activeCallID,
			menus: this.menus,
			activeChannel: this.activeChannel,
			otherChannels: this.otherChannels
		};

		let template = await getTemplate('Dialer.callStatus');
		let rendered = $(handlebarsRender(template, renderData));

		this.callStatusEl.find('.dialer-call-menu').replaceWith(rendered.find('.dialer-call-menu'));
		activateElements('.dialer-call-menu');

		this.emit('render', renderData, this.callStatusEl);
	}

	async onCallEnd(data) {
		this.activeCallID = undefined;

		let renderData = {
			activeCallID: this.activeCallID,
			menus: this.menus
		};

		let template = await getTemplate('Dialer.callStatus');
		let rendered = $(handlebarsRender(template, renderData));

		this.callStatusEl.find('.dialer-call-menu').replaceWith(rendered.find('.dialer-call-menu'));
		this.callCounterEl = this.callStatusEl.find('.dialer-counter-section');

		this.emit('render', renderData, this.callStatusEl);
	}

	startTimer() {
		if (this.callTimer) {
			this.stopTimer();
		}
		let call = get(this.extensionStatus, 'Calls.0.ConnectingCalls') || get(this.extensionStatus, 'Calls.0.BridgedCalls');
		if (!call) {
			return;
		}

		const updateCounter = () => {
			if (!this.callTimer) {
				return;
			}

			let elapsedMs = moment().diff(this.callTimer.startTime);
			let elapsed = moment.utc(elapsedMs);
			const format = (elapsedMs / 1000) > 3600 ? 'HH:mm:ss' : 'mm:ss';

			this.callCounterEl.text(elapsed.format(format));
		};

		this.callTimer = {
			interval: window.setInterval(updateCounter.bind(this), 1000),
			startTime: moment(call[0].CallStart)
		};

		updateCounter();
		this.callCounterEl.prop('hidden', false);
	}

	stopTimer() {
		if (this.callTimer) {
			window.clearInterval(this.callTimer.interval);
		}
		this.callCounterEl.prop('hidden', true);
	}

	processPeerStatus(data) {
		let setStatusBool = (obj) => {
			obj.StatusBool = {
				[obj.Status]: true
			};
		};

		setStatusBool(data);

		forEach(data.Calls || [], (call) => {
			call.isOnHold = call.Status === 'Hold' || call.status === 'InUse_Hold';
			setStatusBool(call);
			forEach(call.ConnectingCalls || [], connectingCall => {
				setStatusBool(connectingCall);
			});
			forEach(call.BridgedCalls || [], bridgedCall => {
				setStatusBool(bridgedCall);
				bridgedCall.isOnHold = bridgedCall.Status === 'Hold' || bridgedCall.status === 'InUse_Hold';
			});
		});

		this.activeChannel = undefined;
		this.activeCallID  = null;
		this.otherChannels = [];

		// console.log(data);
		for(let call in data.Calls) {
			let inner = find(data.Calls[call].BridgedCalls, {OnHold: false});
			if (inner !== undefined) {
				this.activeChannel = inner;
				this.activeCallID = this.activeChannel.ID;
				break;
			}
		}

		let connectingCalls = filter(flatten(map(data.Calls, 'ConnectingCalls')));
		let bridgedCalls = filter(flatten(map(data.Calls, 'BridgedCalls')));

		this.otherChannels = bridgedCalls.concat(connectingCalls);

		if(this.activeChannel) {
			remove(this.otherChannels, (channel) => { return channel.Channel == this.activeChannel.Channel; });
		}

		if(data.Status !== 'InUse') {
			// $('#dialer-number-input').hide();
			// $('#call-actions').hide();
		}

		if(data.Status !== 'Idle') {
			this.hideCallButton();

			if(data.Status === 'Unavaliable' || data.Status === 'Unknown') {
				this.hideEndCallButton();
			} else {
				this.showEndCallButton();
			}
		} else {
			this.showCallButton();
		}

		if(data.Status == 'Ringing' || data.Status == 'Busy') {
			this.disableNumberInput();
		} else {
			this.enableNumberInput();
		}

		if (data.Status === 'Ringing' && !connectingCalls && !bridgedCalls) {
			data.originating = true;
			data.displayStatus = 'Pick up phone';
		} else if (data.Status === 'Busy' && !connectingCalls && !bridgedCalls) {
			data.startingCall = true;
			data.displayStatus = 'Calling';
		} else if (!connectingCalls && !bridgedCalls) {
			data.displayStatus = data.Status;
			$('#dialer-number-input').show();
		} else if (data.Status === 'Idle') {
			data.displayStatus = 'Idle';
			$('#dialer-number-input').show();
		} else if (data.Status === 'Unavailable' || data.Status == 'Unknown') {
			data.displayStatus = data.Status;
			$('#dialer-number-input').hide();
		} else if (data.Status == 'InUse') {
			this.showEndCallButton();
			$('#call-actions').show();
			// $('#dialer-call-button').hide();
			// $('.dialer-end-call-button').show();
		}

		return data;
	}

	bindEvents(target = 'body') {
		let $target = $(target);

		if (this.callStatusEl) {
			this.dialButton = this.callStatusEl.findElement('#dialer-call-button');
			this.transferButton = this.callStatusEl.findElement('.dialer-transfer-call-button');
			this.numberInput = $('#dialer-number-to-dial');

			this.dialButton.off('click.dialer').on('click.dialer', (e) => {
				e.preventDefault();
				this.dialInputtedNumber();
			});

			this.callStatusEl.findElement('.dialer-number-key').off('click.dialer').on('click.dialer', (e) => {
				e.preventDefault();
				this.numberInput.focus();
				this.numberInput.val(this.numberInput.val() + $(e.currentTarget).findElement('.dialer-number-value').text().trim());
			});

			this.transferButton.off('click.dialer').on('click.dialer', (e) => {
				e.preventDefault();
				this.transferCurrentCall();
			});

			this.numberInput.off('input.dialer').on('input.dialer', (event) => {
				if(this.extensionStatus.Status == 'InUse') {
					if(this.getNumberInput() !== '') {
						this.showTransferButton();
					} else {
						this.showEndCallButton();
					}
				} else {
					this.showCallButton();
				}
			});

			this.callStatusEl.findElement('.callstatus-nav').off('click.dialer').on('click.dialer', e => {
				let $clicked = $(e.target);

				if ($clicked.is('ul') || $clicked.is('li.dialer-middle-section')) {
					this.toggleMenu();
				}
			});

			this.callStatusEl.findElement('.toggle-keypad').off('click.dialer').on('click.dialer', (e) => {
				e.preventDefault();

				$('#dialer-keypad').slideToggle('fast');
			});

			this.callStatusEl.findElement('.dialer-end-call-button').off('click.dialer').on('click.dialer', (e) => {
				e.preventDefault();
				let extension = window.Bootstrap.bootquery.session.extension;

				Api.post(`/api/asterisk/extensions/${extension}/hangup`, {}).then(data => {
					console.log('Hungup:', data);
				});
			});
		}

		if (this.dropdownContent) {
			this.checkCanCall();
			this.numberInput.off('input.dialer').on('input.dialer', this.checkCanCall.bind(this));
			this.numberInput.off('keypress.dialer').on('keypress.dialer', (e) => {
				if (e.which === 13) {
					this.dialInputtedNumber();
					this.callStatusEl.popover('hide');
				}
			});
		}

		$target.findElement('.dialer-call-button').ev('click', (e) => {
			e.preventDefault();
			let button = $(e.target);
			this.dial(button.data('number'));
		});

		$target.findElement('a[href^="tel:"]').each((_idx, el) => {
			const $el = $(el);
			const href = $el.attr('href');
			const phoneNumber = href.replace(/^tel:/, '');
			this.activateDialLinkPopover(el, phoneNumber, $el.text());
		});
	}

	getNumberInput() {
		return $('#dialer-number-to-dial').val(); // TEMP!
		// let numberID = $('#dialer-number-to-dial').val();
		// let number = null;

		// if(numberID) {
		// 	if(numberID === '$unselectedNewOption$') {
		// 		number = $('#dialer-number-to-dial').find('option[value="$unselectedNewOption$"]').text();
		// 	} else {
		// 		number = $('#dialer-number-to-dial').pickle('option', numberID).res.fullPhoneNumber;
		// 	}
		// }

		// return number;
	}

	clearNumberInput() {
		$('#dialer-number-to-dial').val('');
	}

	enableNumberInput() {
		$('#dialer-number-to-dial').prop('disabled', false);
	}

	disableNumberInput() {
		$('#dialer-number-to-dial').prop('disabled', true);
	}

	showTransferButton() {
		$('.dialer-header').findElement('#dialer-call-button').hide();
		$('.dialer-header').findElement('.dialer-content').findElement('.dialer-end-call-button').hide();
		$('.dialer-header').findElement('.dialer-transfer-call-button').show();
	}

	hideTransferButton() {
		$('.dialer-header').findElement('.dialer-transfer-call-button').hide();
	}

	showCallButton() {
		$('.dialer-header').findElement('.dialer-transfer-call-button').hide();
		$('.dialer-header').findElement('.dialer-content').findElement('.dialer-end-call-button').hide();
		$('.dialer-header').findElement('#dialer-call-button').show();
	}

	hideCallButton() {
		$('.dialer-header').findElement('#dialer-call-button').hide();
	}

	showEndCallButton() {
		$('.dialer-header').findElement('.dialer-transfer-call-button').hide();
		$('.dialer-header').findElement('#dialer-call-button').hide();
		$('.dialer-header').findElement('.dialer-content').findElement('.dialer-end-call-button').show();
	}

	hideEndCallButton() {
		$('.dialer-header').findElement('.dialer-content').findElement('.dialer-end-call-button').hide();
	}

	dialInputtedNumber() {
		let number = this.getNumberInput();

		if(number) {
			this.dial(number).then(data => {
				this.clearNumberInput();
				this.disableNumberInput();
			});
		}
	}

	/**
	 * Dial a number with local extension
	 * @param {string} number
	 */
	dial(number) {
		if (typeof(number) !== 'string' || !number.length) {
			const numStr = JSON.stringify(number);
			throw new Error(`Can't dial without number. Was given: ${numStr}`);
		}
		const extension = this.localExtension;
		if (!extension) {
			throw new Error('Can\'t dial without extension');
		}
		return Api.post(
			`/api/asterisk/extensions/${this.localExtension}/dial`,
			{number}
		);
	}

	transferCurrentCall() {
		let number = this.getNumberInput();

		if(number && this.extensionStatus.Calls && this.extensionStatus.Calls.length) {
			let params = {
				extension: number,
				channel: this.extensionStatus.Calls[0].Channel,
				attended: true
			};

			$.get('/api/asterisk/Transfer', params, (data) => {
				this.clearNumberInput();
			});
		}
	}

	checkCanCall() {
		this.canCall = this.numberInput.val().trim().length > 0;
		this.dialButton.prop('disabled', !this.canCall);
	}

	toggleMenu() {
		this.callStatusEl.find('.dialer-content').slideToggle('fast');
	}

	hideMenu() {
		this.callStatusEl.find('.dialer-content').slideUp('fast');
	}

	async renderCallStatus(target = 'body') {
		if (!this.extensionStatus) {
			return;
		}
		let $target = $(target);
		this.callStatusEl = null;

		let template = await getTemplate('Dialer.callStatus');
		let renderData = {
			availableExtensions: this.availableExtensions,
			localExtension: this.localExtension,
			localExtensionStatus: this.extensionStatus,
			menus: this.menus,
			activeCallID: this.activeCallID,
			activeChannel: this.activeChannel,
			otherChannels: this.otherChannels
		};
		this.callStatusEl = $(handlebarsRender(template, renderData));

		this.callCounterEl = this.callStatusEl.find('.dialer-counter-section');
		if (!this.callTimer) {
			this.callCounterEl.prop('hidden', true);
		}
		this.onPeerStatusChanged(this.extensionStatus);

		// $target.findElement('.header-middle-container, .callstatus-nav').replaceWith(this.callStatusEl);
		$target.findElement('.callstatus-nav').replaceWith(this.callStatusEl.findElement('.callstatus-nav'));

		this.bindEvents(target);
		this.emit('render', renderData, this.callStatusEl);
	}

	activateDialLinkPopover(target, numberToDial, formattedNumber = null) {
		const $target = $(target);
		if (!formattedNumber) {
			formattedNumber = numberToDial;
		}
		numberToDial = numberToDial.replace(/\s+/g, '');

		$target.off('click');
		$target.ev('click.dialer', ev => {
			ev.preventDefault();
			$target.popover('toggle');
		});
		$target
			.ev('inserted.bs.popover', function(e) {
				popoverForTrigger(this).addClass('popover-opening');
			})
			.ev('shown.bs.popover', function() {
				var $popover = popoverForTrigger(this);
				$popover.removeClass('popover-opening');
				$popover.find('[data-dismiss=popover]').ev('click.popoverDismiss', function(e) {
					$(this).closest('.popover').popover('hide');
				});
			});

		Promise.all([
			getTemplate('Dialer.dialConfirmPopover'),
			getTemplate('Dialer.dialConfirmPopoverTitle')
		]).then(([contentTemplate, titleTemplate]) => {
			$target.popover({
				html: true,
				title: () => {
					return $.render(titleTemplate, {
						formattedNumber
					});
				},
				content: () => {
					const $content = $.render(contentTemplate, {
						numberToDial,
						hasExtension: !!this.localExtension
					});
					$content.find('.clickvox-dial-btn').ev('click', e => {
						e.preventDefault();
						this.dial(numberToDial);
					});
					return $content;
				}
			});
		});
	}
}
