<template>
	<div>
		<div v-if="alerts.length === 0" class="card bg-success text-white">
			<div class="card-body text-center">No alerts</div>
		</div>
		<div v-else class="card border-danger">
			<div class="card-body">
				<div class="row">
					<Alert
						v-for="alert in alerts"
						:key="alert.trackerID"
						:trackerID="alert.trackerID"
						:trackerName="alert.trackerName"
						:personName="alert.personName"
						:personID="alert.personID"
						:phoneNumber="alert.phoneNumber"
						:alerts="alert.alerts"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from './Alert.vue';

export default {
	components: {
		Alert
	},
	props: {
		alerts: Array
	}
};
</script>

<style>
</style>
