module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "								<span><strong>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.reportTitle : depth0), depth0))
    + "</strong></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " -->\n						<!-- "
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.value : depth0), depth0),{"name":"value","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " -->\n						<!-- ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " -->\n						<tr>\n							<td class=\"report-heading-key\">\n								"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + ":\n							</td>\n\n							<td class=\"report-heading-value no-pdf-wide\">&nbsp;\n								"
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\n								"
    + ((stack1 = container.invokePartial(partials.reportColumnWidener,depth0,{"name":"reportColumnWidener","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</td>\n						</tr>\n						<!-- ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, alias4=helpers.blockHelperMissing;

  return "						<tr>\n							<td class=\"report-heading-key no-pdf-wide\">\n								<strong>"
    + alias2(helpers.tr.call(alias1,"report.company",{"name":"tr","hash":{},"data":data}))
    + ":</strong>\n							</td>\n							<td class=\"report-heading-value\">&nbsp;\n								"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.name : depth0), depth0),{"name":"name","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n								"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.name : depth0), depth0),{"name":"name","hash":{},"fn":container.noop,"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n							</td>\n						</tr>\n\n						<tr>\n							<td class=\"report-heading-key no-pdf-wide\">\n								<strong>"
    + alias2(helpers.tr.call(alias1,"report.address",{"name":"tr","hash":{},"data":data}))
    + ":</strong>\n							</td>\n							<td class=\"report-heading-value\">&nbsp;\n"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.primaryStreetName : depth0), depth0),{"name":"primaryStreetName","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.primaryStreetName : depth0), depth0),{"name":"primaryStreetName","hash":{},"fn":container.noop,"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "							</td>\n						</tr>\n\n						<tr>\n							<td class=\"report-heading-key no-pdf-wide\">\n								<strong>"
    + alias2(helpers.tr.call(alias1,"report.city",{"name":"tr","hash":{},"data":data}))
    + ":</strong>\n							</td>\n							<td class=\"report-heading-value\">&nbsp;\n								"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.primaryCityName : depth0), depth0),{"name":"primaryCityName","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n								"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.primaryCityName : depth0), depth0),{"name":"primaryCityName","hash":{},"fn":container.noop,"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n							</td>\n						</tr>\n\n						<tr>\n							<td class=\"report-heading-key no-pdf-wide\">\n								<strong>"
    + alias2(helpers.tr.call(alias1,"report.identification_number",{"name":"tr","hash":{},"data":data}))
    + ":</strong>\n							</td>\n							<td class=\"report-heading-value\">&nbsp;\n								"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.oib : depth0), depth0),{"name":"oib","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n								"
    + ((stack1 = alias4.call(depth0,alias3((depth0 != null ? depth0.oib : depth0), depth0),{"name":"oib","hash":{},"fn":container.noop,"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n							</td>\n						</tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.name : depth0), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    return "-";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "									"
    + alias2(alias1((depth0 != null ? depth0.primaryStreetName : depth0), depth0))
    + "&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.primaryStreetNumber : depth0), depth0))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "									-\n";
},"15":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.primaryCityName : depth0), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.oib : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return "<table class=\"table report-heading\">\n	<thead>\n		<tr>\n			<td colspan=\"2\" style=\"padding: 0;\">\n				<table>\n					<thead>\n						<tr class=\"report-title-row report-first-row\">\n							<td class=\"report-first-col nowrap\">\n								<span class=\"report-logo\">\n									ClickVOX<span style=\"color: #88CC00;\">*</span>\n								</span>\n							</td>\n\n							<td class=\"text-right report-last-col nowrap\" style=\"font-size: 1.2em;\">\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.bootquery : depth0), depth0),{"name":"bootquery","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "							</td>\n						</tr>\n					</thead>\n				</table>\n			</td>\n		</tr>\n\n	</thead>\n\n	<tbody>\n		<tr>\n			<td class=\"report-first-col v-top\" rowspan=\"2\" style=\"border-bottom: 1px solid #DDD;\">\n				<table>\n					<tbody>\n						<!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? depth0.filters : depth0), depth0),{"name":"filters","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  -->\n					</tbody>\n				</table>\n			</td>\n\n			<td class=\"report-last-col v-top\">\n				<table>\n					<tbody>\n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.bootquery : depth0)) != null ? stack1.localCompanyDetails : stack1), depth0),{"name":"bootquery.localCompanyDetails","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					</tbody>\n				</table>\n			</td>\n		</tr>\n\n		<tr class=\"report-last-row\">\n			<td class=\"report-last-col v-bottom text-right nowrap\">\n				"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"report.generated_on",{"name":"tr","hash":{},"data":data}))
    + "\n			</td>\n		</tr>\n	</tbody>\n</table>\n";
},"usePartial":true,"useData":true})