import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import { forEach, reduce } from 'lodash';

window.addEventListener('unhandledrejection', e => {
	e.preventDefault();
	throw e.detail.reason;
});

import { templates, Handlebars } from './templates';
window.Handlebars = Handlebars;
window.templates = templates;

import Popper from 'popper.js';
window.Popper = Popper;

import url from 'url';
window.url = url;

import * as ApiRequest from './apiRequest';
window.ApiRequest = ApiRequest;

import Locales from './locales';
window.Locales = Locales;

import 'bootstrap';
import 'inputmask/dist/inputmask/jquery.inputmask.js';
import 'inputmask/dist/inputmask/inputmask.date.extensions';
import 'bootstrap-daterangepicker';
import 'bootstrap-slider';
import 'selectize/dist/js/standalone/selectize.min.js';
import 'app/assets/js/pickle.js';
import refreshFilters from 'app/assets/js/filters.js';
window.refreshFilters = refreshFilters;

import * as Util from 'app/assets/js/util.js';
forEach(Util, (func, name) => {
	window[name] = func;
});

import Socket from './socket.js';
window.Socket = Socket;

import moment from 'moment';
moment.locale('en');
moment.locale('hr');
moment.locale('de');
window.moment = moment;

import phpToMomentDateFormat from 'BootQuery/Assets/js/phpToMoment.js';
window.phpToMomentDateFormat = phpToMomentDateFormat;

import createModal from 'app/assets/js/modals.js';
window.createModal = createModal;
import 'BootQuery/Assets/js/form.js';

import { Spinner } from 'spin.js';
window.Spinner = Spinner;

const importModules = r => {
	return reduce(
		r.keys(),
		(modules, key) => {
			const moduleName = key.split('/')[1];
			modules[moduleName] = r(key).default;
			return modules;
		},
		{}
	);
};

let Modules = importModules(
	require.context(
		'../../../app/Modules',
		true,
		/\.\/[^/]+\/Assets\/js\/index\.js$/
	)
);

import Datatable from './datatable.js';
window.Datatable = Datatable;
window.initDatatables = Datatable.initDatatables;

import * as BqFuncs from 'BootQuery/Assets/js/BootQuery.js';
forEach(BqFuncs, (func, name) => {
	window[name] = func;
});

class BootQuery {
	constructor() {
		this.moduleInstances = {};

		$(document).on('activateElements.bq', (e, target, data) => {
			forEach(this.moduleInstances, (moduleInstance, moduleName) => {
				if (typeof moduleInstance.activateElements === 'function') {
					moduleInstance.activateElements(target, data, e);
				}
			});
		});
	}

	getModuleInstance(moduleName) {
		if (!Modules[moduleName]) {
			return null;
		}
		if (!this.moduleInstances[moduleName]) {
			this.moduleInstances[moduleName] = new Modules[moduleName]();
		}
		return this.moduleInstances[moduleName];
	}

	/**
	 * Lets the module with manual route handler manually handle the route
	 * @param {string} routePath
	 *
	 * @returns {boolean} true if route handled
	 */
	handleRouteManually(routePath, newBq) {
		for (let moduleName in Modules) {
			const module = Modules[moduleName];
			// Modules without JS have their keys in there too
			if (!module) {
				continue;
			}

			if (module.canHandleRoute(routePath)) {
				const bq = window.Bootstrap.bootquery;
				bq.controller = newBq.controller;
				bq.method = newBq.method;
				bq.parameters = newBq.parameters;

				const instance = this.getModuleInstance(moduleName);
				instance.handleRoute(routePath);
				return true;
			}
		}
		return false;
	}
}

window.BootQuery = new BootQuery();
