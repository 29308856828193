<template>
     <div class="form-group row">
        <slot name="label">
            <label class="col-form-label" :class="labelSizeClasses" v-if="label && label.length" :for="fieldId">{{label}}: </label>
        </slot>
        <slot name="control">
            <div :class="inputSizeClasses">
                <textarea class="form-control" :class="{'is-invalid': hasError}" :name="fieldName" :id="fieldId" rows="3" v-model="inputValue" :disabled="disabled" ref="textarea"></textarea>
            </div>
        </slot>
    </div>
</template>

<script>
import inputField from './mixins/inputField';
import autosize from 'autosize';

export default {
	mixins: [inputField],
	mounted() {
		autosize(this.$refs.textarea);
	},
	updated() {
		autosize.update(this.$refs.textarea);
	}
};
</script>
