<template>
	<div class="h-100">
		<div class="card h-100 rounded-0" v-if="chatID && chatInfo">
			<div class="card-header text-center">{{chatInfo.entity.firstName}} {{chatInfo.entity.lastName}}</div>
			<div class="card-body messages-container" ref="messages">
				<div v-if="loadingMessages" class="text-center">
					<span class="fas fa-spinner fa-spin"></span>
				</div>
				<template v-else>
					<Message
						v-for="message in messages"
						:key="message.ID"
						:direction="message.direction"
						:text="message.text"
						:attachments="message.attachments"
						:date="message.date"
						:isSending="message.isSending"
					/>
				</template>
			</div>
			<div class="card-block">
				<ChatInput @submit="sendMessage"/>
			</div>
		</div>
	</div>
</template>

<script>
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import FileUpload from 'BootQuery/Assets/js/fileUpload.js';
import { processChat } from '../js/util.js';
import Message from './Message.vue';
import ChatInput from './ChatInput.vue';
import uuid from 'uuid/v4';

export default {
	components: {
		Message,
		ChatInput
	},
	props: {
		chats: Array
	},
	data() {
		return {
			chatInfo: null,
			messages: null,
			loadingMessages: false
		};
	},
	computed: {
		chatID() {
			if (this.$route.params.chatID) {
				return parseInt(this.$route.params.chatID);
			} else {
				return null;
			}
		},
		chatInList() {
			if (this.chatID) {
				return this.chats.find(chat => chat.entityID === this.chatID);
			}
			return null;
		}
	},
	methods: {
		async fetchChat() {
			this.loadingMessages = true;
			const chat = await Api.get(
				`/api/facebookChat/chats/${this.chatID}`
			);
			this.chatInfo = processChat(chat);
			this.messages = this.chatInfo.messages;
			if (!this.chatInfo.seen) {
				this.markAsSeen();
			}
			this.loadingMessages = false;
			this.$nextTick().then(() => this.scrollToBottom(500));
		},
		newMessage(message) {
			if (message.tmpUUID) {
				const existingIndex = this.messages.findIndex(
					existing => existing.tmpUUID === message.tmpUUID
				);
				if (existingIndex !== -1) {
					this.$set(this.messages, existingIndex, message);
				} else {
					this.messages.push(message);
				}
			} else {
				this.messages.push(message);
			}
			this.scrollToBottom(100);
			this.markAsSeen();
		},
		markAsSeen() {
			if (!this.chatID) {
				return;
			}
			if (this.chatInList) {
				this.chatInList.seen = true;
			}
			Api.post(`/api/facebookChat/chats/${this.chatID}/markAsSeen`, {});
		},
		sendMessage(content) {
			const tmpUUID = uuid();
			const previewMessage = {
				isSending: true,
				entityID: this.chatInfo.entityID,
				accountID: this.chatInfo.accountID,
				text: content.text,
				direction: true,
				attachments: [],
				tmpUUID
			};
			if (content.attachment) {
				previewMessage.attachments = [{
					progress: 0,
					fileName: content.attachment.name
				}];
				const uploader = new FileUpload(content.attachment);
				uploader.on('progress', progress => {
					previewMessage.attachments[0].progress = progress;
				});
				uploader.on('done', (uploadInfo) => {
					Api.post('/api/facebookChat/sendMessage', {
						entityID: this.chatInfo.entityID,
						accountID: this.chatInfo.accountID,
						text: content.text,
						attachment: uploadInfo,
						tmpUUID
					});
				});
				uploader.start();
			} else if (content.text) {
				Api.post('/api/facebookChat/sendMessage', {
					entityID: this.chatInfo.entityID,
					accountID: this.chatInfo.accountID,
					text: content.text,
					tmpUUID
				});
			} else {
				throw new Error('What the heck am I supposd to send?!?!');
			}
			this.messages.push(previewMessage);
			this.scrollToBottom(0);
		},
		scrollToBottom(delayMS = 0) {
			setTimeout(() => {
				const container = this.$refs.messages;
				if (container) {
					container.scrollTo(0, container.scrollHeight);
				}
			}, delayMS);
		}
	},
	watch: {
		chatID: {
			handler(chatID) {
				this.chatInfo = null;
				this.messages = null;
				if (chatID) {
					if (this.chatInList) {
						this.chatInfo = this.chatInList;
					}
					this.fetchChat();
				}
			},
			immediate: true
		}
	}
};
</script>

<style lang="scss" scoped>
.messages-container {
	overflow-y: auto;
}
</style>
