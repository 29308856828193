module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"list-group-item\">\n	<div class=\"row\">\n		<div class=\"col-xs-12 col-sm-12 col-md-6 form-group\">\n			<label class=\"col-xs-12\">"
    + alias2(helpers.tr.call(alias1,"form.queue",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n			<div class=\"col-xs-12\" style=\"font-size: 16px;\">"
    + alias2(alias3((depth0 != null ? depth0.name : depth0), depth0))
    + "</div>\n		</div>\n		<div class=\"col-xs-12 col-sm-6 col-md-3 form-group\">\n			<label class=\"col-xs-12\">"
    + alias2(helpers.tr.call(alias1,"form.critical_awt",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n			<div class=\"col-xs-12\">\n				<div class=\"input-group durationpicker\" style=\"width: 100%;\">\n	                <span class=\"input-group-addon\">\n	                    <span class=\"glyphicon glyphicon-time\"></span>\n	                </span>\n\n	                <input type=\"text\" class=\"form-control\"  value=\""
    + alias2(alias3((depth0 != null ? depth0.criticalAverageWaitTime : depth0), depth0))
    + "\" name=\"criticalAWT-"
    + alias2(alias3((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"  data-date-format=\"HH:mm:ss\" style=\"border-top-right-radius: 0px; border-bottom-right-radius: 0px;\">\n                </div>\n			</div>\n		</div>\n\n		<div class=\"col-xs-12 col-sm-6 col-md-3 form-group\">\n			<label class=\"col-xs-12\">"
    + alias2(helpers.tr.call(alias1,"form.critical_lwt",{"name":"tr","hash":{},"data":data}))
    + ": </label>\n			<div class=\"col-xs-12\">\n				<div class=\"input-group durationpicker\" style=\"width: 100%;\">\n	                <span class=\"input-group-addon\">\n	                    <span class=\"glyphicon glyphicon-time\"></span>\n	                </span>\n\n	                <input type=\"text\" class=\"form-control\"  value=\""
    + alias2(alias3((depth0 != null ? depth0.criticalMaxWaitTime : depth0), depth0))
    + "\" name=\"criticalLWT-"
    + alias2(alias3((depth0 != null ? depth0.ID : depth0), depth0))
    + "\"  data-date-format=\"HH:mm:ss\" style=\"border-top-right-radius: 0px; border-bottom-right-radius: 0px;\">\n                </div>\n			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true})