var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mail-event-wrapper",class:{
            'mail-event-collapsed': _vm.collapsible && _vm.collapsed
        }},[_c('Message',_vm._b({ref:"message",attrs:{"cardClass":"my-3"},on:{"reply":_vm.onReply,"forward":_vm.onForward},scopedSlots:_vm._u([{key:"title",fn:function(ref){
        var subject = ref.subject;
        var isHighPriority = ref.isHighPriority;
return [_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"fas fa-envelope"}),_vm._v("\n                    "+_vm._s(subject)+"\n                    "),(isHighPriority)?_c('span',{staticClass:"fas fa-exclamation text-danger"}):_vm._e()])]}},{key:"content",fn:function(ref){
        var htmlBody = ref.htmlBody;
        var textBody = ref.textBody;
return [_c('div',{staticClass:"mail-event-content-outer"},[_c('div',{staticClass:"mail-event-content"},[(htmlBody)?_c('HTMLMessage',{attrs:{"html":htmlBody}}):_c('pre',[_vm._v(_vm._s(textBody))])],1),_vm._v(" "),(_vm.collapsible)?_c('a',{class:_vm.collapserClass,attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleCollapse($event)}}},[_vm._v("\n                        "+_vm._s(_vm.collapserText)+"\n                        "),_c('span',{class:_vm.collapserIcon})]):_vm._e()])]}}])},'Message',_vm.message,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }