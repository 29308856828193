const globalLocaleRequires = require.context(
	'app/Locales/',
	true,
	/[^/]+\.json$/
);
const moduleLocaleRequires = require.context(
	'app/Modules/',
	true,
	/\.\/[^/]+\/Locales\/.+\.json$/
);

let locales = moduleLocaleRequires.keys().reduce((locales, path) => {
	// Path:  ./{Module}/Locales/{locale}.json
	const pathParts = path.split('/');
	const moduleName = pathParts[1];
	const localeName = pathParts[3].replace(/\.json$/, '');

	if (!locales[localeName]) {
		locales[localeName] = {};
	}
	locales[localeName][moduleName] = moduleLocaleRequires(path);

	return locales;
}, {});

locales = globalLocaleRequires.keys().reduce((locales, path) => {
	// Path: ./{locale}.json
	const pathParts = path.split('/');
	const localeName = pathParts[1].replace(/\.json$/, '');

	if (!locales[localeName]) {
		locales[localeName] = {};
	}
	locales[localeName].global = globalLocaleRequires(path);
	return locales;
}, locales);

export default locales;
