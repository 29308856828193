module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"col\">\n            <select class=\"form-control form-control-sm ticket-share-group-dropdown datetime\"></select>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"col\">\n            <span class=\"ticket-share-group-name\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.ticketGroupName : depth0), depth0))
    + "\n            </span>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li class=\"ticket-share row\" data-ticket-share-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">\n    <div class=\"col-auto\">\n        <a href=\"#\"\n        class=\"text-danger ticket-share-delete-btn\"\n        >\n            <span class=\"fas fa-times\"></span>\n        </a>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.newRow : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>";
},"useData":true})