import { render, staticRenderFns } from "./ThreadView.vue?vue&type=template&id=66c7f607&scoped=true&"
import script from "./ThreadView.vue?vue&type=script&lang=js&"
export * from "./ThreadView.vue?vue&type=script&lang=js&"
import style0 from "./ThreadView.vue?vue&type=style&index=0&id=66c7f607&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c7f607",
  null
  
)

component.options.__file = "ThreadView.vue"
export default component.exports