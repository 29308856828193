module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.content.call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "		<form id=\"campaign-create-form\" class=\"form-horizontal\" action=\"/campaigns/saveCampaignSettings\" method=\"post\" role=\"form\">\n			<input type=\"hidden\" name=\"campaignID\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.ID : stack1), depth0))
    + "\">\n			<div class=\"card embedable\">\n\n				<div class=\"card-header stick-top\" id=\"campaign-create-container-header\">\n					<span class=\"fa fa-magic\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"label.edit_campaign",{"name":"tr","hash":{},"data":data}))
    + "\n				</div>\n				<div class=\"card-body\">\n					<div id=\"campaign-create-container\">\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.campaign_parameters),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "						<div class=\"col-12 mx-auto\" style=\"max-width: 1000px;\">\n							<div class=\"row\">\n								<div class=\"col-12\">\n									<div class=\"form-group row\">\n										<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"name\">\n											<span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"form.campaign_name",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n										</label>\n										<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n											<input type=\"text\" name=\"name\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"form-control\" autocomplete=\"off\" autofocus placeholder=\""
    + alias2(helpers.tr.call(alias3,"form.campaign_name",{"name":"tr","hash":{},"data":data}))
    + "\">\n										</div>\n									</div>\n									<div class=\"form-group row\">\n										<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"dialtype\">\n											<span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"form.dial_type",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n										</label>\n										<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n											<select class=\"form-control form-pickle\" name=\"dialtype\">\n												<option value=\"auto\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.autodial : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n													"
    + alias2(helpers.tr.call(alias3,"form.auto_dial",{"name":"tr","hash":{},"data":data}))
    + "\n												</option>\n												<option value=\"manual\" "
    + ((stack1 = helpers.unless.call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.autodial : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n													"
    + alias2(helpers.tr.call(alias3,"form.manual_dial",{"name":"tr","hash":{},"data":data}))
    + "\n												</option>\n											</select>\n										</div>\n									</div>\n									<div id=\"auto-dial-extra\">\n										<div class=\"form-group row\">\n											<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"wrapup\">\n												<span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"form.wrapup_time",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n											</label>\n											<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n												<input type=\"number\" name=\"wrapup\" min=\"0\" step=\"1\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.wrapupTime : stack1), depth0))
    + "\" class=\"form-control\" autocomplete=\"off\" placeholder=\""
    + alias2(helpers.tr.call(alias3,"form.wrapup_explanation",{"name":"tr","hash":{},"data":data}))
    + "\" "
    + ((stack1 = helpers.unless.call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.autodial : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n											</div>\n										</div>\n										<div class=\"form-group row\">\n											<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"skip\">\n												<span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"form.skip_time",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n											</label>\n											<div class=\"col-12 col-md-8 col-lg-9 col-xl-9\">\n												<input type=\"number\" name=\"skip\" min=\"0\" step=\"1\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.skipTime : stack1), depth0))
    + "\" class=\"form-control\" autocomplete=\"off\" placeholder=\""
    + alias2(helpers.tr.call(alias3,"form.skip_explanation",{"name":"tr","hash":{},"data":data}))
    + "\" "
    + ((stack1 = helpers.unless.call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.autodial : stack1),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n											</div>\n										</div>\n									</div>\n									<div class=\"form-group row\" id=\"manual-dial-extra\">\n										<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"dialonload\" disabled>\n											<span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"form.dial_helpers",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n										</label>\n										<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n											<div class=\"custom-control custom-checkbox\">\n												<input type=\"checkbox\" class=\"custom-control-input\" id=\"dialonload\" value=\"true\" name=\"dialonload\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.dialOnLoad : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.autodial : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n												<label class=\"custom-control-label\" for=\"dialonload\">"
    + alias2(helpers.tr.call(alias3,"form.dial_on_load",{"name":"tr","hash":{},"data":data}))
    + "</label>\n											</div>\n											<div class=\"custom-control custom-checkbox\">\n												<input type=\"checkbox\" class=\"custom-control-input\" id=\"dial-next\" value=\"true\" name=\"dialnext\" "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.dialAllContactNumbers : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.autodial : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n												<label class=\"custom-control-label\" for=\"dial-next\">"
    + alias2(helpers.tr.call(alias3,"form.dialnext",{"name":"tr","hash":{},"data":data}))
    + "</label>\n											</div>\n										</div>\n									</div>\n									<div class=\"form-group row\">\n										<label class=\"col-12 col-form-label col-md-4 col-lg-3 col-xl-3 col-form-label control-label text-left text-md-right\" for=\"agents\">\n											<span class=\"label-text\">"
    + alias2(helpers.tr.call(alias3,"form.assign_to_agents",{"name":"tr","hash":{},"data":data}))
    + "</span><span class=\"label-after\">: </span>\n										</label>\n										<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2\">\n											<div class=\"card subform-card\">\n												<div class=\"card-body\">\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.agents : stack1),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "												</div>\n											</div>\n										</div>\n									</div>\n									<div class=\"form-group row\">\n										<div class=\"col-12 col-md-8 col-lg-9 col-xl-9 pt-2 offset-md-4 offset-lg-3\">\n											<a href=\"/campaigns/editExistingForm/?campaign="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.result : depth0)) != null ? stack1.campaign : stack1)) != null ? stack1.ID : stack1), depth0))
    + "\" class=\"btn btn-primary\">\n												<span class=\"fa fa-edit\"></span>\n												"
    + alias2(helpers.tr.call(alias3,"button.edit_form",{"name":"tr","hash":{},"data":data}))
    + "\n											</a>\n										</div>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isModal : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    <button type=\"submit\" class=\"btn btn-primary ml-auto\" form=\"campaign-create-form\">\n                        <span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                    </button>\n				</div>\n			</div>\n		</form>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <input type=\"hidden\" name=\""
    + alias2(alias1((data && data.key), depth0))
    + "\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "selected";
},"7":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"9":function(container,depth0,helpers,partials,data) {
    return "checked";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "													<div class=\"custom-control custom-checkbox\">\n														<input type=\"checkbox\" class=\"custom-control-input\" id=\"agents-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\" value=\"true\" name=\"agents["
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "]\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.assigned : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n														<label class=\"custom-control-label\" for=\"agents-"
    + alias2(alias1((depth0 != null ? depth0.ID : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.displayName : depth0), depth0))
    + "</label>\n													</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"/campaigns/index\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n                            <span class=\"fa fa-times\"></span>&nbsp;"
    + container.escapeExpression(helpers.tr.call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n                        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.extend.call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n";
},"useData":true})