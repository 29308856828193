<template>
	<div>
		{{fileName}} <br>
		Uploading: {{progress}}%
	</div>
</template>

<script>
export default {
	props: {
		fileName: String,
		progress: Number
	}
};
</script>

<style lang="scss" scoped>

</style>
