module.exports = (window.Handlebars["default"] || window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "mb-0";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <label class=\"col-12 col-form-label "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" for=\""
    + container.escapeExpression(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\">\n            "
    + ((stack1 = container.invokePartial(partials.formLabel,depth0,{"name":"formLabel","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " col-md-2 col-form-label control-label text-left text-md-right";
},"6":function(container,depth0,helpers,partials,data) {
    return " col-md-6";
},"8":function(container,depth0,helpers,partials,data) {
    return "form-control-plaintext";
},"10":function(container,depth0,helpers,partials,data) {
    return "form-control";
},"12":function(container,depth0,helpers,partials,data) {
    return "autofocus";
},"14":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"16":function(container,depth0,helpers,partials,data) {
    return "readonly";
},"18":function(container,depth0,helpers,partials,data) {
    return "placeholder=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.placeholder : depth0), depth0))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"col-12\">\n    <div class=\"form-group row "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.plain : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"col-6"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nested : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <input type=\""
    + alias3(alias2((depth0 != null ? depth0.type : depth0), depth0))
    + "\" name=\""
    + alias3(helpers.array_to_path.call(alias1,(depth0 != null ? depth0.parentPath : depth0),(depth0 != null ? depth0.key : depth0),{"name":"array_to_path","hash":{},"data":data}))
    + "\" value=\""
    + alias3(alias2((depth0 != null ? depth0.value : depth0), depth0))
    + "\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.plain : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\" autocomplete=\"off\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.autofocus : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.disabled : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readonly : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.placeholder : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        </div>\n        <div class=\"col-6\">\n            <button class=\"btn btn-sm btn-success float-right phonenumber-button\" data-extension=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.session)) && stack1.extension), depth0))
    + "\" data-number=\""
    + alias3(alias2((depth0 != null ? depth0.value : depth0), depth0))
    + "\">\n                <span class=\"fa fa-phone\"></span>&nbsp;\n                "
    + alias3(helpers.tr.call(alias1,"Dialer:button.call",{"name":"tr","hash":{},"data":data}))
    + "\n            </button>\n        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true})