<template>
	<div class="facebook-chat-wrapper">
		<div class="card" v-if="loaded && facebookAccounts.length">
			<div class="card-header">
				<span class="fab fa-facebook-messenger"></span>
				{{ tr("FacebookChat:menu_entries.chat") }}
			</div>
			<div class="card-block">
				<Multipane class="facebook-chat-split">
					<div class="facebook-chat-chats-pane border-right" style="width: 24%; min-width: 250px;">
						<router-view name="chatList" :chats="chats"></router-view>
					</div>
					<MultipaneResizer/>
					<div
						class="facebook-chat-messages-pane bg-light border-right"
						style="flex-grow: 1; flex-shrink: 0; min-width: 600px;"
					>
						<router-view
							name="chatView"
							:chats="chats"
							ref="chatView"
						/>
					</div>
				</Multipane>
			</div>
		</div>
		<div v-else>
			<div v-if="!loaded" class="text-center">
				<span class="fas fa-spin fa-spinner"></span>
			</div>
			<div
				class="alert alert-danger text-center"
				v-else
			>{{ tr("FacebookChat:label.no_accounts_available") }}</div>
		</div>
	</div>
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import { processChat, processMessage } from '../js/util.js';

export default {
	components: {
		Multipane,
		MultipaneResizer
	},
	data() {
		return {
			loaded: false,
			facebookAccounts: null,
			chats: []
		};
	},
	computed: {
		chatID() {
			if (this.$route.params.chatID) {
				return parseInt(this.$route.params.chatID);
			} else {
				return null;
			}
		}
	},
	methods: {
		async init(facebookChat) {
			this.facebookAccounts = facebookChat.facebookAccounts;
			const chats = await Api.get('/api/facebookChat/chats');
			this.chats = chats.map(processChat);
			this.loaded = true;
			facebookChat.on('newMessage', rawMessage => {
				const message = processMessage(rawMessage);
				const entityID = message.entityID;
				const existing = this.chatByEntityID(entityID);
				if (existing) {
					existing.lastMessage = message;
				} else {
					Api.get(`/api/facebookChat/chats/${entityID}`)
						.then(chat => {
							this.chats.unshift(processChat(chat));
						});
				}

				if (this.chatID === entityID && this.$refs.chatView) {
					this.$refs.chatView.newMessage(message);
				}
			});
		},
		chatByEntityID(entityID) {
			return this.chats.find(chat => chat.entityID === entityID);
		}
	},
	created() {
		const facebookChat = window.BootQuery.getModuleInstance('FacebookChat');
		if (facebookChat.loaded) {
			this.init(facebookChat);
		} else {
			facebookChat.on('loaded', () => this.init(facebookChat));
		}
	}
};
</script>

<style lang="scss" scoped>
.facebook-chat-wrapper {
	height: calc(100vh - 88px);
}

.facebook-chat-wrapper {
	> .card,
	> .card > .card-block,
	.facebook-chat-split {
		min-height: 0;
		height: 100%;
	}
}

.facebook-chat-split > * {
	height: 100%;
}
</style>
