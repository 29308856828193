module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?"
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-callerPhoneNumber_like_alnum="
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "&"
    + ((stack1 = container.invokePartial(partials.encode_url,depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n		<span class=\"fa fa-filter\"></span>\n		&nbsp;"
    + alias2(helpers.tr.call(alias3,"Calls:button.show_calls_from_number",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.controller), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.bootquery)) && stack1.method), depth0))
    + "/?"
    + alias2(alias1((depth0 != null ? depth0.tableName : depth0), depth0))
    + "-calleePhoneNumber_like_alnum="
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "&"
    + ((stack1 = container.invokePartial(partials.encode_url,depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n		<span class=\"fa fa-filter\"></span>\n		&nbsp;"
    + alias2(helpers.tr.call(alias3,"Calls:button.show_calls_to_number",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/phonebook/saveNumberToPerson/?fullPhoneNumber="
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\" style=\"width: 100%; text-align: left;\"\n		data-modal-link=\"/phonebook/saveNumberToPerson/?fullPhoneNumber="
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\" data-modal-link-module=\"Phonebook\" data-modal-heading=\".bq-content .card > .card-header\"\n		data-modal-body=\".bq-content .card > .card-body\" data-modal-footer=\".bq-content .card > .card-footer\" data-modal-reload=\"true\">\n		<span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"Phonebook:button.save_number_to_person",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/phonebook/saveNumberToCompany/?fullPhoneNumber="
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\" style=\"width: 100%; text-align: left;\"\n		data-modal-link=\"/phonebook/saveNumberToCompany/?fullPhoneNumber="
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\" data-modal-link-module=\"Phonebook\" data-modal-heading=\".bq-content .card > .card-header\"\n		data-modal-body=\".bq-content .card > .card-body\" data-modal-footer=\".bq-content .card > .card-footer\" data-modal-reload=\"true\">\n		<span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(helpers.tr.call(alias3,"Phonebook:button.save_number_to_company",{"name":"tr","hash":{},"data":data}))
    + "\n	</a>\n	<br>\n	<button class=\"btn btn-success btn-block text-center clickvox-dial-button\" data-number=\""
    + alias2(alias1((depth0 != null ? depth0.numberE164 : depth0), depth0))
    + "\">\n		<span class=\"fa fa-phone\"></span> "
    + alias2(helpers.tr.call(alias3,"Dialer:button.call",{"name":"tr","hash":{},"data":data}))
    + " "
    + alias2(alias1((depth0 != null ? depth0.phoneNumber : depth0), depth0))
    + "\n	</button>\n</div>";
},"usePartial":true,"useData":true})