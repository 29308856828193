<template>
	<button :id="`gsm-${context}-tracker-${trackerID}-map-btn`" :class="buttonClass">
		<span class="fas fa-map-marked-alt"></span>
		<bPopover
			:target="`gsm-${context}-tracker-${trackerID}-map-btn`"
			:trigger="['click', 'blur']"
			placement="left"
			@show="fetchLocationInfo"
		>
			<template v-if="lastLocationFetched">
				<template v-if="lastLocation">
					<span class="text-muted">Podatak od: {{lastLocation.date | formatDate(tr('format.datetime'))}}</span>
					<a
						:href="`https://maps.google.com/?q=${lastLocation.lat},${lastLocation.lon}`"
						target="_blank"
						class="btn btn-primary btn-block"
					>
						Otvori na karti
						<span class="fas fa-arrow-right"></span>
					</a>
				</template>
				<button
					type="button"
					class="btn btn-primary btn-sm btn-block"
					@click="requestLocation"
					:disabled="requesting"
				>
					<span v-if="requesting" class="spinner-border spinner-border-sm"></span>
					<span v-else class="fas fa-sync-alt"></span>
					Zatrazi lokaciju
				</button>
			</template>
			<div v-else class="spinner-grow"></div>
		</bPopover>
	</button>
</template>

<script>
import bPopover from 'bootstrap-vue/es/components/popover/popover';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';

export default {
	components: {
		bPopover
	},
	props: {
		trackerID: {
			type: Number,
			required: true
		},
		context: {
			type: String,
			required: true
		},
		buttonClass: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			lastLocation: null,
			lastLocationFetched: false,
			requesting: false,
			locEventHandle: null
		};
	},
	methods: {
		requestLocation() {
			Api.post(
				`/api/gsmTrackers/trackers/${this.trackerID}/requestLocation/`
			);
			this.requesting = true;
		},
		onLocation(location) {
			this.requesting = false;
			this.lastLocation = location;
		},
		async fetchLocationInfo() {
			this.lastLocationFetched = false;
			const loc = await Api.get(
				`/api/gsmTrackers/trackers/${this.trackerID}/locations/last`
			);
			if (loc) {
				this.lastLocation = loc;
			}
			this.lastLocationFetched = true;
		}
	},
	created() {
		const module = window.BootQuery.getModuleInstance('GsmTrackers');
		this.locEventHandle = module.subscribeWebSocket(
			`gsmTrackers/trackers/${this.trackerID}/location`,
			this.onLocation.bind(this)
		);
	},
	beforeDestroy() {
		if (this.locEventHandle) {
			const module = window.BootQuery.getModuleInstance('GsmTrackers');
			module.unsubscribeWebSocket(this.locEventHandle);
		}
	}
};
</script>

<style>
</style>
