module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<span class=\"user-login-display\">\n    <span class=\"user-presence-set\">\n        <span class=\"\n            text-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.settings : stack1)) != null ? stack1.color : stack1), depth0))
    + "\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.settings : stack1)) != null ? stack1.icon : stack1), depth0))
    + "\">\n        </span>\n    </span>\n    <span>&nbsp;"
    + alias2(alias1((depth0 != null ? depth0.username : depth0), depth0))
    + "</span>\n</span>";
},"useData":true})