module.exports = (window.Handlebars["default"] || window.Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n    <div class=\"modal-dialog modal-lg\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\">\n                    <span class=\"fas fa-list-alt\"></span> "
    + alias1(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "\n                </h5>\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n                    <span aria-hidden=\"true\">&times;</span>\n                </button>\n            </div>\n            <div class=\"modal-body\">\n                <select class=\"ticket-picker\"></select>\n            </div>\n            <div class=\"modal-footer d-flex\">\n                <button type=\"button\" class=\"ml-auto btn btn-link text-danger\" data-dismiss=\"modal\">\n                    "
    + alias1(helpers.tr.call(alias2,"button.cancel",{"name":"tr","hash":{},"data":data}))
    + "\n                </button>\n                <button type=\"submit\" class=\"btn btn-success from-ticket-call-btn\" disabled>\n                    <span class=\"fa fa-check\"></span> "
    + alias1(helpers.tr.call(alias2,"button.save",{"name":"tr","hash":{},"data":data}))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true})