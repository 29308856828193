import Module from 'BootQuery/Assets/js/module.js';
import * as Api from 'BootQuery/Assets/js/apiRequest.js';
import Vue from 'BootQuery/Assets/js/vue.js';

import SMSComponent from '../components/SMS.vue';
import getRouter from './router.js';

export default class Sms extends Module {
	init(_data) {
		super.init();
		this.accounts().then(accounts => {
			if (accounts.length) {
				this.bindEvents();
			}
		});
	}

	bindEvents() {
		this.subscribeWebSocket(
			'sms/messageReceieved',
			this.onMessage.bind(this)
		);
		this.subscribeWebSocket(
			'sms/messageSend',
			this.onMessage.bind(this)
		);
		this.subscribeWebSocket(
			'sms/messageStatus',
			this.onMessageStatus.bind(this)
		);
	}

	onMessage(message) {
		message.date = new Date(message.date);
		this.emit('newMessage', message);
	}

	onMessageStatus(status) {
		this.emit('messageStatus', status);
	}

	activateElements(target, _data) {
		const $container = target.findElement('#sms-container');
		if ($container.length) {
			this.renderInterface($container);
		}
	}

	async renderInterface($container) {
		const container = $container[0];
		if(container.dataset.activated) {
			return; // Don't double-render
		}
		container.dataset.activated = true;
		this.component = new Vue({
			el: container,
			router: getRouter(),
			render: h => h(SMSComponent)
		});
	}

	async accounts() {
		if (!this._accounts) {
			this._accounts = await Api.get(
				'/api/sms/accounts'
			);
		}
		return this._accounts;
	}

	renderSmsRoute() {
		const target = $('<div/>', {id: 'sms-container'});
		$(window.targetElement).html(target);
		this.renderInterface(target);
		$(document).trigger(
			'activateElements',
			[$(window.targetElement), window.Bootstrap]
		);
	}

	handleRoute(route) {
		if (route.startsWith('/sms')) {
			$(document).trigger(
				'renderController',
				[window.targetElement, window.Bootstrap]
			);
			this.renderSmsRoute();
			return;
		}
		throw new Error(`Don't know how to handle route: '${route}'`);
	}

	static canHandleRoute(route) {
		if (route.startsWith('/sms')) {
			return true;
		}
		return false;
	}
}