<template>
    <Modal @close="$emit('close')">
        <template slot="title">
            {{ tr("Mails:label.assign_to_ticket") }}
        </template>

        <TicketPicker v-model="value" />

        <template slot="footer">
            <button
                type="button"
                class="btn btn-link text-danger ml-auto"
                :disabled="isSubmitting"
                @click.prevent="$emit('close')"
            >{{ tr("button.cancel") }}</button>
            <button
                type="button"
                class="btn btn-success"
                :disabled="!canSubmit || isSubmitting"
                @click.prevent="submit"
            >
                <span class="fa fa-paper-plane"></span>
                {{ tr('button.send') }}
            </button>
        </template>
    </Modal>
</template>

<script>
import TicketPicker from 'app/Modules/Ticketing/Assets/components/TicketPicker.vue';
import Modal from 'BootQuery/Assets/js/bootstrap-components/Modal.vue';
export default {
	components: {
		TicketPicker,
		Modal
	},
	data() {
		return {
			value: null,
			isSubmitting: false
		};
	},
	computed: {
		canSubmit() {
			return this.value !== null;
		}
	},
	methods: {
		submit() {
			if (!this.canSubmit) {
				return;
			}
			this.isSubmitting = true;
			this.$emit('submit', this.value);
		}
	}
};
</script>

<style>
</style>
